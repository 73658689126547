import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetAppData } from '../../app/app-reducer';
import { ContactDetail } from '../../constants/user-profile';
import { maskHelper } from '../../utils';

export interface Contact {
  contactPreference: string;
  contactValue: string;
  contactType: string;
  verificationStatus: string;
  guid: string;
  notificationInd: boolean;
}

export interface BillingAddress {
  addressName: string;
  guid: string;
  countryCode: string;
}

export interface PaymentInstrument {
  panGuid?: string;
  panId?: string;
  expirationMonth?: string;
  expirationYear?: string;
  billingAddress?: BillingAddress;
  accountNumberLastFourDigits?: string;
  cardVerificationStatus?: string;
  cvv2VerificationStatus?: string;
  verificationServiceProvider?: string;
  sequenceNumber?: number | undefined | null;
  countryOfIssuance?: string;
  primary?: boolean;
  cardOwnerVerificationStatus?: boolean;
  nameOnCard?: string;
  financialAccountType?: string;
  productBrandCode?: string;
  cardNickname?: string;
}

export interface VUser {
  firstName: string;
  middleName: string;
  suffixName: string;
  lastName: string;
  defaultTimezone: string;
  countryCode: string;
  languageCode: string;
  locale: string;
  phoneNumber?: string;
  guid?: string;
  contacts: Contact[];
}

export enum Tabs {
  ALERTS = 'ALERTS',
  ALERTS_HISTORY = 'ALERTS_HISTORY',
  CARD_CONTROL = 'CARD_CONTROL'
}
export interface UserProfile {
  isUserDetailLoading?: boolean;
  vuser?: VUser;
  paymentInstruments?: PaymentInstrument[];
  numberOfCards?: number | null;
  selectedCard: PaymentInstrument | null;
  isVerifiedContactAdded?: boolean;
  selectedTab?: Tabs;
  isProfileDeleted?: boolean;
  isPageReload?: boolean;
}

const initialState: UserProfile = {
  isUserDetailLoading: true,
  vuser: {
    firstName: '',
    middleName: '',
    suffixName: '',
    lastName: '',
    defaultTimezone: '',
    countryCode: '',
    languageCode: '',
    locale: '',
    phoneNumber: '',
    guid: '',
    contacts: []
  },
  paymentInstruments: [],
  numberOfCards: 0,
  selectedCard: null,
  isVerifiedContactAdded: false,
  selectedTab: Tabs.ALERTS,
  isProfileDeleted: false,
  isPageReload: false
};

const extractPhoneNumber = (userDetailsData: any) => {
  const existingContacts = userDetailsData?.vuser?.contacts;

  const verifiedContact = existingContacts?.find(item => {
    if (item?.verificationStatus === ContactDetail.VERIFIED && item?.contactType === ContactDetail.PHONE) {
      return item;
    }
  });

  const unVerifiedContact = existingContacts?.find(item => {
    if (item?.verificationStatus === ContactDetail.UNVERIFIED && item?.contactType === ContactDetail.PHONE) {
      return item;
    }
  });

  return {
    phone: verifiedContact
      ? maskHelper.phone(verifiedContact?.contactValue) || ''
      : maskHelper.phone(unVerifiedContact?.contactValue) || '',
    guid: verifiedContact ? verifiedContact?.guid || '' : unVerifiedContact?.guid || '',
    isVerifiedContactAdded: !!verifiedContact
  };
};

const userDetailsReducer = createSlice({
  name: 'userDetails',
  initialState,
  reducers: {
    getUserDetails: (state: UserProfile) => {
      return state;
    },
    getUserDetailsSuccess: (state: UserProfile, action) => {
      state.vuser = action?.payload?.vuser;
      return state;
    },
    getUserDetailsFailure: (state: UserProfile, action) => {
      state.vuser = action?.payload?.vuser;
      return state;
    },
    getPaymentInstruments: (state: UserProfile, action) => {
      return state;
    },
    getPaymentInstrumentsSuccess: (state: UserProfile, action) => {
      state.isUserDetailLoading = false;
      const paymentInstruments = action.payload?.paymentInstruments;
      state.paymentInstruments = paymentInstruments;
      if (!paymentInstruments || !paymentInstruments.length) state.selectedCard = null;
      else {
        const previousSelectedCard = paymentInstruments?.find(card => {
          return card.accountNumberLastFourDigits === state.selectedCard?.accountNumberLastFourDigits;
        });
        state.selectedCard = previousSelectedCard ? previousSelectedCard : paymentInstruments[0];
      }
      state.numberOfCards = (paymentInstruments || []).length;
      return state;
    },
    getPaymentInstrumentsFailure: (state: UserProfile, action) => {
      state.isUserDetailLoading = false;
    },
    setPhoneNumber: (state: UserProfile, action: PayloadAction<any>) => {
      const contact = extractPhoneNumber(action.payload);
      return {
        ...state,
        phoneNumber: contact.phone,
        guid: contact.guid,
        isVerifiedContactAdded: contact?.isVerifiedContactAdded
      };
    },
    deleteProfile: (state: UserProfile, action) => {
      return;
    },
    deleteProfileSuccess: (state: UserProfile) => {
      state.isProfileDeleted = true;
      return state;
    },
    // to get selected card details
    setSelectedCard: (state: UserProfile, action) => {
      state.selectedCard = action.payload.card;
      return state;
    },
    setSelectedTab: (state: UserProfile, action) => {
      state.selectedTab = action?.payload;
      return state;
    },
    resetPaymentInstruments: (state: UserProfile) => {
      state.isUserDetailLoading = true;
      state.paymentInstruments = [];
      state.selectedCard = null;
      state.numberOfCards = 0;
    },
    //after phone number is deleted from profile on page reload, update the phone component with latest contact details
    removeDeletedContactFromProfile: (state: UserProfile, action) => {
      state.vuser?.contacts.splice(
        state.vuser.contacts.findIndex(contact => contact.guid === action.payload),
        1
      );
      return state;
    },
    resetUserDetails: (state: UserProfile) => {
      return initialState;
    },
    setReloadFlag: (state: UserProfile, action) => {
      state.isPageReload = action.payload;
      return state;
    }
  },
  extraReducers: builder => {
    builder.addCase(resetAppData, () => {
      return initialState;
    });
  }
});

export const {
  getUserDetails,
  getUserDetailsSuccess,
  getUserDetailsFailure,
  setPhoneNumber,
  deleteProfile,
  getPaymentInstruments,
  getPaymentInstrumentsSuccess,
  setSelectedCard,
  setSelectedTab,
  getPaymentInstrumentsFailure,
  resetPaymentInstruments,
  deleteProfileSuccess,
  removeDeletedContactFromProfile,
  resetUserDetails,
  setReloadFlag
} = userDetailsReducer.actions;

export default userDetailsReducer.reducer;
