import { call, put, select, takeLatest } from 'redux-saga/effects';
import { RedirectRoutes } from '../../../../constants';
import { showAlert } from '../../../../elements/shared';
import { ajaxHandler, apiPaths } from '../../../../setup';
import { Utils } from '../../../../utils';
import { setSelectedTab, Tabs } from '../../home-reducer';
import {
  getCardControls,
  getCardControlsSuccess,
  updateCardControls,
  updateCardControlsSuccess
} from './card-control-reducer';

function* getCardControlHandler(action) {
  const { userAuth, userDetails } = yield select((state: any) => state);
  const headers = {
    access_token: userAuth.accessToken
  };
  const getCardControlURL = Utils.replacePaymentGuid(apiPaths.cardcontrol.url, userDetails.selectedCard.panGuid);
  const response = yield call(ajaxHandler, getCardControlURL, false, {
    headers,
    method: 'GET',
    urlWithPlaceholder: apiPaths.cardcontrol.url
  });
  yield put(getCardControlsSuccess({ isCardControlSupported: !!response.data, data: response.data }));
  if (!response.data && userDetails.selectedTab === Tabs.CARD_CONTROL && action.payload.navigate) {
    //  if selected tab on home is card-control and card doesn't support card-control
    //  redirect to alerts tab
    yield put(setSelectedTab(Tabs.ALERTS));
    action.payload.navigate(RedirectRoutes.homeAlerts);
  }
}

function* updateCardControlHandler(action) {
  const { userAuth, userDetails, cardControls } = yield select((state: any) => state);
  const headers = {
    access_token: userAuth.accessToken
  };
  const getCardControlURL = Utils.replacePaymentGuid(apiPaths.cardcontrol.url, userDetails.selectedCard.panGuid);
  const response = yield call(ajaxHandler, getCardControlURL, false, {
    headers,
    method: 'PUT',
    data: {
      vctcControls: action.payload.request
    },
    urlWithPlaceholder: apiPaths.cardcontrol.url
  });
  if (!response.error) {
    let successMsg = '';
    if (action.payload.cardLockAction == 'other') {
      successMsg = 'cardControl.success.otherMsg';
    }
    if (action.payload.cardLockAction == 'lock') {
      successMsg = 'cardControl.success.message';
    }
    if (action.payload.cardLockAction == 'unlock') {
      successMsg = 'cardControl.success.unlockMessage';
    }
    yield put(updateCardControlsSuccess(response.data));
    yield put(showAlert({ type: 'SUCCESS', message: successMsg }));
  } else {
    yield put(showAlert({ type: 'ERROR', message: 'cardControl.errors.unavailable_alert' }));
  }
}

export default function* watchCardControls() {
  yield takeLatest(getCardControls, getCardControlHandler);
  yield takeLatest(updateCardControls, updateCardControlHandler);
}
