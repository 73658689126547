import i18next from 'i18next';
import moment from 'moment';
import { ContactDetail } from '../constants/user-profile';
import { GlobalConstants, LocaleCookieUrlMapping, RedirectRoutes } from '../constants';
import { clearAlert, showAlert } from '../elements/shared';
import { postRevocation, resetAppData } from '../app/app-reducer';
import { resetSuccessFlags } from '../pages/sign-in/sign-in/sign-in-reducer-slice';
import { resetUserAuth } from '../auth/user-auth-reducer';
import { setSelectedCard } from '../pages/home/home-reducer';
import { maskHelper } from './masking-helper';

declare let VAAP: any;
let codeChallange: any;

export const createCodeChallange = () => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  const charactersLength = characters.length;
  for (let i = 0; i < 48; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  codeChallange = result;
  return result;
};

export const getCodeChallange = () => {
  return codeChallange;
};

export const countryList = [
  { label: 'Anguilla', value: 'AI', A3: 'AIA', num: 660 },
  { label: 'AntiguaAndBarbuda', value: 'AG', A3: 'ATG', num: 28 },
  { label: 'Argentina', value: 'AR', A3: 'ARG', num: 32 },
  { label: 'Aruba', value: 'AW', A3: 'ABW', num: 533 },
  { label: 'Bahamas', value: 'BS', A3: 'BHS', num: 44 },
  { label: 'Barbados', value: 'BB', A3: 'BRB', num: 52 },
  { label: 'Belize', value: 'BZ', A3: 'BLZ', num: 84 },
  { label: 'Bermuda', value: 'BM', A3: 'BMU', num: 60 },
  { label: 'Bolivia', value: 'BO', A3: 'BOL', num: 68 },
  { label: 'Bonaire', value: 'BQ', A3: 'BES', num: 535 },
  { label: 'Brazil', value: 'BR', A3: 'BRA', num: 76 },
  { label: 'BritishVirginIslands', value: 'VG', A3: 'VGB', num: 92 },
  { label: 'CaymanIslands', value: 'KY', A3: 'CYM', num: 136 },
  { label: 'Chile', value: 'CL', A3: 'CHL', num: 152 },
  { label: 'Colombia', value: 'CO', A3: 'COL', num: 170 },
  { label: 'CostaRica', value: 'CR', A3: 'CRI', num: 188 },
  { label: 'Curacao', value: 'CW', A3: 'CUW', num: 531 },
  { label: 'Dominica', value: 'DM', A3: 'DMA', num: 212 },
  { label: 'DominicanRepublic', value: 'DO', A3: 'DOM', num: 214 },
  { label: 'Ecuador', value: 'EC', A3: 'ECU', num: 218 },
  { label: 'ElSalvador', value: 'SV', A3: 'SLV', num: 222 },
  { label: 'Grenada', value: 'GD', A3: 'GRD', num: 308 },
  { label: 'Guadeloupe', value: 'GP', A3: 'GLP', num: 312 },
  { label: 'Guatemala', value: 'GT', A3: 'GTM', num: 320 },
  { label: 'Guyana', value: 'GY', A3: 'GUY', num: 328 },
  { label: 'Haiti', value: 'HT', A3: 'HTI', num: 332 },
  { label: 'Honduras', value: 'HN', A3: 'HND', num: 340 },
  { label: 'Jamaica', value: 'JM', A3: 'JAM', num: 388 },
  { label: 'Martinique', value: 'MQ', A3: 'MTQ', num: 474 },
  { label: 'Mexico', value: 'MX', A3: 'MEX', num: 484 },
  { label: 'Montserrat', value: 'MS', A3: 'MSR', num: 500 },
  { label: 'Nicaragua', value: 'NI', A3: 'NIC', num: 558 },
  { label: 'Panama', value: 'PA', A3: 'PAN', num: 591 },
  { label: 'Paraguay', value: 'PY', A3: 'PRY', num: 600 },
  { label: 'Peru', value: 'PE', A3: 'PER', num: 604 },
  { label: 'PuertoRico', value: 'PR', A3: 'PRI', num: 630 },
  { label: 'SaintLucia', value: 'LC', A3: 'LCA', num: 662 },
  { label: 'StKittsAndNevis', value: 'KN', A3: 'KNA', num: 659 },
  { label: 'StMaarten', value: 'SX', A3: 'SXM', num: 534 },
  { label: 'StMartin', value: 'MF', A3: 'MAF', num: 663 },
  { label: 'StVincent', value: 'VC', A3: 'VCT', num: 670 },
  { label: 'Suriname', value: 'SR', A3: 'SUR', num: 740 },
  { label: 'TrinidadAndTobago', value: 'TT', A3: 'TTO', num: 780 },
  { label: 'TurksAndCaicos', value: 'TC', A3: 'TCA', num: 796 },
  { label: 'UnitedStates', value: 'US', A3: 'USA', num: 840 },
  { label: 'USVirginIslands', value: 'VI', A3: 'VIR', num: 850 },
  { label: 'Uruguay', value: 'UY', A3: 'URY', num: 858 }
];

export const getCountryList = () => {
  const sortedCountryList = JSON.parse(JSON.stringify(countryList))
    .map(country => {
      country.label = i18next.t(`countryList.${country.label}`);
      return country;
    })
    .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));
  return sortedCountryList;
};

export const getCountryNumber = (code: string) => {
  let num: number | undefined;
  countryList.forEach(country => {
    if (country.value === code) num = country.num;
  });
  return num;
};

export const isLac = () => {
  const result = getCountryNumber(getCountryCode());
  return result !== 840;
};

export const isVI = () => {
  const result = getCountryNumber(getCountryCode());
  return result === 850;
};

export const isUS = () => {
  const result = getCountryNumber(getCountryCode());
  return result === 840;
};
export const getCookie = function (cname) {
  const name = cname + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};
export const decodeBase64Url = base64Urlencoded => {
  let base64encoded = base64Urlencoded.replace('-', '+');
  base64encoded = base64encoded.replace('_', '/');
  return window.atob(base64encoded);
};

export const getRememberMeCookie = function () {
  const encoded = getCookie('remember_me');
  if (!encoded || encoded === '') {
    return null;
  }
  let result = null;
  try {
    result = JSON.parse(decodeBase64Url(encoded)).la.lm;
  } catch (e) {}
  return result;
};
export const encodeUsingUrlEncoding = (data: any) => {
  if (typeof data === 'string') {
    return data;
  }

  const result: any = [];
  for (const dataItem in data) {
    if (data.hasOwnProperty(dataItem)) {
      result.push(encodeURIComponent(dataItem) + '=' + encodeURIComponent(data[dataItem]));
    }
  }

  return result.join('&');
};

export type GenericDataType = () => {
  [key: string]: any;
};

export const getRequestCountry = () => {
  let requestCountry: HTMLInputElement | string | null = <HTMLInputElement>document.getElementById('requestCountry');
  requestCountry = requestCountry?.getAttribute('value');
  let requestCountryOverrideHeader: HTMLInputElement | string | null = <HTMLInputElement>(
    document.getElementById('requestCountryOverride')
  );
  requestCountryOverrideHeader = requestCountryOverrideHeader?.getAttribute('value');

  if (requestCountryOverrideHeader) {
    requestCountry = requestCountryOverrideHeader;
  }

  try {
    if (requestCountry) {
      requestCountry = window.atob(requestCountry);
    }
  } catch (err) {
    console.log(err);
    return;
  }

  const requestCountryExist = countryList.find(country => country.value === requestCountry);
  return requestCountryExist && requestCountryExist.value;
};

export const getCountryCode = () => {
  return localStorage.getItem('country_pref') || getRequestCountry() || 'US';
};

export const setCountryCode = (code: string) => {
  localStorage.setItem('country_pref', code);
};

export const getLocale = () => {
  return localStorage.getItem('i18nextLng');
};

export const setLocale = (lang: string) => {
  i18next.changeLanguage(lang);
};

export const setDefaultLng = supportedLocales => {
  const defaultLng = supportedLocales.find(locale => locale.defaultLocale === 'Y');
  setLocale(defaultLng.locale); // place the default locale after successfull api call
};

export const getDefaultLng = supportedLocales => {
  const defaultLng = supportedLocales.find(locale => locale.defaultLocale === 'Y');
  return defaultLng.locale;
};

export const getCookieConsentHtmlUrl = () => {
  //getLocale() || 'en'] || 'en-gb'   ->>> if no locale default to en, if some random locale default the return to en-gb
  return `//policy.cookiereports.com/96c03034-${LocaleCookieUrlMapping[getLocale() || 'en'] || 'en-gb'}.html`;
};

export const getCookieConsentScriptUrl = locale => {
  return `//policy.cookiereports.com/96c03034_panel-${LocaleCookieUrlMapping[locale] || 'en-gb'}.js`;
};

export const loadScript = url => {
  if (url) {
    const script = document.createElement('script');
    script.setAttribute('type', 'text/javascript');
    script.setAttribute('src', url);
    document.body.appendChild(script);
  }
};

export const replaceUserGuid = (path: string, guid: string) => path.replace('--userGuid--', guid);

export const replacePaymentGuid = (path: string, paymentGuid: string) => path.replace('--paymentGuid--', paymentGuid);

export const getContactValue = (
  userDetails: Record<string, any>,
  contactPreference: ContactDetail.PRIMARY | ContactDetail.SECONDARY,
  contactCategory: ContactDetail.EMAIL | ContactDetail.PHONE,
  withoutMask?: boolean,
  sendGuid?: boolean //  in case if need contact guid value
) => {
  const defaultEmail = GlobalConstants.emailDummyValue;
  const contacts = userDetails.contacts;
  if (Array.isArray(contacts) && Boolean(contacts.length)) {
    const primaryEmailContact = contacts.find(
      contact => contact.contactPreference == contactPreference && contact.contactType == contactCategory
    );
    let emailContact = primaryEmailContact && primaryEmailContact.contactValue;
    if (!withoutMask) {
      emailContact = maskHelper.email(emailContact);
    }
    if (sendGuid) {
      return primaryEmailContact.guid;
    }
    return primaryEmailContact ? emailContact : defaultEmail;
  } else {
    return defaultEmail;
  }
};

export const getStates = () => {
  return [
    { value: 'AL', label: 'Alabama' },
    { value: 'AK', label: 'Alaska' },
    { value: 'AS', label: 'American Samoa' },
    { value: 'AZ', label: 'Arizona' },
    { value: 'AR', label: 'Arkansas' },
    { value: 'CA', label: 'California' },
    { value: 'CO', label: 'Colorado' },
    { value: 'CT', label: 'Connecticut' },
    { value: 'DE', label: 'Delaware' },
    { value: 'DC', label: 'District of Columbia' },
    { value: 'FL', label: 'Florida' },
    { value: 'GA', label: 'Georgia' },
    { value: 'GU', label: 'Guam' },
    { value: 'HI', label: 'Hawaii' },
    { value: 'ID', label: 'Idaho' },
    { value: 'IL', label: 'Illinois' },
    { value: 'IN', label: 'Indiana' },
    { value: 'IA', label: 'Iowa' },
    { value: 'KS', label: 'Kansas' },
    { value: 'KY', label: 'Kentucky' },
    { value: 'LA', label: 'Louisiana' },
    { value: 'ME', label: 'Maine' },
    { value: 'MD', label: 'Maryland' },
    { value: 'MA', label: 'Massachusetts' },
    { value: 'MI', label: 'Michigan' },
    { value: 'MN', label: 'Minnesota' },
    { value: 'MS', label: 'Mississippi' },
    { value: 'MO', label: 'Missouri' },
    { value: 'MT', label: 'Montana' },
    { value: 'NE', label: 'Nebraska' },
    { value: 'NV', label: 'Nevada' },
    { value: 'NH', label: 'New Hampshire' },
    { value: 'NJ', label: 'New Jersey' },
    { value: 'NM', label: 'New Mexico' },
    { value: 'NY', label: 'New York' },
    { value: 'NC', label: 'North Carolina' },
    { value: 'ND', label: 'North Dakota' },
    { value: 'MP', label: 'Northern Mariana Islands' },
    { value: 'OH', label: 'Ohio' },
    { value: 'OK', label: 'Oklahoma' },
    { value: 'OR', label: 'Oregon' },
    { value: 'PA', label: 'Pennsylvania' },
    { value: 'PR', label: 'Puerto Rico' },
    { value: 'RI', label: 'Rhode Island' },
    { value: 'SC', label: 'South Carolina' },
    { value: 'SD', label: 'South Dakota' },
    { value: 'TN', label: 'Tennessee' },
    { value: 'TX', label: 'Texas' },
    { value: 'UT', label: 'Utah' },
    { value: 'VT', label: 'Vermont' },
    { value: 'VI', label: 'Virgin Islands' },
    { value: 'VA', label: 'Virginia' },
    { value: 'WA', label: 'Washington' },
    { value: 'WV', label: 'West Virginia' },
    { value: 'WI', label: 'Wisconsin' },
    { value: 'WY', label: 'Wyoming' }
  ];
};

// to update server session id
export const updateServerSessionId = (response: string) => {
  const sessionId = response.replace(/\", \'/g, '');
  const elem = <HTMLInputElement>document.getElementById('serverSessionId');
  if (elem) elem.value = sessionId;
};
export const getServerSessionId = () => {
  return (<HTMLInputElement>document.getElementById('serverSessionId'))?.value || 'test';
};

export const getContactsbyType = (contacts, contactType) => {
  return contacts.filter(contact => {
    return contact.contactType === contactType;
  });
};

export const getUnverifiedSecondaryContactsbyType = (contacts, contactType) => {
  return contacts.filter(contact => {
    return (
      contact.contactPreference !== ContactDetail.PRIMARY &&
      contact.verificationStatus === ContactDetail.UNVERIFIED &&
      contact.contactType === contactType
    );
  });
};

export const mergeSchema = (...schemas) => {
  // multiple yup schema to merge into one for reference go to add-edit-card.tsx file line no : 75
  const [first, ...rest] = schemas;
  const merged = rest.reduce((mergedSchemas, schema) => mergedSchemas.concat(schema), first);
  return merged;
};

//method to show form alert for username , email or password
export const showFormAlertForField = (name: string, errors: any) => {
  return showAlert({
    type: 'ERROR',
    message: errors[name]?.message || '',
    formMode: true
  });
};

//  add/insert describedby field id into the inputfield's aria-describedby ids list
export const addDescribedbyId = (fieldId: string, describedBy?: string) => {
  if (describedBy) {
    //  get html element from document object
    const field: HTMLElement | null = document.getElementById(fieldId);
    if (field) {
      //  get existing id list
      const fieldDescribedBy = field.getAttribute('aria-describedby') || '';
      //  if describedby-id is not already there in the list, insert in it.
      if (!fieldDescribedBy?.includes(describedBy)) {
        field.setAttribute('aria-describedby', fieldDescribedBy + ' ' + describedBy);
      }
    }
  }
};

export const getIconNameFromServiceOffering = (serviceOfferingName: string) => {
  const offeringVSIconNameMap = {
    Gasoline: 'balance',
    Threshold: 'balance',
    CardNotPresent: 'device-mobile',
    CrossBorder: 'transit-airplane',
    Declined: 'card-off',
    GenericCredit: 'bill'
  };
  return offeringVSIconNameMap[serviceOfferingName];
};

export const debounce = (fn: Function, ms = 50) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
};

export const setFocusToElement = elementIdentifier => {
  const element = document.querySelector(elementIdentifier);
  if (element) {
    (element as HTMLElement)?.focus();
    return true;
  }
  return false;
};

export const getQueryPrameterByName = name => {
  const query = decodeURIComponent(window.location.search);
  let variables: any = [];
  let value = null;

  if (!query) {
    return null;
  }

  variables = query.substring(1).split('&');

  if (variables.length === 0) {
    return null;
  }

  variables.forEach(function (qParam) {
    if (qParam.split(/=(.+)?/)[0].toLowerCase() === name.toLowerCase()) {
      value = qParam.split(/=(.+)?/)[1];
    }
  });

  return value;
};

export const getPageTitle = label => {
  return `${i18next.t(`${label}`)} - ${i18next.t(`app.title.visaPurchaseAlerts`)}`;
};

export const executeAppTermination = dispatch => {
  hideServerAlert(dispatch);
  removeFromBrowserStorage();
  invalidateSession(dispatch);
};

export const removeFromBrowserStorage = () => {
  removeDataFromSessionStorage('IFL');
  removeDataFromSessionStorage('renewTokenTimer');
  removeDataFromSessionStorage('lastActiveTimeStamp');
  removeDataFromSessionStorage('data');
};

export const invalidateSession = (dispatch, expireSession?) => {
  !expireSession && dispatch(postRevocation()); // bug number : VMCP-14336
  window.webSDKInstance?.logout()
  dispatch(resetSuccessFlags());
  dispatch(resetUserAuth());
  dispatch(setSelectedCard({ card: null }));
  dispatch(resetAppData());
};

export const sortByRecentFirstOrder = notifications => {
  return notifications.sort(
    (a, b) => moment(b.notificationTimeStamp).valueOf() - moment(a.notificationTimeStamp).valueOf()
  );
};

export const isFeatureSupported = (checkEligibility, featureCode) => {
  const isFeatureSupportedForCountry =
    !!checkEligibility.countryConfiguration?.supportedFeatures?.find(feature => feature.featureCode === featureCode) ||
    false;

  return isFeatureSupportedForCountry;
};

// to get terms link
export const getTermsLink = isFullyLogged => {
  const countryCode = getCountryCode();
  const locale = getLocale();
  const url = `/${GlobalConstants.rootPath}/static/documents${RedirectRoutes.terms}/${countryCode}/${locale}/terms-locale.jsp?isAuth=${isFullyLogged}`;
  return url;
};

// to get faq link
export const getFaqLink = isFullyLogged => {
  const countryCode = getCountryCode();
  const locale = getLocale();
  const url = `/${GlobalConstants.rootPath}/static/documents${RedirectRoutes.faq}/${countryCode}/${locale}/faq.jsp?isAuth=${isFullyLogged}`;
  return url;
};

// removing query params from the url with not allow the i18next library to read the lang param from the url
// Thus storing the lang param from the url in localStoarge before removing it.
export const removeQueryParam = navigate => {
  if (!window.location.pathname.includes(RedirectRoutes.unsubscribe) && window.location.href.split('?').length > 1) {
    setTimeout(() => {
      navigate(window.location.pathname.split('/vca-web').pop());
    });
  }
};

export const hideServerAlert = dispatch => {
  dispatch(clearAlert());
};

//for now we are using this to hide GSM if we have to hide any of the GSM errors we can use this
export const hideAllAlerts = dispatch => {
  hideServerAlert(dispatch);
};
export const timestampToLocaleDateTime = timestamp => {
  if (!timestamp) {
    return '';
  }
  const d = new Date(timestamp);
  const locale = getLocale() || 'en-US';
  return d.toLocaleString(locale);
};

export const shouldStartPartialLoginSession = () => {
  const { pathname } = window.location;
  if (pathname.includes('accept-pending-tnc' || 'add-card')) return true;
  return false;
};

export const getStoredData = () => {
  return sessionStorage.getItem('data');
};

export const clearStoredData = () => {
  // this code is used incase if any route fails to render remove data that was stored to achieve reload functionality.
  if (getStoredData()) {
    setTimeout(() => {
      sessionStorage.removeItem('data');
    }, 5000);
  }
};

export const vbaTokenGenerator = () => {
  const vbaTokenPromise = new Promise(resolve => {
    if (VAAP && VAAP.getToken) {
      VAAP.getToken(function (e) {
        resolve(e);
      });
    }
  });
  return vbaTokenPromise;
};

export const scrollIntoView = el => {
  if (el && el.scrollIntoView) el.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
};

export const setDataInSessionStorage = (key: string, value: any) => {
  sessionStorage.setItem(key, JSON.stringify(value));
};

export const getDataFromSessionStorage = (key: string) => {
  const data = sessionStorage.getItem(key);
  return data && JSON.parse(data);
};

export const removeDataFromSessionStorage = (key: string) => {
  sessionStorage.removeItem(key);
};

export const sortSupportedLocales = supportedLocales => {
  if (!supportedLocales) return [];
  const result = [...supportedLocales].sort((a, b) => {
    if (a.locale < b.locale) return -1;
    if (a.locale < b.locale) return 1;
    return 0;
  });
  return result;
};

//Not removing this function for reference
export const isAuth2 = () => {
  return sessionStorage && sessionStorage.getItem('vimConFeatureToggleFlag') == 'true' ? false : true;
};

export const focusToH1 = () => {
  const element = document?.getElementsByTagName('h1');
  element?.[0]?.setAttribute('tabindex', '-1');
  element?.[0]?.focus();
  setTimeout(() => element?.[0]?.removeAttribute('tabindex'), 1000);
};
