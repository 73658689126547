import { put, takeLatest, call } from 'redux-saga/effects';
import { ajaxHandler, apiPaths } from '../../../setup';
import { showAlert } from '..';
import { replaceUserGuid } from '../../../utils/utils';
import {
  setAnotherEmail,
  anotherEmailFailure,
  anotherEmailSuccess,
  getAnotherEmail
} from './use-another-email-reducer';

function* setAnotherEmailHandler(action) {
  const { payload } = action;

  const data = {
    emailAddress: payload.email
  };
  const headers = {
    access_token: payload.state.accessToken
  };
  const anotherEmailURL = replaceUserGuid(apiPaths.emailUrl, payload.state.userGuid);

  const response = yield call(ajaxHandler, `${anotherEmailURL}/${payload.state.guid}`, false, {
    method: 'PUT',
    data,
    headers,
    urlWithPlaceholder: apiPaths.emailUrl
  });
  payload.setOpenEmailConfirmationDialog(null);
  if (!response.error) {
    const type = 'SUCCESS';
    const message = 'forgotPassword.codeSendSuccess';
    yield put(showAlert({ type, message }));
    //  extract new email guid from location header
    const locationStr: string = response.headers['location'] || 'dummylocation';
    const pathArr = locationStr.split('/');
    const newEmailGuid = pathArr[pathArr.length - 1];
    yield put(anotherEmailSuccess({ ...response.data, newEmailGuid }));

    if (payload.navigate) {
      payload.navigate(payload.redirectAfterSuccess);
    }
  } else {
    yield put(anotherEmailFailure(response.error));
    yield put(showAlert({ type: 'ERROR', message: response.error.errorMessage }));
  }
}
function* getAnotherEmailHandlerUnverified(action) {
  const { payload } = action;
  console.log(payload);
  const headers = {
    access_token: 'Bearer ' + payload.state.accessToken
  };
  const userEmail = replaceUserGuid(apiPaths.getUser, payload.state.userGuid);
  const response = yield call(ajaxHandler, `${userEmail}?contacts=${payload.verificationStatus.toLowerCase()}`, false, {
    headers,
    method: 'GET',
    urlWithPlaceholder: apiPaths.getUser
  });

  if (!response.error) {
    yield put(setAnotherEmail(payload));
  } else {
    yield put(anotherEmailFailure(response.error));
    yield put(showAlert({ type: 'ERROR', message: response.error.errorMessage }));
  }
}
export default function* changeEmailWatchers() {
  yield takeLatest(setAnotherEmail, setAnotherEmailHandler);
  yield takeLatest(getAnotherEmail, getAnotherEmailHandlerUnverified);
}
