import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@visa/vds';
import { ContactDetail, ContactEventType, NotificationTypes } from '../../../../constants/user-profile';
import { getContactValue } from '../../../../utils/utils';
import HomeComponent from '../home-panel/home-panel';
import { useTitle } from '../../../../hooks/use-document-title';
import { getAlertsHistory } from './alert-history-reducer';
import HistoryList from './history-list';
import styles from './alert-history.module.scss';

type AlertHistoryProps = {
  [key: string]: any;
};

const AlertHistory: React.FC<AlertHistoryProps> = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { userDetails, alertsHistory } = useSelector((store: any) => store);
  const alertHistoryCount = Boolean(alertsHistory.totalNotifications);
  useTitle('app.title.alertHistory');

  useEffect(() => {
    if (userDetails.selectedCard?.panGuid) {
      const config = {
        panGuid: userDetails.selectedCard?.panGuid,
        notificationType: NotificationTypes.TransactionAlert, //valid values are "System", "TransactionAlert", "AggregationAlert", "Community"
        contactType: [ContactEventType.EMAIL, ContactDetail.SMS],
        contactValue: getContactValue(userDetails, ContactDetail.PRIMARY, ContactDetail.EMAIL, true),
        phoneCountryCode: null,
        dateFrom: '', // if empty or null then BE will retrieve last 7 days
        dateTo: '', // if empty or null then BE will retrieve last 7 days
        last4: userDetails.selectedCard?.accountNumberLastFourDigits
      };
      // console.log('Payload', config);
      dispatch(getAlertsHistory(config));
    }
  }, [userDetails.selectedCard?.panGuid]);

  return (
    <HomeComponent pageTitle={t('alertsHistory.title')} pageSubTitle={alertHistoryCount && t('alertsHistory.subtitle')}>
      {alertsHistory.isError ? null : alertHistoryCount ? (
        <HistoryList alertHistory={alertsHistory.notifications} alertHistoryCount={alertsHistory.totalNotifications} />
      ) : (
        <EmptyAlertHistoryComponent />
      )}
    </HomeComponent>
  );
};

const EmptyAlertHistoryComponent = () => {
  const { t } = useTranslation();

  return (
    <div className={styles['none-container']}>
      <Typography className={styles['none-title']}>{t('alertsHistory.none')}</Typography>
    </div>
  );
};

AlertHistory.displayName = 'AlertHistory';

export default AlertHistory;
