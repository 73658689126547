import React from 'react';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import styles from './spinner.module.scss';

const Spinner: React.FC<{ show?: boolean }> = ({ show }) => {
  const spinnerStore = useSelector((store: any) => store.spinner);

  //We can use 'show' to override default state
  //<Spinner show={true}></Spinner>
  const [spinner, setSpinner] = useState(show || spinnerStore.show);

  React.useEffect(() => {
    setSpinner(spinnerStore.show);
  }, [spinnerStore.show]);

  return (
    <>
      {/* No need to use VPDS comopnents here for layout. Its a simple div covering whole page */}
      {spinner && (
        <div className="backdrop">
          <div className={styles['spinner-wrap']}>
            <div className={styles['spinner']}></div>
          </div>
        </div>
      )}
    </>
  );
};

export default Spinner;
