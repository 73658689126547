import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ErrorModalInterface {
  message: string;
  show: boolean;
  redirectTo: string;
}

const initialState: ErrorModalInterface = {
  message: '',
  show: false,
  redirectTo: ''
};

const errorModalReducer = createSlice({
  name: 'error-modal',
  initialState,
  reducers: {
    showErrorModal: (state: ErrorModalInterface, action: PayloadAction<any>) => {
      state = action.payload;
      state.show = true;
      return state;
    },
    clearErrorModal: (state: ErrorModalInterface) => {
      state = initialState;
      return state;
    }
  }
});

export const { showErrorModal, clearErrorModal } = errorModalReducer.actions;

export default errorModalReducer.reducer;
