import { createSlice } from '@reduxjs/toolkit';
import { resetAppData } from '../../../app/app-reducer';

interface Enroll {
  setEnrollTokenSuccess: boolean;
  userGuid: string;
  responseError: {
    message: string;
    status: boolean;
    statusCode: number | undefined | null;
    reason: any;
  };
  formData: {
    firstName: string;
    lastName: string;
    email: string;
    username: string;
    password: string;
  };
}
const initialState: Enroll = {
  setEnrollTokenSuccess: false,
  userGuid: '',
  responseError: {
    message: '',
    status: false,
    statusCode: null,
    reason: ''
  },
  formData: {
    firstName: '',
    lastName: '',
    email: '',
    username: '',
    password: ''
  }
};

const enrollSlice = createSlice({
  name: 'enroll',
  initialState,
  reducers: {
    postEnroll: (state: Enroll, action) => {
      const { credentials, identities, vuser } = action.payload.enrollPayload;
      state.formData.firstName = vuser.firstName;
      state.formData.lastName = vuser.lastName;
      state.formData.email = vuser.contacts[0].contactValue;
      state.formData.username = identities[0].identityValue;
      state.formData.password = credentials[0].credentialValue;
      state.responseError = initialState.responseError;
      return state;
    },
    postEnrollSuccess: (state: Enroll, action) => {
      return state;
    },
    postEnrollFailure: (state: Enroll, action) => {
      const { reason, status, responseMessage } = action.payload;
      state.responseError.message = responseMessage;
      state.responseError.status = true;
      state.responseError.statusCode = status;
      state.responseError.reason = reason;
      return state;
    },

    postEnrollToken: (state: Enroll, action) => {
      return state;
    },
    postEnrollTokenSuccess: (state: Enroll, action) => {
      state.setEnrollTokenSuccess = true;
      state.userGuid = action.payload.userGuid;
      return state;
    },
    restoreUserName: (state, action) => {
      // this method is only used for restoring the userName state on reload
      state = action.payload;
      return state;
    }
  },
  extraReducers: builder => {
    builder.addCase(resetAppData, () => {
      return initialState;
    });
  }
});
export const { postEnroll, postEnrollSuccess, postEnrollFailure, postEnrollToken, postEnrollTokenSuccess, restoreUserName } =
  enrollSlice.actions;

export default enrollSlice.reducer;
