import Unsubscribe from './unsubscribe';

const unsubscribeRoutes = [
  {
    path: 'unsubscribe',
    element: <Unsubscribe />
  }
];

export default unsubscribeRoutes;
