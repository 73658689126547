import React from 'react'
import { Navigate } from 'react-router';
import { RedirectRoutes } from './constants';
import { Utils } from './utils';

const PrivateRoutes = ({ children }) => {
    const fullyLoggedIn = Utils.getDataFromSessionStorage('IFL');

    if (!fullyLoggedIn) {
        return <Navigate to={RedirectRoutes.login} replace />
    }
    return children;
}

export default PrivateRoutes