import { put, takeLatest, call } from 'redux-saga/effects';
import { ajaxHandler, apiPaths } from '../../../setup';
import { Utils } from '../../../utils';
import { setTokenSuccess, resetSuccessFlags, setUsername } from '../../sign-in/sign-in/sign-in-reducer-slice';
import { setAccessToken, setUserGuid, setLastLoginTime} from '../../../auth/user-auth-reducer';

import {
  postEnroll,
  postEnrollSuccess,
  postEnrollFailure,
  postEnrollToken,
  postEnrollTokenSuccess
} from './enroll-reducer-slice';
import { GlobalConstants } from '../../../constants';

let xAuthCode = '';

function* postEnrollHandler(action) {
  const { payload } = action;
  payload.enrollPayload['vbaToken'] = yield call(Utils.vbaTokenGenerator);
  const response = yield call(ajaxHandler, apiPaths.auth.enrollUrl, true, {
    method: 'POST',
    data: {
      ...payload.enrollPayload
    },
    urlWithPlaceholder: apiPaths.auth.enrollUrl
  });

  if (!response.error) {
    xAuthCode = response.headers['x-auth-code'] || '';
    yield put(postEnrollSuccess(response.data));
    yield put(setUsername(Utils.getRememberMeCookie()));
    //  token call
    yield put(postEnrollToken(payload));
  } else {
    yield put(postEnrollFailure(response.error));
  }
}

function* postEnrollTokenHandler(action) {
  const { payload } = action;
  const { enrollTokenPayload } = payload;
  const apiUrl = apiPaths.enrollment.b2cOauth;
  const vbaToken = yield call(Utils.vbaTokenGenerator);
  enrollTokenPayload['code'] = xAuthCode;
  enrollTokenPayload['software_statement_claimset.uebas[0].ueba_source'] = 'VBA';
  enrollTokenPayload['grant_type']='authorization_code';
  enrollTokenPayload['code_verifier']= Utils.getCodeChallange()
  enrollTokenPayload['software_statement_claimset.software_version']="2022.04.00";
  enrollTokenPayload['software_statement_claimset.tenancy_context.product_code'] = 'PAS';
  enrollTokenPayload['software_statement_claimset.oauth2_version'] = '1.0';
  enrollTokenPayload['software_statement_claimset.tenancy_context.app_id'] = GlobalConstants.extAppId;
  const response = yield call(ajaxHandler, apiUrl, true, {
    data: Utils.encodeUsingUrlEncoding(enrollTokenPayload),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    },
    method: 'POST',
    urlWithPlaceholder: apiUrl
  });
  if (!response.error) {
    yield put(postEnrollTokenSuccess(response.data));
    yield put(setUserGuid(response.data.subjects[0].subject_id));
    yield put(setTokenSuccess());
    yield put(setAccessToken(response.data.access_token));
    yield put(setLastLoginTime(response));
  } else {
    yield put(resetSuccessFlags());
  }
}

export default function* watchEnroll() {
  yield takeLatest(postEnroll, postEnrollHandler);
  yield takeLatest(postEnrollToken, postEnrollTokenHandler);
}
