import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit';

interface App {
  sessionId: string;
  webSDK: object;
  encNonce: string;
}

const initialState: App = {
  sessionId: '',
  webSDK: {},
  encNonce: ''
};

const appReducer = createSlice({
  name: 'header',
  initialState,
  reducers: {
    getRenewSession: (state: App) => {
      return {
        ...state
      };
    },
    getRenewSessionSuccess: (state: App, action: PayloadAction<any>) => {
      return {
        ...state,
        sessionId: action.payload
      };
    },
    postRevocation: (state: App) => {
      return {
        ...state
      };
    },
    postRevocationSuccess: (state: App, action: PayloadAction<any>) => {
      return {
        ...state
      };
    },
    setEncNonce: (state: App, action: PayloadAction<any>) => {
      state.encNonce = action.payload
    }
  }
});

export const resetAppData = createAction('header/resetAppData');

export const { getRenewSession, getRenewSessionSuccess, postRevocation, postRevocationSuccess, setEncNonce } = appReducer.actions;

export default appReducer.reducer;
