import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Row from '@visa/vds/row';
import Col from '@visa/vds/col';
import Typography from '@visa/vds/typography';
import Checkbox from '@visa/vds/checkbox';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { ButtonWrapper, InputWrapper, FormWrapper } from '../../../elements/core-ui';
import { Utils } from '../../../utils';
import { GlobalConstants, RedirectRoutes } from '../../../constants';
import { GenericPanelComponent, clearAlert } from '../../../elements/shared';
import { useTitle } from '../../../hooks/use-document-title';
import { postLogin, getConfigurations, postToken, setUsername, resetSuccessFlags } from './sign-in-reducer-slice';
import styles from './sign-in.module.scss';

type SignInProps = {
  [key: string]: any;
};

const SignIn: React.FC<SignInProps> = () => {
  const initialValues = {
    username: Utils.getRememberMeCookie() || '',
    password: '',
    remember_me: !!Utils.getRememberMeCookie()
  };
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const rememeberMeRef: any = useRef();
  const [rememberMeInputChecked, setRememberMeInputChecked] = useState(!!Utils.getRememberMeCookie());
  const { signin, userAuth, userDetails } = useSelector((store: any) => store);

  useTitle('app.title.login');

  useEffect(() => {
    if (userAuth.token2Success) {
      navigate(RedirectRoutes.home);
    }
  }, [userAuth.token2Success]);

  useEffect(() => {
    if (signin.configurationsSuccess) {
      if (signin.pendingTerms) {
        navigate(RedirectRoutes.acceptPendingTnc);
      } else {
        dispatch(postToken());
      }
    }
  }, [signin.configurationsSuccess]);

  useEffect(() => {
    if (signin.tokenSuccess) {
      if (signin.scope.unverified) {
        navigate(RedirectRoutes.loginVerifyEmail);
      } else if (signin.scope.challenge && !signin.scope.isAcceptTerms) {
        navigate(RedirectRoutes.loginChallengeVerifyEmail);
      } else {
        dispatch(getConfigurations());
      }
    }
  }, [signin.tokenSuccess]);
  useEffect(() => {
    return () => {
      dispatch(resetSuccessFlags());
    };
  }, []);

  const schema = yup.object({
    username: yup.string().trim().required('signin.formValidate.error.username'),
    password: yup.string().trim().required('signin.formValidate.error.password'),
    remember_me: yup.boolean()
  });

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(schema),
    mode: 'onBlur',
    reValidateMode: 'onBlur'
  });

  const {
    formState: { errors },
    getValues
  } = methods;

  // useEffect(() => {
  //   if (signin.tokenFailure) {
  //     const errorResponse: any = signin.tokenFailureResponse;
  //       const msg = errorResponse.errorMessage?errorResponse.errorMessage : `signin.errorCodes.${errorResponse.reason}`;
  //       dispatch(
  //         showAlert({
  //           type: 'ERROR',
  //           message: msg
  //         })
  //       );
  //     }

  // }, [signin.tokenFailureResponse]);

  const getPayload = (data: any) => {
    const payload: any = {};
    const rememberMeChecked = rememeberMeRef.current.checked;
    payload['grant_type'] = 'password';
    payload.username = data.username;
    payload.password = data.password;
    payload.password_type = 'PASSWORD';
    payload.context = Utils.encodeUsingUrlEncoding(
      JSON.stringify({
        subContext: 'CONSUMER_V_ME',
        channel: 'Web',
        context: 'LOGIN'
      })
    );
    payload.apikey = 'apikey';
    payload.appid = GlobalConstants.extAppId;
    const rememberMeCookie = Utils.getRememberMeCookie(),
      userName = data.username;
    payload['response_type'] =
      'urn:ext:ietf:params:oauth:response-type:remember_me urn:ext:ietf:params:oauth:response-type:remember_me_code';
    switch (true) {
      //User 'A' Logins without checking remember me Checkbox (First time)
      case !rememberMeChecked && rememberMeCookie === null:
        payload.username_type = 'USERNAME';
        delete payload['response_type'];
        break;
      //User 'A' Logins with checking remember me Checkbox (First time)
      case rememberMeChecked && rememberMeCookie === null:
        payload['username_type'] = 'USERNAME';
        break;
      //User 'B' Logins with checked (First time) User A is first time log in and the remember me cookie existed in browser
      case rememberMeChecked && userName !== rememberMeCookie:
        payload['username_type'] = 'USERNAME';
        break;
      //User 'B' Logins without checked (First time) User A is first time log in and the remember me cookie existed in browser
      case !rememberMeChecked && userName !== rememberMeCookie:
        payload['username_type'] = 'USERNAME';
        break;
      //User 'A' Logins with checking remember me Checkbox (second time)
      case rememberMeChecked && userName === rememberMeCookie:
        payload['username_type'] = 'remember_me';
        break;
      //User A login uncheck remember me (revoke)
      case !rememberMeChecked && userName === rememberMeCookie:
        payload['username_type'] = 'remember_me';
        payload['response_type'] =
          '~urn:ext:ietf:params:oauth:response-type:remember_me ~urn:ext:ietf:params:oauth:response-type:remember_me_code';
        break;
    }
    return payload;
  };

  const handleCheckBoxChange = e => {
    setRememberMeInputChecked(e.target.checked);
  };
  const submit = (data: any) => {
    const payload = getPayload(data);
    // dispatch(postLogin(Utils.encodeUsingUrlEncoding(payload)));
    dispatch(postLogin({ payload, navigate }));
    dispatch(setUsername(data.username));
    dispatch(clearAlert());
  };
  const bodyComponent = (
    <FormWrapper onSubmit={methods.handleSubmit(submit)} noValidate>
      <Row>
        <Col>
          <InputWrapper
            id="username"
            methods={methods}
            label={t('signin.fieldLabel.username')}
            name={'username'}
            type={'text'}
            required={true}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <InputWrapper
            id="password"
            methods={methods}
            label={t('signin.fieldLabel.password')}
            name={'password'}
            type={'password'}
            required={true}
            hidePasswordIcon={true}
          />
        </Col>
      </Row>

      <Row className={styles['remember-me']}>
        <Col>
          <Checkbox
            methods={methods}
            ref={rememeberMeRef}
            name={'remember_me'}
            checked={rememberMeInputChecked}
            onChange={handleCheckBoxChange}
            aria-invalid={false}
            label={t('signin.fieldLabel.rememberMe')}
            aria-required={false} //https://jira.trusted.visa.com/browse/VMCP-14783
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <ButtonWrapper
            colorScheme="primary"
            type="submit"
            tag="button"
            isFullWidth
            ariaLabel={t('signin.button.signin')}
            label={t('signin.button.signin')}
          />
        </Col>
      </Row>
      <Row className={styles['forgot-links-row']}>
        <Col>
          <Typography>
            {t('signin.forgotText.part1')}
            <Link to="/forgot-username" className={styles['link']} aria-label={t('signin.forgotUsername_screen')}>
              {t('signin.forgotText.part2')}
            </Link>
            {t('signin.forgotText.part3')}
            <Link to="/forgot-password" className={styles['link']} aria-label={t('signin.forgotPassword_screen')}>
              {t('signin.forgotText.part4')}
            </Link>
            ?
          </Typography>
        </Col>
      </Row>
    </FormWrapper>
  );

  return (
    <div className={styles['container']}>
      <GenericPanelComponent bodyComponent={bodyComponent} pageTitle={t('signin.heading')} />
    </div>
  );
};

export default SignIn;
