import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Icon, Row } from '@visa/vds';
import { useSelector } from 'react-redux';
import { CardTileComponent } from '../../../../elements/shared';
import { RedirectRoutes } from '../../../../constants';
import { ButtonWrapper } from '../../../../elements/core-ui';
import { GenericPanelComponent } from '../../../../elements/shared';
import { useTitle } from '../../../../hooks/use-document-title';
import { Utils } from '../../../../utils';
import { getPaymentInstruments } from '../../home-reducer';
import { resetFlags } from '../../../../elements/shared/add-edit-card/add-edit-card-reducer';
import { setSelectedEditCard } from './manage-cards-reducer';
import styles from './manage-cards.module.scss';

type ManageCardsProps = {
  [key: string]: any;
};

const ManageCards: React.FC<ManageCardsProps> = () => {
  const { userDetails, addEditCard } = useSelector((state: any) => state);
  const [cards, setCards] = useState<any>();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  useTitle('app.title.manageCards');

  const handleBackIconClick = () => {
    navigate(RedirectRoutes.home);
  };

  const handleEditIconClick = card => {
    Utils.hideServerAlert(dispatch);
    dispatch(resetFlags());
    dispatch(setSelectedEditCard(card));
  };

  const handleAddCardClick = () => {
    dispatch(resetFlags());
    navigate(RedirectRoutes.check);
  };

  useEffect(() => {
    //setting updated card details when user comes to manage cards after adding or editing card in add-edit card
    setCards(userDetails.paymentInstruments);
  }, [userDetails.paymentInstruments]);

  useEffect(() => {
    if (
      addEditCard.deleteCard.status ||
      addEditCard.postPaymentInstruments.status ||
      addEditCard.patchPaymentInstruments.status
    ) {
      const config = {
        t
      };
      dispatch(getPaymentInstruments(config));
      setCards(userDetails.paymentInstruments);
    }
  }, [
    addEditCard.deleteCard.status,
    addEditCard.postPaymentInstruments.status,
    addEditCard.patchPaymentInstruments.status
  ]);

  const addCardLabel = (
    <>
      <Icon className={styles['add-icon']} name="add" resolution="tiny" />
      {t('manage_cards.button.addCard')}
    </>
  );

  const bodyComponent = (
    <Row className={styles['body']}>
      {cards?.map(card => (
        <Link key={card.cardNickname} to={`${RedirectRoutes.homeEditCard}`} onClick={() => handleEditIconClick(card)}>
          <CardTileComponent iconName="edit" className={styles['cardtile-row']} card={card} />
        </Link>
      ))}
    </Row>
  );

  const actionButton = (
    //https://issues.trusted.visa.com/browse/MOBENB-18507
    <ButtonWrapper
      colorScheme="tertiary"
      buttonType="add"
      tag="button"
      isFullWidth={true}
      label={addCardLabel}
      ariaLabel={t('manage_cards.button.addCard')}
      handleClick={handleAddCardClick}
    />
  );

  return (
    <GenericPanelComponent
      backIcon={true}
      pageTitle={t('manage_cards.heading')}
      panelSubText={t('manage_cards.subHeading')}
      bodyComponent={bodyComponent}
      actionBtns={actionButton}
      containerClass={styles['custom-container']}
      handleIconClick={handleBackIconClick}
    />
  );
};

ManageCards.displayName = 'ManageCards';

export default ManageCards;
