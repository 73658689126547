import { Navigate } from 'react-router-dom';
import { UseAnotherEmail } from '../../elements/shared';
import AcceptTnC from './accept-pending-tnc/accept-pending-tnc';
import { SignIn, EmailVerify, IdvVerify, ForcePasswordReset } from './index';

const signInRoutes = [
  { path: '/', element: <Navigate to="/login" replace /> },

  {
    path: 'login',
    children: [
      {
        path: '',
        element: <SignIn />,
        index: true
      },
      {
        path: 'verify',
        element: <IdvVerify />
      },
      {
        path: 'challenge/verify/email',
        element: <EmailVerify />
      },
      {
        path: 'accept-pending-tnc',
        element: <AcceptTnC />
      },
      {
        path: 'edit-email',
        element: <UseAnotherEmail />
      },
      {
        path: 'force-password-reset',
        element: <ForcePasswordReset />
      }
    ]
  }
];

export default signInRoutes;
