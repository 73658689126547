import { call, put, select, takeLatest } from 'redux-saga/effects';
import { RedirectRoutes } from '../../../constants';
import { showAlert } from '../../../elements/shared';
import { ajaxHandler, apiPaths } from '../../../setup';
import { replaceUserGuid } from '../../../utils/utils';
import { deleteProfile, deleteProfileSuccess } from '../../home/home-reducer';

function* deleteProfileHandler(action) {
  const { payload } = action;
  const userAuth = yield select(state => state.userAuth);
  const headers = {
    access_token: userAuth.accessToken
  };
  const deleteProfileUrl = replaceUserGuid(apiPaths.auth.userdetails, userAuth.userGuid);
  const response = yield call(ajaxHandler, deleteProfileUrl, true, {
    headers,
    method: 'DELETE',
    urlWithPlaceholder: apiPaths.auth.userdetails
  });
  if (!response.error) {
    yield put(deleteProfileSuccess());
    if (action.payload.navigate) {
      action.payload.navigate(RedirectRoutes.deleteProfile);
    }
  } else {
    if (!response.error.errorHandled) {
      const type = 'ERROR';
      const message = 'errorCodes.technical_error_message';
      yield put(showAlert({ type, message }));
    }
  }
}

export default function* watchChangePassword() {
  yield takeLatest(deleteProfile, deleteProfileHandler);
}
