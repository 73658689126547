import { put, takeLatest, call } from 'redux-saga/effects';
import { ajaxHandler, apiPaths } from '../../setup';
import { postUnsubscribe, unsubscribeSuccess, unsubscribeFailure } from './unsubscribe-reducer';

function* unsubscribeHandler(action) {
  const response = yield call(ajaxHandler, apiPaths.unsubscribe, true, {
    method: 'post',
    data: action.payload,
    urlWithPlaceholder: apiPaths.unsubscribe
  });

  if (!response.error) {
    yield put(unsubscribeSuccess(response.data));
  } else {
    yield put(unsubscribeFailure(response.error));
  }
}

export default function* watchUnsubscribe() {
  yield takeLatest(postUnsubscribe, unsubscribeHandler);
}
