import React from 'react';
import { AddEditCard as AddEditCardComponent } from '../../../../elements/shared';

type AddEditCardProps = {
  editCard?: boolean;
};

const AddEditCard: React.FC<AddEditCardProps> = props => {
  const { editCard = false } = props;
  return <AddEditCardComponent edit={editCard} postLogin={true} />;
};

export default AddEditCard;
