import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useTranslator from '../../../../hooks/translation-hook';
import { VerifyCode as VerifyCodeGeneric } from '../../../../elements/shared';
import { changeEmailOtp } from '../change-email-reducer';
import { RedirectRoutes } from '../../../../constants';
import { getLocale } from '../../../../utils/utils';

type CodeVerifyProps = {
  [key: string]: any;
};

const VerifyCode: React.FC<CodeVerifyProps> = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const t = useTranslator();
  const { userAuth, signin, userDetails, changeEmail } = useSelector((store: any) => store);

  const handleSubmit = (data: any) => {
    const state = {
      userGuid: userAuth.userGuid,
      accessToken: userAuth.accessToken
    };
    const config = {
      t,
      state,
      navigate,
      code: data.otpCode
    };
    dispatch(changeEmailOtp(config));
  };
  const handleCancel = () => {
    navigate(RedirectRoutes.changeEmail);
  };
  const handleResend = () => {
    const state = {
      userGuid: userAuth.userGuid,
      accessToken: userAuth.accessToken
    };
    const config = {
      t,
      state,
      navigate,
      locale: getLocale()
    };
    dispatch(changeEmailOtp(config));
  };

  return (
    <div>
      <VerifyCodeGeneric
        submitHandler={handleSubmit}
        cancelHandler={handleCancel}
        resendHandler={handleResend}
        contactValue={changeEmail.newEmail}
        backIconHandler={handleCancel}
        dialogContinueRedirect={RedirectRoutes.profile}
      />
    </div>
  );
};

export default VerifyCode;
