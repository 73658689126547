import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RedirectRoutes } from '../../constants';
import { ButtonWrapper } from '../../elements/core-ui';
import { Utils } from '../../utils';
import styles from './page-not-found.module.scss';

const PageNotFound = props => {
  const { userAuth } = useSelector((store: any) => store);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = () => {
    if (userAuth.isFullyLogged) {
      navigate(RedirectRoutes.home);
    } else {
      Utils.invalidateSession(dispatch);
      navigate(RedirectRoutes.login);
    }
  };

  return (
    <div className={styles['container']}>
      <div className={styles['inner-container']}>
        <h1 className={styles['title-text']}>
          <span>{t('404-page.heading1')}</span>
          <span>{t('404-page.heading2')}</span>
        </h1>
        <ButtonWrapper
          ariaLabel={userAuth.isFullyLogged ? t('404-page.btnText') : t('404-page.btnTextSigin')}
          label={userAuth.isFullyLogged ? t('404-page.btnText') : t('404-page.btnTextSigin')}
          className={styles['btn-style']}
          handleClick={handleClick}
        />
      </div>
    </div>
  );
};

export default PageNotFound;
