import { getPaymentInstruments, getUserDetails } from '../pages/home/home-reducer';
import { setAccessToken, setUserGuid, setPartiallyLogged, setFullyLogged } from '../auth/user-auth-reducer';
import store from '../setup/store';
import { setSelectedEditCard } from '../pages/home/components/manage-cards/manage-cards-reducer';
import { clearAlert } from '../elements/shared';
import { restoreForgotPasswordData } from '../pages/forgot-password/forgot-password-reducer';
import { RedirectRoutes } from '../constants';
import { postRevocation } from '../app/app-reducer';
import { unsubscribeSuccess } from '../pages/unsubscribe/unsubscribe-reducer';
import { setContactType } from '../pages/profile/update-phone/update-phone.reducer';
import { Utils } from '../utils';
import { restoreUserName } from '../pages/create-account/enroll/enroll-reducer-slice';

enum API {
  SECURE = 'apiPaths.secureUrl',
  PAYMENTINSTRUMENTS = 'apiPaths.payment.baseUrl',
  ALERTS = 'apiPaths.alerts.alertUrl',
  HISTORY = 'apiPaths.transactions.url',
  CARDCONTROL = 'apiPaths.cardcontrol',
  REVOCATION = 'apiPaths.auth.logoutUrl'
}

enum ROUTE_TYPE {
  PRE_LOGIN = 'preLogin',
  PARTIALLY_LOGGED_IN = 'partiallyLoggedIn',
  FULLY_LOGGED_IN = 'fullyLoggedIn',
  BOTH = 'preLogin/fullyLoggedIn',
  MISC_CASE = 'miscellaneousCase' // MISC_CASE is used as on reload on forgot password routes we have to persist the access token coming from  token/idproof/promise and stay on the same route
}

const { dispatch } = store;

const reloadDetails = {
  [RedirectRoutes.check]: {
    routeTo: RedirectRoutes.check,
    apiCalls: [],
    routeType: ROUTE_TYPE.BOTH
  },
  [RedirectRoutes.createAccountEnroll]: {
    routeTo: RedirectRoutes.check,
    apiCalls: [],
    routeType: ROUTE_TYPE.PRE_LOGIN
  },
  [RedirectRoutes.createAccountVerifyCode]: {
    routeTo: RedirectRoutes.login,
    apiCalls: [],
    routeType: ROUTE_TYPE.PARTIALLY_LOGGED_IN
  },
  [RedirectRoutes.createAccountAddCard]: {
    routeTo: RedirectRoutes.check,
    apiCalls: [],
    routeType: ROUTE_TYPE.FULLY_LOGGED_IN
  },
  [RedirectRoutes.createAccountEditEmail]: {
    routeTo: RedirectRoutes.login,
    apiCalls: [],
    routeType: ROUTE_TYPE.PARTIALLY_LOGGED_IN
  },

  // time to render the component is less than setting the data in the redux. Hence the secure and paymentInstruments
  // needs to be handle here.

  [RedirectRoutes.home]: {
    routeTo: RedirectRoutes.home,
    apiCalls: [API.PAYMENTINSTRUMENTS, API.SECURE],
    routeType: ROUTE_TYPE.FULLY_LOGGED_IN
  },

  // because of the navigation from RedirectRoutes.homeAlerts to RedirectRoutes.home time
  // is greater than setting the data in the redux. Hence the secure and paymentInstruments
  // needs to be handle by the Home component.

  [RedirectRoutes.homeAlerts]: {
    routeTo: RedirectRoutes.home,
    apiCalls: [],
    routeType: ROUTE_TYPE.FULLY_LOGGED_IN
  },

  [RedirectRoutes.homeAlertHistory]: {
    routeTo: RedirectRoutes.home,
    apiCalls: [],
    routeType: ROUTE_TYPE.FULLY_LOGGED_IN
  },

  [RedirectRoutes.homeCardControl]: {
    routeTo: RedirectRoutes.home,
    apiCalls: [],
    routeType: ROUTE_TYPE.FULLY_LOGGED_IN
  },

  [RedirectRoutes.homeManageCards]: {
    routeTo: RedirectRoutes.homeManageCards,
    apiCalls: [API.SECURE, API.PAYMENTINSTRUMENTS],
    routeType: ROUTE_TYPE.FULLY_LOGGED_IN
  },

  [RedirectRoutes.homeAddCard]: {
    routeTo: RedirectRoutes.check,
    apiCalls: [API.PAYMENTINSTRUMENTS, API.SECURE],
    routeType: ROUTE_TYPE.FULLY_LOGGED_IN
  },

  [RedirectRoutes.homeEditCard]: {
    routeTo: RedirectRoutes.homeEditCard,
    apiCalls: [API.PAYMENTINSTRUMENTS, API.SECURE],
    routeType: ROUTE_TYPE.FULLY_LOGGED_IN
  },

  [RedirectRoutes.login]: {
    routeTo: RedirectRoutes.login,
    apiCalls: [],
    routeType: ROUTE_TYPE.PRE_LOGIN
  },

  [RedirectRoutes.loginVerifyEmail]: {
    routeTo: RedirectRoutes.login,
    apiCalls: [API.REVOCATION],
    routeType: ROUTE_TYPE.PARTIALLY_LOGGED_IN
  },

  [RedirectRoutes.loginChallengeVerifyEmail]: {
    routeTo: RedirectRoutes.login,
    apiCalls: [API.REVOCATION],
    routeType: ROUTE_TYPE.PARTIALLY_LOGGED_IN
  },

  [RedirectRoutes.acceptPendingTnc]: {
    routeTo: RedirectRoutes.login,
    apiCalls: [],
    routeType: ROUTE_TYPE.PARTIALLY_LOGGED_IN
  },

  [RedirectRoutes.forcePasswordReset]: {
    routeTo: RedirectRoutes.login,
    apiCalls: [],
    routeType: ROUTE_TYPE.PARTIALLY_LOGGED_IN
  },

  [RedirectRoutes.loginEditEmail]: {
    routeTo: RedirectRoutes.login,
    apiCalls: [],
    routeType: ROUTE_TYPE.PARTIALLY_LOGGED_IN
  },

  [RedirectRoutes.forgotPassword]: {
    routeTo: RedirectRoutes.forgotPassword,
    apiCalls: [],
    routeType: ROUTE_TYPE.PRE_LOGIN
  },
  [RedirectRoutes.forgotPasswordVerifyCode]: {
    routeTo: RedirectRoutes.forgotPasswordVerifyCode,
    apiCalls: [],
    routeType: ROUTE_TYPE.MISC_CASE
  },
  [RedirectRoutes.resetPassword]: {
    routeTo: RedirectRoutes.resetPassword,
    apiCalls: [],
    routeType: ROUTE_TYPE.MISC_CASE
  },

  [RedirectRoutes.forgotUsername]: {
    routeTo: RedirectRoutes.forgotUsername,
    apiCalls: [],
    routeType: ROUTE_TYPE.PRE_LOGIN
  },

  [RedirectRoutes.profile]: {
    routeTo: RedirectRoutes.profile,
    apiCalls: [API.SECURE, API.PAYMENTINSTRUMENTS],
    routeType: ROUTE_TYPE.FULLY_LOGGED_IN
  },

  [RedirectRoutes.changeEmail]: {
    routeTo: RedirectRoutes.changeEmail,
    apiCalls: [API.SECURE],
    routeType: ROUTE_TYPE.FULLY_LOGGED_IN
  },
  [RedirectRoutes.changeEmailVerifyCode]: {
    routeTo: RedirectRoutes.profile,
    apiCalls: [API.SECURE],
    routeType: ROUTE_TYPE.FULLY_LOGGED_IN
  },
  [RedirectRoutes.changePassword]: {
    routeTo: RedirectRoutes.changePassword,
    apiCalls: [API.SECURE],
    routeType: ROUTE_TYPE.FULLY_LOGGED_IN
  },
  [RedirectRoutes.messageLanguage]: {
    routeTo: RedirectRoutes.profile,
    apiCalls: [API.SECURE],
    routeType: ROUTE_TYPE.FULLY_LOGGED_IN
  },
  [RedirectRoutes.updatePhone]: {
    routeTo: RedirectRoutes.updatePhone,
    apiCalls: [API.SECURE],
    routeType: ROUTE_TYPE.FULLY_LOGGED_IN
  },
  [RedirectRoutes.editPhone]: {
    routeTo: RedirectRoutes.editPhone,
    apiCalls: [API.SECURE],
    routeType: ROUTE_TYPE.FULLY_LOGGED_IN
  },
  [RedirectRoutes.phoneVerifyCode]: {
    routeTo: RedirectRoutes.profile,
    apiCalls: [API.SECURE],
    routeType: ROUTE_TYPE.FULLY_LOGGED_IN
  },
  [RedirectRoutes.deleteProfile]: {
    routeTo: RedirectRoutes.login,
    apiCalls: [],
    routeType: ROUTE_TYPE.FULLY_LOGGED_IN
  },

  [RedirectRoutes.unsubscribe]: {
    routeTo: RedirectRoutes.unsubscribe,
    apiCalls: [],
    routeType: ROUTE_TYPE.FULLY_LOGGED_IN
  }
};

export const persistData = store => {
  // this function will store the required data to session storage
  const { userAuth, app, forgotPassword, manageCard, unsubscribe, userDetails, updatePhone, enroll } = store;
  const { accessToken, userGuid, isPartiallyLogged, isFullyLogged } = userAuth;
  const data = {
    accessToken: accessToken,
    userGuid: userGuid,
    isPartiallyLogged: isPartiallyLogged,
    isFullyLogged: isFullyLogged,
    sessionId: app.sessionId,
    pathname: window.location.pathname,
    contactType: updatePhone.contactType,
    username: enroll?.formData?.username
  };

  if (window.location.pathname.includes('edit-card')) {
    data['cardPanGuid'] = manageCard.card?.panGuid;
  }

  if (window.location.pathname.includes('forgot-password') && forgotPassword.accessToken) {
    data['accessToken'] = forgotPassword.accessToken;
  }

  if (window.location.pathname.includes('unsubscribe')) {
    data['isUnsubscribed'] = unsubscribe.isUnsubscribed;
  }

  sessionStorage.setItem('data', JSON.stringify(data));
};

const apiInvokeOnReload = data => {
  // api calls to be made once the reload
  const { pathname, navigate } = data;
  const queryParam = window.location.search;
  const previousPath = pathname.split('vca-web').pop();
  const newPath = window.location.pathname.split('vca-web').pop();
  const newPathObj = newPath && reloadDetails[newPath];

  /*

      if the user is fullyLoggedIn and newPath is /check ,  then we navigate the user to /check 
      and hide the country drop down field.
  */
  if (newPath == '/check' && data.isFullyLogged) {
    dispatch(getPaymentInstruments({}));
    dispatch(getUserDetails());
    return;
  }

  /* 
      we are dealing with 3 different urls

      1. The current path 
      2. Up on reload, route to path
      3. The typed url
  */

  if (newPathObj) {
    /*   
        if the user is fullyLoggedIn and PAYMENTINSTRUMENTS and SECURE calls 
        are presesnt in the api calls added then make the below calls.
    */
    if (data.isFullyLogged) {
      newPathObj.apiCalls.includes(API.PAYMENTINSTRUMENTS) && dispatch(getPaymentInstruments({}));
      newPathObj.apiCalls.includes(API.SECURE) && dispatch(getUserDetails());
    }

    /*
        if the user is partiallyLoggedIn and the current route is pre login route.
        Upon reload we make a revocation api call.
    */
    if (data.isPartiallyLogged) newPathObj.apiCalls.includes(API.REVOCATION) && dispatch(postRevocation());

    /*
        if the user tries to go to /edit-card by typing the url, as user did not select any card for edit,
        we navigate the user to /home/manage-cards.
    */
    if (newPath === RedirectRoutes.homeEditCard && previousPath !== RedirectRoutes.homeEditCard) {
      queryParam ? navigate(RedirectRoutes.homeManageCards + queryParam) : navigate(RedirectRoutes.homeManageCards);
      return;
    }

    /*
        if the user post login reloads by adding ?lang=pt-BR parameter on /add-card  url, 
        then as per requirement we navigate to /check, as the user has added the ?lang=pt-BR
        we append the query param to the page we are navigating to.
    */
    if (newPathObj.routeTo !== newPath) {
      queryParam ? navigate(newPathObj.routeTo + queryParam) : navigate(newPathObj.routeTo);
    }
  }
};

export const restoreRedux = async navigate => {
  // this function is to retrive the data from session storage, make the required api calls and clear the session storage.
  const storedData = sessionStorage.getItem('data');
  const data = storedData !== null && JSON.parse(storedData);
  const newPath = window.location.pathname.split('vca-web').pop();
  const newPathObj = newPath && reloadDetails[newPath];

  /*   
       if newPathObj is the typed url and it si a part of the pre login flow
       then we invalidated the session and logout and redirect to /login      
   */
  if (newPathObj && data?.isFullyLogged && newPathObj.routeType !== ROUTE_TYPE.BOTH) {
    if (newPathObj.routeType !== ROUTE_TYPE.FULLY_LOGGED_IN) {
      navigate(newPathObj.routeTo);
      Utils.executeAppTermination(dispatch);
      return;
    }
  }

  if (data) {
    data.navigate = navigate;
    const {
      accessToken,
      userGuid,
      isPartiallyLogged,
      isFullyLogged,
      cardPanGuid,
      pathname,
      isUnsubscribed,
      contactType,
      username
    } = data;

    dispatch(clearAlert());
    accessToken && !pathname.includes('forgot-password') && dispatch(setAccessToken(accessToken));
    userGuid && dispatch(setUserGuid(userGuid));
    cardPanGuid && dispatch(setSelectedEditCard({ panGuid: cardPanGuid }));
    isPartiallyLogged && dispatch(setPartiallyLogged());
    isFullyLogged && dispatch(setFullyLogged());
    accessToken &&
      pathname.includes('forgot-password') &&
      dispatch(restoreForgotPasswordData({ accessToken: accessToken }));

    username && dispatch(restoreUserName({ formData: { username: username } }));
    isUnsubscribed && dispatch(unsubscribeSuccess({ isSuccess: isUnsubscribed }));
    contactType && dispatch(setContactType(contactType));

    apiInvokeOnReload(data);
    sessionStorage.removeItem('data');
  }
};
