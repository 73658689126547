import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { GenericPanelComponent } from '../../../elements/shared';
import useTranslator from '../../../hooks/translation-hook';
import { ButtonWrapper, InputWrapper } from '../../../elements/core-ui';
import { RedirectRoutes } from '../../../constants';
import { FormWrapper } from '../../../elements/core-ui';
import { Validator } from '../../../utils';
import { useTitle } from '../../../hooks/use-document-title';
import { changeUserPassword, updateAccessToken } from './change-password-reducer';

const ChangePassword: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const t = useTranslator();
  const { changePassword, enroll } = useSelector((store: any) => store);

  useEffect(() => {
    const state = {
      ...changePassword
    };
    const config = {
      t,
      state,
      navigate
    };
    if (changePassword.changePasswordSuccess) {
      dispatch(updateAccessToken(config));
    }
  }, [changePassword.changePasswordSuccess]);

  useTitle('app.title.changePassword');

  const schema = yup.object({
    newPassword: yup
      .string()
      .required('updatePassword.validation.newPasswordRequiredField')
      .min(8, 'resetPassword.model.attributes.password.minLength')
      .test((value: any, context: any) => Validator.passwordValidator(value, context)),
    currentPassword: yup.string().required('updatePassword.validation.currentPasswordRequiredField')
  });
  const initialValues = {
    currentPassword: '',
    newPassword: '',
    userName: enroll?.formData?.username
  };
  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: initialValues,
    resolver: yupResolver(schema)
  });

  const handleCancelClick = () => {
    navigate(RedirectRoutes.profile);
  };
  const handleContinueClick = (data: any) => {
    const config = {
      t,
      navigate,
      newPassword: data.newPassword,
      currentPassword: data.currentPassword
    };
    dispatch(changeUserPassword(config));
  };

  const bodyComponent = (
    <>
      <FormWrapper onSubmit={methods.handleSubmit(handleContinueClick)}>
        <InputWrapper
          id="currentPassword"
          required={true}
          type="password"
          methods={methods}
          name="currentPassword"
          hidePasswordIcon={true}
          label={t('updatePassword.currentPassword')}
          maxLength={32}
        />
        <InputWrapper
          id="newPassword"
          required={true}
          type="password"
          methods={methods}
          name="newPassword"
          hidePasswordIcon={true}
          label={t('updatePassword.newPassword')}
          maxLength={32}
        />
        <>
          <ButtonWrapper
            isFullWidth={true}
            tag="button"
            type="submit"
            colorScheme="primary"
            ariaLabel={t('updatePassword.btnSubmit')}
            label={t('updatePassword.btnSubmit')}
          />
          <ButtonWrapper
            isFullWidth={true}
            colorScheme="tertiary"
            handleClick={handleCancelClick}
            ariaLabel={t('updatePassword.btnCancel')}
            label={t('updatePassword.btnCancel')}
          />
        </>
      </FormWrapper>
    </>
  );

  return (
    <GenericPanelComponent
      backIcon={true}
      postLogin={true}
      bodyComponent={bodyComponent}
      pageTitle={t('updatePassword.password')}
      handleIconClick={handleCancelClick}
      panelMainText={t('updatePassword.title')}
    />
  );
};

export default ChangePassword;
