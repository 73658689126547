import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Grid, Row, Col, Typography, Card, Icon, Checkbox, Input } from '@visa/vds';
import { Link } from 'react-router-dom';
import { AllRegex, RedirectRoutes } from '../../../../constants';
import { ContactDetail, ContactEventType } from '../../../../constants/user-profile';
import useTranslator from '../../../../hooks/translation-hook';
import HomeComponent from '../home-panel/home-panel';
import { Utils } from '../../../../utils';
import { useTitle } from '../../../../hooks/use-document-title';
import { setSelectedTab, Tabs } from '../../home-reducer';
import { AlertsResponse, ContactOffering, getAlerts, resetFlags, ServiceOffering } from './alerts-reducer';
import styles from './alerts.module.scss';
// import Styles from "./Alerts.module.scss";

type AlertsProps = {
  hasCard?: boolean;
};

export enum ServiceOfferings {
  THRESHOLD = 'Threshold',
  CARDNOTPRESENT = 'CardNotPresent',
  DECLINED = 'Declined',
  CROSSBORDER = 'CrossBorder',
  GENERICCREDIT = 'GenericCredit'
}

const Alerts: React.FC<AlertsProps> = props => {
  const { hasCard = true } = props;
  const navigate = useNavigate();

  let existingAlerts = {};

  const dispatch = useDispatch();
  const t = useTranslator();
  const [response, setResponse] = useState<AlertsResponse>();
  const [payload, setPayload] = useState<AlertsResponse>();
  const [resetServiceOfferings, setResetServiceOfferings] = useState<AlertsResponse>();
  const [thresholdAmount, setThresholdAmount] = useState<string>('1');
  const [thresholdAmountErrorMsg, setThresholdAmountErrorMsg] = useState<string>('');
  const [thresholdAmtError, setThresholdAmtError] = useState<boolean>(false);
  const { userDetails, alerts, cardControls } = useSelector((state: any) => state);
  const defaultThresholdAmount = '1';

  useTitle('app.title.chooseAlerts');

  useEffect(() => {
    if (userDetails.selectedCard?.panGuid) {
      if (userDetails?.paymentInstruments?.length) {
        const config = {
          t,
          method: 'GET'
        };
        dispatch(getAlerts(config));
      }
    }
  }, [userDetails.selectedCard?.panGuid]);

  useEffect(() => {
    if (Object.keys(alerts?.response).length) {
      setResponse(alerts?.response);
      getThresholdAmount();
    }
  }, [alerts.response]);

  if (!hasCard) {
    navigate(RedirectRoutes.homeNoCard);
  }

  const isVerifiedContactAdded = userDetails?.isVerifiedContactAdded;

  const existingServiceOfferings: ServiceOffering[] = [];

  useEffect(() => {
    if (response && !!Object.keys(response).length) {
      //to get all the offering codes from the nested array of alerts
      const offeringCodes = Array.from(new Set(response?.serviceOfferings?.map(item => item.offeringCode)));

      for (let i = 0; i < offeringCodes.length; i++) {
        const serviceOffering = getServiceOffering(response?.serviceOfferings, offeringCodes[i]);

        const existingContactOfferings = serviceOffering?.contactOfferings?.map(item => {
          return {
            contactId: item.contactId,
            offeringProperties: item.offeringProperties
          };
        });

        const existingServiceOffering = {
          offeringCode: offeringCodes[i],
          contactOfferings: existingContactOfferings
        };
        existingServiceOfferings.push(existingServiceOffering);
      }
      existingAlerts = {
        lastFourDigits: response?.lastFourDigits,
        currencyCode: response?.currencyCode,
        isActive: !!getContacts?.length,
        contacts: getContacts,
        serviceOfferings: existingServiceOfferings
      };

      setPayload(existingAlerts);
      setResetServiceOfferings(existingAlerts);
    }
  }, [response]);

  // to get the serviceOfferings information by passing OfferingCode as a parameter
  const getServiceOffering = (serviceOfferings, offeringCode) => {
    return serviceOfferings?.find(item => {
      if (item.offeringCode === offeringCode) {
        return item;
      }
    });
  };

  const onChangeThresholdAmt = (val: any) => {
    let thresholdError = '';
    let hasthresholdError = false;
    const pattern = AllRegex.wholeNumbers;
    if (val === '') {
      thresholdError = 'alerts.patternError';
      setThresholdAmount(val);
      hasthresholdError = true;
    } else {
      if (!pattern.test(val)) {
        return
      } else if (pattern.test(val) && val?.length <= 7) {
        setThresholdAmount(val);
        if (val.startsWith('0')) {
          thresholdError = 'alerts.rangeError';
          hasthresholdError = true;
        } else {
          hasthresholdError = false;
        }
      } else {
        if (val.startsWith('0')) {
          thresholdError = 'alerts.rangeError';
          hasthresholdError = true;
        }
      }
    }
    setThresholdAmountErrorMsg(thresholdError);
    setThresholdAmtError(hasthresholdError);
  };

  //to get the verifiedchannel details by passing contactType as a parameter.
  const getVerifiedChannel = contactType => {
    return payload?.contacts?.find(item => {
      if (item.contactType === contactType) {
        return item;
      }
    });
  };

  // //to check the checkbox active state before loading the page by passing respective Offeringcode and contactType.
  const isDefaultChecked = (offeringCode, contactType) => {
    const contactId = getVerifiedChannel(contactType)?.contactId;

    const serviceOffering = getServiceOffering(payload?.serviceOfferings, offeringCode);

    return serviceOffering?.contactOfferings?.find(item => {
      if (item?.contactId === contactId) {
        return item;
      }
    });
  };

  const getThresholdAmount = () => {
    //to get default threshold object from the Threshold service Offering.
    const threshold = getServiceOffering(alerts?.response?.serviceOfferings, ServiceOfferings.THRESHOLD);
    const defaultAmount = threshold?.serviceOfferingProperties?.find(item => {
      if (item.key === 'default Amount') {
        return item.value;
      }
    });
    const thresholdAmt = threshold?.contactOfferings.find(item => {
      return item;
    });
    setThresholdAmount(
      !!thresholdAmt
        ? thresholdAmt?.offeringProperties?.[0]?.value
        : defaultAmount?.value
          ? defaultAmount?.value
          : defaultThresholdAmount
    );

    return thresholdAmount;
  };

  const getContacts = response?.contacts?.map(item => {
    return {
      contactId: item.contactId,
      contactType: item.contactType,
      contactValue: item.contactValue,
      format: item.format
    };
  });

  const onCheckboxChecked = (
    e: { target: { checked: boolean; id: string; value: string } },
    contactType,
    contactId
  ) => {
    let serviceOfferings: ServiceOffering[] = [];

    const serviceOffering = payload?.serviceOfferings?.find(item => {
      if (item.offeringCode === e.target.value) {
        return item;
      }
    });

    let checkedServiceOfferings: ContactOffering[] = [];
    if (serviceOffering?.contactOfferings) {
      checkedServiceOfferings = Array.from(serviceOffering?.contactOfferings);
    }

    if (e.target.checked) {
      const checkedContactOffering = {
        contactId: contactId,
        offeringProperties: [
          {
            key: 'ThresholdAmount',
            value: e.target.value === ServiceOfferings.THRESHOLD ? thresholdAmount : defaultThresholdAmount
          }
        ]
      };

      if (
        Array.from(new Set(serviceOffering?.contactOfferings?.map(item => item.contactId)))?.indexOf(contactId) == -1
      ) {
        checkedServiceOfferings?.push(checkedContactOffering);
      }
    } else {
      if (serviceOffering?.contactOfferings) {
        checkedServiceOfferings = serviceOffering?.contactOfferings?.filter(item => {
          if (item.contactId != contactId) {
            return item;
          }
        });
      }
    }
    if (payload?.serviceOfferings) {
      serviceOfferings = payload?.serviceOfferings?.map(item => {
        if (item.offeringCode === e.target.value) {
          return { ...item, contactOfferings: checkedServiceOfferings };
        } else {
          return item;
        }
      });
    }

    const changeAlertsPayload = {
      lastFourDigits: payload?.lastFourDigits,
      currencyCode: payload?.currencyCode,
      isActive: !!getContacts?.length,
      contacts: getContacts,
      serviceOfferings: serviceOfferings
    };

    setPayload(changeAlertsPayload);
  };

  const handleSaveClick = () => {
    const thresholdServiceOffering = payload?.serviceOfferings?.find(item => {
      if (item?.offeringCode === ServiceOfferings.THRESHOLD) {
        return item;
      }
    });

    const updatedThresholdContactOfferings = thresholdServiceOffering?.contactOfferings?.map(item => {
      const updatedOfferingProperties = [
        {
          key: 'ThresholdAmount',
          value: thresholdAmount || defaultThresholdAmount
        }
      ];

      return { ...item, offeringProperties: updatedOfferingProperties };
    });

    const updatedServiceOfferings = payload?.serviceOfferings?.map(item => {
      if (item?.offeringCode === ServiceOfferings.THRESHOLD) {
        return { ...item, contactOfferings: updatedThresholdContactOfferings };
      } else {
        return { ...item };
      }
    });

    const changeAlertsPayload = {
      lastFourDigits: payload?.lastFourDigits,
      currencyCode: payload?.currencyCode,
      isActive: !!getContacts?.length,
      contacts: getContacts,
      serviceOfferings: updatedServiceOfferings
    };

    const config = {
      t,
      method: 'PUT',
      updatedServiceOfferings: changeAlertsPayload
    };
    if (!thresholdAmtError) {
      dispatch(getAlerts(config));
    } else {
      document.getElementById('threshold-amount-input')?.focus();
    }
  };

  const isDefaultSelected = (alertType, channelType) => {
    return isVerifiedContactAdded ? !!isDefaultChecked(alertType, channelType) : false;
  };

  useEffect(() => {
    dispatch(resetFlags());
    if (alerts.updateAlertsSuccess) {
      const config = {
        t,
        method: 'GET'
      };
      dispatch(getAlerts(config));
    }
  }, [alerts.updateAlertsSuccess]);

  const handleCancelClick = () => {
    setPayload(resetServiceOfferings);
    getThresholdAmount();
  };

  const handleRedirectToCardControl = () => {
    dispatch(setSelectedTab(Tabs.CARD_CONTROL));
  };

  return (
    <HomeComponent
      showActionButtons={!alerts.isError ? true : false}
      pageTitle={t('alerts.title')}
      handleSaveBtnClick={handleSaveClick}
      handleCancelBtnClick={handleCancelClick}
      pageSubTitle={t('alerts.subTitle')}
    >
      <>
        {!alerts.isError && (
          <Grid className={styles['container']}>
            {!Utils.isLac() && !isVerifiedContactAdded && (
              <Card type="content" className={styles['enable-notifications']}>
                <Row>
                  <Col>
                    <Link to={RedirectRoutes.profile} className={styles['link']}>
                      {t('alerts.heading')}
                    </Link>
                    <Typography tag="span" className={styles['alert-service-offerings-label']}>
                      {t('alerts.enableNotifications')}
                    </Typography>
                  </Col>
                </Row>
              </Card>
            )}
            <Row>
              <Col noGutter>
                <Card type="content" className={styles['card']}>
                  <Row className={styles['alerts']}>
                    <Icon
                      aria-hidden="true"
                      name="balance"
                      resolution="low"
                      alt="Threshold"
                      className={styles['alert-icon']}
                    />
                    <Col xs={3} sm={7} md={7} lg={11} className={styles['alerts-offering']}>
                      <Row>
                        <Col>
                          <div className={styles['service-offering']}>
                            <div className={styles['service-offering-title']}>
                              <Typography tag="p" className={styles['label']} id="alert-threshold-part1">
                                {t('alerts.threshold.part1')}
                              </Typography>
                              <Typography tag="p" className={styles['description']} id="alert-threshold-part2">
                                {t('alerts.threshold.part2')}
                              </Typography>
                            </div>
                            <div className={styles['service-offering-channels']}>
                              <div className={Utils.isLac() ? styles['hide-sms'] : styles['sms']}>
                                <Typography tag="p" className={styles['label']} id="threshold-sms-label">
                                  {t('alerts.text')}
                                </Typography>
                                <Checkbox
                                  id="threshold-sms"
                                  value="Threshold"
                                  label=""
                                  aria-label={t('alerts.text')}
                                  checked={isDefaultSelected(ServiceOfferings.THRESHOLD, ContactDetail.SMS)}
                                  disabled={!isVerifiedContactAdded}
                                  onChange={e =>
                                    onCheckboxChecked(
                                      e,
                                      ContactDetail.SMS,
                                      getVerifiedChannel(ContactDetail.SMS)?.contactId
                                    )
                                  }
                                  aria-describedby="alert-threshold-part1 alert-threshold-part2 threshold-sms-label"
                                />
                              </div>
                              <div className={styles['email']}>
                                <Typography tag="p" className={styles['label']} id="threshold-email-label">
                                  {t('alerts.email')}
                                </Typography>
                                <Checkbox
                                  id="threshold-email"
                                  value="Threshold"
                                  aria-label={t('alerts.email')}
                                  label=""
                                  onChange={e =>
                                    onCheckboxChecked(
                                      e,
                                      ContactEventType.EMAIL,
                                      getVerifiedChannel(ContactEventType.EMAIL)?.contactId
                                    )
                                  }
                                  checked={!!isDefaultChecked(ServiceOfferings.THRESHOLD, ContactEventType.EMAIL)}
                                  disabled={!getVerifiedChannel(ContactEventType.EMAIL)}
                                  aria-describedby="alert-threshold-part1 alert-threshold-part2 threshold-email-label"
                                />
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className={styles['threshold-amount']}>
                            <Input
                              id={'threshold-amount-input'}
                              invalid={thresholdAmtError}
                              aria-invalid={thresholdAmtError}
                              errorText={<span role="alert">{t(thresholdAmountErrorMsg)}</span>}
                              label={t('alerts.label')}
                              name="thresholdAmount"
                              value={thresholdAmount}
                              required
                              showErrorText={thresholdAmtError}
                              aria-label={t('alerts.label')}
                              contentBeforeInput={
                                <Typography tag="span" className={styles['currency-symbol']}>
                                  {response?.currencyCode}
                                </Typography>
                              }
                              onChange={e => onChangeThresholdAmt(e.target.value)}
                            />
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
                <Card type="content" className={styles['card']}>
                  <Row className={styles['alerts']}>
                    <Icon
                      aria-hidden="true"
                      name="device-mobile"
                      resolution="low"
                      className={styles['alert-icon']}
                      alt=""
                    />
                    <Col xs={3} sm={7} md={7} lg={11} className={styles['alerts-offering']}>
                      <Row>
                        <Col>
                          <div className={styles['service-offering']}>
                            <div className={styles['service-offering-title']}>
                              <Typography tag="p" className={styles['label']} id="alerts-cardNotPresent-part1">
                                {t('alerts.cardNotPresent.part1')}
                              </Typography>
                              <Typography tag="p" className={styles['description']} id="alerts-cardNotPresent-part2">
                                {t('alerts.cardNotPresent.part2')}
                              </Typography>
                            </div>
                            <div className={styles['service-offering-channels']}>
                              <div className={Utils.isLac() ? styles['hide-sms'] : styles['sms']}>
                                <Typography tag="p" className={styles['label']} id="cardNotPresent-sms-label">
                                  {t('alerts.text')}
                                </Typography>
                                <Checkbox
                                  id="cardNotPresent-sms"
                                  value="CardNotPresent"
                                  label=""
                                  aria-label={t('alerts.text')}
                                  checked={isDefaultSelected(ServiceOfferings.CARDNOTPRESENT, ContactDetail.SMS)}
                                  disabled={!isVerifiedContactAdded}
                                  onChange={e =>
                                    onCheckboxChecked(
                                      e,
                                      ContactDetail.SMS,
                                      getVerifiedChannel(ContactDetail.SMS)?.contactId
                                    )
                                  }
                                  aria-describedby="alerts-cardNotPresent-part1 alerts-cardNotPresent-part2 cardNotPresent-sms-label"
                                />
                              </div>
                              <div className={styles['email']}>
                                <Typography tag="p" className={styles['label']} id="cardNotPresent-email-label">
                                  {t('alerts.email')}
                                </Typography>
                                <Checkbox
                                  id="cardNotPresent-email"
                                  value="CardNotPresent"
                                  label=""
                                  aria-label={t('alerts.email')}
                                  onChange={e =>
                                    onCheckboxChecked(
                                      e,
                                      ContactEventType.EMAIL,
                                      getVerifiedChannel(ContactEventType.EMAIL)?.contactId
                                    )
                                  }
                                  checked={!!isDefaultChecked(ServiceOfferings.CARDNOTPRESENT, ContactEventType.EMAIL)}
                                  disabled={!getVerifiedChannel(ContactEventType.EMAIL)}
                                  aria-describedby="alerts-cardNotPresent-part1 alerts-cardNotPresent-part2 cardNotPresent-email-label"
                                />
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
                <Card type="content" className={styles['card']}>
                  <Row className={styles['alerts']}>
                    <Icon aria-hidden="true" name="card-off" resolution="low" className={styles['alert-icon']} alt="" />
                    <Col xs={3} sm={7} md={7} lg={11} className={styles['alerts-offering']}>
                      <Row>
                        <Col>
                          <div className={styles['service-offering']}>
                            <div className={styles['service-offering-title']}>
                              <Typography tag="p" className={styles['label']} id="alerts-declined-part1">
                                {t('alerts.declined.part1')}
                              </Typography>
                              <Typography tag="p" className={styles['description']} id="alerts-declined-part2">
                                {t('alerts.declined.part2')}
                              </Typography>
                              {cardControls.temporaryLock && (
                                <Typography tag="p" className={styles['label']} id="alerts-declined-part3">
                                  <Link
                                    to={RedirectRoutes.homeCardControl}
                                    onClick={handleRedirectToCardControl}
                                    className={styles['label-link']}
                                  >
                                    {t('alerts.unlockCard1')}
                                  </Link>
                                  {t('alerts.unlockCard2')}
                                </Typography>
                              )}
                            </div>
                            <div className={styles['service-offering-channels']}>
                              <div className={Utils.isLac() ? styles['hide-sms'] : styles['sms']}>
                                <Typography
                                  tag="p"
                                  className={`${styles['label']} ${cardControls.temporaryLock ? styles['disabled-label'] : ''
                                    }`}
                                  id="declined-sms-label"
                                >
                                  {t('alerts.text')}
                                </Typography>
                                <Checkbox
                                  id="declined-sms"
                                  value="Declined"
                                  label=""
                                  aria-label={t('alerts.text')}
                                  checked={isDefaultSelected(ServiceOfferings.DECLINED, ContactDetail.SMS)}
                                  disabled={!isVerifiedContactAdded || cardControls.temporaryLock}
                                  onChange={e =>
                                    onCheckboxChecked(
                                      e,
                                      ContactDetail.SMS,
                                      getVerifiedChannel(ContactDetail.SMS)?.contactId
                                    )
                                  }
                                  aria-describedby="alerts-declined-part1 alerts-declined-part2 alerts-declined-part3 declined-sms-label"
                                />
                              </div>
                              <div className={styles['email']}>
                                <Typography
                                  tag="p"
                                  className={`${styles['label']} ${cardControls.temporaryLock ? styles['disabled-label'] : ''
                                    }`}
                                  id="declined-email-label"
                                >
                                  {t('alerts.email')}
                                </Typography>
                                <Checkbox
                                  id="declined-email"
                                  value="Declined"
                                  label=""
                                  aria-label={t('alerts.email')}
                                  onChange={e =>
                                    onCheckboxChecked(
                                      e,
                                      ContactEventType.EMAIL,
                                      getVerifiedChannel(ContactEventType.EMAIL)?.contactId
                                    )
                                  }
                                  checked={!!isDefaultChecked(ServiceOfferings.DECLINED, ContactEventType.EMAIL)}
                                  disabled={!getVerifiedChannel(ContactEventType.EMAIL) || cardControls.temporaryLock}
                                  aria-describedby="alerts-declined-part1 alerts-declined-part2 alerts-declined-part3 declined-email-label"
                                />
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
                <Card type="content" className={styles['card']}>
                  <Row className={styles['alerts']}>
                    <Icon
                      aria-hidden="true"
                      name="transit-airplane"
                      resolution="low"
                      className={styles['alert-icon']}
                      alt="International"
                    />
                    <Col xs={3} sm={7} md={7} lg={11} className={styles['alerts-offering']}>
                      <Row>
                        <Col>
                          <div className={styles['service-offering']}>
                            <div className={styles['service-offering-title']}>
                              <Typography tag="p" className={styles['label']} id="alerts-crossBorder-part1">
                                {t('alerts.crossBorder.part1')}
                              </Typography>
                              <Typography tag="p" className={styles['description']} id="alerts-crossBorder-part2">
                                {t('alerts.crossBorder.part2')}
                              </Typography>
                            </div>
                            <div className={styles['service-offering-channels']}>
                              <div className={Utils.isLac() ? styles['hide-sms'] : styles['sms']}>
                                <Typography tag="p" className={styles['label']} id="crossBorder-sms-label">
                                  {t('alerts.text')}
                                </Typography>
                                <Checkbox
                                  id="crossBorder-sms"
                                  value="CrossBorder"
                                  aria-label={t('alerts.text')}
                                  label=""
                                  checked={isDefaultSelected(ServiceOfferings.CROSSBORDER, ContactDetail.SMS)}
                                  disabled={!isVerifiedContactAdded}
                                  onChange={e =>
                                    onCheckboxChecked(
                                      e,
                                      ContactDetail.SMS,
                                      getVerifiedChannel(ContactDetail.SMS)?.contactId
                                    )
                                  }
                                  aria-describedby="alerts-crossBorder-part1 alerts-crossBorder-part2 crossBorder-sms-label"
                                />
                              </div>
                              <div className={styles['email']}>
                                <Typography tag="p" className={styles['label']} id="crossBorder-email-label">
                                  {t('alerts.email')}
                                </Typography>
                                <Checkbox
                                  id="crossBorder-email"
                                  value="CrossBorder"
                                  label=""
                                  aria-label={t('alerts.email')}
                                  onChange={e =>
                                    onCheckboxChecked(
                                      e,
                                      ContactEventType.EMAIL,
                                      getVerifiedChannel(ContactEventType.EMAIL)?.contactId
                                    )
                                  }
                                  checked={!!isDefaultChecked(ServiceOfferings.CROSSBORDER, ContactEventType.EMAIL)}
                                  disabled={!getVerifiedChannel(ContactEventType.EMAIL)}
                                  aria-describedby="alerts-crossBorder-part1 alerts-crossBorder-part2 crossBorder-email-label"
                                />
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
                <Card type="content" className={styles['card']}>
                  <Row className={styles['alerts']}>
                    <Icon aria-hidden="true" name="bill-alt" resolution="low" className={styles['alert-icon']} alt="" />
                    <Col xs={3} sm={7} md={7} lg={11} className={styles['alerts-offering']}>
                      <Row>
                        <Col>
                          <div className={styles['service-offering']}>
                            <div className={styles['service-offering-title']}>
                              <Typography tag="p" className={styles['label']} id="alerts-genericCredit-part1">
                                {t('alerts.genericCredit.part1')}
                              </Typography>
                              <Typography tag="p" className={styles['description']} id="alerts-genericCredit-part2">
                                {t('alerts.genericCredit.part2')}
                              </Typography>
                            </div>
                            <div className={styles['service-offering-channels']}>
                              <div className={Utils.isLac() ? styles['hide-sms'] : styles['sms']}>
                                <Typography tag="p" className={styles['label']} id="genericCredit-sms-label">
                                  {t('alerts.text')}
                                </Typography>
                                <Checkbox
                                  id="genericCredit-sms"
                                  value="GenericCredit"
                                  aria-label={t('alerts.text')}
                                  label=""
                                  checked={isDefaultSelected(ServiceOfferings.GENERICCREDIT, ContactDetail.SMS)}
                                  disabled={!isVerifiedContactAdded}
                                  onChange={e =>
                                    onCheckboxChecked(
                                      e,
                                      ContactDetail.SMS,
                                      getVerifiedChannel(ContactDetail.SMS)?.contactId
                                    )
                                  }
                                  aria-describedby="alerts-genericCredit-part1 alerts-genericCredit-part2 genericCredit-sms-label"
                                />
                              </div>
                              <div className={styles['email']}>
                                <Typography tag="p" className={styles['label']} id="genericCredit-email-label">
                                  {t('alerts.email')}
                                </Typography>
                                <Checkbox
                                  id="genericCredit-email"
                                  value="GenericCredit"
                                  aria-label={t('alerts.email')}
                                  label=""
                                  onChange={e =>
                                    onCheckboxChecked(
                                      e,
                                      ContactEventType.EMAIL,
                                      getVerifiedChannel(ContactEventType.EMAIL)?.contactId
                                    )
                                  }
                                  checked={!!isDefaultChecked(ServiceOfferings.GENERICCREDIT, ContactEventType.EMAIL)}
                                  disabled={false || !getVerifiedChannel(ContactEventType.EMAIL)}
                                  aria-describedby="alerts-genericCredit-part1 alerts-genericCredit-part2 genericCredit-email-label"
                                />
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Grid>
        )}
      </>
    </HomeComponent>
  );
};

Alerts.displayName = 'Alerts';

export default Alerts;
