import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { RedirectRoutes } from '../../../constants';
import { ButtonWrapper, DialogWrapper } from '../../core-ui';
import { Utils } from '../../../utils';
import { clearErrorModal } from './error-modal-reducer';

const ErrorModal: React.FC = () => {
  const errorModalStore = useSelector((store: any) => store.errorModal);
  const userAuth = useSelector((store: any) => store.userAuth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const buttonLabel =
    errorModalStore.redirectTo === RedirectRoutes.login ? t('errorModal.returnToSignIn') : t('errorModal.returnToHome');

  const handleErrorModalButton = () => {
    //handle navigation make api calls if post login to pre login
    if (errorModalStore.redirectTo === RedirectRoutes.login && (userAuth.isFullyLogged || userAuth.isPartiallyLogged)) {
      Utils.invalidateSession(dispatch);
    }
    navigate(errorModalStore.redirectTo);
    dispatch(clearErrorModal());
  };

  const bodyDialogComponent = <span id="modal-error-message">{t(errorModalStore.message)}</span>;
  const actionDialogButtons = (
    <ButtonWrapper
      type="button"
      tag="button"
      isFullWidth
      colorScheme="primary"
      label={buttonLabel}
      ariaLabel={buttonLabel}
      aria-describedby="modal-error-message"
      handleClick={handleErrorModalButton}
    />
  );

  return errorModalStore.show ? (
    <DialogWrapper
      role="alertdialog"
      hasCloseIcon={false}
      open={errorModalStore.show}
      body={bodyDialogComponent}
      actionButtons={actionDialogButtons}
    />
  ) : null;
};

export default ErrorModal;
