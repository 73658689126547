import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { VerifyCode, showAlert } from '../../../elements/shared';
import { GlobalConstants, RedirectRoutes } from '../../../constants';
import { getLocale } from '../../../utils/utils';
import { ContactDetail } from '../../../constants/user-profile';
import { Utils } from '../../../utils';
import { postIdentityVerify, getContactUnverified } from './enroll-idv-reducer-slice';
import { postOtvc } from './enroll-idv-reducer-slice';

type EnrollIdvVerifyProps = {
  [key: string]: any;
};

const EnrollIdvVerify: React.FC<EnrollIdvVerifyProps> = () => {
  const { enroll, enrollIdvVerify, userAuth, anotherEmail } = useSelector((store: any) => store);
  const getContact = () => {
    if (userAuth.userContactsDetails && userAuth.userContactsDetails.length > 0) {
      return userAuth.userContactsDetails.contactValue;
    } else {
      return enroll.formData.email;
    }
  };
  const [contactValueV2, setContactValueV2] = useState(getContact());
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleCancel = () => {
    return;
  };

  const currentEmailContext = useMemo(() => {
    if (enrollIdvVerify.unverifiedSuccess) {
      const contacts = enrollIdvVerify.vuser.contacts;
      if (Array.isArray(contacts) && Boolean(contacts.length)) {
        const primaryEmailContact = contacts.find(
          contact => contact.contactPreference == ContactDetail.PRIMARY && contact.contactType == ContactDetail.EMAIL
        );
        if (primaryEmailContact) {
          const context = primaryEmailContact;
          return context;
        }
      }
    }
  }, [enrollIdvVerify.unverifiedSuccess]);

  const getPayload = (data?: any) => {
    const payload: { [key: string]: any } = {};

    payload.contacts = {
      contactsUnverified: 'unverified'
    };
    payload.idvPayload = {
      grant_type: 'identity_verification',
      locale: 'en-US',
      contextKey: currentEmailContext?.guid,
      eventType: 'IDENTITY_VERIFICATION',
      verificationType: 'OTP_LONG',
      verificationValue: data?.otpCode,
      context: {
        channel: 'Web'
      }
    };
    payload.otvcPayload = {
      contactType: 'EMAIL',
      eventType: 'IDENTITY_VERIFICATION',
      otvcType: 'LONG',
      locale: getLocale(),
      guid: userAuth?.emailGuid
    };
    return payload;
  };

  const handleSubmit = data => {
    Utils.hideServerAlert(dispatch);
    dispatch(postIdentityVerify(getPayload(data)));
  };
  const handleResend = () => {
    dispatch(postOtvc(getPayload()));
    dispatch(
      showAlert({
        type: 'SUCCESS',
        message: 'verify_code.success.codeSent'
      })
    );
  };

  // useEffect(() => {
  //   if (!enroll.userGuid) {
  //     navigate(RedirectRoutes.check);
  //   }
  // }, [enroll.userGuid]);

  useEffect(() => {
    dispatch(postOtvc(getPayload()));
  }, []);

  useEffect(() => {
    if (anotherEmail.newEmail != '') {
      setContactValueV2(anotherEmail.newEmail);
    }
  }, [anotherEmail.newEmail]);

  useEffect(() => {
    if (enrollIdvVerify.idvSuccess) {
      navigate(RedirectRoutes.createAccountAddCard);
    }
  }, [enrollIdvVerify.idvSuccess]);
  return (
    <div>
      <VerifyCode
        contactValueMasking={true}
        submitHandler={handleSubmit}
        cancelHandler={handleCancel}
        resendHandler={handleResend}
        contactValue={
          contactValueV2 ?? GlobalConstants.emailDummyValue //https://jira.trusted.visa.com/browse/VMCP-14796
        }
      />
    </div>
  );
};
export default EnrollIdvVerify;
