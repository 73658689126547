import React from 'react';
import { Checkbox, CheckboxErrorMessage, Icon } from '@visa/vds';
import { Controller, Noop } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CheckboxWrapperProps } from '../model';
import Styles from './checkbox.module.scss';

const CheckboxWrapper: React.FC<CheckboxWrapperProps> = ({ id, required, methods, label, name, handleBlur }) => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    control
  } = methods;

  const hancleCheckboxBlur = (blur: Noop) => {
    handleBlur && handleBlur(name);
    blur();
  };
  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          return (
            <Checkbox
              {...field}
              id={id}
              aria-required={Boolean(required)}
              invalid={errors?.[name] ? true : false}
              label={label}
              onBlur={() => hancleCheckboxBlur(field.onBlur)}
              aria-describedby="checkbox-error-message"
            />
          );
        }}
      />
      <CheckboxErrorMessage className={Styles['error']} id="checkbox-error-message">
        {errors?.[name] && (
          <React.Fragment>
            <Icon name="error" resolution="tiny" />
            {t(`${errors?.[name]?.message}`)}
          </React.Fragment>
        )}
      </CheckboxErrorMessage>
    </>
  );
};

export default CheckboxWrapper;
