import { put, takeLatest, call, select } from 'redux-saga/effects';
import { RedirectRoutes, GlobalConstants } from '../constants';
import { ajaxHandler, apiPaths } from '../setup';
import { Utils } from '../utils';
import { postTokenRenewal, postTokenRenewalSuccess, postTokenRenewalFailure } from './user-auth-reducer';

function* postTokenRenewalHandler(action) {
  const { userAuth } = yield select((store: any) => store);
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    Authorization: 'Bearer ' + userAuth.accessToken
  };
  const response = yield call(ajaxHandler, apiPaths.b2cKeepAliveUrl, false, {
    headers,
    method: 'post',
    data: Utils.encodeUsingUrlEncoding({ grant_type: 'access_token_renewal' }),
    urlWithPlaceholder: apiPaths.b2cKeepAliveUrl
  });
  if (!response.error) {
    yield put(postTokenRenewalSuccess(response.data));
  } else {
    yield put(postTokenRenewalFailure(response.error));
    Utils.invalidateSession(action.payload.dispatch);
    action.payload.navigate(RedirectRoutes.login);
  }
}

export default function* watchUserAuth() {
  yield takeLatest(postTokenRenewal, postTokenRenewalHandler);
}
