import React from 'react';
import { Icon, ButtonIcon } from '@visa/vds';
import LogoBlue from '@visa/vds/_logos/visa/visa_vbm_blu.svg';
import LogoWhite from '@visa/vds/_logos/visa/visa_vbm_wht.svg';
import NavBrandLogoImage from '@visa/vds/nav-brand-logo-image';
import { useTranslation } from 'react-i18next';
import styles from './card-art.module.scss';

type CardArtProps = {
  showAdd?: boolean;
  theme?: 'grey' | 'blue';
  className?: string;
  handleIconClick?: (e: any) => void;
};

const CardArtComponent: React.FC<CardArtProps> = props => {
  const { theme = '', className = '', showAdd = false, handleIconClick = f => f } = props;

  const { t } = useTranslation();

  const themeLogoMap = {
    blue: LogoWhite,
    grey: LogoBlue
  };

  const visaLogo = themeLogoMap[theme];

  const wrapperClass = () => {
    const defaultClass = styles['wrapper'];
    const themeClassMap = {
      blue: 'wrapper-blue',
      grey: 'wrapper-grey'
    };
    if (theme || className) {
      return `cardart-wrapper ${defaultClass} ${styles[themeClassMap[theme]]} ${props.className}`;
    }
    return defaultClass;
  };

  return (
    <div className={wrapperClass()}>
      {showAdd ? (
        <ButtonIcon onClick={handleIconClick} ariaLabel={t('noCards.addCardBtn')} className={styles['add-card-button']}>
          <div className={styles['add-icon-background']}></div>

          <Icon name="add" resolution="low" className={styles['plus-icon']} />
        </ButtonIcon>
      ) : null}
      <NavBrandLogoImage alt="Visa" src={visaLogo} className={styles['logo']} />
    </div>
  );
};

export default CardArtComponent;
