import { createSlice } from '@reduxjs/toolkit';
import { resetAppData } from '../../../app/app-reducer';

interface EmailVerify {
  response: any;
  contactsVerifiedSuccess: boolean;
  otvcSuccess: boolean;
  twoFactorSuccess: boolean;
  username: string;
  twoFactorResponseError: any;
  vuser: any;
  contactsVerifiedResponseError: any;
}
const initialState: EmailVerify = {
  response: {},
  contactsVerifiedSuccess: false,
  otvcSuccess: false,
  twoFactorSuccess: false,
  username: '',
  twoFactorResponseError: {},
  vuser: {},
  contactsVerifiedResponseError: {}
};

const emailVerifyReducer = createSlice({
  name: 'emailVerify',
  initialState,
  reducers: {
    /// contacts verified
    getContactsVerified: (state: EmailVerify, action) => {
      return state;
    },
    getContactsVerifiedSuccess: (state: EmailVerify, action) => {
      const { vuser } = action.payload;
      return {
        ...state,
        vuser,
        contactsVerifiedSuccess: true
      };
    },
    getContactsVerifiedFailure: (state: EmailVerify, action) => {
      return {
        ...state
      };
    },
    //// otvc reducers
    putOtpVerification: (state: EmailVerify, action) => {
      return state;
    },
    putOtpVerificationSuccess: (state: EmailVerify, action) => {
      return {
        ...state,
        otvcSuccess: action.payload.flag
      };
    },
    putOtpVerificationFailure: (state: EmailVerify, action) => {
      return state;
    },
    postVerifyTwoFactor: (state: EmailVerify, action) => {
      return state;
    },
    postVerifyTwoFactorSuccess: (state: EmailVerify, action) => {
      return {
        ...state,
        twoFactorSuccess: true,
        response: {
          ...action.payload.data
        }
      };
    },
    postVerifyTwoFactorFailure: (state: EmailVerify, action) => {
      // return state;
      return {
        ...state,
        twoFactorResponseError: action.payload.response.data
      };
    },
    resetSuccessFlags: (state: EmailVerify) => {
      state.contactsVerifiedSuccess = false;
      state.otvcSuccess = false;
      state.twoFactorSuccess = false;
      return state;
    }
  },
  extraReducers: builder => {
    builder.addCase(resetAppData, () => {
      return initialState;
    });
  }
});
export const {
  postVerifyTwoFactor,
  postVerifyTwoFactorSuccess,
  postVerifyTwoFactorFailure,
  getContactsVerified,
  getContactsVerifiedSuccess,
  getContactsVerifiedFailure,
  putOtpVerification,
  putOtpVerificationSuccess,
  putOtpVerificationFailure,
  resetSuccessFlags
} = emailVerifyReducer.actions;

export default emailVerifyReducer.reducer;
