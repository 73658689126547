export const maskHelper = {
  email: (email = '') => {
    if (email.includes('@')) {
      const arr = email.split('@');
      const f = arr[0][0];

      const masked = f + '******' + '@' + arr[1]; //  fix MOBENB-17528
      return masked;
    }
    return '';
  },

  phone: phoneNo => {
    let maskedPhoneNo = '';
    if (!phoneNo) {
      maskedPhoneNo = '';
    } else {
      const length = phoneNo.length;
      const maxLength = Math.floor(length / 3);
      const unMaskedCharLength = length - Math.min(3, maxLength);
      const maskedChar = '******';
      maskedPhoneNo = maskedChar + phoneNo.substring(unMaskedCharLength, length);
    }
    return maskedPhoneNo;
  }
};
