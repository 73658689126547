const GlobalConstants = {
  // serverSessionId: (<HTMLInputElement>document.getElementById('serverSessionId'))?.value || 'test',
  correlationId: (<HTMLInputElement>document.getElementById('correlationId'))?.value || 'test',
  //isAuth2: sessionStorage && sessionStorage.getItem('vimConFeatureToggleFlag') == 'true' ? false : true, //toto
  extAppId: (<HTMLInputElement>document.getElementById('extAppId'))?.value || '7d955368-76f3-4862-410a-18d406710101',
  apiBase: (<HTMLInputElement>document.getElementById('prefixEnvVar'))?.value || '/pas',
  assetsPrefix: (<HTMLInputElement>document.getElementById('assetsPreFix'))?.value || '/vca-web',
  // thmId: (<HTMLInputElement>document.getElementById('serverSessionId'))?.value || 'test',
  // dfpSessionId: (<HTMLInputElement>document.getElementById('serverSessionId'))?.value || 'test',
  isProd: ['prod', 'production'].includes(process.env.NODE_ENV),
  usCountryCode: '001',
  emailDummyValue: '*****@***.***',
  rootPath: 'vca-web',
  sdkVersion: 'V1',
  defaultSdkUrl: 'https://vdca-web-server-dev.k8s-np-cls1-p.trusted.visa.com'
};

export default GlobalConstants;
