import React, { useEffect } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { RedirectRoutes } from '../../../constants';
import useTranslator from '../../../hooks/translation-hook';
import { GenericPanelComponent, showAlert } from '../../../elements/shared';
import { ButtonWrapper, InputWrapper, FormWrapper } from '../../../elements/core-ui';
import { Validator } from '../../../utils';
import { useTitle } from '../../../hooks/use-document-title';
import { setFullyLogged } from '../../../auth/user-auth-reducer';
import { forcePasswordResetUpdateAccessToken, forcePasswordReset } from './force-password-reset-reducer';

type ForcePasswordResetProps = {
  [key: string]: any;
};

const ForcePasswordReset: React.FC<ForcePasswordResetProps> = props => {
  const navigate = useNavigate();
  const t = useTranslator();
  const dispatch = useDispatch();

  useTitle('app.title.forcePasswordReset');
  const { forgotPassword, forceResetPassword, userAuth } = useSelector((store: any) => store);

  const initialValues = {
    userName: forgotPassword.username,
    newPassword: ''
  };
  const schema = yup.object({
    newPassword: yup
      .string()
      .required('resetPassword.model.attributes.password.blank')
      .min(8, 'resetPassword.model.attributes.password.minLength')
      .test((value: any, context: any) => Validator.passwordValidator(value, context))
  });
  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: initialValues,
    resolver: yupResolver(schema)
  });

  const handleForcePasswordResetClick = (data: any) => {
    const config = {
      t,
      navigate,
      state: forgotPassword,
      newPassword: data.newPassword
    };
    dispatch(forcePasswordReset(config));
  };
  const handleCancelClick = () => {
    navigate(RedirectRoutes.login);
  };

  useEffect(() => {
    const state = {
      ...forceResetPassword
    };
    const config = {
      t,
      state,
      navigate
    };
    if (forceResetPassword.forcePasswordResetSuccess) {
      dispatch(forcePasswordResetUpdateAccessToken(config));
    }
  }, [forceResetPassword.forcePasswordResetSuccess]);

  const handleContinue = () => {
    dispatch(setFullyLogged());
    dispatch(
      showAlert({
        type: 'SUCCESS',
        message: 'signin.lastLoginTime',
        isDynamic: true,
        dynamicParts: [userAuth.lastLoginTimeStamp]
      })
    );
    navigate(RedirectRoutes.home);
  };

  const bodyComponent = (
    <FormWrapper onSubmit={methods.handleSubmit(handleForcePasswordResetClick)}>
      <InputWrapper
        id="newPassword"
        required={true}
        type={'password'}
        methods={methods}
        name={'newPassword'}
        hidePasswordIcon={true}
        label={t('resetPassword.newPassword')}
      />
      <>
        <ButtonWrapper
          isFullWidth
          tag="button"
          type="submit"
          colorScheme="primary"
          ariaLabel={t('resetPassword.btnSubmit')}
          label={t('resetPassword.btnSubmit')}
        />
        <ButtonWrapper
          isFullWidth
          colorScheme="tertiary"
          handleClick={handleCancelClick}
          ariaLabel={t('resetPassword.btnCancel')}
          label={t('resetPassword.btnCancel')}
        />
      </>
    </FormWrapper>
  );

  return userAuth.token2Success ? (
    <GenericPanelComponent
      bodyComponent={
        <ButtonWrapper
          isFullWidth={true}
          tag="button"
          handleClick={handleContinue}
          colorScheme="primary"
          ariaLabel={t('forgotPassword.btnContinue')}
          label={t('forgotPassword.btnContinue')}
        />
      }
      panelMainText={t('updatePassword.success')}
    />
  ) : (
    <GenericPanelComponent
      bodyComponent={bodyComponent}
      pageTitle={t('forcePasswordReset.heading')}
      panelSubText={t('forcePasswordReset.passwordPolicy')}
    />
  );
};

export default ForcePasswordReset;
