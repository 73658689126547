import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Avatar from '@visa/vds/avatar';
import AvatarPhoto from '@visa/vds/avatar-photo';
import Button from '@visa/vds/button';
import ButtonGroup from '@visa/vds/button-group';
import ButtonText from '@visa/vds/button-text';
import Col from '@visa/vds/col';
import Grid from '@visa/vds/grid';
import Nav from '@visa/vds/nav';
import Navmenu from '@visa/vds/navmenu';
import NavBrand from '@visa/vds/nav-brand';
import NavBrandInfo from '@visa/vds/nav-brand-info';
import NavBrandLogo from '@visa/vds/nav-brand-logo';
import NavBrandLogoImage from '@visa/vds/nav-brand-logo-image';
import NavUser from '@visa/vds/nav-user';
import Listbox from '@visa/vds/listbox';
import ListboxItem from '@visa/vds/listbox-item';
import ListboxItemContainer from '@visa/vds/listbox-item-container';
import Icon from '@visa/vds/icon';
import Row from '@visa/vds/row';
import Typography from '@visa/vds/typography';
import { Link, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LogoBlue from '@visa/vds/_logos/visa/visa_vbm_blu.svg';
// '@visa/vds/_logos/visa/visa_vbm_blu.svg';
import { RedirectRoutes } from '../../../constants';
import { resetUserAuth } from '../../../auth/user-auth-reducer';
import { Utils } from '../../../utils';
import { resetSuccessFlags } from '../../../pages/sign-in/sign-in/sign-in-reducer-slice';
import { setReloadFlag } from '../../../pages/home/home-reducer';
import styles from './header.module.scss';

type headerProps = {
  [key: string]: any;
};

const Header: React.FC<headerProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [buttonText, setButtonText] = useState<any>('header.button.signup');
  const { userAuth } = useSelector((store: any) => store);
  const navigate = useNavigate();
  const location = useLocation();

  const handleRedirect = () => {
    Utils.hideServerAlert(dispatch);
    dispatch(resetUserAuth());
    dispatch(resetSuccessFlags());
    if (buttonText === 'header.button.signin') {
      setButtonText('header.button.signup');
      navigate(RedirectRoutes.login);
    } else if (buttonText === 'header.button.signup') {
      setButtonText('header.button.signin');
      navigate(RedirectRoutes.check);
    }
  };
  const handleSignOut = () => {
    Utils.hideServerAlert(dispatch);
    Utils.invalidateSession(dispatch);
  };

  const hideButton = () => {
    // hide signup / signin button eg : unsubscribe page
    const paths = [RedirectRoutes.unsubscribe, RedirectRoutes.supportedCarriers, RedirectRoutes.deleteProfile];
    return !paths.some(path => location.pathname === path);
  };

  useEffect(() => {
    const paths = [
      RedirectRoutes.forgotPassword,
      RedirectRoutes.forgotPasswordVerifyCode,
      RedirectRoutes.resetPassword,
      RedirectRoutes.loginEditEmail,
      RedirectRoutes.forgotUsername,
      RedirectRoutes.loginChallengeVerifyEmail,
      RedirectRoutes.check,
      RedirectRoutes.createAccountEnroll,
      RedirectRoutes.createAccountVerifyCode,
      RedirectRoutes.createAccountAddCard,
      RedirectRoutes.acceptPendingTnc,
      RedirectRoutes.createAccountEditEmail
    ];
    const result = paths.some(path => {
      return location.pathname === path;
    });
    if (result) {
      setButtonText('header.button.signin');
    } else {
      setButtonText('header.button.signup');
    }
  }, [location.pathname]);

  const handleClearAlert = () => {
    Utils.hideServerAlert(dispatch);
    dispatch(setReloadFlag(false));
  };

  return (
    <Nav type="white" className={styles['header-container']} role="banner">
      <Grid type="fluid">
        <Row>
          <Col>
            <NavBrand className={styles['navbrand']}>
              <NavBrandInfo>
                <NavBrandLogo
                  className={styles['nav-logo']}
                  tag={
                    <NavLink
                      to={userAuth.isFullyLogged ? RedirectRoutes.home : RedirectRoutes.login}
                      state={{ from: '/reactNavigation' }}
                      onClick={handleClearAlert}
                    />
                  }
                >
                  <NavBrandLogoImage alt={t('header.logoAlt')} src={LogoBlue} />
                  <Typography className={styles['nav-cobrand']}>{t('header.heading')}</Typography>
                </NavBrandLogo>
              </NavBrandInfo>
            </NavBrand>
            {hideButton() ? (
              <NavUser>
                {userAuth.isFullyLogged ? (
                  <Navmenu
                    closeOnSelect={true}
                    className={styles['profile-nav-dropdown']}
                    menu={
                      <Listbox className={styles['profile-nav-list']}>
                        <ListboxItem>
                          <ListboxItemContainer>
                            <Link to={RedirectRoutes.profile} onClick={handleClearAlert}>
                              {t('header.myAccount')}
                            </Link>
                          </ListboxItemContainer>
                        </ListboxItem>
                        <ListboxItem>
                          <ListboxItemContainer className={styles['logout-btn-text']}>
                            {/* <Typography onClick={() => handleSignOut()}>Sign out</Typography> */}
                            <Link to={RedirectRoutes.login} onClick={() => handleSignOut()}>
                              {t('header.signOut')}
                            </Link>
                          </ListboxItemContainer>
                        </ListboxItem>
                      </Listbox>
                    }
                    placement="bottom-end"
                  >
                    <Button flippable className={styles['profile-nav-button']} aria-label={t('app.profile')}>
                      <Avatar className={styles['vds-avatar']}>
                        <AvatarPhoto label={t('app.profile')}>
                          <Icon name="account" resolution="low" aria-hidden="false" />
                        </AvatarPhoto>
                      </Avatar>
                    </Button>
                  </Navmenu>
                ) : (
                  <ButtonGroup>
                    <ButtonText
                      tag="button"
                      className={styles['button-text']}
                      colorScheme="secondary"
                      onClick={() => handleRedirect()}
                      aria-label={t(buttonText)}
                    >
                      {t(buttonText)}
                    </ButtonText>
                  </ButtonGroup>
                )}
              </NavUser>
            ) : null}
          </Col>
        </Row>
      </Grid>
    </Nav>
  );
};
export default Header;
