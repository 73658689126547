import React from 'react';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Typography } from '@visa/vds';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import useTranslator from '../../../hooks/translation-hook';
import { GenericPanelComponent } from '../../../elements/shared';
import { ButtonWrapper, FormWrapper, InputWrapper } from '../../../elements/core-ui';
import { AllRegex, RedirectRoutes, GlobalConstants } from '../../../constants';
import { ContactDetail } from '../../../constants/user-profile';
import { getContactValue } from '../../../utils/utils';
import { useTitle } from '../../../hooks/use-document-title';
import { changeUserEmail } from './change-email-reducer';
import { Utils} from '../../../utils';

const ChangeEmail: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const t = useTranslator();
  const { signin, changeEmail, userAuth, userDetails } = useSelector((store: any) => store);

  useTitle('app.title.changeEmail');

  const initialValues = {
    email: ''
  };
  const schema = yup.object({
    email: yup
      .string()
      .required('update_email.validation.blank')
      .min(7, 'update_email.validation.minLength')
      .matches(AllRegex.emailRegex, {
        excludeEmptyString: true,
        message: 'update_email.validation.invalid'
      })
  });
  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: initialValues,
    resolver: yupResolver(schema)
  });

  const handleContinueClick = (data: any) => {
    const state = {
      ...changeEmail,
      userGuid: userAuth.userGuid,
      accessToken: userAuth.accessToken
    };
    const config = {
      t,
      state,
      navigate,
      newEmail: data.email
    };
    dispatch(changeUserEmail(config));
  };
  const handleCancelClick = () => {
    navigate(RedirectRoutes.profile);
  };

  const bodyComponent = (
    <>
      <Row>
        <Typography variant="body--alt">{t('update_email.current_email')}</Typography>
      </Row>
      <Row>
        <Typography variant="body">
        {getContactValue({contacts:[userAuth.userContactsDetails]}, ContactDetail.PRIMARY, ContactDetail.EMAIL)}
       
        </Typography>
      </Row>
      <FormWrapper onSubmit={methods.handleSubmit(handleContinueClick)}>
        <InputWrapper
          id="email"
          type={'email'}
          name={'email'}
          required={true}
          methods={methods}
          label={t('update_email.label')}
          contentBeforeInputIconName="email"
        />
        <>
          <ButtonWrapper
            id="updateEmailBtn"
            isFullWidth
            tag="button"
            type="submit"
            colorScheme="primary"
            ariaLabel={t('update_email.update')}
            label={t('update_email.update')}
          />
          <ButtonWrapper
            isFullWidth
            colorScheme="tertiary"
            ariaLabel={t('update_email.cancel')}
            label={t('update_email.cancel')}
            handleClick={handleCancelClick}
          />
        </>
      </FormWrapper>
    </>
  );

  return (
    <GenericPanelComponent
      backIcon={true}
      postLogin={true}
      bodyComponent={bodyComponent}
      handleIconClick={handleCancelClick}
      pageTitle={t('update_email.email')}
      panelMainText={t('update_email.title')}
    />
  );
};

export default ChangeEmail;
