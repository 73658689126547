import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface UpdatePhone {
  guid: '';
  updatePhoneSuccess: boolean;
  deletePreviousPhoneSuccess: boolean;
  phoneNumber: string;
  contactType: string;
  isupdatePhoneFailure: boolean;
}

const initialState: UpdatePhone = {
  guid: '',
  updatePhoneSuccess: false,
  deletePreviousPhoneSuccess: false,
  phoneNumber: '',
  contactType: '',
  isupdatePhoneFailure: false
};

const updatePhoneReducer = createSlice({
  name: 'updatePhone',
  initialState,
  reducers: {
    updatePhone: (state: UpdatePhone, action) => {
      return state;
    },
    updatePhoneSuccess: (state: UpdatePhone, action) => {
      return {
        ...state,
        updatePhoneSuccess: true
      };
    },
    updatePhoneFailure: (state: UpdatePhone, action) => {
      return {
        ...state,
        isupdatePhoneFailure: true,
        state: action?.payload
      };
    },
    setPhoneNumber: (state: UpdatePhone, action: PayloadAction<any>) => {
      state.phoneNumber = action.payload;
      return state;
    },
    setGuid: (state: UpdatePhone, action: PayloadAction<any>) => {
      state.guid = action.payload;
      return state;
    },
    setContactType: (state: UpdatePhone, action: PayloadAction<any>) => {
      state.contactType = action.payload;
      return state;
    },
    resetUpdatePhoneFlags: (state: UpdatePhone) => {
      state.updatePhoneSuccess = false;
      state.deletePreviousPhoneSuccess = false;
      state.isupdatePhoneFailure = false;
      return state;
    },
    deletePreviousPhone: (state: UpdatePhone) => {
      return state;
    },
    deletePreviousPhoneSuccess: (state: UpdatePhone, action) => {
      return {
        ...state,
        deletePreviousPhoneSuccess: true
      };
    },
    deletePreviousPhoneFailure: (state: UpdatePhone, action) => {
      state = action?.payload;
      return state;
    }
  }
});

export const {
  updatePhone,
  updatePhoneSuccess,
  updatePhoneFailure,
  resetUpdatePhoneFlags,
  setPhoneNumber,
  deletePreviousPhone,
  deletePreviousPhoneSuccess,
  deletePreviousPhoneFailure,
  setContactType,
  setGuid
} = updatePhoneReducer.actions;

export default updatePhoneReducer.reducer;
