export enum CardControlType {
  TEMPORARY_LOCK = 'TEMPORARY_LOCK',
  TCT_E_COMMERCE = 'TCT_E_COMMERCE',
  TCT_CROSS_BORDER = 'TCT_CROSS_BORDER'
}

export enum CardActionType {
  LOCK = 'lock',
  UNLOCK = 'unlock',
  OTHER = 'other'
}
