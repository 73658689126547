import React, { useEffect, useState } from 'react';
import { Grid, Row, Col, Typography, Card, CardContent, Icon, ButtonIcon } from '@visa/vds';
import { NavigationType, useNavigate, useNavigationType } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { maskHelper, Utils } from '../../utils';
import { RedirectRoutes } from '../../constants';
import { GlobalAlert } from '../../elements/shared';
import { ButtonWrapper, DialogWrapper } from '../../elements/core-ui';
import { deleteProfile, getUserDetails } from '../home/home-reducer';
import { ContactDetail, PhoneContactType } from '../../constants/user-profile';
import { useTitle } from '../../hooks/use-document-title';
import { getContactValue } from '../../utils/utils';
import { resetChangePhoneFlags } from './change-email/change-email-reducer';
import Styles from './profile.module.scss';
import { resetUpdatePhoneFlags, setContactType } from './update-phone/update-phone.reducer';

type ProfileProps = {
  [key: string]: any;
};

const Profile: React.FC<ProfileProps> = () => {
  const { checkEligibility, userDetails, userAuth } = useSelector((store: any) => store);
  const fullName = userDetails?.vuser?.firstName + ' ' + userDetails?.vuser?.lastName;
  const countryCode = userDetails?.vuser?.countryCode;
  const navigate = useNavigate();
  const navigationType = useNavigationType();

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [openUpdateDialog, setOpenUpdateDialog] = useState({ open: false });
  const bodyDialogComponent = t('deleteProfile.confirmation');

  let verifiedContact;
  let unVerifiedContact;
  let existingContacts;

  useEffect(() => {
    dispatch(resetUpdatePhoneFlags());
    dispatch(resetChangePhoneFlags());
  }, []);

  useEffect(() => {
    if (navigationType != NavigationType.Pop) {
      Utils.focusToH1(); //to focus to H1 during navigation between pages
    }
  }, []);

  useEffect(() => {
    if (userDetails?.isProfileDeleted) {
      Utils.removeFromBrowserStorage();
      Utils.invalidateSession(dispatch);
    }
  }, [userDetails?.isProfileDeleted]);

  useEffect(() => {
    if (userAuth?.userGuid && userAuth?.accessToken) dispatch(getUserDetails());
  }, []);

  useTitle('app.title.myAccout');

  const getLocale = () => {
    return checkEligibility.countryConfiguration.supportedLocales.find(item => {
      if (item.locale === userDetails?.vuser?.locale) {
        return item;
      }
    });
  };

  const getContact = () => {
    existingContacts = userDetails?.vuser?.contacts.filter(item => {
      if (item.contactType === ContactDetail.PHONE) {
        return item;
      }
    });

    verifiedContact = existingContacts?.find(item => {
      if (item?.verificationStatus === ContactDetail.VERIFIED && item?.contactType === ContactDetail.PHONE) {
        return item;
      }
    });

    unVerifiedContact = existingContacts?.find(item => {
      if (item?.verificationStatus === ContactDetail.UNVERIFIED && item?.contactType === ContactDetail.PHONE) {
        return item;
      }
    });

    return verifiedContact ? verifiedContact : unVerifiedContact;
  };

  const handleClick = itemType => {
    Utils.hideServerAlert(dispatch);
    switch (itemType) {
      case 'password':
        return navigate(RedirectRoutes.updatePassword);
      case 'email':
        return navigate(RedirectRoutes.changeEmail);
      case 'updateLanguage':
        return navigate(RedirectRoutes.messageLanguage);
      case 'mobileNumber':
        return getRoute();
      case 'delete':
        return setOpenUpdateDialog({ open: true });
      default:
        return navigate(RedirectRoutes.profile);
    }
  };

  const getRoute = () => {
    if (verifiedContact) {
      dispatch(setContactType(PhoneContactType.VERIFIED));
      return navigate(RedirectRoutes.editPhone);
    } else if (unVerifiedContact) {
      dispatch(setContactType(PhoneContactType.UNVERIFIED));
      return navigate(RedirectRoutes.editPhone);
    } else if (!existingContacts?.length) {
      dispatch(setContactType(PhoneContactType.ADD));
      return navigate(RedirectRoutes.updatePhone);
    }
  };

  const handleDialogCancelClick = () => {
    setOpenUpdateDialog({ open: false });
    navigate(RedirectRoutes.profile);
  };

  const handleDialogContinueClick = () => {
    const config = {
      t,
      navigate
    };
    dispatch(deleteProfile(config));
  };

  const actionDialogButtons = (
    <>
      <ButtonWrapper
        type="button"
        tag="button"
        isFullWidth
        colorScheme="primary"
        label={t('deleteProfile.dialogueBtnYes')}
        ariaLabel={t('deleteProfile.dialogueBtnYes')}
        handleClick={handleDialogContinueClick}
      />
      <ButtonWrapper
        tag="button"
        type="button"
        isFullWidth
        colorScheme="tertiary"
        label={t('deleteProfile.dialogueBtnCancel')}
        ariaLabel={t('deleteProfile.dialogueBtnCancel')}
        handleClick={handleDialogCancelClick}
      />
    </>
  );
  return openUpdateDialog.open ? (
    <DialogWrapper
      role="alertdialog"
      hasCloseIcon={true}
      open={openUpdateDialog.open}
      title={t('deleteProfile.confirmation')}
      body={t('deleteProfile.dialogBody')}
      handleCloseIconClick={handleDialogCancelClick}
      actionButtons={actionDialogButtons}
      parentActiveElementId={'deleteProfileIcon'}
    />
  ) : (
    <>
      <Grid className={Styles['profile']}>
        <Row>
          <Col>
            <GlobalAlert className={Styles['alert-row']} />
          </Col>
        </Row>
        <Row noGutter>
          <Col>
            <Typography tag="h1" className={`page-title ${Styles['profile-title']}`}>
              {t('profile.myAccout')}
            </Typography>
            <Typography tag="h2" className={`panel-text ${Styles['profile-sub-title']}`}>
              {t('profile.manageYourAccount')}
            </Typography>
          </Col>
        </Row>
        <Row>
          <Col noGutter className={Styles['profile-content']}>
            <Card type="content" className={Styles['profile-card']}>
              <CardContent className="no-padding">
                <Row className={Styles['profile-name']}>
                  <Col>
                    <Typography tag="p" className={Styles['label']}>
                      {t('profile.name')}
                    </Typography>
                    <Typography tag="span" className={Styles['value']}>
                      {fullName ? fullName : ' '}
                    </Typography>
                  </Col>
                </Row>
                <Row className={Styles['profile-item']} onClick={() => handleClick('password')}>
                  <Col lg={11} xs={3} md={7} sm={7}>
                    <Typography tag="p" className={Styles['label']}>
                      {t('profile.password')}
                    </Typography>
                    <Typography tag="span" className={Styles['value']} id="change-password">
                      {t('profile.changeYourPassword')}
                    </Typography>
                  </Col>
                  <Col className={Styles['icon-col']} textAlign="center">
                    <ButtonIcon
                      iconType="light-tiny"
                      aria-describedby={t('profile.changeYourPassword')}
                      ariaLabel={t('profile.changeYourPassword')}
                    >
                      <Icon aria-hidden="false" name="arrow-next" resolution="low" className={Styles['arrow-icon']} />
                    </ButtonIcon>
                  </Col>
                </Row>
                <Row className={Styles['profile-item']} onClick={() => handleClick('email')}>
                  <Col lg={11} xs={3} md={7} sm={7}>
                    <Typography tag="p" className={Styles['label']}>
                      {t('profile.email')}
                    </Typography>
                    <Typography tag="span" className={Styles['value']} id="user-email">
                      {getContactValue(
                        { contacts: [userAuth.userContactsDetails] },
                        ContactDetail.PRIMARY,
                        ContactDetail.EMAIL
                      )}
                    </Typography>
                  </Col>
                  <Col className={Styles['icon-col']} textAlign="center">
                    <ButtonIcon
                      iconType="light-tiny"
                      aria-describedby={t('profile.email')}
                      ariaLabel={t('profile.email')}
                    >
                      <Icon aria-hidden="false" name="arrow-next" resolution="low" className={Styles['arrow-icon']} />
                    </ButtonIcon>
                  </Col>
                </Row>
                {countryCode === 'US' && (
                  <Row className={Styles['profile-item']} onClick={() => handleClick('mobileNumber')}>
                    <Col lg={11} xs={3} md={7} sm={7}>
                      <Typography tag="p" className={Styles['label']}>
                        {t('profile.mobile')}
                      </Typography>
                      {getContact() ? (
                        getContact().verificationStatus === ContactDetail.VERIFIED ? (
                          <>
                            <Typography tag="span" className={Styles['value']} id="user-mobile-number">
                              {maskHelper.phone(getContact().contactValue)}
                            </Typography>
                          </>
                        ) : (
                          <>
                            <Typography tag="span" className={Styles['value']}>
                              {' '}
                              {maskHelper.phone(getContact().contactValue)}{' '}
                            </Typography>
                            <Typography tag="span" className={Styles['unverified-label']}>
                              {t('profile.unverified')}
                            </Typography>
                          </>
                        )
                      ) : (
                        <>
                          <Typography tag="span" className={Styles['value']} id="user-mobile-number">
                            {t('profile.phoneNote')}
                          </Typography>
                        </>
                      )}
                    </Col>
                    <Col className={Styles['icon-col']} textAlign="center">
                      <ButtonIcon
                        iconType="light-tiny"
                        aria-describedby={t('profile.phoneNote')}
                        ariaLabel={t('profile.phoneNote')}
                      >
                        <Icon aria-hidden="false" name="arrow-next" resolution="low" className={Styles['arrow-icon']} />
                      </ButtonIcon>
                    </Col>
                  </Row>
                )}
                {checkEligibility.countryConfiguration?.supportedLocales?.length > 1 && (
                  <Row className={Styles['profile-item']} onClick={() => handleClick('updateLanguage')}>
                    <Col lg={11} xs={3} md={7} sm={7}>
                      <Typography tag="p" className={Styles['label']}>
                        {t('profile.messageLanguage')}
                      </Typography>
                      <Typography tag="span" className={Styles['value']} id="update-language">
                        {getLocale()?.name}
                      </Typography>
                    </Col>
                    <Col className={Styles['icon-col']} textAlign="center">
                      <ButtonIcon
                        iconType="light-tiny"
                        aria-describedby={t('profile.messageLanguage')}
                        ariaLabel={t('profile.messageLanguage')}
                      >
                        <Icon aria-hidden="false" name="arrow-next" resolution="low" className={Styles['arrow-icon']} />
                      </ButtonIcon>
                    </Col>
                  </Row>
                )}
                <Row className={Styles['profile-delete']} onClick={() => handleClick('delete')}>
                  <Col lg={11} xs={3} md={7} sm={7}>
                    <Typography tag="p" className={Styles['delete-label']}>
                      {t('profile.deleteAccount')}
                    </Typography>
                    <Typography tag="p" className={Styles['delete-text']}>
                      {t('profile.delteNote')}
                    </Typography>
                  </Col>
                  <Col className={Styles['icon-col']} textAlign="center">
                    <ButtonIcon
                      iconType="light-tiny"
                      id="deleteProfileIcon"
                      className={Styles['delete-icon-button']}
                      ariaLabel={t('profile.deleteAccount')}
                    >
                      <Icon aria-hidden="false" name="delete" resolution="low" className={Styles['delete-icon']} />
                    </ButtonIcon>
                  </Col>
                </Row>
              </CardContent>
            </Card>
          </Col>
        </Row>
      </Grid>
    </>
  );
};

export default Profile;
