import { put, takeLatest, call, select, all } from 'redux-saga/effects';
import { showAlert } from '../../elements/shared';
import { ajaxHandler, apiPaths } from '../../setup';
import { getContactsbyType, getUnverifiedSecondaryContactsbyType, replaceUserGuid } from '../../utils/utils';
import { getCountryConfigration } from '../../pages/create-account/check/check-reducer-slice';
import { Utils } from '../../utils';
import { showErrorModal } from '../../elements/shared/error-modal/error-modal-reducer';
import { RedirectRoutes } from '../../constants';
import {
  deleteUnverifiedEmail,
  deleteUnverifiedPhone
} from '../../elements/shared/add-edit-card/add-edit-card-reducer';
import { setV2ContactDetails } from '../../auth/user-auth-reducer';
import { ContactDetail } from '../../constants/user-profile';
import {
  getPaymentInstruments,
  getPaymentInstrumentsSuccess,
  getPaymentInstrumentsFailure,
  getUserDetails,
  getUserDetailsFailure,
  getUserDetailsSuccess,
  setPhoneNumber
} from './home-reducer';

function* getUserDetailsHandler(action) {
  const { userAuth, userDetails  } = yield select((state: any) => state);
  const headers = {
    access_token: userAuth.accessToken
  };
  // console.log(userAuth)
  const getUserDetailsURL = replaceUserGuid(apiPaths.secureUrl, userAuth.userGuid);

  const response = yield call(ajaxHandler, getUserDetailsURL, true, {
    headers,
    urlWithPlaceholder: apiPaths.secureUrl
  });

  if (!response.error) {
    const firstUserDetailsCall = userDetails.vuser?.contacts && userDetails.vuser.contacts.length==0;
    yield put(setV2ContactDetails(response.data.vuser));
    yield put(getUserDetailsSuccess(response.data));
    if (response.data.vuser?.countryCode && response.data.vuser?.countryCode !== Utils.getCountryCode()) {
      // once the user is logged in and secure api contains countryCode and locale in its response,
      // and countryCode ane locale in localStorage does not match, then updating the localStorage with the countryCode and locale received from secure api.
      // if it matches do nothing.
      yield put(
        getCountryConfigration({
          country_pref: response.data.vuser.countryCode
        })
      );
    }
    //delete unverified contacts after logging in  MOBENB-19068
    try {
      if (firstUserDetailsCall) {
        const unverifiedSecondaryEmailContacts = getUnverifiedSecondaryContactsbyType(
          response.data.vuser?.contacts,
          ContactDetail.EMAIL
        );
        const unverifiedSecondaryPhoneContacts = getUnverifiedSecondaryContactsbyType(
          response.data.vuser?.contacts,
          ContactDetail.PHONE
        );
        const phoneContacts = getContactsbyType(response.data.vuser?.contacts, ContactDetail.PHONE);
        if (unverifiedSecondaryEmailContacts.length > 0) {
          yield all(
            unverifiedSecondaryEmailContacts.map(contact =>
              put(deleteUnverifiedEmail({ unverifiedEmailContact: contact }))
            )
          );
        }
        //if only one unverified contact is present don't delete it and delete all other unverified contacts if verified contact is present
        if (phoneContacts.length > 1 && unverifiedSecondaryPhoneContacts.length > 0) {
          yield all(
            unverifiedSecondaryPhoneContacts.map(contact =>
              put(deleteUnverifiedPhone({ unverifiedPhoneContact: contact }))
            )
          );
        }
      }
      
    } catch (error) {
   
    }
    
    yield put(setPhoneNumber(response.data));
  } else {
    yield put(getUserDetailsFailure(response.error.response));
    yield put(showErrorModal({ redirectTo: RedirectRoutes.login, message: 'signin.errorCodes.VMCP_001' }));
  }
}

function* getPaymentInstrumentsHandler(action) {
  const { userAuth } = yield select((state: any) => state);
  const { payload } = action;
  const headers = {
    access_token: userAuth.accessToken
  };
  const getPaymentInstrumentsURL = replaceUserGuid(apiPaths.payment.baseUrl, userAuth.userGuid);

  const response = yield call(ajaxHandler, getPaymentInstrumentsURL, true, {
    headers,
    urlWithPlaceholder: apiPaths.payment.baseUrl
  });
  if (!response.error) {
    yield put(getPaymentInstrumentsSuccess(response.data));
  } else {
    yield put(getPaymentInstrumentsFailure(response.error));
    const type = 'ERROR';
    const message = response.error?.reason && `add_edit_card.errorCodes.${response.error.reason}`;
    yield put(showAlert({ type, message }));
  }
}

export default function* watchUserDetails() {
  yield takeLatest(getUserDetails, getUserDetailsHandler);
  yield takeLatest(getPaymentInstruments, getPaymentInstrumentsHandler);
}
