import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, Noop } from 'react-hook-form';
import Input from '@visa/vds/input';
import Icon from '@visa/vds/icon';
import InputOption from '@visa/vds/input-option';
import { InputPhoneWrapperProps } from '../model';
import { addDescribedbyId } from '../../../utils/utils';

const InputPhoneWrapper: React.FC<InputPhoneWrapperProps> = ({
  id = '',
  describedBy = '',
  methods,
  label,
  name,
  type,
  required = false,
  readOnly,
  disabled,
  placeholder,
  maxLength,
  className,
  handleFocus,
  handleBlur,
  contentBeforeInputIconName,
  contentBeforeInputIconResolution,
  formatPhoneNumber
}) => {
  const { t } = useTranslation();
  const {
    formState: { errors },
    control,
    setValue
  } = methods;

  useEffect(() => {
    addDescribedbyId(id, describedBy);
  }, [id, describedBy]);

  const handleContentChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    let formattedNumber = '';
    setValue(name, value);
    if (formatPhoneNumber) {
      const input = value.replace(/\D/g, '').substring(0, 12);

      // Divide numbers in 3 parts :"123-456-7890"
      const first = input.substring(0, 3);
      const middle = input.substring(3, 6);
      const last = input.substring(6);

      if (input.length > 6) {
        formattedNumber = `${first}-${middle}-${last}`;
      } else if (input.length > 3) {
        formattedNumber = `${first}-${middle}`;
      } else if (input.length >= 0) {
        formattedNumber = input;
      }
      setValue(name, formattedNumber);
    } else {
      setValue(name, value);
    }
  };

  const handleInputFocus = () => {
    handleFocus && handleFocus(name);
  };

  const handleInputBlur = (blur: Noop) => {
    handleBlur && handleBlur(name);
    blur();
  };

  const checkInput = (value: string) => {
    if (value.length > 0 && value.length <= 4 && !Number(value)) {
      setValue(name, value.slice(0, value.length - 1));
    }
  };
  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          checkInput(field.value);
          return (
            <Input
              id={id}
              className={className ? className : null}
              contentBeforeInput={
                contentBeforeInputIconName && (
                  <InputOption>
                    <Icon
                      name={contentBeforeInputIconName}
                      alt="phone number"
                      resolution={contentBeforeInputIconResolution || 'low'}
                    />
                  </InputOption>
                )
              }
              label={label}
              type={type}
              {...field}
              onChange={(e: any) => handleContentChange(e)}
              onFocus={() => handleInputFocus()}
              onBlur={() => handleInputBlur(field.onBlur)}
              errorText={t(`${errors?.[name]?.message}`)}
              showErrorText={errors?.[name]?.message ? true : false}
              required={required ? true : false}
              invalid={errors?.[name]?.message && required ? true : false}
              readOnly={readOnly ? true : false}
              disabled={disabled ? true : false}
              placeholder={placeholder ? placeholder : null}
              noValidate
              maxLength={maxLength}
              aria-required={Boolean(required)}
            />
          );
        }}
      />
    </>
  );
};

export default InputPhoneWrapper;
