import React, { useEffect, useState } from 'react';
import { Grid, Row, Col, Typography, Card, Switch } from '@visa/vds';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import HomeComponent from '../home-panel/home-panel';
import useTranslator from '../../../../hooks/translation-hook';
import RedirectRoutes from '../../../../constants/redirect-routes';
import { LinkWrapper, DialogWrapper, ButtonWrapper } from '../../../../elements/core-ui';
import { CardActionType, CardControlType } from '../../../../constants/card-control';
import { useTitle } from '../../../../hooks/use-document-title';
import { Utils } from '../../../../utils';
import { setSelectedTab, Tabs } from '../../home-reducer';
import { updateCardControls } from './card-control-reducer';
import styles from './card-control.module.scss';

const CardControl: React.FC = () => {
  const t = useTranslator();
  const dispatch = useDispatch();
  const { userDetails, cardControls, alerts, userAuth } = useSelector((state: any) => state);
  const [temporaryLock, setTemporaryLock] = useState(false);
  const [internationalTransaction, setInternationalTransaction] = useState(false);
  const [declineOnlineTransaction, setDeclineOnlineTransaction] = useState(false);
  useTitle('app.title.cardControls');

  const [openCardLockDialog, setOpenCardLockDialog] = useState(false);
  const [payload, setPayload] = useState({
    documentId: '',
    globalControls: [
      {
        isControlEnabled: false
      }
    ],
    transactionControls: [
      {
        controlType: CardControlType.TCT_CROSS_BORDER,
        declineThreshold: null,
        isControlEnabled: false
      },
      {
        controlType: CardControlType.TCT_E_COMMERCE,
        declineThreshold: null,
        isControlEnabled: false
      }
    ]
  });

  function getCardControlValue(payload: any, cardControlType: string): any {
    if (payload) {
      if (cardControlType === CardControlType.TEMPORARY_LOCK) {
        return payload.globalControls[0]?.isControlEnabled;
      } else {
        const transactionControls = payload.transactionControls;
        for (const transactionControl of transactionControls) {
          if (transactionControl.controlType == cardControlType) {
            return transactionControl.isControlEnabled;
          }
        }
      }
    }
    return false;
  }

  const initializePayload = () => {
    if (Object.keys(cardControls?.response).length) {
      const tempLock = getCardControlValue(cardControls?.response, CardControlType.TEMPORARY_LOCK);
      const intnlTxn = getCardControlValue(cardControls?.response, CardControlType.TCT_CROSS_BORDER);
      const onlineTxn = getCardControlValue(cardControls?.response, CardControlType.TCT_E_COMMERCE);
      const globalControls = [
        {
          isControlEnabled: tempLock
        }
      ];
      const transactionControls = [
        {
          controlType: CardControlType.TCT_CROSS_BORDER,
          declineThreshold: null,
          isControlEnabled: intnlTxn
        },
        {
          controlType: CardControlType.TCT_E_COMMERCE,
          declineThreshold: null,
          isControlEnabled: onlineTxn
        }
      ];
      const initialPayLoad = {
        documentId: cardControls?.response?.documentId,
        globalControls,
        transactionControls
      };
      setTemporaryLock(tempLock);
      setDeclineOnlineTransaction(onlineTxn);
      setInternationalTransaction(intnlTxn);
      setPayload(initialPayLoad);
    }
  };
  useEffect(() => {
    if (cardControls.isCardControlSupported) {
      initializePayload();
    }
  }, [cardControls.isCardControlSupported]);

  const handleSaveClick = () => {
    let cardLockAction = CardActionType.OTHER;
    const cardLock = cardControls?.response?.globalControls[0]?.isControlEnabled;
    if (!cardLock && temporaryLock) {
      return setOpenCardLockDialog(true);
    }
    if (cardLock && !temporaryLock) {
      cardLockAction = CardActionType.UNLOCK;
    }
    const config = {
      t,
      cardLockAction,
      request: payload
    };
    dispatch(updateCardControls(config));
  };

  const handleCancelClick = () => {
    initializePayload();
  };

  const onValueChange = (e, cardControlType: CardControlType) => {
    const { checked } = e.target;
    const newPayload = JSON.parse(JSON.stringify(payload));
    if (Object.keys(payload).length) {
      //The flag values are toggled instead of directly using the switch e.target.checked because
      // the target value doesn't update unless the user clicks it twice for the first time (in case of true->false)
      if (cardControlType == CardControlType.TEMPORARY_LOCK) {
        setTemporaryLock(checked);
        newPayload['globalControls'][0]['isControlEnabled'] = checked;
      }
      for (const transactionControl of newPayload.transactionControls) {
        if (
          cardControlType == CardControlType.TCT_CROSS_BORDER &&
          transactionControl.controlType == CardControlType.TCT_CROSS_BORDER
        ) {
          setInternationalTransaction(checked);
          transactionControl.isControlEnabled = checked;
        }
        if (
          cardControlType == CardControlType.TCT_E_COMMERCE &&
          transactionControl.controlType == CardControlType.TCT_E_COMMERCE
        ) {
          setDeclineOnlineTransaction(checked);
          transactionControl.isControlEnabled = checked;
        }
      }
      setPayload(newPayload);
    }
  };

  const handleDialogContinueClick = () => {
    const cardLockAction = CardActionType.LOCK;
    const config = {
      t,
      cardLockAction,
      request: payload
    };
    dispatch(updateCardControls(config));
    setOpenCardLockDialog(false);
  };
  const handleDialogCancelClick = () => {
    setOpenCardLockDialog(false);
  };

  const handleRedirectToAlerts = () => {
    dispatch(setSelectedTab(Tabs.ALERTS));
  };

  const actionDialogButtons = (
    <>
      <ButtonWrapper
        type="button"
        tag="button"
        isFullWidth
        colorScheme="primary"
        label={t('cardControl.dialogBtnYes')}
        ariaLabel={t('cardControl.dialogBtnYes')}
        handleClick={handleDialogContinueClick}
      />
      <ButtonWrapper
        tag="button"
        type="button"
        isFullWidth
        colorScheme="tertiary"
        label={t('cardControl.dialogBtnCancel')}
        ariaLabel={t('cardControl.dialogBtnCancel')}
        handleClick={handleDialogCancelClick}
      />
    </>
  );

  const dialogBodyComponent = (
    <>
      <Typography tag="span">{t('cardControl.dialogBodyPart1')}</Typography>
      <LinkWrapper
        // href={RedirectRoutes.terms}
        href={Utils.getTermsLink(userAuth.isFullyLogged)}
        className={styles['dialog-link']}
        ariaLabel={t('cardControl.tncDialogLink')}
        openInNewTab
      >
        {t('cardControl.tncDialogLink')}
      </LinkWrapper>
      <Typography tag="span">{t('cardControl.dialogBodyPart2')}</Typography>
    </>
  );

  return (
    <>
      <HomeComponent
        showActionButtons={true}
        saveBtnId="cardcontrol-save-btn"
        pageTitle={t('cardControl.title')}
        handleSaveBtnClick={handleSaveClick}
        handleCancelBtnClick={handleCancelClick}
        pageSubTitle={t('cardControl.subTitle')}
      >
        <>
          <Grid className={styles['container']}>
            <Row className={styles['cardcontrol']}>
              <Col noGutter>
                <Card type="content" className={styles['disclaimer-wrap']}>
                  <Row>
                    <Col>
                      <Typography tag="span" className={styles['cardcontrol-disclaimer']}>
                        {t('cardControl.tncDisclaimerPart1')}
                      </Typography>
                      <LinkWrapper
                        href={Utils.getTermsLink(userAuth.isFullyLogged)}
                        className={styles['link']}
                        ariaLabel={t('cardControl.tncLink')}
                        openInNewTab={true}
                      >
                        {t('cardControl.tncLink')}
                      </LinkWrapper>
                      <Typography tag="span" className={styles['cardcontrol-disclaimer']}>
                        {t('cardControl.tncDisclaimerPart2')}
                      </Typography>
                    </Col>
                  </Row>
                </Card>
                <Card type="content" className={styles['cardcontrol-card']}>
                  <Row className={styles['cardcontrol-card-row']}>
                    <Col>
                      <Col>
                        <Typography tag="span" className={styles['cardcontrol-option-label']} id="templock-txn-label">
                          {t('cardControl.temporaryLock')}
                        </Typography>
                      </Col>
                      {!alerts.hasDeclinedContactOfferings && (
                        <Col>
                          <Typography
                            tag="span"
                            className={styles['cardcontrol-option-sublabel']}
                            id="templock-subtxn-label"
                          >
                            <Link
                              to={RedirectRoutes.homeAlerts}
                              onClick={handleRedirectToAlerts}
                              className={styles['cardcontrol-label-link']}
                            >
                              {t('cardControl.setAlert1')}
                            </Link>
                            {t('cardControl.setAlert2')}
                          </Typography>
                        </Col>
                      )}
                    </Col>
                    <Col>
                      <Switch
                        id="lock-switch"
                        name="lock-switch"
                        defaultChecked={false}
                        checked={temporaryLock}
                        disabled={!alerts.hasDeclinedContactOfferings}
                        switchOffText={t('cardControl.off')}
                        switchOnText={t('cardControl.on')}
                        className={styles['switch']}
                        onChange={e => onValueChange(e, CardControlType.TEMPORARY_LOCK)}
                        aria-describedby="templock-txn-label templock-subtxn-label"
                      />
                    </Col>
                  </Row>
                </Card>
                <Card type="content" className={styles['cardcontrol-card']}>
                  <Row className={styles['cardcontrol-card-row']}>
                    <Col>
                      <Col>
                        <Typography tag="span" className={styles['cardcontrol-option-label']} id="online-txn-label">
                          {t('cardControl.onlineTxn')}
                        </Typography>
                      </Col>
                      {temporaryLock && (
                        <Col>
                          <Typography
                            tag="span"
                            className={styles['cardcontrol-option-sublabel']}
                            id="online-subtxn-label"
                          >
                            {t('cardControl.unlockCardMsg')}
                          </Typography>
                        </Col>
                      )}
                    </Col>
                    <Col>
                      <Switch
                        id="online-txn-switch"
                        name="online-txn-switch"
                        defaultChecked={false}
                        checked={declineOnlineTransaction}
                        switchOffText={t('cardControl.off')}
                        switchOnText={t('cardControl.on')}
                        className={styles['switch']}
                        disabled={temporaryLock}
                        onChange={e => onValueChange(e, CardControlType.TCT_E_COMMERCE)}
                        aria-describedby="online-txn-label online-subtxn-label"
                      />
                    </Col>
                  </Row>
                </Card>
                <Card type="content" className={styles['cardcontrol-card']}>
                  <Row className={styles['cardcontrol-card-row']}>
                    <Col>
                      <Col>
                        <Typography tag="span" className={styles['cardcontrol-option-label']} id="intnl-txn-label">
                          {t('cardControl.intnlTxn')}
                        </Typography>
                      </Col>
                      {temporaryLock && (
                        <Col>
                          <Typography
                            tag="span"
                            className={styles['cardcontrol-option-sublabel']}
                            id="intnl-subtxn-label"
                          >
                            {t('cardControl.unlockCardMsg')}
                          </Typography>
                        </Col>
                      )}
                    </Col>
                    <Col>
                      <Switch
                        id="intnl-switch"
                        name="intnl-switch"
                        defaultChecked={false}
                        checked={internationalTransaction}
                        disabled={temporaryLock}
                        switchOffText={t('cardControl.off')}
                        switchOnText={t('cardControl.on')}
                        className={styles['switch']}
                        onChange={e => onValueChange(e, CardControlType.TCT_CROSS_BORDER)}
                        aria-describedby="intnl-txn-label intnl-subtxn-label"
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Grid>
        </>
      </HomeComponent>
      <DialogWrapper
        role="alertdialog"
        hasCloseIcon={true}
        open={openCardLockDialog}
        title={t('cardControl.dialogTitle')}
        body={dialogBodyComponent}
        handleCloseIconClick={handleDialogCancelClick}
        actionButtons={actionDialogButtons}
        parentActiveElementId="cardcontrol-save-btn"
      />
    </>
  );
};

CardControl.displayName = 'CardControl';

export default CardControl;
