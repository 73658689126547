import { useTranslation } from 'react-i18next';

function useTranslator(): (reason: string) => string {
  const { t } = useTranslation();
  const reasonMap = {
    invalidForm: 'errors.invalidForm',

    '9602': 'errors.verifyCode.invalidCode',
    '9616': 'errors.verifyCode.OTPExpired',
    OTP_EXPIRED: 'errors.verifyCode.OTPExpired',
    PROFILE_1014: 'errors.verifyCode.emailInUse',
    Profile_2006: 'errors.verifyCode.phoneInUse',
    PROFILE_2011: 'errors.verifyCode.invalidOTVC',
    inputValidationError: 'errors.verifyCode.invalidCode',
    OTP_VALIDATION_FAILED: 'errors.verifyCode.invalidCode',
    OTP_VERIFICATION_FAILED_1: 'errors.verifyCode.invalidCode',
    OTP_VERIFICATION_FAILED_2: 'errors.verifyCode.invalidCode',
    OTP_VERIFICATION_FAILED_3: 'errors.verifyCode.invalidCode',
    invalid_grant_loginlockout: 'errors.verifyCode.armAccLock',

    resetSuccess: 'resetPassword.resetSuccess',
    VMCP_1006: 'errors.resetPassword.VMCP_1006',
    ICM_1012: 'errors.resetPassword.old_nomatch',
    ICM_0001: 'errors.resetPassword.noDuplicate',
    ICM_3001: 'errors.resetPassword.noDuplicateNew'
  };

  const fn = (reason: string): string => {
    return reasonMap.hasOwnProperty(reason) ? t(reasonMap[reason]) : t(reason);
  };
  return fn;
}

export default useTranslator;
