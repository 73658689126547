import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { SelectWrapper, CheckEligibilityInputWrapper, ButtonWrapper, FormWrapper } from '../../../elements/core-ui';
import { GenericPanelComponent, MessageNodeType } from '../../../elements/shared';
import { showAlert, clearAlert } from '../../../elements/shared';
import { AllRegex, RedirectRoutes } from '../../../constants';
import { Utils } from '../../../utils';

import reasonCodes from '../../../constants/reason-codes';
import {
  postCheckEligibility,
  getCountryConfigration,
  countryConfigurationResetResponseFailure,
  checkEligibilityResetResponseFailure,
  resetIssuerOptStatus
} from './check-reducer-slice';
import Styles from './check.module.scss';

type CheckProps = {
  [key: string]: any;
};

const Check: React.FC<CheckProps> = props => {
  const { t } = useTranslation();
  const {
    checkEligibility,
    alert,
    userAuth: { isFullyLogged },
    userDetails
  } = useSelector((state: any) => state);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isFullyLogged) {
      document.title = Utils.getPageTitle('app.title.check');
    } else {
      document.title = Utils.getPageTitle('app.title.signup');
    }
  }, [isFullyLogged, t]);

  const schema = yup.object({
    panPrefix: yup
      .string()
      .matches(AllRegex.panPrefix, 'check.formValidate.errors.onlyNumbers')
      .min(11, 'check.formValidate.errors.lessThanNine')
      .required('check.formValidate.errors.lessThanNine')
      
  });

  const defaultValues = {
    panPrefix: '',
    countryCode: isFullyLogged
      ? Utils.getCountryCode()
      : checkEligibility.countryConfiguration.countryCode || Utils.getCountryCode()
  };

  const methods = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
    mode: 'onBlur',
    reValidateMode: 'onBlur'
  });

  const {
    formState: { errors },
    getValues
  } = methods;

  useEffect(() => {
    dispatch(resetIssuerOptStatus());
  }, []);

  useEffect(() => {
    let message;
    if (
      checkEligibility.issuerOptStatus &&
      (!checkEligibility.issuerOptStatus.eligibility || checkEligibility.issuerOptStatus.eligibility === 'false')
    ) {
      if (checkEligibility.issuerOptStatus.errorCode === reasonCodes.ISSUER_NOT_SUBSCRIBED) {
        //  we are sending enum as msg here, which will be checked in global-alert component
        message = MessageNodeType.CheckFinacialInstitution;
      } else if (checkEligibility.issuerOptStatus.errorCode === reasonCodes.PRODUCT_NOT_ELIGIBLE) {
        message = 'check.errorCodes.PAS_4004';
      }
      dispatch(showAlert({ type: 'ERROR', message }));
    }
  }, [checkEligibility?.issuerOptStatus?.eligibility, checkEligibility?.issuerOptStatus?.errorCode]);

  const handleCloseAlert = () => {
    dispatch(countryConfigurationResetResponseFailure(false));
    dispatch(checkEligibilityResetResponseFailure(false));
    dispatch(clearAlert());
  };

  const handleSetCountry = async (code: string) => {
    dispatch(getCountryConfigration({ country_pref: code }));
  };

  const handleBlur = name => {
    // if (errors?.panPrefix && getValues(name).length === 11) {
    //   dispatch(clearAlert());
    // }
    // if (errors?.panPrefix && getValues(name).length === 0) {
    //   dispatch(clearAlert());
    // }
    // if (alert.message) {
    //   handleCloseAlert();
    // }
  };

  const handleCancel = () => {
    if (isFullyLogged && userDetails.numberOfCards) {
      navigate(RedirectRoutes.homeManageCards);
    } else if (isFullyLogged && !userDetails.numberOfCards) {
      navigate(RedirectRoutes.home);
    } else {
      navigate(RedirectRoutes.login);
    }
  };

  const submit = async (data: any) => {
    const cardNumber = data.panPrefix.split(' ').join('');
    const countryNum = Utils.getCountryNumber(data.countryCode);
    const payload = {
      isFullyLogged,
      navigate,
      panPrefix: cardNumber,
      countryCode: countryNum
    };
    dispatch(postCheckEligibility(payload));
  };

  const bodyComponent = (
    <>
      <FormWrapper onSubmit={methods.handleSubmit(submit)} noValidate className={Styles['form']}>
        <CheckEligibilityInputWrapper
          id="panPrefix"
          methods={methods}
          label={t('check.fieldLabel.cardNumber')}
          name={'panPrefix'}
          type={'text'}
          maxLength={11}
          required={true}
          handleBlur={handleBlur}
        />
        {!isFullyLogged ? (
          <>
            <SelectWrapper
              id="countryCode"
              required={true}
              methods={methods}
              name={'countryCode'}
              setCountry={handleSetCountry}
              selectList={Utils.getCountryList()}
              label={t('check.fieldLabel.country')}
              describedBy={'countrySelectorHelperTextId'}
            />
            <div>
              <label id={'countrySelectorHelperTextId'}>{t('check.note')}</label>
            </div>
          </>
        ) : (
          <></>
        )}

        <ButtonWrapper
          colorScheme={'primary'}
          type="submit"
          tag="button"
          isFullWidth={true}
          ariaLabel={t('check.button.checkEligibility')}
          label={t('check.button.checkEligibility')}
        />
        <ButtonWrapper
          isFullWidth={true}
          colorScheme="tertiary"
          ariaLabel={t('check.button.cancel')}
          label={t('check.button.cancel')}
          handleClick={handleCancel}
        />
      </FormWrapper>
    </>
  );

  return (
    <GenericPanelComponent
      backIcon={isFullyLogged}
      handleIconClick={handleCancel}
      bodyComponent={bodyComponent}
      pageTitle={isFullyLogged ? t('check.altHeading') : t('check.heading')}
      panelSubText={t('check.subheading')}
    />
  );
};

export default Check;
