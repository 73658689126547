import React, { useEffect, useState } from 'react';
import { Alert, Row, AlertIcon, AlertLabel, Grid, Icon, ButtonIcon } from '@visa/vds';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from './alert.module.scss';
import { clearAlert } from './global-alert-reducer';

export type AlertWrapperProps = {
  iconResolution?: string;
  closeIconDirection?: string;
  closeIconResolution?: string;
  alertType?: any;
  closeIcon?: boolean;
  message: string | React.ReactNode;
  isOpenAlert?: boolean;
  altRole?: string;
  altText?: string;
};

const AlertWrapper: React.FC<AlertWrapperProps> = props => {
  const {
    iconResolution,
    closeIconDirection,
    closeIconResolution,
    message,
    isOpenAlert,
    alertType,
    altRole,
    altText,
    closeIcon
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [alertMessage, setAlertMessage] = useState<any>(null);

  useEffect(() => {
    setTimeout(() => {
      setAlertMessage(message);
    });
  }, [message]);

  const handleAlertClose = () => {
    dispatch(clearAlert());
  };

  return (
    <Alert
      id="global-alert"
      show={isOpenAlert}
      alertType={alertType}
      className={isOpenAlert ? styles['open-style'] : null}
      role={altRole}
    >
      <Grid className={styles['container']}>
        <Row className={styles['row']} aria-live="assertive">
          <AlertIcon>
            <Icon name={alertType} resolution={iconResolution || 'low'} alt={altText && altText} />
          </AlertIcon>
          <AlertLabel>{alertMessage}</AlertLabel>
          {closeIcon && alertMessage ? (
            <ButtonIcon
              ariaLabel={t('app.close')}
              iconType="light-tiny"
              className={styles['close-button-icon']}
              onClick={handleAlertClose}
              direction={closeIconDirection || 'right'}
            >
              <Icon
                name="close"
                title="Close"
                alt="error"
                resolution={closeIconResolution || 'tiny'}
                className={styles[alertType]}
              />
            </ButtonIcon>
          ) : null}
        </Row>
      </Grid>
    </Alert>
  );
};

export default AlertWrapper;
