import { UseAnotherEmail } from '../../elements/shared';
import { CreateAccount, Enroll, EnrollIdvVerify, AddCard, Check } from './index';

const createAccountRoutes = [
  {
    path: 'check',
    element: <Check />
  },
  {
    path: 'create-account',

    children: [
      {
        element: <CreateAccount />,
        index: true
      },
      {
        path: 'enroll',
        element: <Enroll />
      },
      {
        path: 'verify',
        element: <EnrollIdvVerify />
      },
      {
        path: 'add-card',
        element: <AddCard />
      },
      {
        path: 'edit-email',
        element: <UseAnotherEmail />
      }
    ]
  }
];

export default createAccountRoutes;
