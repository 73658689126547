import { put, takeLatest, call, select } from 'redux-saga/effects';
import { showAlert } from '../../../../elements/shared';
import { ajaxHandler, apiPaths } from '../../../../setup';
import { replacePaymentGuid, replaceUserGuid } from '../../../../utils/utils';
import { getAlertsHistory, getAlertsHistoryFailure, getAlertsHistorySuccess } from './alert-history-reducer';

function* getAlertsHistoryHandler(action) {
  const { userAuth } = yield select((state: any) => state);
  const { payload } = action;
  const headers = {
    access_token: userAuth.accessToken
  };
  const getAlertsHistoryURL = replacePaymentGuid(
    replaceUserGuid(apiPaths.transactions.url, userAuth.userGuid),
    payload.panGuid
  );
  const data = payload;
  delete data['panGuid'];

  const response = yield call(ajaxHandler, getAlertsHistoryURL, true, {
    headers,
    method: 'POST',
    data: payload,
    urlWithPlaceholder: apiPaths.transactions.url
  });

  if (!response.error) {
    yield put(getAlertsHistorySuccess(response.data));
  } else {
    const type = 'ERROR';
    const message = `alertsHistory.errorCodes.sectionUnavailable`;
    yield put(showAlert({ type, message }));
    yield put(getAlertsHistoryFailure());
  }
}

export default function* watchUserDetails() {
  yield takeLatest(getAlertsHistory, getAlertsHistoryHandler);
}
