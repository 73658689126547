import React, { useEffect, useState } from 'react';
import Nav from '@visa/vds/nav';
import NavSite from '@visa/vds/nav-site';
import NavLinks from '@visa/vds/nav-links';
import TabListStateless from '@visa/vds/tab-list-stateless';
import TabItem from '@visa/vds/tab-item';
import Tab from '@visa/vds/tab';
import Icon from '@visa/vds/icon';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Typography } from '@visa/vds';
import { useDispatch, useSelector } from 'react-redux';
import { CardListDropdown, GlobalAlert } from '../../../../elements/shared';
import { RedirectRoutes, ProfileEums } from '../../../../constants';
import { ButtonWrapper } from '../../../../elements/core-ui';
import { Utils } from '../../../../utils';
import { setReloadFlag, setSelectedTab, Tabs } from '../../home-reducer';
import styles from './home-panel.module.scss';

type HomePanelProps = {
  showLeftPanel?: boolean;
  children: React.ReactNode;
  pageTitle: string;
  showActionButtons?: boolean;
  handleSaveBtnClick?: () => void;
  handleCancelBtnClick?: () => void;
  pageSubTitle: string | React.ReactNode;
  saveBtnId?: string;
};

type Tab = {
  identifier: Tabs;
  label: string;
  icon: string;
  show: boolean;
  link: string;
};

const HomeComponent: React.FC<HomePanelProps> = props => {
  const {
    children,
    pageTitle = '',
    pageSubTitle = '',
    showLeftPanel = true,
    showActionButtons = false,
    handleSaveBtnClick = f => f,
    handleCancelBtnClick = f => f,
    saveBtnId = ''
  } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userDetails, checkEligibility, cardControls, alert } = useSelector((state: any) => state);
  const { t } = useTranslation();
  const selectedTab = userDetails.selectedTab;
  const location = useLocation();

  const [activateSticky, setActivateSticky] = useState(false);
  const [visibleTabList, setVisibleTabList] = useState<Array<Tab>>([]);

  const tabList: Array<Tab> = [
    {
      identifier: Tabs.ALERTS,
      label: 'homePage.alerts',
      icon: 'notifications',
      show: Utils.isFeatureSupported(checkEligibility, ProfileEums.FeatureTypes.VTA),
      link: RedirectRoutes.homeAlerts
    },
    {
      identifier: Tabs.ALERTS_HISTORY,
      label: 'homePage.history',
      icon: 'time',
      show: Utils.isFeatureSupported(checkEligibility, ProfileEums.FeatureTypes.VTA),
      link: RedirectRoutes.homeAlertHistory
    },
    {
      identifier: Tabs.CARD_CONTROL,
      label: 'homePage.cardControl',
      icon: 'security-lock',
      show:
        cardControls.isCardControlSupported &&
        Utils.isFeatureSupported(checkEligibility, ProfileEums.FeatureTypes.VCTC),
      link: RedirectRoutes.homeCardControl
    }
  ];

  // we added a P element and added attributes id and tabindex and then focussing it, when user first time visits the page => this is for screenreader
  useEffect(() => {
    if (location.state === null) {
      const parentEl = document.getElementById('root');
      const pageTitle = document.createElement('p');
      pageTitle.textContent = t('app.title.chooseAlerts');
      pageTitle?.setAttribute('id', 'screen-reader-title');
      pageTitle?.classList?.add(styles['visuallyhidden']);
      pageTitle?.setAttribute('tabindex', '-1');
      parentEl?.insertBefore(pageTitle, parentEl.firstChild);
      pageTitle?.focus();
    }

    return () => {
      const el = document.getElementById('screen-reader-title');
      el?.remove();
    };
  }, []);

  useEffect(() => {
    if (!userDetails?.isPageReload) {
      Utils.focusToH1();
    }
  }, []);

  useEffect(() => {
    if (userDetails.selectedCard?.panGuid) {
      setVisibleTabList(tabList.filter(tab => tab.show));
    }
  }, [cardControls.isCardControlSupported, userDetails.selectedCard?.panGuid]);

  const onTabSelect = (item: Tab) => {
    Utils.hideServerAlert(dispatch);
    dispatch(setSelectedTab(item.identifier));
    navigate(item.link);
    dispatch(setReloadFlag(false));
  };

  const handleStickyDisplay = () => {
    const homeChildElt = document.getElementById('home-container');
    const homeChildEltBottom = homeChildElt?.getBoundingClientRect().bottom;
    const homeChildEltBottomWindowTopDifference =
      Math.round(homeChildEltBottom || 0) - Math.round(window.innerHeight || 0);
    setActivateSticky(homeChildEltBottomWindowTopDifference > 0);
  };

  useEffect(() => {
    handleStickyDisplay();
    if (showActionButtons) {
      const debouncedScrollListener = Utils.debounce(handleStickyDisplay, 99);
      window.addEventListener('scroll', debouncedScrollListener, { passive: true });
      return () => window.removeEventListener('scroll', debouncedScrollListener);
    }
  }, []);

  const rightContainerClass = () => {
    const defalutClass = styles['right-container'];
    const conditionalClass = styles['right-container-no-action-btns'];
    if (showActionButtons) {
      return `${defalutClass} ${conditionalClass}`;
    }
    return styles['right-container'];
  };
  const Body = () => (
    <div>
      <div className={showLeftPanel ? styles['flex-container'] : styles['container']} id={'home-container'}>
        {showLeftPanel && (
          <div className={styles['left-container']}>
            <div className={styles['card-list']}>
              <CardListDropdown />
            </div>
            <Nav tag="nav" type="white" role="navigation" className={styles['nav-item']}>
              <NavSite className={styles['nav-site-new']}>
                <NavLinks>
                  <TabListStateless role="tablist" className={styles['item']}>
                    {visibleTabList.map((item, idx) => (
                      <TabItem
                        key={item.identifier}
                        className={`${styles['tab-item']} ${
                          item.identifier === selectedTab ? styles['tab-item-active'] : ''
                        }`}
                        onClick={() => onTabSelect(item)}
                        role="none"
                      >
                        <Tab className={styles['tab']} tag={<button />} aria-controls={item.label} role="tab">
                          <div className={styles['label-with-icon-container']}>
                            <Icon
                              className={`${styles['nav-icon']} ${
                                item.identifier === selectedTab ? styles['nav-icon-active'] : ''
                              }`}
                              name={item.icon}
                              resolution="low"
                            />
                            <Typography
                              className={`${styles['nav-font']} ${
                                item.identifier === selectedTab ? styles['nav-font-active'] : ''
                              }`}
                              tag="span"
                              variant="subtitle--alt"
                            >
                              {t(item.label)}
                            </Typography>
                          </div>
                        </Tab>
                      </TabItem>
                    ))}
                  </TabListStateless>
                </NavLinks>
              </NavSite>
            </Nav>
          </div>
        )}

        <div className={rightContainerClass()}>
          <div className={styles['alert']}>
            <GlobalAlert />
          </div>
          <div className={styles['title']}>
            <Typography tag="h1" className={'page-title'}>
              {pageTitle}
            </Typography>
            <Typography tag="h2" className={`panel-text ${styles['page-subtitle']}`}>
              {pageSubTitle}
            </Typography>
          </div>
          <div className={styles['main']} id="home-children">
            {children}
          </div>
        </div>
      </div>
      {showActionButtons && (
        <div className={activateSticky ? styles['action-btns-sticky'] : styles['action-btns']} id="home-action-btns">
          <div className={styles['spacer']}></div>
          <ButtonWrapper
            id={saveBtnId ? saveBtnId : 'panel-save-btn'}
            label={t('stickyBtns.primary')}
            colorScheme="primary"
            handleClick={handleSaveBtnClick}
            className={activateSticky ? styles['sticky-save-btn'] : styles['save-btn']}
            ariaLabel={t('stickyBtns.primary')}
          />
          <ButtonWrapper
            label={t('stickyBtns.secondary')}
            colorScheme="tertiary"
            handleClick={handleCancelBtnClick}
            className={activateSticky ? styles['sticky-cancel-btn'] : styles['cancel-btn']}
            ariaLabel={t('stickyBtns.secondary')}
          />
        </div>
      )}
    </div>
  );

  return <>{Body()}</>;
};

export default HomeComponent;
