import { Row, Col, Typography } from '@visa/vds';
import { useTranslation } from 'react-i18next';
import { InputWrapper } from '../../../core-ui';
import { Utils } from '../../../../utils';
import Styles from '../card-billing/card-billing.module.scss';

const CardProfile = ({ methods, edit = false }) => {
  const { setValue } = methods;
  const { t } = useTranslation();

  const handleChange = (name, e) => {
    if (name === 'postalCode' && Utils.isUS()) {
      const { value } = e.target;
      const result = value.replace(/[^0-9]/gi, '');
      setValue(name, result);
    }
  };

  const bodyComponent = (
    <>
      <Row noGutter className={Styles['first-row']}>
        <Col>
          <Typography tag="h2" className={'panel-text ' + Styles['panel-text']}>
            {t('add_edit_card.subHeading.profile')}
          </Typography>
        </Col>
      </Row>
      {!edit ?
        <>
          <Row className={Styles['input-row'] + ' two-col'}>
            <Col xs={4} sm={8} md={8} lg={5}>
              <InputWrapper
                id="firstName"
                className={Styles['input-field']}
                methods={methods}
                label={t('add_edit_card.fieldLabel.firstName')}
                name={'firstName'}
                type={'text'}
                required={true}
                maxLength={100}
              />
            </Col>
            <Col xs={4} sm={8} md={8} lg={5}>
              <InputWrapper
                id="lastName"
                className={Styles['input-field']}
                methods={methods}
                label={t('add_edit_card.fieldLabel.lastName')}
                name={'lastName'}
                type={'text'}
                required={true}
                maxLength={100}
              />
            </Col>

          </Row>
        </> :
        <InputWrapper
          id="name"
          className={Styles['input-field']}
          methods={methods}
          label={t('add_edit_card.fieldLabel.name')}
          name={'name'}
          type={'text'}
          required={true}
          maxLength={100}
        />
      }
      <InputWrapper
        id="postalCode"
        className={Styles['input-field']}
        methods={methods}
        label={
          Utils.isVI() ? t('add_edit_card.fieldLabel.postalCode') : t('add_edit_card.fieldLabel.postalCodeOptional')
        }
        name={'postalCode'}
        type={'text'}
        required={Utils.isVI() ? true : false}
        maxLength={Utils.isLac() ? 20 : 5}
        handleChange={handleChange}
      />
    </>
  );
  return bodyComponent;
};

export default CardProfile;
