import React from 'react';
import ButtonText from '@visa/vds/button-text';
import useTranslator from '../../../hooks/translation-hook';
import { GlobalConstants, RedirectRoutes } from '../../../constants';
import styles from './skip-link.module.scss';

const SkipLink: React.FC = () => {
  const t = useTranslator();
  const isLoginScreen = window.location.pathname === `${GlobalConstants.assetsPrefix}${RedirectRoutes.login}`;
  const handleFocus = evt => {
    evt.preventDefault && evt.preventDefault();
    //For login screen it should focus username field and button should be skip to login
    if (isLoginScreen) {
      const element: HTMLElement | null = document.querySelector('#username');
      if (element) element.focus();
      return;
    }
    const container: HTMLElement | null = document.querySelector('#app-main');
    if (container) {
      container.tabIndex = -1;
      container.focus();
      setTimeout(() => container.removeAttribute('tabindex'), 1000);
    }
  };

  return (
    <ButtonText
      tag="button"
      className={styles['skip-btn']}
      colorScheme="primary"
      onClick={handleFocus}
      onKeyDown={evt => {
        if (evt.key === 'Enter') handleFocus(evt);
      }}
      aria-label={isLoginScreen ? t('app.skipToLogin') : t('app.skipToMain')}
    >
      {isLoginScreen ? t('app.skipToLogin') : t('app.skipToMain')}
    </ButtonText>
  );
};

export default SkipLink;
