import React, { useState } from 'react';
import { utilities } from '@visa/ui-utils/lib/js/card-number';
import InputCard from '@visa/vds/input-card';
import { Controller, Noop } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

const { validator } = utilities;

type InputCardWrapperProps = {
  id: string;
  required?: boolean;
  methods: any;
  label: string;
  name: string;
  acceptedCards: string[];
  cardBins: number[];
  handleBlur?: (name: string, errorMessage?: string) => void;
  handleFocus?: (e: any) => void;
  onChange?: (e: any) => void;
};

const InputCardWrapped: React.FC<InputCardWrapperProps> = ({
  id = '',
  methods,
  label,
  name,
  acceptedCards,
  cardBins,
  handleFocus,
  handleBlur,
  required = false
}) => {
  const {
    formState: { errors, submitCount },
    control,
    setValue,
    getValues,
    setError,
    clearErrors
  } = methods;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // const [cardBrand, setCardBrand] = useState(null);
  const [isSupportedBrand, setIsSupportedBrand] = useState(true);
  const [isValidCardNumber, setIsValidCardNumber] = useState(true);

  const checkMod11 = false;
  const checkMods = true;

  const getInlineError = (type, brand) => {
    const inlineErrors = {
      EMPTY: 'add_edit_card.error.empty',
      INVALID_BIN: 'add_edit_card.error.invalidBin',
      INVALID_BIN_BRAND: 'add_edit_card.error.invalidBinReal',
      INVALID_GENERIC: 'add_edit_card.error.invalidGeneric',
      NOT_SUPPORTED: 'add_edit_card.error.notSupported',
      PARTIAL: 'add_edit_card.error.partial'
    };
    return inlineErrors[type];
  };

  const handleInputCardBlur = (blur: Noop) => {
    const isBlur = true;
    const conditions = {
      brand: getValues('cardType'),
      checkMod11,
      checkMods,
      isSupported: isSupportedBrand,
      isValid: isValidCardNumber,
      value: getValues(name)
    };
    const errorType = validator(isBlur, conditions);
    const errorMessage = getInlineError(errorType, getValues('cardType'));
    setIsValidCardNumber(Boolean(errorMessage) !== true);
    // (errorMessage && !submitCount) && dispatch(clearAlert())
    handleBlur && handleBlur(name, errorMessage);
    !errorMessage && blur();
  };

  const handleInputCardChange = (e, { brand, isSupported, isValid, value }) => {
    const isBlur = false;
    const conditions = {
      brand: getValues('cardType'),
      checkMod11,
      checkMods,
      isSupported,
      isValid,
      value
    };
    const errorType = validator(isBlur, conditions);
    const errorMessage = getInlineError(errorType, brand);
    // setCardBrand(brand)
    setValue('cardType', brand);
    setValue(name, value);
    setIsSupportedBrand(isSupported);
    setIsValidCardNumber(isValid);
    errorMessage ? setError(name, { message: errorMessage }) : clearErrors(name);
  };

  const handleInputFocus = () => {
    handleFocus && handleFocus(name);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <InputCard
            {...field}
            id={id}
            aria-required={Boolean(required)}
            acceptedCards={acceptedCards}
            cardBins={cardBins}
            errorText={t(errors[name]?.message)}
            showErrorText={errors[name]?.message ? true : false}
            handleCardNumberChange={handleInputCardChange}
            invalid={errors[name]?.message ? true : false}
            label={label}
            noValidate
            checkMods
            onBlur={() => handleInputCardBlur(field.onBlur)}
            onFocus={() => handleInputFocus()}
            required
            shouldValidateAcceptedCards
          />
        );
      }}
    />
  );
};
export default InputCardWrapped;
