import { put, takeLatest, call } from 'redux-saga/effects';
import { ajaxHandler, apiPaths } from '../../../setup';
import { RedirectRoutes } from '../../../constants';
import { showAlert } from '../../../elements/shared';
import {
  checkEligibilitySuccess,
  postCheckEligibility,
  checkEligibilityFailure,
  getCountryConfigration,
  countryConfigurationSuccess,
  countryConfigurationFailure,
  resetIssuerOptStatus
} from './check-reducer-slice';

function* getCountryConfigurationHandler(action) {
  const { payload } = action;
  const response = yield call(ajaxHandler, `${apiPaths.countryConfig}?countryCode=${payload.country_pref}`, false, {
    urlWithPlaceholder: apiPaths.countryConfig
  });
  if (!response.error) {
    yield put(countryConfigurationSuccess(response.data));
    sessionStorage && sessionStorage.setItem('vimConFeatureToggleFlag',response.data.vimConFlow)
  } else {
    yield put(countryConfigurationFailure(response.error));
  }
}

function* postCheckEligibilityHandler(action) {
  const { payload } = action;
  yield put(resetIssuerOptStatus());
  const response = yield call(ajaxHandler, `${apiPaths.auth.checkeligibility}`, true, {
    method: 'post',
    data: {
      panPrefix: payload.panPrefix,
      countryCode: payload.countryCode
    },
    urlWithPlaceholder: apiPaths.auth.checkeligibility
  });
  if (!response.error) {
    if (response.data.eligibility === true || response.data.eligibility === 'true') {
      yield put(
        showAlert({
          type: 'SUCCESS',
          message: 'check.formValidate.success'
        })
      );
      if (payload.isFullyLogged) {
        payload.navigate(RedirectRoutes.homeAddCard);
      } else {
        payload.navigate(RedirectRoutes.createAccountEnroll);
      }
    }
    yield put(checkEligibilitySuccess(response.data));
  } else {
    yield put(checkEligibilityFailure(response.error));
  }
}

export default function* watchCheckEligibility() {
  yield takeLatest(postCheckEligibility, postCheckEligibilityHandler);
  yield takeLatest(getCountryConfigration, getCountryConfigurationHandler);
}
