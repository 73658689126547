import { put, takeLatest, call, select } from 'redux-saga/effects';
import { resetUserDetails } from '../pages/home/home-reducer';
import { ajaxHandler, apiPaths } from '../setup';
import { Utils } from '../utils';
import { getRenewSession, getRenewSessionSuccess, postRevocation, postRevocationSuccess } from './app-reducer';

function* getRenewSessionHandler(action) {
  const response = yield call(ajaxHandler, apiPaths.renewSession, false, {
    method: 'GET',
    urlWithPlaceholder: apiPaths.renewSession
  });

  if (!response.error) {
    Utils.updateServerSessionId(response.data);
    yield put(getRenewSessionSuccess(response.data));
  } else {
    // window.location.reload();
    window.alert('Failed to sign out please close the window');
  }
}

function* postRevocationHandler(action) {
  const { userAuth } = yield select((store: any) => store);
  const headers = {
    Authorization: 'Bearer ' + userAuth.accessToken,
    'content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
  };
  const response = yield call(ajaxHandler, apiPaths.auth.logoutUrl, false, {
    headers,
    data: {},
    method: 'POST',
    urlWithPlaceholder: apiPaths.renewSession
  });
  yield put(getRenewSession());
  if (!response.error) {
    yield put(postRevocationSuccess(response.data));
    yield put(resetUserDetails());
  } else {
  }
}

export default function* watchHeader() {
  yield takeLatest(getRenewSession, getRenewSessionHandler);
  yield takeLatest(postRevocation, postRevocationHandler);
}
