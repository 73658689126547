import { createSlice } from '@reduxjs/toolkit';
import { resetAppData } from '../../../../app/app-reducer';
import { CardControlType } from '../../../../constants/card-control';

export interface CardControlStore {
  response?: CardControlResponse;
  temporaryLock?: boolean; // needed in alerts component to show warning
  internationalTransaction?: boolean;
  declineOnlineTransaction?: boolean;
  isCardControlSupported?: boolean;
  updateCardControlsSuccess?: boolean;
}

export interface CardControlResponse {
  documentId?: string;
  globalControls?: GlobalControls;
  transactionControls?: TransactionControls;
}

export interface GlobalControls {
  declineThreshold?: string;
  shouldDeclineAll?: boolean;
  defaultDeclineThresholdAmount?: string;
  encryptedPan?: string;
  declineAllNonTokenizeTransactions?: boolean;
  isControlEnabled?: boolean;
  isDeclineThresholdSupported?: boolean;
}

export interface TransactionControls {
  controlType?: string;
  declineThreshold?: string;
  shouldDeclineAll?: boolean;
  timeRange?: string;
  isControlEnabled?: boolean;
}

const initialState: CardControlStore = {
  response: {},
  temporaryLock: false,
  isCardControlSupported: false,
  updateCardControlsSuccess: false
};

function getCardControlValue(payload: any, cardControlType: string): any {
  if (payload) {
    if (cardControlType === CardControlType.TEMPORARY_LOCK) {
      return payload.globalControls[0]?.isControlEnabled;
    } else {
      const transactionControls = payload.transactionControls;
      for (const transactionControl of transactionControls) {
        if (transactionControl.controlType == cardControlType) {
          return transactionControl.isControlEnabled;
        }
      }
    }
  }
  return false;
}
const getLockStatus = (state, data) => {
  let temporaryLock = state.temporaryLock;
  const { globalControls = [] } = data;
  if (globalControls.length) {
    temporaryLock = globalControls[0].isControlEnabled;
  }
  return temporaryLock;
};

const cardControlReducer = createSlice({
  name: 'cardControls',
  initialState,
  reducers: {
    getCardControls: (state: CardControlStore, action) => {
      return state;
    },
    getCardControlsSuccess: (state: CardControlStore, action) => {
      return {
        ...state,
        response: action.payload.data,
        isCardControlSupported: action.payload.isCardControlSupported,
        temporaryLock: action.payload.isCardControlSupported ? getLockStatus(state, action.payload.data) : false
      };
    },
    updateCardControls: (state: CardControlStore, action) => {
      return state;
    },
    updateCardControlsSuccess: (state: CardControlStore, action) => {
      return {
        ...state,
        response: action.payload,
        updateCardControlsSuccess: true,
        temporaryLock: getLockStatus(state, action.payload)
      };
    },
    updateCardControlSupport: (state: CardControlStore, action) => {
      return {
        ...state,
        isCardControlSupported: action.payload
      };
    }
  },
  extraReducers: builder => {
    builder.addCase(resetAppData, () => {
      return initialState;
    });
  }
});

export const {
  getCardControls,
  getCardControlsSuccess,
  updateCardControls,
  updateCardControlsSuccess,
  updateCardControlSupport
} = cardControlReducer.actions;

export default cardControlReducer.reducer;
