import React, { useEffect } from 'react';
import Select from '@visa/vds/select';
import { useTranslation } from 'react-i18next';
import { SelectWrapperProps } from '../model';
import { addDescribedbyId } from '../../../utils/utils';

const SelectWrapper: React.FC<SelectWrapperProps> = ({
  id = '',
  describedBy = '',
  methods,
  label,
  name,
  required,
  selectList,
  defaultValue,
  helpText,
  showHelpText,
  setCountry,
  className
}) => {
  const {
    register,
    getValues,
    formState: { errors }
  } = methods;
  const { t } = useTranslation();

  useEffect(() => {
    addDescribedbyId(id, describedBy);
  }, [id, describedBy]);

  return (
    <Select
      id={id}
      className={className ? className : null}
      label={label}
      {...register(name)}
      defaultValue={defaultValue ? defaultValue : null}
      helpText={helpText}
      showHelpText={showHelpText}
      errorText={t(`${errors?.[name]?.message}`)}
      showErrorText={errors?.[name]?.message ? true : false}
      invalid={errors?.[name]?.message && required ? true : false}
      required={required ? true : false}
      onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setCountry && setCountry(e.target.value)}
    >
      {Array.isArray(selectList) ? (
        selectList.map((ele: any) => (
          <option key={ele.value} value={ele.value}>
            {ele.label}
          </option>
        ))
      ) : (
        <option>{typeof selectList === 'string' ? selectList : 'Loading...'}</option>
      )}
    </Select>
  );
};

export default SelectWrapper;
