import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Typography from '@visa/vds/typography';
import useTranslator from '../../hooks/translation-hook';
import { Utils } from '../../utils';
import { RedirectRoutes, ProfileEums } from '../../constants';
import { getUserDetails, getPaymentInstruments, Tabs, setSelectedTab, setReloadFlag } from './home-reducer';
import NoCards from './components/no-cards/no-cards';
import { getCardControls } from './components/card-control/card-control-reducer';

type HomeProps = {
  [key: string]: any;
};

const Home: React.FC<HomeProps> = () => {
  const dispatch = useDispatch();
  const t = useTranslator();
  const navigate = useNavigate();
  const location = useLocation();

  const { userDetails, checkEligibility, userAuth } = useSelector((store: any) => store);

  const message = (
    <Typography>
      {t('check.fieldLabel.cardNumber')}
      Your Mobile number is not verified yet.
      <Link to="/" style={{ textDecoration: 'none' }}>
        verify now
      </Link>
    </Typography>
  );

  useEffect(() => {
    if (location.state === null) {
      dispatch(setReloadFlag(true)); // if state is null, then we are setting the reload flag to true => to reload the page
    }
  }, []);

  useEffect(() => {
    const config = {
      t
    };
    if (userAuth?.userGuid && userAuth?.accessToken) {
      dispatch(getUserDetails());
      dispatch(getPaymentInstruments(config));
      dispatch(setSelectedTab(Tabs.ALERTS));
    }
  }, []);

  useEffect(() => {
    if (userDetails.selectedCard?.panGuid) {
      dispatch(getCardControls({}));
    }
  }, [userDetails.selectedCard?.panGuid]);

  if (
    userDetails.paymentInstruments &&
    userDetails.numberOfCards &&
    Utils.isFeatureSupported(checkEligibility, ProfileEums.FeatureTypes.VTA)
  ) {
    //  by default redirect to alerts page
    navigate(RedirectRoutes.homeAlerts);
  }

  if (userDetails.isUserDetailLoading) {
    return null;
  } else if (!userDetails.paymentInstruments || !Boolean(userDetails.numberOfCards)) {
    return <NoCards />;
  } else {
    return null;
  }
};

Home.displayName = 'Home';

export default Home;
