import { fork, all } from 'redux-saga/effects';
import { watchAcceptTnC } from '../pages/sign-in';
import { watchForgotPassword } from '../pages/forgot-password';
import { watchCheckEligibility } from '../pages/create-account/check';
import { watchUnsubscribe } from '../pages/unsubscribe';
import { watchAddEditCard } from '../elements/shared/add-edit-card';
import { watchEnroll, watchEnrollIdvVerify } from '../pages/create-account/enroll';
import { watchSignin, watchEmailVerify, watchIdvVerify, watchForcePasswordReset } from '../pages/sign-in';
import { watchForgotUserName } from '../pages/forgot-username';
import { watchChangeEmail } from '../pages/profile/change-email';
import { watchAnotherEmail } from '../elements/shared';
import { watchAlerts, watchAlertsHistory, watchUserDetails, watchCardControls } from '../pages/home';
import { watchApp } from '../app';
import { watchUpdatePhone, watchChangePassword, watchDeleteProfile, watchMessageLanguage } from '../pages/profile';
import { watchUserAuth } from '../auth';

const forkSagas = sagaObject => {
  const tempArray: any[] = [];
  for (const key in sagaObject) {
    tempArray.push(fork(sagaObject[key]));
  }
  return tempArray;
};

/**
 * This is the root Saga which would load/fork different saga
 */
function* rootSaga() {
  yield all([
    ...forkSagas(watchChangeEmail),
    ...forkSagas(watchAnotherEmail),
    ...forkSagas(watchCheckEligibility),
    ...forkSagas(watchEnroll),
    ...forkSagas(watchEnrollIdvVerify),
    ...forkSagas(watchSignin),
    ...forkSagas(watchEmailVerify),
    ...forkSagas(watchForgotPassword),
    ...forkSagas(watchForgotUserName),
    ...forkSagas(watchIdvVerify),
    ...forkSagas(watchAcceptTnC),
    ...forkSagas(watchChangePassword),
    ...forkSagas(watchUserDetails),
    ...forkSagas(watchUpdatePhone),
    ...forkSagas(watchApp),
    ...forkSagas(watchAddEditCard),
    ...forkSagas(watchDeleteProfile),
    ...forkSagas(watchMessageLanguage),
    ...forkSagas(watchAlerts),
    ...forkSagas(watchCardControls),
    ...forkSagas(watchAlertsHistory),
    ...forkSagas(watchUnsubscribe),
    ...forkSagas(watchUserAuth),
    ...forkSagas(watchForcePasswordReset)
  ]);
}
export default rootSaga;
