import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Typography } from '@visa/vds';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { GenericPanelComponent } from '../../../elements/shared';
import { ButtonWrapper, FormWrapper, LinkWrapper, DialogWrapper } from '../../../elements/core-ui';
import { AllRegex, GlobalConstants, RedirectRoutes } from '../../../constants';
import { maskHelper, Utils } from '../../../utils';
import InputPhoneWrapper from '../../../elements/core-ui/input-phone/input-phone';
import { PhoneContactType } from '../../../constants/user-profile';
import { resetUpdatePhoneFlags, setPhoneNumber, updatePhone } from './update-phone.reducer';
import styles from './update-phone.module.scss';

const UpdatePhone: React.FC = () => {
  const [openUpdateDialog, setOpenUpdateDialog] = useState({ open: false });
  const [contactNumber, setContactNumber] = useState('');
  const [maskedContactNumber, setMaskedContactNumber] = useState('');

  const currentPhoneNumber = useSelector((state: any) => state.userDetails.phoneNumber);
  const contactType = useSelector((state: any) => state.updatePhone.contactType);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const updatePhoneData = useSelector((state: any) => state.updatePhone);
  const userAuth = useSelector((state: any) => state.userAuth);

  const initialValues = {
    phoneNumber: ''
  };
  const schema = yup.object({
    phoneNumber: yup.string().matches(AllRegex.phoneNumber, {
      excludeEmptyString: false,
      message: 'updatePhone.update.invalidPhoneNumberFormatUS'
    })
  });

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: initialValues,
    resolver: yupResolver(schema)
  });

  const handleContinueClick = (data: any) => {
    setOpenUpdateDialog({ open: true });
    setContactNumber(data.phoneNumber.replace(/\-/g, ''));
    setMaskedContactNumber(maskHelper.phone(data.phoneNumber));
  };

  const handleCancelClick = () => {
    if (contactType === PhoneContactType.ADD) {
      navigate(RedirectRoutes.profile);
    } else {
      navigate(RedirectRoutes.editPhone);
    }
  };

  const handleDialogCancelClick = () => {
    setOpenUpdateDialog({ open: false });
  };

  const handleAcceptContinueClick = () => {
    const data = {
      phoneNumber: {
        phoneNumber: GlobalConstants.usCountryCode + contactNumber
      },
      requestContext: {
        clientReferenceId: null,
        eventSource: null,
        eventSourceId: null
      }
    };
    const config = {
      t,
      data,
      navigate
    };
    dispatch(setPhoneNumber(data.phoneNumber.phoneNumber));
    dispatch(updatePhone(config));
  };

  useEffect(() => {
    if (updatePhoneData.updatePhoneSuccess) {
      navigate(RedirectRoutes.phoneVerifyCode);
    }
  }, [updatePhoneData.updatePhoneSuccess]);

  useEffect(() => {
    if (updatePhoneData.isupdatePhoneFailure) {
      setOpenUpdateDialog({ open: false });
      dispatch(resetUpdatePhoneFlags());
    }
  }, [updatePhoneData.isupdatePhoneFailure]);

  useEffect(() => {
    return () => {
      dispatch(resetUpdatePhoneFlags());
    };
  }, []);

  useEffect(() => {
    if (contactType !== PhoneContactType.ADD) {
      document.title = `${t('app.title.changeMobileNumber')} - ${t('app.title.visaPurchaseAlerts')}`;
    } else {
      document.title = `${t('app.title.addMobileNumber')} - ${t('app.title.visaPurchaseAlerts')}`;
    }
  }, [contactType]);

  const bodyComponent = (
    <>
      {contactType !== PhoneContactType.ADD && (
        <>
          <Row>
            <Typography variant="body--alt" className={styles['currentPhone-label']}>
              {t('updatePhone.update.currentPhone')}
            </Typography>
          </Row>
          <Row>
            <Typography variant="body">{currentPhoneNumber}</Typography>
          </Row>
        </>
      )}
      <FormWrapper onSubmit={methods.handleSubmit(handleContinueClick)}>
        <InputPhoneWrapper
          id="phoneNumber"
          describedBy={'phoneHelperTextId'}
          className={styles['input-field']}
          methods={methods}
          label={t('updatePhone.update.phone')}
          name={'phoneNumber'}
          type={'text'}
          maxLength={12}
          required={true}
          contentBeforeInputIconName={'phone'}
          formatPhoneNumber={true}
        />
        <label id={'phoneHelperTextId'} className={styles['note']}>
          {t('updatePhone.update.note')}
        </label>
        <>
          <ButtonWrapper
            id="updatePhoneBtn"
            isFullWidth
            tag="button"
            type="submit"
            colorScheme="primary"
            ariaLabel={
              contactType === PhoneContactType.ADD ? t('updatePhone.update.save') : t('updatePhone.update.label_update')
            }
            label={
              contactType === PhoneContactType.ADD ? t('updatePhone.update.save') : t('updatePhone.update.label_update')
            }
          />
          <ButtonWrapper
            isFullWidth
            colorScheme="tertiary"
            ariaLabel={t('updatePhone.update.cancel')}
            label={t('updatePhone.update.cancel')}
            handleClick={handleCancelClick}
          />
        </>
      </FormWrapper>
    </>
  );

  const dialogBodyComponent = (
    <>
      <div className={styles['text']}>
        {t('updatePhone.update.agree_static_prefix')} {maskedContactNumber}{' '}
        {t('updatePhone.update.agree_static_suffix')}
        <LinkWrapper
          href={Utils.getTermsLink(userAuth.isFullyLogged)}
          // href={RedirectRoutes.terms}
          className={styles['link']}
          ariaLabel={t('updatePhone.update.label_TC')}
          openInNewTab
        >
          {t('updatePhone.update.label_TC')}
        </LinkWrapper>
        and{' '}
        <LinkWrapper
          href="https://usa.visa.com/legal/privacy-policy.html"
          className={styles['link']}
          ariaLabel={t('updatePhone.update.label_Privacy')}
          openInNewTab
        >
          {t('updatePhone.update.label_Privacy')}
        </LinkWrapper>
        .
      </div>
      {t('updatePhone.update.email_static_prefix')}
      <LinkWrapper
        href="mailto:VisaPurchaseAlertsHelp@Visa.com"
        className={styles['link']}
        ariaLabel="mailto:VisaPurchaseAlertsHelp@Visa.com"
        openInNewTab
      >
        VisaPurchaseAlertsHelp@Visa.com
      </LinkWrapper>
      {t('updatePhone.update.email_static_suffix_first')} <strong>90934</strong>{' '}
      {t('updatePhone.update.email_static_suffix_second')} <strong>90934</strong>{' '}
      {t('updatePhone.update.email_static_suffix_third')}{' '}
      <LinkWrapper
        href={`${GlobalConstants.assetsPrefix}${RedirectRoutes.supportedCarriers}`}
        className={styles['link']}
        ariaLabel={t('updatePhone.update.label_carriers')}
        openInNewTab
      >
        {t('updatePhone.update.label_carriers')}
      </LinkWrapper>
    </>
  );
  const dialogactionButtons = (
    <>
      <ButtonWrapper
        type="button"
        tag="button"
        isFullWidth
        colorScheme="primary"
        ariaLabel={t('updatePhone.update.continue')}
        label={t('updatePhone.update.continue')}
        handleClick={handleAcceptContinueClick}
      />
      <ButtonWrapper
        isFullWidth
        colorScheme="tertiary"
        ariaLabel={t('updatePhone.update.cancel')}
        handleClick={handleDialogCancelClick}
        label={t('updatePhone.update.cancel')}
      />
    </>
  );
  return (
    <>
      {openUpdateDialog.open ? (
        <DialogWrapper
          role="alertdialog"
          open={openUpdateDialog.open}
          hasCloseIcon={true}
          body={dialogBodyComponent}
          handleCloseIconClick={handleDialogCancelClick}
          actionButtons={dialogactionButtons}
          parentActiveElementId={'updatePhoneBtn'}
        />
      ) : (
        <GenericPanelComponent
          backIcon={true}
          postLogin={true}
          bodyComponent={bodyComponent}
          handleIconClick={handleCancelClick}
          pageTitle={t('updatePhone.edit.phone')}
          panelMainText={
            contactType !== PhoneContactType.ADD
              ? t('updatePhone.update.changeYourPhoneNumber')
              : t('updatePhone.update.addYourPhoneNumber')
          }
          panelSubText={t('updatePhone.update.subText')}
        />
      )}
    </>
  );
};

export default UpdatePhone;
