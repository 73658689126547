import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@visa/vds';
import { RedirectRoutes } from '../../../constants';
import { ButtonWrapper } from '../../../elements/core-ui';
import { GenericPanelComponent } from '../../../elements/shared';
import useTranslator from '../../../hooks/translation-hook';
import { useTitle } from '../../../hooks/use-document-title';
import Styles from './delete-profile.module.scss';

const DeleteProfile: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const t = useTranslator();
  const handleCancelClick = () => {
    navigate(RedirectRoutes.login);
  };
  const handleCreateAccountClick = () => {
    navigate(RedirectRoutes.check);
  };

  useTitle('app.title.deleteAccount');

  const bodyComponent = (
    <>
      <Typography tag="h1" className={Styles['delete-profile-title']}>
        {t('deleteProfile.deletedTitle')}
      </Typography>
      <>
        <ButtonWrapper
          isFullWidth={true}
          type="button"
          tag="button"
          colorScheme="primary"
          handleClick={handleCreateAccountClick}
          ariaLabel={t('deleteProfile.btnCreateAccount')}
          label={t('deleteProfile.btnCreateAccount')}
        />
        <ButtonWrapper
          type="button"
          tag="button"
          isFullWidth={true}
          colorScheme="tertiary"
          handleClick={handleCancelClick}
          ariaLabel={t('updatePassword.btnCancel')}
          label={t('updatePassword.btnCancel')}
        />
      </>
    </>
  );

  return <GenericPanelComponent backIcon={false} postLogin={true} bodyComponent={bodyComponent} />;
};

export default DeleteProfile;
