import { createSlice } from '@reduxjs/toolkit';
import { Utils } from '../../../utils';

interface CheckEligibility {
  countryConfiguration: {
    countryCode?: string;
    terms?: any[];
    privacyUrl?: '';
    shouldLoadSDK?: boolean;
    supportedLocales?: any[];
    supportedFeatures?: any[];
    fieldFormats?: any[];
    responseError?: {
      message: string;
      status: boolean;
      statusCode: number | undefined | null;
      reason: any;
    };
  };

  issuerOptStatus: {
    eligibility: boolean | string;
    errorCode: string;
    issuerUrl: string;
    responseError?: {
      message: string;
      status: boolean;
      statusCode: number | undefined | null;
      reason: any;
    };
  };

  formData: {
    countryCode: string;
    panPrefix: string;
  };
}

const issuerOptStatusInitialValue = {
  eligibility: false,
  errorCode: '',
  issuerUrl: '',
  responseError: { message: '', status: false, statusCode: null, reason: null }
};

const initialState: CheckEligibility = {
  countryConfiguration: {
    countryCode: '',
    terms: [],
    privacyUrl: '',
    shouldLoadSDK: false,
    supportedLocales: [],
    supportedFeatures: [],
    fieldFormats: [],
    responseError: { message: '', status: false, statusCode: null, reason: null }
  },

  issuerOptStatus: issuerOptStatusInitialValue,

  formData: {
    countryCode: '',
    panPrefix: ''
  }
};

const loadCookieBanner = (lngExists, supportedLocales) => {
  const lang = lngExists ? Utils.getLocale() : Utils.getDefaultLng(supportedLocales);
  Utils.loadScript(Utils.getCookieConsentScriptUrl(lang));
};

//TODO: Move country configuration to seperate reducer
const checkEligibilitySlice = createSlice({
  name: 'check-eligibility',
  initialState,
  reducers: {
    getCountryConfigration: (state: CheckEligibility, action) => {
      Utils.setCountryCode(action.payload.country_pref);
      return state;
    },
    countryConfigurationSuccess: (state: CheckEligibility, action) => {
      const { countryCode, fieldFormats, privacyUrl, supportedFeatures, supportedLocales, terms,vimConFlow, extendedAddCard } = action.payload;
      const lngExists = supportedLocales.some(ele => ele.locale === Utils.getLocale());
      !lngExists && Utils.setDefaultLng(supportedLocales);
      loadCookieBanner(lngExists, supportedLocales);
      return {
        ...state,
        countryConfiguration: {
          ...state.countryConfiguration,
          countryCode: countryCode,
          fieldFormats: fieldFormats,
          privacyUrl: privacyUrl,
          supportedFeatures: supportedFeatures,
          supportedLocales: supportedLocales,
          terms: terms,
          vimConFlow:vimConFlow,
          shouldLoadSDK: extendedAddCard
        }
      };
    },
    countryConfigurationFailure: (state: CheckEligibility, action) => {
      const { errorMessage, reason, status, responseMessage } = action.payload;
      return {
        ...state,
        countryConfiguration: {
          ...state.countryConfiguration,
          responseError: {
            ...state.countryConfiguration?.responseError,
            message: responseMessage,
            status: true,
            statusCode: status,
            reason: reason
          }
        }
      };
    },
    countryConfigurationResetResponseFailure: (state: CheckEligibility, action) => {
      return {
        ...state,
        countryConfiguration: {
          ...state.countryConfiguration,
          responseError: {
            ...state.countryConfiguration.responseError,
            message: '',
            status: action.payload,
            statusCode: null,
            reason: null
          }
        }
      };
    },

    postCheckEligibility: (state: CheckEligibility, action) => {
      const { panPrefix, countryCode } = action.payload;
      return {
        ...state,
        issuerOptStatus: {
          ...state.issuerOptStatus,
          responseError: { message: '', status: false, statusCode: null, reason: null }
        },
        formData: {
          ...state.formData,
          panPrefix: panPrefix,
          countryCode: countryCode
        }
      };
    },
    checkEligibilitySuccess: (state: CheckEligibility, action) => {
      const { eligibility, errorCode, issuerUrl } = action.payload;
      return {
        ...state,
        issuerOptStatus: {
          ...state.issuerOptStatus,
          eligibility: eligibility,
          errorCode: errorCode,
          issuerUrl: issuerUrl
        }
      };
    },
    checkEligibilityFailure: (state: CheckEligibility, action) => {
      const { errorMessage, reason, status } = action.payload;
      return {
        ...state,
        issuerOptStatus: {
          ...state.issuerOptStatus,
          responseError: {
            ...state.issuerOptStatus.responseError,
            message: errorMessage,
            status: true,
            statusCode: status,
            reason: reason
          }
        }
      };
    },
    checkEligibilityResetResponseFailure: (state: CheckEligibility, action) => {
      return {
        ...state,
        issuerOptStatus: {
          ...state.issuerOptStatus,
          responseError: {
            ...state.issuerOptStatus.responseError,
            message: '',
            status: action.payload,
            statusCode: null,
            reason: null
          }
        }
      };
    },
    resetIssuerOptStatus: (state: CheckEligibility) => {
      return {
        ...state,
        issuerOptStatus: issuerOptStatusInitialValue
      };
    }
    // checkEligibilityReset: (state: CheckEligibility) => {
    //   state = initialState;
    //   return state;
    // }
  }
});

export const {
  checkEligibilitySuccess,
  postCheckEligibility,
  checkEligibilityFailure,
  checkEligibilityResetResponseFailure,
  // checkEligibilityReset,
  getCountryConfigration,
  resetIssuerOptStatus,
  countryConfigurationSuccess,
  countryConfigurationFailure,
  countryConfigurationResetResponseFailure
} = checkEligibilitySlice.actions;

export default checkEligibilitySlice.reducer;
