import { put, takeLatest, call, select } from 'redux-saga/effects';
import { ajaxHandler, apiPaths } from '../../../setup';
import { replaceUserGuid } from '../../../utils/utils';
import  GlobalConstants  from '../../../../src/constants/global';
import { setEmailGuid, setAccessToken, setFullyLogged } from '../../../auth/user-auth-reducer';
import { Utils} from '../../../utils';
import { showAlert } from '../../../elements/shared';
import {
  postIdentityVerify,
  postIdentityVerifySuccess,
  postIdentityVerifyFailure,
  getContactUnverified,
  getContactUnverifiedSuccess,
  getContactUnverifiedFailure,
  resetSuccessFlags,
  postOtvc,
  postOtvcSuccess,
  postOtvcFailure
} from './enroll-idv-reducer-slice';
import { showErrorModal } from '../../../elements/shared';
import { RedirectRoutes } from '../../../constants';

// contacts unverified api
function* getContactsUnverifiedHanlder(action) {
  const { payload } = action;
  const enroll = yield select(state => state.enroll);
  const { userAuth } = yield select((store: any) => store);
  const contactsUnverifiedUrl = replaceUserGuid(apiPaths.auth.contactsUnverified, enroll.userGuid);
  const response = yield call(
    ajaxHandler,
    `${contactsUnverifiedUrl}?contacts=${payload.contacts.contactsUnverified}`,
    true,
    {
      headers: {
        access_token: userAuth.accessToken
      },
      urlWithPlaceholder: apiPaths.auth.contactsUnverified
    }
  );
  if (!response.error) {
    yield put(getContactUnverifiedSuccess(response.data));
    yield put(setEmailGuid(response.data));
  } else {
    yield put(resetSuccessFlags());
    yield put(getContactUnverifiedFailure(response.error.response));
    if (!response.error.errorHandled) {
      const message = 'errorCodes.technical_error_message';
      yield put(showErrorModal({ message, redirectTo: RedirectRoutes.login }));
    }
  }
}

// idv api
function* postIdentityVerificationHandler(action) {
  const { payload } = action;
  const enroll = yield select(state => state.enroll);
  const { userAuth } = yield select((store: any) => store);
  const apiUrl = apiPaths.enrollment.b2cVerifyIdentity;
  const data = {
    grant_type: 'authorization_probe',
    probes_ref: [
      {
        data: {
          type: "OTP",
          value: payload.idvPayload.verificationValue
        }
      }
    ],
    data:{}
  };
  const response = yield call(ajaxHandler, apiUrl, false, {
    method: 'post',
    data,
    headers: {
      Authorization: 'Bearer ' + userAuth.accessToken
    },
    urlWithPlaceholder: apiUrl
  });

  if (!response.error) {
    yield put(showAlert({ type: 'SUCCESS', message: 'createAccount.success' }));
    yield put(postIdentityVerifySuccess(response.data));
    yield put(setAccessToken(response.data.access_token));
    yield put(setFullyLogged());
  } else {
    yield put(resetSuccessFlags());
    yield put(postIdentityVerifyFailure(response.error));
  }
}

//otvc
function* postOtvcHanlder(action) {
  const { payload } = action;
  const { otvcPayload } = payload;
  const enroll = yield select(state => state.enroll);
  const { userAuth } = yield select((store: any) => store);
  const sendOtvcUrl = apiPaths.auth.b2cSendOtvcUrl;
  const data = {
    grant_type: 'authorization_probe_reissuance',
    probes_ref: [
      {
        data: {
          type: "OTP"
        }
      }
    ]
  }
  const response = yield call(ajaxHandler, sendOtvcUrl, false, {
    method: 'post',
    data,
    headers: {access_token: userAuth.accessToken},
    urlWithPlaceholder: apiPaths.auth.b2cSendOtvcUrl
  });

  if (!response.error) {
    yield put(postOtvcSuccess(response.data));
  } else {
    yield put(resetSuccessFlags());
    yield put(postOtvcFailure(response.error));
  }
}

export default function* watchEnrollIdvVerify() {
  yield takeLatest(postIdentityVerify, postIdentityVerificationHandler);
  yield takeLatest(getContactUnverified, getContactsUnverifiedHanlder);
  yield takeLatest(postOtvc, postOtvcHanlder);
}
