import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Typography, Row, Col } from '@visa/vds';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { GenericPanelComponent } from '../../../elements/shared';
import { ButtonWrapper, InputWrapper, CheckboxWrapper, FormWrapper, LinkWrapper } from '../../../elements/core-ui';
import { AllRegex, GlobalConstants, RedirectRoutes } from '../../../constants';
import { clearAlert } from '../../../elements/shared/alert/global-alert-reducer';
import { Utils, Validator } from '../../../utils';
import { resetIssuerOptStatus } from '../check/check-reducer-slice';
import { useTitle } from '../../../hooks/use-document-title';
import { ContactDetail } from '../../../constants/user-profile';
import { containsSpecialCharacters } from '../../../utils/validators';
import { resetSuccessFlags } from '../../sign-in/sign-in/sign-in-reducer-slice';
import { postEnroll } from './enroll-reducer-slice';
import Styles from './enroll.module.scss';

type EnrollProps = {
  [key: string]: any;
};

const Enroll: React.FC<EnrollProps> = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { enroll, signin, checkEligibility, userAuth, alert } = useSelector((store: any) => store);
  useTitle('app.title.createAccount');

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    userName: '',
    password: '',
    checked: false
  };
  const schema = yup.object({
    firstName: yup.string().trim().required('createAccount.formValidate.error.firstName'),
    lastName: yup.string().trim().required('createAccount.formValidate.error.lastName'),
    email: yup
      .string()
      .trim()
      .required('createAccount.formValidate.error.email.blank')
      .min(7, 'createAccount.formValidate.error.email.minLength')
      .matches(AllRegex.emailRegex, {
        excludeEmptyString: true,
        message: 'createAccount.formValidate.error.email.invalid'
      }),
    userName: yup
      .string()
      .required('createAccount.formValidate.error.username.blank')
      .min(7, 'createAccount.formValidate.error.username.minLength')
      .test((value: any, context: any) => Validator.userNameValidator(value, context)),
    password: yup
      .string()
      .required('createAccount.formValidate.error.password.blank')
      .min(8, 'createAccount.formValidate.error.password.minLength')
      .test((value: any, context: any) => Validator.passwordValidator(value, context)),
    checked: yup.bool().oneOf([true], 'createAccount.formValidate.error.acceptT&C')
  });
  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(schema),
    mode: 'onBlur',
    reValidateMode: 'onBlur'
  });

  const {
    formState: { errors, submitCount },
    getValues,
    clearErrors,
    setError
  } = methods;

  const getPayload = (data?: any) => {
    const payload: { [key: string]: any } = {};
    payload.enrollPayload = {
      credentials: [
        {
          credentialType: 'PASSWORD',
          credentialValue: data?.password
        }
      ],
      identities: [
        {
          identityType: 'USERNAME',
          identityValue: data?.userName,
          primaryInd: true
        }
      ],
      termsAcceptance: {
        termsGUIDs: [
          {
            guid: checkEligibility.countryConfiguration.terms[0]?.termsGuid
          }
        ]
      },
      vuser: {
        countryCode: Utils.getCountryCode(),
        firstName: data?.firstName,
        languageCode: Utils.getLocale()?.split('-')[0] || 'en',
        lastName: data?.lastName,
        locale: Utils.getLocale(),
        userType: 'CONSUMER',
        contacts: [
          {
            contactType: ContactDetail.EMAIL,
            contactPreference: ContactDetail.PRIMARY,
            loginInd: false,
            contactValue: data?.email
          }
        ]
      }
    }

    payload.enrollPayload.codeChallenge=Utils.createCodeChallange(),
    payload.enrollPayload.codeChallengeMethod= 'plain'
    
    payload.enrollTokenPayload = {
      grant_type: 'authorization_code',
      context: Utils.encodeUsingUrlEncoding(
        JSON.stringify({
          channel: 'Web',
          subContext: 'VMCP'
        })
      )
    };
    return payload;
  };

  const submit = (data: any, e) => {
    const payload = getPayload(data);
    dispatch(postEnroll(payload));
    dispatch(clearAlert());
  };
  const handleCancel = () => {
    dispatch(resetIssuerOptStatus());
    navigate(RedirectRoutes.check);
  };

  useEffect(() => {
    !checkEligibility.formData.panPrefix &&
      !checkEligibility.issuerOptStatus.eligibility &&
      navigate(RedirectRoutes.check);
  }, [checkEligibility.issuerOptStatus.eligibility]);

  useEffect(() => {
    if (signin.tokenSuccess && userAuth.userGuid) {
      navigate(RedirectRoutes.createAccountVerifyCode);
    }
  }, [signin.tokenSuccess, userAuth.userGuid]);

  useEffect(() => {
    return () => {
      dispatch(resetSuccessFlags());
    };
  }, []);
  // useEffect(() => {
  //   if (enroll.responseError.reason) {
  //     dispatch(
  //       showAlert({
  //         type: 'ERROR',
  //         message: `createAccount.errorCodes.${enroll.responseError.reason}`
  //       })
  //     );
  //   }
  // }, [enroll.responseError]);

  const handleFocus = (name: string) => {
    if ((name === 'userName' || name === 'email' || name === 'password') && errors?.[name] && getValues(name)) {
      dispatch(Utils.showFormAlertForField(name, errors));
    }
  };

  const handleBlur = (name: any) => {
    const value = getValues(name);
    if (name === 'email') {
      const emailValidation = AllRegex.emailRegex.test(value);
      if (!emailValidation) {
        setError(name, { message: 'createAccount.formValidate.error.email.invalid' });
      } else {
        clearErrors(name);
      }
    } else if (name === 'userName') {
      const hasSpecailCharacters = containsSpecialCharacters(value);
      if (hasSpecailCharacters) {
        return setError(name, { message: 'createAccount.formValidate.error.username.format' });
      } else {
        clearErrors(name);
      }
    }
  };
  const bodyComponent = (
    <FormWrapper onSubmit={methods.handleSubmit(submit)} noValidate>
      <>
        {/* {alert.formMode && <GlobalAlert />} */}
        <Row className={Styles['input-row'] + ' two-col'}>
          <Col xs={4} sm={8} md={8} lg={5}>
            <InputWrapper
              id="firstName"
              className={Styles['input-field']}
              methods={methods}
              label={t('createAccount.fieldLabel.firstName')}
              name={'firstName'}
              type={'text'}
              required={true}
              handleBlur={handleBlur}
              maxLength={100}
            />
          </Col>
          <Col xs={4} sm={8} md={8} lg={5}>
            <InputWrapper
              id="lastName"
              className={Styles['input-field']}
              methods={methods}
              label={t('createAccount.fieldLabel.lastName')}
              name={'lastName'}
              type={'text'}
              required={true}
              handleBlur={handleBlur}
              maxLength={100}
            />
          </Col>
        </Row>
        <InputWrapper
          id="email"
          describedBy={'emailHelperTextId'}
          className={Styles['input-field']}
          methods={methods}
          label={t('createAccount.fieldLabel.email')}
          name={'email'}
          type={'email'}
          required={true}
          showHelpText={true}
          contentBeforeInputIconName={'email'}
          contentBeforeInputIconResolution={'low'}
          handleFocus={handleFocus}
          handleBlur={handleBlur}
          maxLength={100}
        />
        <div>
          <label id={'emailHelperTextId'}>{t('createAccount.note')}</label>
        </div>
        <InputWrapper
          id="userName"
          className={Styles['input-field']}
          methods={methods}
          label={t('createAccount.fieldLabel.userName')}
          name={'userName'}
          type={'text'}
          required={true}
          handleFocus={handleFocus}
          handleBlur={handleBlur}
          maxLength={64}
        />
        <InputWrapper
          id="password"
          className={Styles['input-field']}
          methods={methods}
          label={t('createAccount.fieldLabel.password')}
          name={'password'}
          type={'password'}
          required={true}
          hidePasswordIcon={true}
          handleFocus={handleFocus}
          handleBlur={handleBlur}
          maxLength={32}
        />
        <Row noGutter>
          <Col noGutter className={Styles['checkbox']}>
            <CheckboxWrapper
              id="enroll-confirmation-check"
              methods={methods}
              required={true}
              label={
                <Typography variant="body--alt" className={Styles['check-label']} id={'checkboxHelperText'}>
                  {t('createAccount.acceptT&C.part1')}
                  <LinkWrapper
                    // href={RedirectRoutes.terms}
                    href={Utils.getTermsLink(userAuth.isFullyLogged)}
                    openInNewTab
                    className={Styles['link']}
                    ariaLabel={t('createAccount.acceptT&C.part2')}
                  >
                    {t('createAccount.acceptT&C.part2')}
                  </LinkWrapper>
                  {t('createAccount.acceptT&C.part3')}
                  <LinkWrapper
                    href={checkEligibility?.countryConfiguration?.privacyUrl}
                    openInNewTab
                    className={Styles['link']}
                    ariaLabel={t('createAccount.acceptT&C.part4')}
                  >
                    {t('createAccount.acceptT&C.part4')}
                  </LinkWrapper>
                </Typography>
              }
              name={'checked'}
              handleBlur={handleBlur}
            />
          </Col>
        </Row>

        <ButtonWrapper
          tag="button"
          type="submit"
          isFullWidth
          colorScheme="primary"
          ariaLabel={t('createAccount.button.continue')}
          label={t('createAccount.button.continue')}
        />
        <ButtonWrapper
          isFullWidth
          colorScheme="tertiary"
          ariaLabel={t('createAccount.button.cancel')}
          label={t('createAccount.button.cancel')}
          handleClick={handleCancel}
        />
      </>
    </FormWrapper>
  );

  //spacing in the first 9 digits of the card numbers
  const firstNineCardNumbers = `${checkEligibility.formData.panPrefix.substring(
    0,
    4
  )} ${checkEligibility.formData.panPrefix.substring(4, 8)} ${checkEligibility.formData.panPrefix.substring(8, 9)}`;
  const pageSubTitle = (
    <Typography className={Styles['page-subtitle']}>
      {t('createAccount.page.subHeading.part1')}
      <strong>{firstNineCardNumbers}</strong>
      {t('createAccount.page.subHeading.part2')}
    </Typography>
  );
  return (
    <GenericPanelComponent
      bodyComponent={bodyComponent}
      pageTitle={t('createAccount.page.heading')}
      pageSubTitle={pageSubTitle}
      panelMainText={t('createAccount.heading')}
      panelCardSubText={t('add_edit_card.subHeading.info')}
    />
  );
};

export default Enroll;
