import { put, takeLatest, call } from 'redux-saga/effects';
import { showAlert } from '../../elements/shared';
import { ajaxHandler, apiPaths } from '../../setup';
import { RedirectRoutes } from '../../constants';
import { postForgotUserName, postForgotUserNameFailure, postForgotUserNameSuccess } from './forgot-username-reducer';

function* forgetUsernameHandler(action) {
  const { payload } = action;

  const requestPayload = {
    emailAddress: payload.emailAddress
  };
  const response = yield call(ajaxHandler, apiPaths.forgotUserName, false, {
    method: 'POST',
    data: requestPayload,
    urlWithPlaceholder: apiPaths.forgotUserName
  });

  if (!response.error) {
    yield put(postForgotUserNameSuccess(response.data));
    const type = 'SUCCESS';
    const message = 'forgotUserName.emailSent';
    yield put(showAlert({ type, message }));
    if (action.payload.navigate) {
      action.payload.navigate(RedirectRoutes.login);
    }
  } else {
    yield put(postForgotUserNameFailure(response.error.response));
  }
}

export default function* watchForgotUserName() {
  yield takeLatest(postForgotUserName, forgetUsernameHandler);
}
