import { createSlice } from '@reduxjs/toolkit';

interface ManageCard {
  card: any;
}

const initialState: ManageCard = {
  card: null
};

const manageCardSlice = createSlice({
  name: 'manage-card',
  initialState,
  reducers: {
    setSelectedEditCard: (state: ManageCard, action) => {
      state.card = action.payload;
      return state;
    },
    clearSelectedEditCard: (state: ManageCard) => {
      state = initialState;
      return state;
    },
    setSelectedCardPanGuid: (state: ManageCard, action) => {
      // this method will be invoked only on reload. Use to get the card selected for editing
      state.card = action.payload.panGuid;
      return state;
    }
  }
});

export const { setSelectedEditCard, clearSelectedEditCard } = manageCardSlice.actions;

export default manageCardSlice.reducer;
