import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useNavigate, useLocation } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import useTranslator from '../../../hooks/translation-hook';
import { GenericPanelComponent } from '..';
import { ButtonWrapper, FormWrapper, InputWrapper, DialogWrapper } from '../../core-ui';
import { AllRegex, RedirectRoutes } from '../../../constants';
import { useTitle } from '../../../hooks/use-document-title';
import { setAnotherEmail } from './use-another-email-reducer';
import styles from './use-another-email.module.scss';

const UseAnotherEmail: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const t = useTranslator();
  const { pathname } = useLocation();
  const { signin, idvVerify, enrollIdvVerify, userAuth } = useSelector((store: any) => store);
  const [openEmailConfirmationDialog, setOpenEmailConfirmationDialog] = useState(null);

  useTitle('app.title.useAnotherEmail');

  const initialValues = {
    email: ''
  };
  const schema = yup.object({
    email: yup
      .string()
      .required('use_another_email.validation.blank')
      .min(7, 'use_another_email.validation.minLength')
      .matches(AllRegex.emailRegex, {
        excludeEmptyString: true,
        message: 'use_another_email.validation.invalid'
      })
  });
  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: initialValues,
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    // if (!idvVerify.vuser.contacts[0]?.guid && pathname.includes(RedirectRoutes.login)) {
    //   navigate(RedirectRoutes.login);
    // }
    if (!enrollIdvVerify.vuser.contacts[0]?.guid && pathname.includes(RedirectRoutes.createAccount)) {
      navigate(RedirectRoutes.check);
    }
  }, [enrollIdvVerify.vuser.contacts, idvVerify.vuser.contacts]);

  const handleContinueClick = (data: any) => {
    setOpenEmailConfirmationDialog(data.email);
  };
  const handleCancelClick = () => {
    if (pathname.includes(RedirectRoutes.login)) {
      navigate(RedirectRoutes.loginVerifyEmail);
    } else {
      navigate(RedirectRoutes.createAccountVerifyCode);
    }
  };

  const handleDialogContinueClick = () => {
    const email = openEmailConfirmationDialog;
    let guid = '';
    let redirectAfterSuccess = '';
    if (pathname.includes(RedirectRoutes.login)) {
      //guid = idvVerify.vuser.contacts[0].guid;
      guid = signin.emailGuid || idvVerify?.vuser?.contacts?.[0]?.guid || 'dfdfsd-dfsdf3-sdfsdf1';
      redirectAfterSuccess = RedirectRoutes.loginVerifyEmail;
    } else {
      //guid = enrollIdvVerify.vuser.contacts[0].guid;
      guid = enrollIdvVerify?.vuser?.contacts?.[0]?.guid || 'dfdfsd-dfsdf3-sdfsdf1';
      redirectAfterSuccess = RedirectRoutes.createAccountVerifyCode;
    }

    const state = {
      guid,
      userGuid: userAuth.userGuid,
      accessToken: userAuth.accessToken
    };
    const config = {
      t,
      email,
      state,
      navigate,
      redirectAfterSuccess,
      setOpenEmailConfirmationDialog,
      verificationStatus: ''
    };
    dispatch(setAnotherEmail(config));
    // setOpenEmailConfirmationDialog(null);
  };
  const handleDialogCancelClick = () => {
    setOpenEmailConfirmationDialog(null);
  };

  const bodyDialogComponent = t('use_another_email.dialog_body');
  const bodyComponent = (
    <FormWrapper onSubmit={methods.handleSubmit(handleContinueClick)}>
      <InputWrapper
        id="email"
        describedBy={'emailHelperTextId'}
        className={styles['email-field']}
        type={'email'}
        name={'email'}
        required={true}
        methods={methods}
        label={t('use_another_email.label')}
        contentBeforeInputIconName="email"
      />
      <div>
        <label id={'emailHelperTextId'}>{t('use_another_email.helperNote')}</label>
      </div>
      <>
        <ButtonWrapper
          id={'useAnotherEmailSave'}
          isFullWidth
          tag="button"
          type="submit"
          colorScheme="primary"
          ariaLabel={t('use_another_email.save')}
          label={t('use_another_email.save')}
        />
        <ButtonWrapper
          isFullWidth
          colorScheme="tertiary"
          ariaLabel={t('use_another_email.cancel')}
          label={t('use_another_email.cancel')}
          handleClick={handleCancelClick}
        />
      </>
    </FormWrapper>
  );

  const actionDialogButtons = (
    <>
      <ButtonWrapper
        type="button"
        tag="button"
        isFullWidth
        colorScheme="primary"
        ariaLabel={t('use_another_email.dialog_btn_yes')}
        label={t('use_another_email.dialog_btn_yes')}
        handleClick={handleDialogContinueClick}
      />
      <ButtonWrapper
        tag="button"
        type="button"
        isFullWidth
        colorScheme="tertiary"
        ariaLabel={t('use_another_email.dialog_btn_cancel')}
        label={t('use_another_email.dialog_btn_cancel')}
        handleClick={handleDialogCancelClick}
      />
    </>
  );

  return openEmailConfirmationDialog ? (
    <DialogWrapper
      role="alertdialog"
      hasCloseIcon={true}
      open={Boolean(openEmailConfirmationDialog)}
      body={bodyDialogComponent}
      handleCloseIconClick={handleDialogCancelClick}
      actionButtons={actionDialogButtons}
      parentActiveElementId={'useAnotherEmailSave'}
    />
  ) : (
    <GenericPanelComponent
      bodyComponent={bodyComponent}
      pageTitle={t('use_another_email.title')}
      panelSubText={t('use_another_email.panelText')}
    />
  );
};

export default UseAnotherEmail;
