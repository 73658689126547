const LOCALE_COOKIE_URL_MAPPING = {
  en: 'en-gb',
  'en-US': 'en-gb',
  'en-GB': 'en-gb',
  'pt-BR': 'pt-br',
  'es-AR': 'es-ar',
  'es-419': 'es'
};

export default LOCALE_COOKIE_URL_MAPPING;
