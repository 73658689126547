import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Utils } from '../utils';

export function useTitle(label) {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = Utils.getPageTitle(label);
  });
}
