import React from 'react';
import { ButtonText } from '@visa/vds';
import { useDispatch } from 'react-redux';
import { ButtonWrapperProps } from '../model';
import { Utils } from '../../../utils';
import styles from './button-text.module.scss';

const ButtonWrapper: React.FC<ButtonWrapperProps> = ({
  id,
  className = '',
  type,
  tag,
  isFullWidth,
  colorScheme = 'primary',
  label,
  active,
  alignLeft,
  alignRight,
  flippable,
  iconSpacingLeft,
  iconSpacingRight,
  ariaLabel,
  handleClick,
  skipAlertClear = false,
  buttonType = null
}) => {
  const dispatch = useDispatch();

  const handleButton = e => {
    if (handleClick) {
      if (!skipAlertClear) {
        Utils.hideServerAlert(dispatch);
      }
      handleClick(e);
    }
  };

  return (
    <>
      <ButtonText
        id={id}
        type={type}
        tag={tag}
        isFullWidth={colorScheme !== 'tertiary' && isFullWidth}
        colorScheme={colorScheme}
        active={active}
        alignLeft={alignLeft}
        alignRight={alignRight}
        flippable={flippable}
        iconSpacingLeft={iconSpacingLeft}
        iconSpacingRight={iconSpacingRight}
        className={`${styles[`${colorScheme}`]} ${styles[`${buttonType}`]} ${className}`} //https://issues.trusted.visa.com/browse/MOBENB-18507
        aria-label={ariaLabel}
        onClick={type !== 'submit' && handleClick ? (e: React.MouseEvent<HTMLButtonElement>) => handleButton(e) : null}
      >
        {label}
      </ButtonText>
    </>
  );
};

export default ButtonWrapper;
