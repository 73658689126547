import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { VerifyCode } from '../../../elements/shared';
import { getConfigurations, postToken } from '../../sign-in/sign-in/sign-in-reducer-slice';
import { RedirectRoutes } from '../../../constants';
import { Utils } from '../../../utils';
import { showAlert } from '../../../elements/shared';
import { getContactValue, getLocale } from '../../../utils/utils';
import { ContactDetail } from '../../../constants/user-profile';
import {
  postVerifyTwoFactor,
  getContactsVerified,
  putOtpVerification,
  resetSuccessFlags
} from './email-verify-reducer-slice';

type EmailVerifyProps = {
  [key: string]: any;
};

const EmailVerify: React.FC<EmailVerifyProps> = () => {
  const { signin, emailVerify, userAuth } = useSelector((store: any) => store);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getPayload = (data: any) => {
    const payload: { [key: string]: any } = {};
    (payload.access_token = userAuth.accessToken),
      (payload.guid = userAuth.emailGuid),
      (payload.userGuid = userAuth.userGuid);

    payload.twoFactAuthPayload = {
      grant_type: 'two_factor_auth',
      '2faType': 'OTP_LONG',
      '2faValue': data.otpCode,
      context: Utils.encodeUsingUrlEncoding(
        JSON.stringify({
          subContext: 'CONSUMER_LIGHTBOX',
          eventSource: 'LightboxTW',
          context: 'CHALLENGE_ADVICE',
          channel: 'Web',
          eventSubType: 'StepUp',
          dfpSessionId: 'test'
        })
      )
    };
    payload.otvcPayload = {
      locale: getLocale(),
      eventType: 'CHALLENGE',
      contactType: 'EMAIL',
      otvcType: 'LONG'
    };
    return payload;
  };

  const handleSubmit = data => {
    const payload = getPayload(data);
    dispatch(postVerifyTwoFactor({ payload, navigate }));
    // dispatch(clearAlert(false));
  };

  const handleCancel = () => {
    dispatch(resetSuccessFlags());
  };

  const handleResend = () => {
    dispatch(resetSuccessFlags());
    dispatch(putOtpVerification({ flag: true, getPayload: getPayload({}) }));
  };

  // useEffect(() => {
  //   if (emailVerify.twoFactorResponseError?.error) {
  //     dispatch(
  //       showAlert({
  //         type: 'ERROR',
  //         message: `signin.errorCodes.${emailVerify.twoFactorResponseError.error}`
  //       })
  //     );
  //   }
  // }, [emailVerify.twoFactorResponseError, emailVerify.contactsVerifiedResponseError]);

  useEffect(() => {
    if (emailVerify.otvcSuccess) {
      const type = 'SUCCESS';
      const message = 'forgotPassword.codeSendSuccess';
      dispatch(showAlert({ type, message }));
    }
  }, [emailVerify.otvcSuccess]);

  useEffect(() => {
    if (!userAuth.userGuid) {
      navigate(RedirectRoutes.login);
    }
  }, [userAuth.userGuid]);

  useEffect(() => {
    if (userAuth.userGuid) {
      dispatch(putOtpVerification({ getPayload: getPayload({}) }));
    }
  }, [userAuth.userGuid]);

  useEffect(() => {
    if (emailVerify.contactsVerifiedSuccess) {
      dispatch(putOtpVerification({ getPayload: getPayload({}) }));
    }
  }, [emailVerify.contactsVerifiedSuccess]);

  useEffect(() => {
    if (emailVerify.twoFactorSuccess) {
      dispatch(getConfigurations());
    }
  }, [emailVerify.twoFactorSuccess]);

  useEffect(() => {
    if (signin.configurationsSuccess && signin.pendingTerms) {
      navigate(RedirectRoutes.acceptPendingTnc);
    }
  }, [signin.configurationsSuccess]);
  useEffect(() => {
    if (userAuth.token2Success) {
      navigate(RedirectRoutes.home);
    }
  }, [userAuth.token2Success]);

  useEffect(() => {
    return () => {
      dispatch(resetSuccessFlags());
    };
  }, []);
  return (
    <div>
      <VerifyCode
        contactValueMasking={false}
        submitHandler={handleSubmit}
        cancelHandler={handleCancel}
        resendHandler={handleResend}
        contactValue={getContactValue(
          { contacts: [userAuth.userContactsDetails] },
          ContactDetail.PRIMARY,
          ContactDetail.EMAIL
        )}
      />
    </div>
  );
};

export default EmailVerify;
