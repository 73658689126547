import { put, takeLatest, call, select } from 'redux-saga/effects';
import { setAccessToken, setEmailGuid, setV2ContactDetails } from '../../../auth/user-auth-reducer';
import { RedirectRoutes } from '../../../constants';
import { showErrorModal } from '../../../elements/shared';
import { ajaxHandler, apiPaths } from '../../../setup';
import { replaceUserGuid } from '../../../utils/utils';

import { setLastLoginTime } from '../../../auth/user-auth-reducer';
import { Utils } from '../../../utils';
import { postEmailUpdateSuccess } from '../sign-in/sign-in-reducer-slice';
import {
  postIdentityVerification,
  postIdentityVerificationSuccess,
  postIdentityVerificationFailure,
  getContactsUnverified,
  getContactsUnverifiedSuccess,
  getContactsUnverifiedFailure,
  postSendOtvc,
  postSendOtvcSuccess,
  postSendOtvcFailure,
  resetSuccessFlags
} from './idv-verify-reducer-slice';

// contacts unverified api
function* getContactsUnverifiedHanlder(action) {
  const { payload } = action;
  const signin = yield select((state: any) => state.signin);
  const { userAuth } = yield select((store: any) => store);
  const getContactsUnverifiedURL = replaceUserGuid(apiPaths.auth.contactsUnverified, userAuth.userGuid);

  const response = yield call(
    ajaxHandler,
    `${getContactsUnverifiedURL}?contacts=${payload.contacts.contactsUnverified}`,
    false,
    {
      headers: {
        access_token: userAuth.accessToken
      },
      urlWithPlaceholder: apiPaths.auth.contactsUnverified
    }
  );
  if (!response.error) {
    yield put(getContactsUnverifiedSuccess(response.data));
    yield put(setEmailGuid(response.data));
  } else {
    yield put(resetSuccessFlags());
    yield put(getContactsUnverifiedFailure(response.error));
    //show generic msg for any error not handled by centralized gandling
    if (!response.error.errorHandled) {
      const message = 'errorCodes.technical_error_message';
      yield put(showErrorModal({ message, redirectTo: RedirectRoutes.login }));
    }
  }
}

// idv api
function* postIdentityVerificationHandler(action) {
  const { payload, navigate } = action.payload;
  const { idvPayload } = payload;
  const signin = yield select((state: any) => state.signin);
  const { userAuth } = yield select((store: any) => store);
  idvPayload['contextKey'] = userAuth.emailGuid;
  const response = yield call(
    ajaxHandler,
    apiPaths.enrollment.b2cVerifyIdentity,
    false,
    {
      method: 'post',
      data: {
            grant_type: 'authorization_probe',
            probes_ref: [
              {
                data: {
                  type: 'OTP',
                  value: idvPayload['verificationValue']
                }
              }
            ],
            data: {}
          },
      headers: {
        Authorization: 'Bearer ' + userAuth.accessToken
      },
      urlWithPlaceholder: apiPaths.enrollment.b2cVerifyIdentity
    }
  );
  if (!response.error) {
    yield put(setLastLoginTime(response));
    yield put(setAccessToken(response.data.access_token));
    if (response.data?.scope?.includes('/credentials/reset')) {
      navigate(RedirectRoutes.forcePasswordReset);
    } else {
      yield put(postIdentityVerificationSuccess(response));
    }
  } else {
    yield put(resetSuccessFlags());
    yield put(postIdentityVerificationFailure(response.error));
  }
}

// send otvc
function* postSendOtvcHanlder(action) {
  const { payload } = action;
  const { otvcPayload } = payload;
  const signin = yield select((state: any) => state.signin);
  const { userAuth } = yield select((store: any) => store);
  otvcPayload['guid'] = userAuth.emailGuid;
  const postSendOtvcURL = apiPaths.auth.b2cSendOtvcUrl;
  const b2cPayload = {
    grant_type: 'authorization_probe_reissuance',
    probes_ref: [
      {
        data: {
          type: 'OTP'
        }
      }
    ]
  };
  if (action.payload.contacts.contactsUnverified !== 'unverified') {
    b2cPayload['data'] = {
      contact: {
        contactValue: userAuth.userContactsDetails.contactValue,
        guid: userAuth.userContactsDetails.guid,
        contactType: userAuth.userContactsDetails.contactType
      }
    };
  }
  const response = yield call(ajaxHandler, postSendOtvcURL, false, {
    method: 'post',
    data: { ...b2cPayload },
    headers: { Authorization: 'Bearer ' + userAuth.accessToken },
    urlWithPlaceholder: apiPaths.auth.b2cSendOtvcUrl
  });
  if (!response.error) {
    yield put(postSendOtvcSuccess(response.data));
    yield put(postEmailUpdateSuccess(response.data));
    yield put(setV2ContactDetails(response.data.data));
  } else {
    yield put(resetSuccessFlags());
    yield put(postSendOtvcFailure(response.error));
  }
}
export default function* watchIdvVerify() {
  yield takeLatest(postIdentityVerification, postIdentityVerificationHandler);
  yield takeLatest(getContactsUnverified, getContactsUnverifiedHanlder);
  yield takeLatest(postSendOtvc, postSendOtvcHanlder);
}
