import React from 'react';
import { Outlet } from 'react-router-dom';

type CreateAccountProps = {
  [key: string]: any;
};

const CreateAccount: React.FC<CreateAccountProps> = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

export default CreateAccount;
