import { createSlice } from '@reduxjs/toolkit';
import { resetAppData } from '../../../../app/app-reducer';
import { ServiceOfferings } from './alerts';

export interface AlertsStore {
  response?: AlertsResponse;
  updateAlertsSuccess?: boolean;
  hasDeclinedContactOfferings: boolean;
  isError?: boolean;
}
export interface AlertsResponse {
  lastFourDigits?: string;
  currencyCode?: string;
  isCustomerActive?: boolean;
  isCardActive?: boolean;
  contacts?: Contact[];
  serviceOfferings?: ServiceOffering[];
  supportedDeliveryChannels?: SupportedDeliveryChannel[];
  readOnly?: boolean;
}

export interface Contact {
  contactId?: string;
  contactType?: string;
  contactValue?: string;
  verificationStatus?: string;
  contactAlertStatus?: string;
  sendOTVCode?: boolean;
  sourceType?: string;
  format?: string;
}

export interface ServiceOffering {
  offeringCode?: string;
  offeringDescription?: string;
  serviceOfferingProperties?: ServiceOfferingProperty[];
  contactOfferings?: ContactOffering[];
}

export interface SupportedDeliveryChannel {
  channelType?: string;
  formats?: string[];
}

export interface OfferingProperty {
  key?: string;
  value?: string;
}

export interface ContactOffering {
  contactId?: string;
  isActive?: boolean;
  offeringProperties?: OfferingProperty[];
}

export interface ServiceOfferingProperty {
  key?: string;
  value?: string;
}

const initialState: AlertsStore = {
  response: {},
  updateAlertsSuccess: false,
  hasDeclinedContactOfferings: true,
  isError: false
};

const getDeclinedContactOfferingsStatus = (state, action) => {
  let hasDeclinedContactOfferings = state.hasDeclinedContactOfferings;
  const declinedOffering = action.payload?.serviceOfferings?.find(
    offering => offering.offeringCode === ServiceOfferings.DECLINED
  );
  if (declinedOffering && declinedOffering.contactOfferings && Array.isArray(declinedOffering.contactOfferings)) {
    hasDeclinedContactOfferings = !!declinedOffering.contactOfferings.length;
  }
  return hasDeclinedContactOfferings;
};

const alertsReducer = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    getAlerts: (state: AlertsStore, action) => {
      return state;
    },
    getAlertsSuccess: (state: AlertsStore, action) => {
      return {
        ...state,
        response: action?.payload,
        hasDeclinedContactOfferings: getDeclinedContactOfferingsStatus(state, action)
      };
    },
    getAlertsFailure: (state: AlertsStore, action) => {
      return {
        ...state,
        response: action?.payload,
        isError: true
      };
    },
    updateAlertsSuccess: (state: AlertsStore) => {
      return {
        ...state,
        updateAlertsSuccess: true
      };
    },
    updateAlertsFailure: (state: AlertsStore, action) => {
      return {
        ...state,
        response: action?.payload,
      };
    },
    resetFlags: (state: AlertsStore) => {
      state.updateAlertsSuccess = false;
      state.response = {};
      state.isError = false;
      return state;
    }
  },
  extraReducers: builder => {
    builder.addCase(resetAppData, () => {
      return initialState;
    });
  } 
});

export const { getAlerts, getAlertsSuccess, getAlertsFailure, updateAlertsSuccess, resetFlags, updateAlertsFailure } = alertsReducer.actions;

export default alertsReducer.reducer;
