import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Typography from '@visa/vds/typography';
import { ButtonWrapper, DialogWrapper, LinkWrapper } from '../../../elements/core-ui';
import { postToken, resetSuccessFlags } from '../../sign-in/sign-in/sign-in-reducer-slice';
import useTranslator from '../../../hooks/translation-hook';
import { GlobalConstants, RedirectRoutes } from '../../../constants';
import { useTitle } from '../../../hooks/use-document-title';
import { Utils } from '../../../utils';
import { postAcceptTnC, resetTncFlags } from './accept-pending-tnc-slice';
import styles from './accept-pending-tnc.module.scss';

type AcceptTnCProps = {
  [key: string]: any;
};

const AcceptTnC: React.FC<AcceptTnCProps> = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { signin, acceptTnc, userAuth } = useSelector((store: any) => store);

  const t = useTranslator();
  useTitle('app.title.login');

  useEffect(() => {
    if (userAuth.isFullyLogged) {
      navigate(RedirectRoutes.home);
    }
  }, [userAuth.isFullyLogged]);

  useEffect(() => {
    return () => {
      dispatch(resetTncFlags());
      dispatch(resetSuccessFlags());
    };
  }, []);

  useEffect(() => {
    if (acceptTnc.acceptTncSuccess) {
      dispatch(postToken());
    } else {
      dispatch(resetSuccessFlags);
    }
  }, [acceptTnc.acceptTncSuccess]);

  const handleContinueClick = () => {
    dispatch(postAcceptTnC({ navigate }));
    dispatch(resetSuccessFlags());
  };
  const handleCancelClick = () => {
    dispatch(resetSuccessFlags());
    navigate(RedirectRoutes.login);
  };

  const getTermsUrl = () => {
    const configurationsResponse = signin.configurationsResponseSuccess;
    const [country, locale] = getCountryAndLocaleForTnc(configurationsResponse);
    const url = `/${GlobalConstants.rootPath}/static/documents${RedirectRoutes.terms}/${country}/${locale}/terms-locale.jsp?isAuth=true`;
    return url;
  };

  const getCountryAndLocaleForTnc = configData => {
    const countryCode = configData?.countryCode;
    let locale = Utils.getLocale();
    if (countryCode && Utils.getCountryCode() !== countryCode) {
      const localeFound = configData?.supportedLocales?.find(el => el.locale == Utils.getLocale());
      if (!localeFound) {
        const defaultLocaleObj = configData.supportedLocales?.find(el => el.defaultLocale == 'Y');
        locale = defaultLocaleObj?.locale;
      }
    }
    return [countryCode, locale];
  };

  const bodyComponent = (
    <>
      <Typography tag="span">{`${t('accept_pending_tnc.heading.part1')} `}</Typography>
      <LinkWrapper
        href={getTermsUrl()}
        className={styles['link']}
        ariaLabel={t('updatePhone.update.label_TC')}
        openInNewTab
      >
        {t('accept_pending_tnc.heading.part2')}
      </LinkWrapper>
      <Typography tag="span">. {t('accept_pending_tnc.heading.part3')}</Typography>
    </>
  );
  const actionButtons = (
    <>
      <ButtonWrapper
        type="button"
        tag="button"
        isFullWidth
        colorScheme="primary"
        ariaLabel={t('accept_pending_tnc.button.continue')}
        label={t('accept_pending_tnc.button.continue')}
        handleClick={handleContinueClick}
      />
      <ButtonWrapper
        tag="button"
        type="button"
        isFullWidth
        colorScheme="tertiary"
        ariaLabel={t('accept_pending_tnc.button.cancel')}
        label={t('accept_pending_tnc.button.cancel')}
        handleClick={handleCancelClick}
      />
    </>
  );
  return (
    <DialogWrapper
      role="alertdialog"
      hasCloseIcon={true}
      open={true}
      body={bodyComponent}
      handleCloseIconClick={handleCancelClick}
      actionButtons={actionButtons}
    />
  );
};

export default AcceptTnC;
