import React, { useEffect, useState } from 'react';
import { Row, Typography } from '@visa/vds';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ButtonWrapper } from '../../../elements/core-ui';
import { GenericPanelComponent, showAlert } from '../../../elements/shared';
import { RedirectRoutes } from '../../../constants';
import { deletePreviousPhone } from '../update-phone/update-phone.reducer';
import { getUserDetails } from '../../home/home-reducer';
import { PhoneContactType } from '../../../constants/user-profile';
import { changePhoneOtp } from '../change-email/change-email-reducer';
import styles from './edit-phone.module.scss';

const EditPhoneNumber: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    userDetails: { phoneNumber },
    updatePhone: { contactType },
    updatePhone,
    userAuth
  } = useSelector((store: any) => store);

  const [deletePhone, setDeletePhone] = useState(false);

  const handleContinueClick = () => {
    if (contactType === PhoneContactType.VERIFIED) {
      navigate(RedirectRoutes.updatePhone);
    } else {
      const config = {
        t,
        navigate,
        resendFlag: true
      };
      dispatch(changePhoneOtp(config));
      navigate(RedirectRoutes.phoneVerifyCode);
    }
  };

  const handleCancelClick = () => {
    navigate(RedirectRoutes.profile);
  };

  const handleDelete = () => {
    setDeletePhone(true);
  };

  const deleteHandler = () => {
    dispatch(deletePreviousPhone());
  };

  const handleDeleteCancel = () => {
    setDeletePhone(false);
  };

  useEffect(() => {
    if (contactType === PhoneContactType.VERIFIED && !deletePhone) {
      document.title = `${t('app.title.editMobileNumber')} - ${t('app.title.visaPurchaseAlerts')}`;
    } else if (contactType === PhoneContactType.UNVERIFIED && !deletePhone) {
      document.title = `${t('app.title.verifyMobileNumber')}  - ${t('app.title.visaPurchaseAlerts')}`;
    } else if (deletePhone) {
      document.title = `${t('app.title.deleteMobileNumber')} - ${t('app.title.visaPurchaseAlerts')}`;
    }
  }, [contactType, deletePhone]);

  useEffect(() => {
    if (updatePhone.deletePreviousPhoneSuccess) {
      dispatch(
        showAlert({
          type: 'SUCCESS',
          message: 'updatePhone.delete_confirmation_message'
        })
      );
      if (userAuth?.userGuid && userAuth?.accessToken) dispatch(getUserDetails());
      navigate(RedirectRoutes.profile);
    }
  }, [updatePhone.deletePreviousPhoneSuccess]);

  const bodyComponent = (
    <>
      <Row>
        <Typography variant="body" className={styles['maskedNumber']}>
          {phoneNumber}
        </Typography>
      </Row>
      {!deletePhone ? (
        <>
          <ButtonWrapper
            isFullWidth
            tag="button"
            type="button"
            colorScheme="primary"
            ariaLabel={
              contactType === PhoneContactType.VERIFIED
                ? t('updatePhone.edit.label_edit')
                : t('updatePhone.edit.label_verify')
            }
            label={
              contactType === PhoneContactType.VERIFIED
                ? t('updatePhone.edit.label_edit')
                : t('updatePhone.edit.label_verify')
            }
            handleClick={handleContinueClick}
          />
          <ButtonWrapper
            isFullWidth
            colorScheme="tertiary"
            buttonType="delete"
            ariaLabel={t('updatePhone.edit.delete')}
            label={t('updatePhone.edit.delete')}
            handleClick={handleDelete}
          />
        </>
      ) : (
        <>
          <ButtonWrapper
            isFullWidth
            tag="button"
            type="button"
            colorScheme="primary"
            ariaLabel={t('updatePhone.edit.yes')}
            label={t('updatePhone.edit.yes')}
            handleClick={deleteHandler}
          />
          <ButtonWrapper
            isFullWidth
            colorScheme="tertiary"
            label={t('updatePhone.update.cancel')}
            ariaLabel={t('updatePhone.update.cancel')}
            handleClick={handleDeleteCancel}
          />
        </>
      )}
    </>
  );

  const getPanelMainText = () => {
    if (contactType === PhoneContactType.VERIFIED && !deletePhone) {
      return t('updatePhone.edit.editNumber');
    } else if (contactType === PhoneContactType.UNVERIFIED && !deletePhone) {
      return t('updatePhone.edit.verifyNumber');
    } else if (deletePhone) {
      return t('updatePhone.edit.confirmation_message');
    }
  };

  const getPanelSubText = () => {
    if (contactType === PhoneContactType.VERIFIED && !deletePhone) {
      return t('updatePhone.edit.changeDeleteNumber');
    } else if (contactType === PhoneContactType.UNVERIFIED && !deletePhone) {
      return t('updatePhone.update.verify_deleteNumber');
    } else if (deletePhone) {
      return '';
    }
  };
  return (
    <GenericPanelComponent
      backIcon={true}
      postLogin={true}
      bodyComponent={bodyComponent}
      handleIconClick={!deletePhone ? handleCancelClick : handleDeleteCancel}
      pageTitle={!deletePhone ? t('updatePhone.edit.phone') : t('updatePhone.edit.delete_mobile')}
      panelMainText={getPanelMainText()}
      panelSubText={getPanelSubText()}
    />
  );
};

export default EditPhoneNumber;
