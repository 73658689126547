import { GlobalConstants } from '../constants';

const userGuidPlaceholder = '--userGuid--';
const paymentGuidPlaceholder = '--paymentGuid--';

let apiPaths = {
  renewSession: `/vca-web/renew-server-sessionid`, //This directly goes to server(we don`t handle this route on client). Can be used to get a new sessionId
  unsubscribe: `--endPoint--/apps/--extAppId--/email/unsubscribe`,
  partner: {
    partnerLogo: `--endPoint--/vmeservices/v1/partner/logo/locale/{locale}`
  },
  countryConfig: `--endPoint--/apps/--extAppId--/configurations`,
  auth: {
    loginUrl: `--endPoint--/apps/--extAppId--/oauth2/token`,
    b2cLoginUrl: `/apn/pas-web/oauth2/token`,
    logoutUrl: `/apn/pas-web/oauth2/lcm/token/authorized/revocation`,
    sendOtvcUrl: `--endPoint--/apps/--extAppId--/users/${userGuidPlaceholder}/otvc`,
    b2cSendOtvcUrl: `/apn/pas-web/oauth2/token/probe/reissuance`,
    verifyOtvcUrl: `--endPoint--/apps/--extAppId--/users/${userGuidPlaceholder}/otvc`,
    b2cVerifyOtvcUrl: `/apn/pas-web/oauth2/token/probe/reissuance`,
    contactsVerified: `--endPoint--/apps/--extAppId--/users/${userGuidPlaceholder}`,
    contactsUnverified: `--endPoint--/apps/--extAppId--/users/${userGuidPlaceholder}`,
    checkeligibility: `--endPoint--/apps/--extAppId--/issuerOptStatus`,
    enrollUrl: `--endPoint--/apps/--extAppId--/enroll`,
    verifyOtvcForgotCred: `--endPoint--/apps/--extAppId--/users/${userGuidPlaceholder}/otp/verification/forgotpassword`,
    b2cVerifyOtvcForgotCred: `/apn/pas-web/oauth2/token/probe`,
    verifyTwoFactor: `--endPoint--/apps/--extAppId--/oauth2/token/2fa`,
    userdetails: `--endPoint--/apps/--extAppId--/users/${userGuidPlaceholder}`,
    getTerms: `--endPoint--/apps/--extAppId--/terms`,
    getUserTerms: `--endPoint--/apps/--extAppId--/users/${userGuidPlaceholder}/getTerms`,
    userAcceptTerms: `--endPoint--/apps/--extAppId--/oauth2/token/tnc`
  },
  userTerms: `--endPoint--/apps/--extAppId--/users/${userGuidPlaceholder}/terms`,
  b2cUserTerms: `/apn/pas-web/oauth2/token/probe`,
  getUserTerms: `--endPoint--/apps/--extAppId--/users/${userGuidPlaceholder}/configurations`,
  alerts: {
    alertUrl: `--endPoint--/apps/--extAppId--/users/${userGuidPlaceholder}/paymentInstruments/${paymentGuidPlaceholder}/alerts`
  },
  cardcontrol: {
    url: `--endPoint--/apps/--extAppId--/cardControl/--paymentGuid--`
  },
  balances: {
    balanceUrl: `--endPoint--/apps/--extAppId--/users/${userGuidPlaceholder}/paymentInstruments/--paymentGuid--/balances`
  },
  accounts: {
    url: `--endPoint--/apps/--extAppId--/users/${userGuidPlaceholder}/paymentInstruments/--paymentGuid--/accounts`
  },
  transactions: {
    url: `--endPoint--/apps/--extAppId--/users/${userGuidPlaceholder}/paymentInstruments/--paymentGuid--/alerts/history`
  },
  cardStatus: {
    url: `--endPoint--/apps/--extAppId--/users/${userGuidPlaceholder}/paymentInstruments/--paymentGuid--/status`
  },
  secureUrl: `--endPoint--/apps/--extAppId--/users/${userGuidPlaceholder}/secure`,
  phoneUrl: `--endPoint--/apps/--extAppId--/users/${userGuidPlaceholder}/phones`,
  emailUrl: `--endPoint--/apps/--extAppId--/users/${userGuidPlaceholder}/emails`,
  messageLang: {
    get: `--endPoint--/apps/--extAppId--/users/${userGuidPlaceholder}/preferences/language`,
    put: `--endPoint--/apps/--extAppId--/users/${userGuidPlaceholder}/preferences/language`
  },
  challenge: {
    verifyUrl: null
  },
  payment: {
    cardArtUrl: null,
    baseUrl: `--endPoint--/apps/--extAppId--/users/${userGuidPlaceholder}/paymentInstruments`
  },
  sdk: {
    createEncryptedPayloadUrl: `--endPoint--/apps/--extAppId--/encryptedPayload`
  },
  address: {
    baseUrl: null
  },
  forgotUserName: `--endPoint--/apps/--extAppId--/identities`,
  forgotPassword: {
    sendEmailUrl: `--endPoint--/apps/--extAppId--/forgotcredential`,
    b2cSendEmailUrl: `/apn/pas-web/oauth2/token/idproof/promise`,
    b2cReissuance: `/apn/pas-web/oauth2/token/probe/reissuance`
  },
  resetPassword: {
    resetPasswordUrl: `--endPoint--/apps/--extAppId--/users/${userGuidPlaceholder}/resetCredential`,
    b2cResetPasswordUrl: `/apn/pas-web/oauth2/lcm/subject/authorized/credentials/reset`
  },
  changePassword: {
    changePasswordUrl: `/apn/pas-web/oauth2/lcm/subject/authorized/credentials/change`,
    oauth: `--endPoint--/apps/--extAppId--/oauth2/token`,
    b2cOauth: `/apn/pas-web/oauth2/token`
  },
  keepAliveUrl: `--endPoint--/apps/--extAppId--/oauth2/token/renewal`,
  b2cKeepAliveUrl: `/apn/pas-web/oauth2/token/renewal`,
  issuer: {
    getFedUserUrl: `--endPoint--/issuer-controller/v1/federatedProvision`
  },
  enrollment: {
    oauth: `--endPoint--/apps/--extAppId--/oauth2/token`,
    b2cOauth: `/apn/pas-web/oauth2/token`,
    verifyIdentity: `--endPoint--/apps/--extAppId--/oauth2/token/idv`,
    b2cVerifyIdentity: `/apn/pas-web/oauth2/token/probe`
  },
  getUser: `--endPoint--/apps/--extAppId--/users/${userGuidPlaceholder}`
};

apiPaths = JSON.parse(
  JSON.stringify(apiPaths)
    .replace(/--endPoint--/gi, GlobalConstants.apiBase)
    .replace(/--extAppId--/gi, GlobalConstants.extAppId)
);

// export const setUserGuid = (guid: string) => {
//   const placeholder = userGuidPlaceholder;
//   ////NEED TO TEST
//   apiPaths = JSON.parse(JSON.stringify(apiPaths).replace(/${userGuidPlaceholder}/gi, guid));
//   return apiPaths;
// };

//Needs to be called when LOGIN ROUTE, logout, ideal timeout, season close
// export const resetUserGuid = (guid: string) => {
//   apiPaths = JSON.parse(JSON.stringify(apiPaths).replace(/guid/gi, userGuidPlaceholder));
// };

export default apiPaths;
