import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { LinkWrapper } from '../../core-ui';
import { Utils } from '../../../utils';
import AlertWrapper from './alert';
import { MessageNodeType, updatePreviousMessage } from './global-alert-reducer';

export type GlobalAlertProps = {
  styles?: any;
  show?: boolean;
  topnav?: string;
  position?: string;
  iconName?: string;
  className?: string;
  alertMessage?: string;
  dismissable?: boolean;
  // alertClose: () => void;
  autoFocusAlertButton?: boolean;
  isDynamic?: boolean;
  dynamicParts?: Array<any>;
};

const GlobalAlert: React.FC<GlobalAlertProps> = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { alert, checkEligibility } = useSelector((store: any) => store);
  const [isOpenAlert, setOpenAlert] = useState(Boolean(alert.message));
  const [altRole, setAltRole] = React.useState('');
  const [altText, setAltText] = React.useState('');

  React.useEffect(() => {
    setOpenAlert(Boolean(alert.message));
    if (alert.type === 'ERROR' && alert.formMode) {
      setAltRole('alert');
      setAltText('app.error');
      return;
    }
    if (alert.type === 'ERROR') {
      setAltRole('alert');
      setAltText('app.alert');
    }
    if (alert.type === 'SUCCESS') {
      setAltRole('status');
      setAltText('app.success');
    }
  }, [alert.message]);

  const getMessage = () => {
    if (alert.message === MessageNodeType.CheckFinacialInstitution) {
      //  we are returning a component here, which should show a message with a link in it.
      if (checkEligibility?.issuerOptStatus?.issuerUrl)
        return (
          <Trans>
            {t('check.errorCodes.ISSUER_NOT_SUBSCRIBED_WITH_LINK')}
            <LinkWrapper
              openInNewTab
              href={checkEligibility?.issuerOptStatus?.issuerUrl}
              ariaLabel={t('check.errorCodes.here')}
            >
              {t('check.errorCodes.here')}
            </LinkWrapper>
          </Trans>
        );
      else return t('check.errorCodes.ISSUER_NOT_SUBSCRIBED_WITHOUT_LINK');
    } else {
      let message = t(alert.message);
      if (!alert.isDynamic) {
        return message;
      }

      alert.dynamicParts.forEach(element => {
        if (element) {
          message = message.replace('{{d}}', element);
        }
      });
      return message;
    }
  };

  (function (isOpenAlert, message, previousAlertMessage) {
    if (isOpenAlert && message && message != previousAlertMessage) {
      setTimeout(() => {
        const element = document.getElementById('global-alert');
        Utils.scrollIntoView(element);
      }, 200);
    }
  })(Boolean(alert.message), alert.message, alert.previousAlertMessage);

  // if(isOpenAlert && message && message != alert.previousAlertMessage){
  //   setTimeout(() => {
  //     const element = document.getElementById('global-alert');
  //     Utils.scrollIntoView(element);
  //   }, 200);
  // }

  if (alert.message) dispatch(updatePreviousMessage(alert.message));

  return (
    <div aria-live="assertive">
      <AlertWrapper
        message={getMessage()}
        alertType={alert.type.toLowerCase()}
        isOpenAlert={isOpenAlert}
        altRole={altRole}
        altText={t(altText)}
        closeIcon={alert.closeIcon}
      />
    </div>
  );
};

export default GlobalAlert;
