import { ForgotUserName } from './index';

const forgotUsernameRoutes = [
  {
    path: 'forgot-username',
    element: <ForgotUserName />
  }
];

export default forgotUsernameRoutes;
