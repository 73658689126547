import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useTranslator from '../../../hooks/translation-hook';
import { showAlert, VerifyCode as VerifyCodeGeneric } from '../../../elements/shared';
import { RedirectRoutes } from '../../../constants';
import { changePhoneOtp } from '../change-email/change-email-reducer';
import { deletePreviousPhone } from '../update-phone/update-phone.reducer';
import { getUserDetails } from '../../home/home-reducer';
import { PhoneContactType } from '../../../constants/user-profile';

type CodeVerifyProps = {
  [key: string]: any;
};

const PhoneVerifyCode: React.FC<CodeVerifyProps> = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const t = useTranslator();
  const { signin, changeEmail, updatePhone, userDetails, userAuth } = useSelector((store: any) => store);

  const unverifiedContact = userDetails.vuser.contacts.find(item => {
    if (item.guid === userDetails.guid) {
      return item;
    }
  });

  const handleSubmit = (data: any) => {
    const state = {
      ...changeEmail,
      accessToken: userAuth.accessToken
    };
    const config = {
      t,
      state,
      navigate,
      otp: data.otpCode,
      username: signin.username
    };
    dispatch(changePhoneOtp(config));
  };

  const handleBackIcon = () => {
    if (updatePhone.contactType === PhoneContactType.VERIFIED || updatePhone.contactType === PhoneContactType.ADD) {
      navigate(RedirectRoutes.updatePhone);
    } else if (updatePhone.contactType === PhoneContactType.UNVERIFIED) {
      navigate(RedirectRoutes.editPhone);
    }
  };

  useEffect(() => {
    if (changeEmail.otvcPhoneSuccess) {
      if (updatePhone.contactType === PhoneContactType.UNVERIFIED || updatePhone.contactType === PhoneContactType.ADD) {
        if (userAuth?.userGuid && userAuth?.accessToken) dispatch(getUserDetails());
        navigate(RedirectRoutes.profile);
      } else {
        dispatch(deletePreviousPhone);
      }
    }
  }, [changeEmail.otvcPhoneSuccess]);

  useEffect(() => {
    if (updatePhone.deletePreviousPhoneSuccess) {
      if (updatePhone.contactType === PhoneContactType.VERIFIED) {
        dispatch(
          showAlert({
            type: 'SUCCESS',
            message: 'updatePhone.success'
          })
        );
      } else if (updatePhone.contactType === PhoneContactType.DELETE) {
        dispatch(
          showAlert({
            type: 'SUCCESS',
            message: 'updatePhone.delete_confirmation_message'
          })
        );
      }

      if (userAuth?.userGuid && userAuth?.accessToken) dispatch(getUserDetails());
      navigate(RedirectRoutes.profile);
    }
  }, [updatePhone.deletePreviousPhoneSuccess]);
  const handleCancel = () => {
    navigate(RedirectRoutes.profile);
  };

  const handleResend = () => {
    const state = {
      ...changeEmail,
      accessToken: userAuth.accessToken
    };
    const config = {
      t,
      state,
      navigate,
      username: signin.username,
      resendFlag: true
    };
    dispatch(changePhoneOtp(config));
  };

  return (
    <div>
      <VerifyCodeGeneric
        phoneNumber={
          updatePhone.contactType === PhoneContactType.UNVERIFIED
            ? unverifiedContact.contactValue
            : updatePhone?.phoneNumber
        }
        submitHandler={handleSubmit}
        cancelHandler={handleCancel}
        resendHandler={handleResend}
        backIconHandler={handleBackIcon}
        dialogContinueRedirect={RedirectRoutes.profile}
      />
    </div>
  );
};

export default PhoneVerifyCode;
