import { apiPaths } from '../setup';

export const pluckErrorCode = description => {
  if (/Error:|Error :/gi.test(description)) {
    return description.substring(description.search('Error') + 7, description.indexOf('.')).replace(/[: \s]+/g, '');
  } else {
    return null;
  }
};

export const getCommonErrorCodes = errorCode => {
  const techErrorCodes = [
    'VMCP_001',
    'VMCP_002',
    'VMCP_003',
    'VMCP_004',
    'VMCP_1001',
    'VMCP_1002',
    'VMCP_1003',
    'VMCP_1004',
    'VMCP_1005',
    'VMCP_1008',
    'VMCP_1009',
    'VMCP_1010',
    'VMCP_1011',
    '9612'
  ];
  return Boolean(techErrorCodes.find(element => element === errorCode));
};

export const isPermTempLock = (status, errorCode) => {
  return (
    (status === 403 ||
      (status === 409 &&
        (errorCode === 'ICM_1004' || errorCode === '9617' || errorCode === 'VMCP_1007' || errorCode === 'ICM_1014' || errorCode === '9618'))) &&
    errorCode !== 'VMCP_1006'
  );
};

export const isErrorHandlingExcepted = exceptedEndPoint => {
  const skipEndPoints: any = [
    apiPaths.renewSession,
    apiPaths.auth.logoutUrl,
    apiPaths.alerts.alertUrl,
    apiPaths.cardcontrol.url,
    apiPaths.transactions.url
  ];

  return skipEndPoints.findIndex(endpoint => endpoint === exceptedEndPoint) != -1;
};

export const isErrorCodePresentInErrorDescription = errorDescription => {
  return /Error:|Error :/gi.test(errorDescription);
};

export const getErrorDetails = serverResponse => {
  let errorCode, errorMessage, errorObject;
  //Check for object depth
  const responseArr: any = isFlatObject(serverResponse),
    isFlat = responseArr[0],
    errorVariation: string = responseArr[1];
  //if response.error|| response.verificationStatus is present response id flat
  if (isFlat) {
    errorCode = serverResponse.verificationStatus || serverResponse.error || serverResponse.reason || 'VMCP_001';
    errorMessage = serverResponse.error_description;
    if (isErrorCodePresentInErrorDescription(serverResponse.error_description)) {
      errorCode = pluckErrorCode(serverResponse.error_description);
    }
  } else {
    //status/httpStatus/statusCode, reason/code, message
    //if not direct code is present inside responsestatus||errorResponse i.e one level deep
    errorObject = errorVariation == 'direct' ? serverResponse : serverResponse[errorVariation];
    errorCode = errorObject.reason || errorObject.code || '';
    errorMessage = errorObject.message;
  }
  return { errorCode: errorCode, errorMessage: errorMessage };
};

//Check for object structure
export const isFlatObject = responseObject => {
  let isFlat = [false, 'direct', 'type0'];
  //TODO: move below strings to constants(enums)
  const conditions = {
    ResponseStatus: [false, 'ResponseStatus', 'type1'],
    responseStatus: [false, 'responseStatus', 'type2'],
    errorResponse: [false, 'errorResponse', 'type3'],
    error: [true, 'error', 'type4'],
    verificationStatus: [true, 'verificationStatus', 'type5']
  };
  for (const key in conditions) {
    if (responseObject[key]) {
      isFlat = conditions[key];
      break;
    }
  }

  return isFlat;
};
