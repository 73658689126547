import { FC } from 'react';
import { Typography, Icon } from '@visa/vds';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import ptbrLocale from 'moment/locale/pt-br';
import esLocale from 'moment/locale/es';
import { Utils } from '../../../../utils';
import { Notification } from './alert-history-reducer';
import styles from './alert-history.module.scss';

const momentLangMap = {
  en: { code: 'en', specification: [] },
  'en-US': { code: 'en', specification: [] },
  'es-419': { code: 'es', specification: [esLocale] },
  'es-AR': { code: 'es', specification: [esLocale] },
  'pt-BR': { code: 'pt-br', specification: [ptbrLocale] }
};

type HistoryListProps = {
  alertHistory: Notification[];
  alertHistoryCount: number;
};

const fallbackData = {
  merchantName: '--',
  merchantCity: '--',
  merchantCountryCode: '--',
  transactionDisplayAmount: '',
  billingDisplayAmount: '',
  transactionCurrency: 'USD'
};
const HistoryList: FC<HistoryListProps> = props => {
  const { alertHistory, alertHistoryCount } = props;

  const { t } = useTranslation();
  const transactionDisplayAmount = (transactionDetails, serviceOffering) => {
    const transactionDisplayAmount = transactionDetails.transactionDisplayAmount;
    const splitAmountAndText = transactionDisplayAmount.split(' ');
    if (!splitAmountAndText.length) {
      return t('alertsHistory.amountNotFound');
    }
    const transactionAmount = Number(splitAmountAndText[0]);
    const absAmount = Math.abs(transactionAmount);
    let displayAmount: string | number = transactionAmount;
    //Consider transaction amount as 0 for all operations , for gasoline transactions.
    if (!transactionAmount || serviceOffering === 'Gasoline') {
      return t('alertsHistory.amountNotFound');
    }
    displayAmount =
      transactionDetails.transactionCurrency +
      ' ' +
      parseFloat(`${absAmount}`)
        .toFixed(2)
        .replace(/./g, function (c, i, a) {
          return i > 0 && c !== '.' && (a.length - i) % 3 === 0 ? ',' + c : c;
        });

    if (transactionAmount < 0) {
      displayAmount = '-' + displayAmount;
    }
    return displayAmount;
  };

  const getDescriptionFromServiceOffering = notification => {
    const offeringVSIconNameMap = {
      Threshold: t('alertsHistory.serviceOfferings.threshold'),
      CrossBorder: t('alertsHistory.serviceOfferings.crossBorder'),
      Declined: t('alertsHistory.serviceOfferings.declined'),
      GenericCredit: t('alertsHistory.serviceOfferings.genericCredit')
    };
    return offeringVSIconNameMap[notification.serviceOffering] || t('alertsHistory.serviceOfferings.default');
  };

  const checkDay = (txnDate, day) => {
    const today = new Date();
    if (!txnDate) return false;
    const dayMap = {
      today: 0,
      yesterday: 1
    };

    return (
      today.getDate() - dayMap[day] === txnDate.getDate() &&
      today.getMonth() === txnDate.getMonth() &&
      today.getFullYear() === txnDate.getFullYear()
    );
  };

  const getTransactionTime = (datetime: Date) => {
    const currentLanguage = Utils.getLocale() || 'en';
    const momentConfig = momentLangMap[currentLanguage];
    //  change locale of moment js (dates)
    moment.locale(momentConfig.code, momentConfig.specification);

    if (checkDay(new Date(datetime), 'today')) {
      return t('alertsHistory.transactions.date.today') + ', ' + moment(datetime).format('hh:mm a');
    } else if (checkDay(new Date(datetime), 'yesterday')) {
      return t('alertsHistory.transactions.date.yesterday') + ', ' + moment(datetime).format('hh:mm a');
    } else {
      return moment(datetime).format('MMM D, hh:mm a');
    }
  };

  const eachNotification = (notification: Notification, index: number) => {
    const transactionDetails = Boolean(notification.transactionDetails.length)
      ? notification.transactionDetails[0]
      : fallbackData;
    const merchantName = transactionDetails.merchantName;
    const merchantCity = transactionDetails.merchantCity;
    const merchantCountryCode = transactionDetails.merchantCountryCode;
    const transactionTime = getTransactionTime(notification.notificationTimeStamp);

    return (
      <div className={styles['history-container']} key={index}>
        <div className={styles['service-offering-details']}>
          <div>
            <Icon
              alt={getDescriptionFromServiceOffering(notification)}
              resolution="low"
              aria-hidden="true"
              name={Utils.getIconNameFromServiceOffering(notification.serviceOffering)}
            />
          </div>
          <Typography tag="p" className={styles['main-text']}>
            {getDescriptionFromServiceOffering(notification)}
          </Typography>
          <Typography tag="p" className={styles['main-text']}>
            {transactionTime}
          </Typography>
        </div>
        <div className={styles['merchant-details']}>
          <Typography tag="p" className={styles['sub-text']}>
            {merchantName + ' - ' + merchantCity + ', ' + merchantCountryCode}
          </Typography>
          <Typography tag="p" className={styles['sub-text']}>
            {transactionDisplayAmount(transactionDetails, notification.serviceOffering)}
          </Typography>
        </div>
      </div>
    );
  };

  return <div className={styles['list-container']}>{alertHistory.map(eachNotification)}</div>;
};

export default HistoryList;
