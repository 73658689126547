import React, { useEffect } from 'react';
import {
  Dialog,
  DialogCardBody,
  DialogCardHeadTitle,
  ButtonGroup,
  DialogCardFooter,
  DialogCardHead,
  DialogCardClose,
  ButtonIcon,
  Icon,
  Typography,
  Row
} from '@visa/vds';
import { t } from 'i18next';
import Styles from './dialog.module.scss';

type DialogWrapperProps = {
  containerClass?: string;
  open: boolean;
  title?: string;
  role?: string;
  body?: string | React.ReactNode;
  type?: string;
  ariaModal?: boolean;
  handleCloseIconClick?: () => void;
  hasCloseIcon?: boolean;
  handleOnExit?: () => void;
  actionButtons: React.ReactNode;
  parentActiveElementId?: string;
};

const DialogWrapper: React.FC<DialogWrapperProps> = ({
  containerClass = '',
  open = false,
  title = '',
  body = '',
  role = 'dialog',
  type = 'success',
  ariaModal = true,
  handleCloseIconClick,
  hasCloseIcon = false,
  handleOnExit,
  actionButtons,
  parentActiveElementId
}) => {
  const containerClassStyles = () => {
    const defaultClass = Styles['container'];
    return containerClass ? `${defaultClass} ${containerClass}` : defaultClass;
  };

  const dialogElement = document.getElementById('dialogWrapper');

  const closeDialog = () => {
    handleCloseIconClick && handleCloseIconClick();
    dialogElement?.removeEventListener('keydown', onEscapePressHandler);
    setTimeout(() => {
      //  on escape key press set the focus to previously focused element
      const element = document.getElementById(parentActiveElementId || '');
      if (element) {
        (element as HTMLElement)?.focus();
        return;
      }
    }, 0);
  };

  const onEscapePressHandler = e => {
    if (e.key === 'Escape') {
      closeDialog();
    }
  };

  useEffect(() => {
    const dialogWrapperElement = document.getElementById('dialogRootEl');
    dialogWrapperElement?.addEventListener('keydown', onEscapePressHandler);

    return () => {
      dialogWrapperElement?.removeEventListener('keydown', onEscapePressHandler);
    };
  }, []);

  const focusSetter = (elementId): boolean => {
    // on dialog open set focus to the first element (excluding the close icon) in actionableElements array
    const element = document.getElementById(elementId);
    const actionableElements = ['input', 'button', 'a'];
    for (let ele = 0; ele < actionableElements.length; ele++) {
      const actionElement = element?.querySelector(actionableElements[ele]);
      (actionElement as HTMLElement)?.focus();
      if (actionElement) return true; // if it finds the element to be focus, set focus the focus and break the loop
    }
    return false;
  };

  const focusFirstElement = () => {
    setTimeout(() => {
      const focused = focusSetter('dialogBody');
      // if no element in dialogBody is focused, then set focus to th element in dialogFooter
      if (!focused) focusSetter('dialogFooter');
    }, 0);
  };
  focusFirstElement();

  return (
    <Dialog
      className={containerClassStyles()}
      rootElId={'dialogRootEl'}
      aria-labelledby={title ? 'dialogHead' : 'dialogBody'}
      aria-modal={ariaModal}
      role={role}
      type={type}
      open={open}
      onExit={handleOnExit && handleOnExit} // onExit gets invoked after the dialog has completely closed
    >
      {hasCloseIcon && (
        <DialogCardClose className={Styles['close-icon-wrap']}>
          <ButtonGroup direction="right">
            <ButtonIcon ariaLabel={t('app.close')} iconType="light-tiny" id="iconId" onClick={closeDialog}>
              <Icon name="close" resolution="tiny" />
            </ButtonIcon>
          </ButtonGroup>
        </DialogCardClose>
      )}
      <Row>
        {title && (
          <DialogCardHead className={Styles['header']}>
            <DialogCardHeadTitle tag="h2" className={Styles['title']} id="dialogHead">
              {title}
            </DialogCardHeadTitle>
          </DialogCardHead>
        )}
        {body && (
          <DialogCardBody className={Styles['body']} id="dialogBody">
            <Typography tag="p" className={Styles['body-text']}>
              {body}
            </Typography>
          </DialogCardBody>
        )}
        <DialogCardFooter className={Styles['footer']} id="dialogFooter">
          {actionButtons}
        </DialogCardFooter>
      </Row>
    </Dialog>
  );
};

export default DialogWrapper;
