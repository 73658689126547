// To differentiate account management Mobile Number flows
export enum PhoneContactType {
  VERIFIED = 'VERIFIED',
  UNVERIFIED = 'UNVERIFIED',
  ADD = 'ADD',
  DELETE = 'DELETE'
}

// UserDetail response Contact Object
export enum ContactDetail {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  EMAIL = 'EMAIL',
  VERIFIED = 'VERIFIED',
  UNVERIFIED = 'UNVERIFIED',
  PHONE = 'PHONE_NUMBER',
  SMS = 'SMS'
}

export enum ContactEventType {
  EMAIL_VERIFICATION = 'EMAIL_VERIFICATION',
  PHONE_VERIFICATION = 'PHONE_VERIFICATION',
  EMAIL = 'Email'
}
export enum OTVCType {
  LONG = 'LONG',
  SHORT = 'SHORT'
}

export enum NotificationTypes {
  System = 'System',
  Community = 'Community',
  TransactionAlert = 'TransactionAlert',
  AggregationAlert = 'AggregationAlert'
}

export enum FeatureTypes {
  VTA = 'VTA',
  VCTC = 'VCTC'
}

export default {
  OTVCType,
  FeatureTypes,
  ContactDetail,
  PhoneContactType,
  ContactEventType,
  NotificationTypes
};
