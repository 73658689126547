import { createSlice } from '@reduxjs/toolkit';

interface SpinnerInterface {
  show: boolean;
}

const initialState: SpinnerInterface = {
  show: false
};

const spinnerReducer = createSlice({
  name: 'spinner',
  initialState,
  reducers: {
    showSpinner: (state: SpinnerInterface) => {
      state.show = true;
      return state;
    },
    hideSpinner: (state: SpinnerInterface) => {
      state.show = false;
      return state;
    }
  }
});

export const { showSpinner, hideSpinner } = spinnerReducer.actions;

export default spinnerReducer.reducer;
