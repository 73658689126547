import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@visa/vds/button';
import Listbox from '@visa/vds/listbox';
import ListboxItem from '@visa/vds/listbox-item';
import Typography from '@visa/vds/typography';
import Icon from '@visa/vds/icon';
import Card from '@visa/vds/card';
import CardContent from '@visa/vds/card-content';
import CardContentCategory from '@visa/vds/card-content-category';
import Menu from '@visa/vds/menu';

import { t } from 'i18next';
import { Link, useNavigate } from 'react-router-dom';
import { CardTileComponent, CardList } from '../../shared';
import { setSelectedCard } from '../../../pages/home/home-reducer';
import { RedirectRoutes } from '../../../constants';
import {
  getCardControls,
  updateCardControlSupport
} from '../../../pages/home/components/card-control/card-control-reducer';
import { Utils } from '../../../utils';
import { getAlerts, resetFlags } from '../../../pages/home/components/alerts/alerts-reducer';
import styles from './cardlist-dropdown.module.scss';

const CardListDropdown: React.FC = props => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userDetails } = useSelector((state: any) => state);
  const { selectedCard } = userDetails;
  const cards = userDetails?.paymentInstruments;
  const [isCardListOpen, setIsCardListOpen] = useState(false);
  const [toggleCardsList, setToggleCardsList] = useState(false);
  const [selectedOption, setSelectedOption] = useState<null | number>(null);

  const handleArrowClick = () => {
    setToggleCardsList(!toggleCardsList);
    setIsCardListOpen(!isCardListOpen);
  };

  const handleSelect = (e, card: any) => {
    e.stopPropagation();
    dispatch(setSelectedCard({ card }));
    // disable card control support so that the support is again set as true after get card control success
    // and the new card control values are reflected on screen
    dispatch(updateCardControlSupport(false));
    //  with every card selection change, call get card control api
    //  this api response affect on home page tabs; wheather to show card control or not.
    dispatch(getCardControls({ navigate }));
    // to toggle dropdown
    setToggleCardsList(false);
    // to close dropdown after selecting a card
    setIsCardListOpen(false);
    //  if we are on card controls page, fetch alerts data
    //  required for card lock warning msg
    dispatch(resetFlags());
    // to reset the flags whenever changing the cards

    if (window.location.pathname.includes(RedirectRoutes.homeCardControl)) {
      dispatch(
        getAlerts({
          t,
          method: 'GET'
        })
      );
    }
  };

  const navMenuClass = () => {
    const defaultClass = styles['cards-content'];
    return isCardListOpen ? `${defaultClass} ${styles['content-show']}` : `${defaultClass} ${styles['content-hide']}`;
  };

  const handleClearAlert = () => {
    Utils.hideServerAlert(dispatch);
  };

  const closeDropDownThenFocusCardSelectEle = () => {
    if (isCardListOpen) {
      const btnEle = document.getElementById('cardDropdownButton');
      btnEle?.focus();
    }
    setToggleCardsList(false);
    setIsCardListOpen(false);
  };

  const handleKeyDown = e => {
    switch (e.key) {
      case 'Tab':
        closeDropDownThenFocusCardSelectEle();
        break;
      case 'Escape':
        e.preventDefault();
        closeDropDownThenFocusCardSelectEle();
        break;
      case 'Enter':
        e.preventDefault();
        if (selectedOption === cards.length) navigate(RedirectRoutes.homeManageCards);
        else if (e.target.nodeName === 'LI') handleSelect(e, cards[selectedOption === null ? 0 : selectedOption]);
        closeDropDownThenFocusCardSelectEle();
        break;
      case 'ArrowUp':
        e.preventDefault();
        setSelectedOption(selectedOption === null ? 0 : selectedOption - 1 >= 0 ? selectedOption - 1 : cards.length);
        break;
      case 'ArrowDown':
        e.preventDefault();
        setSelectedOption(selectedOption === null ? 0 : selectedOption == cards.length ? 0 : selectedOption + 1);
        break;
      default:
        break;
    }
  };

  const handleListKeyDown = e => {
    switch (e.key) {
      case 'ArrowUp':
        e.preventDefault();
        const selectedOptionViaUpArrow =
          selectedOption === null ? 0 : selectedOption - 1 >= 0 ? selectedOption - 1 : cards.length - 1;
        if (isCardListOpen === false) {
          handleSelect(e, cards[selectedOptionViaUpArrow]);
        }
        setSelectedOption(selectedOptionViaUpArrow);
        break;
      case 'ArrowDown':
        e.preventDefault();
        const selectedOptionViaDownArrow =
          selectedOption === null ? 0 : selectedOption == cards.length - 1 ? 0 : selectedOption + 1;
        if (isCardListOpen === false) {
          handleSelect(e, cards[selectedOptionViaDownArrow]);
        }
        setSelectedOption(selectedOptionViaDownArrow);
        break;
      case 'Enter':
        e.preventDefault();
        setIsCardListOpen(!isCardListOpen);
        setToggleCardsList(!toggleCardsList);
        setSelectedOption(selectedOption);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    // this piece of code is to focus the selected card once the drop down opens
    const liELements = Array.from(document.querySelectorAll('#profile-cardlist li.vds-listbox-item'));
    liELements.forEach((ele, idx) => {
      if (idx === selectedOption) {
        ele.setAttribute('aria-selected', 'true');
        (ele as HTMLElement).focus();
      } else {
        ele.setAttribute('aria-selected', 'false');
      }
    });
  }, [selectedOption]);

  useEffect(() => {
    const selectedCardIndex = cards.findIndex(ele => ele.cardNickname === selectedCard.cardNickname);
    setSelectedOption(selectedCardIndex);
  }, []);

  return (
    <>
      <Card
        type="content"
        className={navMenuClass()}
        id="cardlist-wrapper"
        onBlur={e => {
          if (!e.currentTarget.contains(e.relatedTarget)) {
            // Not triggered when swapping focus between children
            const liELements = Array.from(document.querySelectorAll('#profile-cardlist li.vds-listbox-item'));
            liELements.forEach((ele, idx) => {
              ele.setAttribute('aria-selected', 'false');
            });
            setIsCardListOpen(false);
            setToggleCardsList(false);
          }
        }}
      >
        <CardContentCategory className={styles['content-category']} onClick={handleArrowClick}>
          <CardContent className={styles['content']}>
            <Button
              id="cardDropdownButton"
              className={styles['button']}
              onClick={handleClearAlert}
              onKeyDown={handleListKeyDown}
              aria-haspopup="listbox"
              aria-expanded={isCardListOpen}
            >
              {toggleCardsList ? (
                <>
                  <div className={styles['card-content']}>
                    <Typography tag="h3" className={styles['card-text']}>
                      {t('selectCard')}
                    </Typography>
                  </div>
                  <Icon name="arrow-collapse" resolution="tiny" className={styles['icon']} />
                </>
              ) : (
                <>
                  <CardTileComponent className={styles['cardtile-row']} card={selectedCard} />
                  <Icon name="arrow-expand" resolution="tiny" className={styles['icon']} />
                </>
              )}
            </Button>
          </CardContent>
        </CardContentCategory>
        <CardContent className={styles['content-main']}>
          <Menu className={styles['menu']}>
            <Listbox
              className={styles['listbox']}
              aria-labelledby={t('menu')}
              aria-activedescendant={cards[selectedOption === null ? 0 : selectedOption]?.cardNickname}
              id="profile-cardlist"
            >
              <CardList
                handleKeyDown={handleKeyDown}
                handleSelect={handleSelect}
                selectedOption={selectedOption === null ? 0 : selectedOption}
              />
              <ListboxItem
                role="option"
                listItemValue={t('manageCard')}
                className={styles['manage-cards']}
                tabIndex={0}
                onKeyDown={handleKeyDown}
              >
                <Link to={RedirectRoutes.homeManageCards} onClick={handleClearAlert}>
                  <div className={styles['inner-row']}>
                    <Icon name="card-manage-alt" className={styles['icon']} resolution="low" />
                    <Typography tag="h3" className={styles['card-text']}>
                      {t('manageCard')}
                    </Typography>
                  </div>
                </Link>
              </ListboxItem>
            </Listbox>
          </Menu>
        </CardContent>
      </Card>
    </>
  );
};

export default CardListDropdown;
