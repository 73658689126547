import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Typography, InputCvv } from '@visa/vds';
import { Controller, Noop, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { ButtonWrapper, FormWrapper, DialogWrapper } from '../../../elements/core-ui';
import { clearAlert, GenericPanelComponent } from '../../../elements/shared';
import { maskHelper, Utils } from '../../../utils';
import { RedirectRoutes } from '../../../constants';
import { resetSuccessFlags } from '../../../pages/sign-in/idv-verify/idv-verify-reducer-slice';
import styles from './verify-code.module.scss';

type VerifyCodeProps = {
  phoneNumber?: string;
  contactValue?: string;
  contactValueMasking?: boolean;
  dialogContinueRedirect?: string;
  submitHandler: (data: any) => void;
  cancelHandler: (f: any) => void;
  resendHandler: (f: any) => void;
  backIconHandler?: (f: any) => void;
  forgotPasswordOtpPageTitle?: string;
};

const VerifyCode: React.FC<VerifyCodeProps> = props => {
  const {
    phoneNumber,
    contactValue,
    contactValueMasking = true,
    submitHandler,
    cancelHandler,
    resendHandler,
    backIconHandler,
    dialogContinueRedirect,
    forgotPasswordOtpPageTitle
  } = props;
  // const t = useTranslator();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  phoneNumber
    ? (document.title = `${t('app.title.confirmMobileCode')} - ${t('app.title.visaPurchaseAlerts')}`)
    : (document.title = `${t('app.title.emailCode')} - ${t('app.title.visaPurchaseAlerts')}`);

  const initialValues = {
    otpCode: ''
  };
  const schema = yup.object({
    otpCode: yup.string().required('verify_code.errors.enterCode').length(6, 'verify_code.errors.invalidCode')
  });
  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(schema),
    mode: 'onBlur',
    reValidateMode: 'onBlur'
  });
  const {
    formState: { errors },
    getValues,
    control,
    register
  } = methods;
  const [openDialog, setOpenDialog] = useState(false);
  const { alert, signin } = useSelector((store: any) => store);

  const backIcon = backIconHandler ? true : false;
  const shouldShowCancelBtn =
    (!signin.verifyEmail && pathname.includes(RedirectRoutes.loginVerifyEmail)) || // fix for  MOBENB-19561
    pathname.includes(RedirectRoutes.loginChallengeVerifyEmail) ||
    pathname.includes(RedirectRoutes.forgotPasswordVerifyCode) ||
    pathname.includes(RedirectRoutes.changeEmailVerifyCode) ||
    pathname.includes(RedirectRoutes.phoneVerifyCode);

  const getPanelText = () => {
    if (forgotPasswordOtpPageTitle) {
      //  for forgot-password-otp screen; we have different text to show.
      return forgotPasswordOtpPageTitle;
    }
    return (
      <>
        {phoneNumber ? (
          <>
            {t('updatePhone.verifyCode.phone_number_prefix')}
            <Typography tag="b"> {phoneNumber && maskHelper.phone(phoneNumber)}. </Typography>
            {t('updatePhone.verifyCode.phone_number_suffix')}
          </>
        ) : (
          <>
            <div>{t('verify_code.subheading.part1')}</div>
            <div>
              <Typography tag="b">{contactValueMasking ? maskHelper.email(contactValue) : contactValue}</Typography>
              {t('verify_code.subheading.part2')}
            </div>
            {t('verify_code.subheading.part3')}
          </>
        )}
      </>
    );
  };

  const handleNavigate = (route: string) => {
    if (phoneNumber) {
      navigate(RedirectRoutes.profile);
    } else {
      navigate(route);
      dispatch(resetSuccessFlags());
    }
  };

  // useEffect(() => {
  //   // if (!contactValue && !phoneNumber && !forgotPasswordOtpPageTitle) navigate(RedirectRoutes.check);
  // }, []);

  useEffect(() => {
    if (errors && Object.keys(errors).length) {
      if (errors?.otpCode) {
        Utils.setFocusToElement('#otpCode');
      }
      dispatch(resetSuccessFlags());
      dispatch(clearAlert());
    }
  }, [errors]);

  const handleInputCvvBlur = (blur: Noop) => {
    blur();
  };

  const handleInputCvvOnChange = (event, field) => {
    dispatch(clearAlert());
    field.onChange(event);
  };
  const handleNavigationForAnotherEmail = () => {
    if (pathname.includes(RedirectRoutes.login)) {
      handleNavigate(RedirectRoutes.loginEditEmail);
    } else if (pathname.includes(RedirectRoutes.createAccount)) {
      handleNavigate(RedirectRoutes.createAccountEditEmail);
    }
  };
  const handleCancelClick = () => {
    setOpenDialog(true);
  };

  const dialogBody = (
    <p role="alert">
      {t('verify_code.dialog.body')}
    </p>
  );
  const handleDialogContinueClick = () => {
    //  redirect to given path else login
    //  why login?
    //  => if session expires by any chance login will be displayed else user will be redirected to home page.
    const redirectTo = dialogContinueRedirect ? dialogContinueRedirect : RedirectRoutes.login;
    navigate(redirectTo);
  };
  const handleDialogCancelClick = () => {
    setOpenDialog(false);
  };

  const actionDialogButtons = (
    <>
      <ButtonWrapper
        type="button"
        tag="button"
        isFullWidth
        colorScheme="primary"
        label={t('verify_code.dialog.continue')}
        ariaLabel={t('verify_code.dialog.continue')}
        handleClick={handleDialogContinueClick}
      />
      <ButtonWrapper
        tag="button"
        type="button"
        isFullWidth
        colorScheme="tertiary"
        label={t('verify_code.dialog.cancel')}
        ariaLabel={t('verify_code.dialog.cancel')}
        handleClick={handleDialogCancelClick}
      />
    </>
  );

  const inputField = (
    <FormWrapper onSubmit={methods.handleSubmit(submitHandler)}>
      <Controller
        name={'otpCode'}
        control={control}
        render={({ field }) => {
          return (
            <InputCvv
              noValidate
              aria-required="true"
              type="password"
              id="otpCode"
              maxLength={6}
              required={true}
              showErrorText={errors?.otpCode?.message ? true : false}
              className={styles.cvv}
              showInfoButton={false}
              label={t('verify_code.fieldLabel.code')}
              onChange={(e: any) => handleInputCvvOnChange(e, field)}
              onBlur={() => handleInputCvvBlur(field.onBlur)}
              errorText={t(`${errors?.otpCode?.message}`)}
              invalid={errors?.otpCode?.message ? true : false}
            />
          );
        }}
      />
      <>
        <ButtonWrapper
          isFullWidth
          type="submit"
          colorScheme="primary"
          ariaLabel={t('verify_code.button.continue')}
          label={t('verify_code.button.continue')}
        />
        <ButtonWrapper
          isFullWidth
          colorScheme="secondary"
          handleClick={resendHandler}
          ariaLabel={t('verify_code.button.resend')}
          label={t('verify_code.button.resend')}
        />
        {shouldShowCancelBtn ? (
          <ButtonWrapper
            id="verifyCodeCancel"
            isFullWidth
            colorScheme="tertiary"
            handleClick={handleCancelClick}
            ariaLabel={t('verify_code.button.cancel')}
            label={t('verify_code.button.cancel')}
          />
        ) : (
          <ButtonWrapper
            isFullWidth
            colorScheme="tertiary"
            handleClick={handleNavigationForAnotherEmail}
            ariaLabel={t('verifyCode.btnUseAnotherEmail')}
            label={t('verifyCode.btnUseAnotherEmail')}
          />
        )}
      </>
    </FormWrapper>
  );

  return openDialog ? (
    <DialogWrapper
      role="alertdialog"
      hasCloseIcon={true}
      open={openDialog}
      body={dialogBody}
      handleCloseIconClick={handleDialogCancelClick}
      actionButtons={actionDialogButtons}
      parentActiveElementId={'verifyCodeCancel'}
    />
  ) : (
    <GenericPanelComponent
      backIcon={backIcon}
      bodyComponent={inputField}
      pageTitle={phoneNumber ? t('updatePhone.verifyCode.confirm_mobile') : t('verify_code.heading')}
      panelSubText={getPanelText()}
      handleIconClick={backIconHandler}
    />
  );
};

export default VerifyCode;
