import { put, takeLatest, call, select } from 'redux-saga/effects';
import { clearAlert, showAlert } from '../../../elements/shared';
import { ajaxHandler, apiPaths } from '../../../setup';
import { Utils } from '../../../utils';
import { replaceUserGuid } from '../../../utils/utils';
import { GlobalConstants, RedirectRoutes } from '../../../constants';
import {
  setAccessToken,
  setUserGuid,
  setToken2Success,
  setPartiallyLogged,
  setFullyLogged,
  setLastLoginTime
} from '../../../auth/user-auth-reducer';
import {
  postLogin,
  getConfigurations,
  postToken,
  postTokenFailure,
  postLoginSuccess,
  setConfigurationsSuccess,
  setPendingTerms,
  resetSuccessFlags,
  setAuthCode,
  postLoginFailure
} from './sign-in-reducer-slice';

function* postTokenHandler(action) {
  const signin = yield select(state => state.signin);
  const { userAuth } = yield select((store: any) => store);
  const requestPayload: any = {};
  requestPayload['grant_type'] = 'authorization_code';
  requestPayload.context = Utils.encodeUsingUrlEncoding(
    JSON.stringify({
      channel: 'Web',
      subContext: 'VMCP'
    })
  );
  requestPayload['code'] = signin.authCode;
  const headers = {
    access_token: userAuth.accessToken,
    'content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
  };
  const response = yield call(ajaxHandler, apiPaths.auth.b2cLoginUrl, true, {
    data: Utils.encodeUsingUrlEncoding(requestPayload),
    headers,
    method: 'POST',
    urlWithPlaceholder: apiPaths.auth.b2cLoginUrl
  });

  if (!response.error) {
    yield put(setAccessToken(response.data.access_token));
    yield put(setFullyLogged());
    yield put(setToken2Success());
    yield put(
      showAlert({
        type: 'SUCCESS',
        message: 'signin.lastLoginTime',
        isDynamic: true,
        dynamicParts: [userAuth.lastLoginTimeStamp]
      })
    );
  } else {
    yield put(postTokenFailure());
    // yield put(resetSuccessFlags());
  }
}

function* getConfigurationsHandler(action) {
  const signin = yield select(state => state.signin);
  const { userAuth } = yield select((store: any) => store);
  let pendingTerms = false;
  if (
    userAuth.subjects &&
    userAuth.subjects.length &&
    userAuth.subjects[0] &&
    userAuth.subjects[0].probes &&
    userAuth.subjects[0].probes.length &&
    userAuth.subjects[0].probes[0].type
  ) {
    pendingTerms = userAuth.subjects[0].probes[0].type === 'ACCEPT_TNC';
  }
  yield put(setConfigurationsSuccess(userAuth.userConfigDetails));
  if (userAuth.subjects.length && userAuth.subjects[0].subject_auth_status === 'A') {
    yield put(setAccessToken(userAuth.accessToken));
    yield put(setFullyLogged());
    yield put(setToken2Success());
    // yield put(setAccessToken(response.data.access_token));
    // yield put(setUserGuid(response.data.subjects[0].subject_id));
    // yield put(setLastLoginTime(response));
    yield put(
      showAlert({
        type: 'SUCCESS',
        message: 'signin.lastLoginTime',
        isDynamic: true,
        dynamicParts: [userAuth.lastLoginTimeStamp]
      })
    );
  }
  if (pendingTerms) {
    yield put(setPendingTerms(pendingTerms));
  }
  
  //VIMCON Logic - To check if need to remove this completely
  // const headers = {
  //   access_token: userAuth.accessToken
  // };
  // const getConfigurationsURL = replaceUserGuid(apiPaths.getUserTerms, userAuth.userGuid);

  // const response = yield call(ajaxHandler, getConfigurationsURL, false, {
  //   headers,
  //   method: 'GET',
  //   urlWithPlaceholder: apiPaths.getUserTerms
  // });

  // if (!response.error) {
  //   yield put(setConfigurationsSuccess(response.data));
  //   yield put(setPendingTerms(response.data.pendingTerms));
  //   yield put(setAuthCode(response.headers['x-auth-code']));
  // } else {
  //   yield put(resetSuccessFlags());
  // }
}

function* postLoginHandler(action) {
  const { payload, navigate } = action.payload;
  const headers = { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' };
  const vbaToken = yield call(Utils.vbaTokenGenerator);
  const oauth2Data = {};
  
  oauth2Data['password'] = payload.password;
  oauth2Data['grant_type'] = payload.grant_type;
  oauth2Data['username'] = Utils.encodeUsingUrlEncoding(payload.username);
  oauth2Data['username_type'] = 'EMAIL';
  oauth2Data['password_type'] = payload.password_type;
  oauth2Data['software_statement_claimset.software_version'] = '2022.04.00';
  oauth2Data['software_statement_claimset.tenancy_context.product_code'] = 'PAS';
  oauth2Data['software_statement_claimset.oauth2_version'] = '1.0';
  oauth2Data['software_statement_claimset.software_id'] = '8055d89e-e845-4d37-aea3-c843afbdab3c';
  oauth2Data['software_statement_claimset.tenancy_context.app_id'] = GlobalConstants.extAppId;
  oauth2Data['software_statement_claimset.uebas[1].ueba_source'] = 'VBA';
  oauth2Data['software_statement_claimset.uebas[1].ueba_data'] = Utils.encodeUsingUrlEncoding(vbaToken);
  oauth2Data['software_statement_claimset.uebas[0].ueba_id'] = Utils.getServerSessionId();
  oauth2Data['software_statement_claimset.uebas[0].ueba_source'] = 'THREAT_METRIX';
  
  //VIMCON LOGIC - To check removal
  // payload['software_statement_claimset.uebas[0].ueba_source'] = 'VBA';
  // payload['software_statement_claimset.uebas[0].ueba_data'] = Utils.encodeUsingUrlEncoding(vbaToken);

  const data = Utils.encodeUsingUrlEncoding(payload);
  const response = yield call(ajaxHandler, apiPaths.auth.b2cLoginUrl, true, {
    data: Utils.encodeUsingUrlEncoding(oauth2Data),
    headers,
    method: 'POST',
    urlWithPlaceholder: apiPaths.auth.b2cLoginUrl
  });

  if (!response.error) {
    const hasForceResetPassword = response.data?.scope?.includes('/credentials/reset');
    if (response.data.auth_status === 'FULL' && !hasForceResetPassword) {
      yield put(setFullyLogged());
      yield put(setToken2Success());
      yield put(setAccessToken(response.data.access_token));
      yield put(setUserGuid(response.data.subjects[0].subject_id));
      yield put(setLastLoginTime(response));

      yield put(
        showAlert({
          type: 'SUCCESS',
          message: 'signin.lastLoginTime',
          isDynamic: true,
          dynamicParts: [response.data.lastLoginTimeStamp]
        })
      );
    } else {
      yield put(setAccessToken(response.data.access_token));
      yield put(setUserGuid(response.data.subjects[0].subject_id));
      yield put(setPartiallyLogged());
      yield put(setLastLoginTime(response));
      if (hasForceResetPassword) {
        navigate(RedirectRoutes.forcePasswordReset);
      } else {
        yield put(postLoginSuccess(response.data));
      }
    }
    yield put(clearAlert());
  } else {
    yield put(postLoginFailure(response.error));
  }
}

export default function* watchLogin() {
  yield takeLatest(postLogin, postLoginHandler);
  yield takeLatest(getConfigurations, getConfigurationsHandler);
  yield takeLatest(postToken, postTokenHandler);
}
