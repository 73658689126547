//libraries
import axios from 'axios';
import { put, select } from 'redux-saga/effects';
import { GlobalConstants, RedirectRoutes } from '../constants';
import { showSpinner, hideSpinner, showAlert } from '../elements/shared';
import { GlobalErrorHandler, Utils } from '../utils';
import errorMap from '../utils/error-helper';
import { getServerSessionId } from '../utils/utils';
import { showErrorModal } from '../elements/shared/error-modal/error-modal-reducer';

const MOCK_BASE_URL = 'http://localhost:3001';
const prodMode = GlobalConstants.isProd;
// const appServerName = process.env.REACT_APP_SERVER;
// const useMockApi = appServerName === 'mock';

const getBaseUrl = () => {
  if (prodMode) {
    return `${window.location.protocol}//${window.location.host}`;
  }
  return MOCK_BASE_URL;
};

const getHeaders = (passedHeaders, endpoint) => {
  const headers = passedHeaders || {};

  // const mockHeaders = !prodMode
  //   ? {
  //       'X-Requested-With': 'XMLHttpRequest',
  //       'X-CORRELATION-ID': 'test',
  //       'X-THMID': 'test',
  //       DFPSESSIONID: 'test'
  //     }
  //   : {};

  //TODO: We would not need mock headers. Have a seperate method to add basic ids, dsp, thm etc
  const additionalHeaders = {
    'X-CORRELATION-ID': GlobalConstants.correlationId,
    'X-THMID': getServerSessionId(),
    DFPSESSIONID: getServerSessionId(),
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
    Accept: 'application/json'
    // ...mockHeaders
  };

  return {
    ...additionalHeaders,
    ...headers
  };
};

const handleError = error => {
  const res = error && error.response;
  const status = error.response.status;
  let errorCode = 'VMCP_001';
  let errorObj;
  if (res?.data) {
    try {
      errorObj = GlobalErrorHandler.getErrorDetails(res.data);
      errorCode = errorObj.errorCode;
    } catch (e) {
      errorCode = '';
    }
  } else {
    errorCode = '';
  }

  return {
    error: {
      api: res?.config?.url,
      status,
      reason: errorCode,
      response: error && error.response,
      error: error //Complete error response is returned. Might not required for most of the use cases.
    }
  };
};

const handleSuccess = response => {
  return response;
};

const makeAjaxCall = async (endpoint: string, extendedOptions) => {
  const defaultRequestData = {
    method: 'GET',
    headers: getHeaders(extendedOptions.headers, endpoint),
    url: endpoint,
    baseURL: getBaseUrl()
  };

  //We have already overridden the headers using getHeaders()
  delete extendedOptions.headers;

  //extendedOptions are provided to override default options
  const options = Object.assign(defaultRequestData, extendedOptions);
  if (options.hasOwnProperty('method') && options.method.toLowerCase() !== 'get') {
    options.headers['v-c-vaap-token'] = await Utils.vbaTokenGenerator();
  }
  ///AXIOS DOESN'T SEND CONTENT TYPE IN REQUEST IF 'DATA' ATTRIBUTE IS NOT SET. So if need to send some other content type for get request. Send data as null or {}.
  const request = axios(options);
  return request.then(handleSuccess, handleError);
};
const handlePermAndTempLock = function* (isDFM, errorMessage) {
  if (window.location.pathname == GlobalConstants.rootPath + RedirectRoutes.login && !isDFM) {
    //show GSM with permenanet lock// temp lock msg
    yield put(showAlert({ type: 'ERROR', message: errorMessage }));
  } else {
    //show popup with permenanet lock// temp lock msg
    yield put(showErrorModal({ redirectTo: RedirectRoutes.login, message: errorMessage }));
  }
};

const handleErrorType = function* (errorVal) {
  const type = 'ERROR';
  if (errorVal.type === 'modal') {
    yield put(showErrorModal({ redirectTo: errorVal.redirectTo, message: errorVal.msg }));
  } else {
    yield put(showAlert({ type, message: errorVal.msg }));
  }
};
//In extendedoptions urlWithPlaceholder has to be passes for error validation
const ajaxHandler = function* (endpoint, shouldShowLoader, extendedOptions) {
  ////////
  //Reset timer can be used to show idle session timeout errors and popups
  ////////
  // yield put({type: SessionHandler.RESET_SESSION_TIMER});

  ////////////
  //handle clear alert based on endpoints.
  ////////////
  if (!extendedOptions.urlWithPlaceholder && !prodMode) {
    alert('urlWithPlaceHolder missing in extendedOptions for ' + endpoint);
  }
  const { userGuid } = yield select((store: any) => store.userAuth);

  ////////////
  //shouldShowLoader can be used to show global loader (testing needs to be done)
  ////////////
  if (shouldShowLoader) yield put(showSpinner());

  let response;
  try {
    response = yield makeAjaxCall(endpoint, extendedOptions);
    if (response.error) {
      const status = response.error.status;
      let errorCode = response.error.reason;
      if(errorCode==="invalid_grant"){
        const data = response.error.response.data;
        if(data && data.error_details && Array.isArray(data.error_details)){
        errorCode = data.error_details[0].error
        }
      }
      const urlWithPlaceholder = response.error.response.config?.urlWithPlaceholder;
      let errorHandled = false;
      let errorMessage = '';
      if (
        //MOBENB-19049
        (status === 401 || status >= 500) &&
        !GlobalErrorHandler.isErrorHandlingExcepted(urlWithPlaceholder)
      ) {
        // Included PAS_5003 and PAS_5005 SDK error codes to prevent the display of the error modal when these errors occur
        const excludedErrorCodes = ['PAS_1008', 'PAS_1009', 'PAS_5003', 'PAS_5005'];
        if (!excludedErrorCodes.includes(errorCode)) {
          yield put(showErrorModal({ redirectTo: RedirectRoutes.login, message: 'signin.errorCodes.VMCP_001' }));
          errorHandled = true;
        }
      } else if ((status > 299 || status < 200) && !GlobalErrorHandler.isErrorHandlingExcepted(urlWithPlaceholder)) {
        const isDFM = GlobalErrorHandler.getCommonErrorCodes(errorCode);
        const isPermTempLock = GlobalErrorHandler.isPermTempLock(status, errorCode);
        // In case of SDK error (PAS_5002), neither lock the user account nor display the error modal
        if (isPermTempLock && errorCode!='PAS_5002') {
          errorMessage = 'signin.errorCodes.9618';
          if (errorCode === 'ICM_1004' || errorCode === '9617') {
            errorMessage = 'signin.errorCodes.9617';
          }
          yield handlePermAndTempLock(isDFM, errorMessage);
          errorHandled = true;
        } else if (isDFM) {
          yield put(showErrorModal({ redirectTo: RedirectRoutes.login, message: 'signin.errorCodes.VMCP_001' }));
          errorHandled = true;
        }
      }
      const errorConfig = urlWithPlaceholder ? errorMap[urlWithPlaceholder] : null;
      const errorVal: any = errorConfig && errorConfig[errorCode];
      if (errorVal && !errorHandled) {
        yield handleErrorType(errorVal);
        errorHandled = true;
      }
      response.error = { ...response.error, errorHandled, errorMessage };
    }

    return response;
  } catch (message) {
    if (shouldShowLoader) yield put(hideSpinner());

    throw message;
  } finally {
    if (shouldShowLoader) yield put(hideSpinner());
  }
};

export default ajaxHandler;
