import React from 'react';
import Grid from '@visa/vds/grid';
import Row from '@visa/vds/row';
import Typography from '@visa/vds/col';
import Icon from '@visa/vds/icon';
import { useTranslation } from 'react-i18next';
import CardArtComponent from '../card-art/card-art';

import styles from './card-tile.module.scss';

type CardTileProps = {
  className?: string;
  iconName?: string;
  leftIconName?: string;
  showSelected?: boolean;
  card: {
    panGuid: string;
    cardNickname: string;
    accountNumberLastFourDigits: string;
    transactionDetails: any;
  };
};

const CardTileComponent: React.FC<CardTileProps> = props => {
  const { className, iconName, card, leftIconName, showSelected } = props;
  const icon = iconName ? iconName : '';
  const leftIcon = leftIconName ? leftIconName : '';
  const { t } = useTranslation();
  const wrapperClass = () => {
    const defaultClass = styles['wrapper'];
    if (className) {
      return `${defaultClass} ${className}`;
    }
    return defaultClass;
  };

  return (
    <div className={wrapperClass()}>
      <Grid type="fluid">
        <Row noGutter className={styles['card-row']}>
          {/* to show checkmark icon for selected card */}
          {leftIconName &&
            (showSelected ? (
              <Icon name={leftIcon} resolution="tiny" className={styles['left-icon']} />
            ) : (
              <Icon name="" resolution="tiny" className={styles['left-icon']} />
            ))}

          <div>
            <CardArtComponent showAdd={false} theme="blue" className={styles['card-tile-art']} />
          </div>
          <div className={styles['card-content']}>
            <Typography tag="span" className={styles['card-text']}>
              {card?.cardNickname}
            </Typography>
            <Typography className={styles['card-sub-text']}>
              {`•••• ${card?.accountNumberLastFourDigits} `}{' '}
              {card?.transactionDetails &&
                `/ ${card?.transactionDetails[0]?.cardType[0].toUpperCase()}${card?.transactionDetails[0]?.cardType.slice(
                  1
                )}`}
            </Typography>
          </div>
          {icon && <Icon name={icon} resolution="tiny" className={styles['icon-wrapper']} />}
        </Row>
      </Grid>
    </div>
  );
};

export default CardTileComponent;
