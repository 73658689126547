import { createSlice } from '@reduxjs/toolkit';
import React from 'react';

type TYPES = 'ERROR' | 'SUCCESS' | 'WARNING' | 'INFORMATION';
interface AlertInterface {
  type: TYPES;
  message: string | React.ReactNode;
  closeIcon?: boolean;
  formMode?: boolean;
  isDynamic?: boolean;
  dynamicParts?: Array<any>;
  previousAlertMessage?: any;
}
export enum MessageNodeType {
  CheckFinacialInstitution = 'CheckFinacialInstitution'
}
interface ShowActionInterface {
  payload: {
    type: TYPES;
    message: string | React.ReactNode;
    formMode?: boolean;
    dynamicParts?: Array<any>;
    isDynamic?: boolean;
    previousAlertMessage?: any;
  };
}

const initialState: AlertInterface = {
  type: 'SUCCESS',
  previousAlertMessage:'',
  message: '',
  closeIcon: false,
  formMode: false,
  isDynamic: false,
  dynamicParts: []
};

const alertReducer = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    // Global alert
    showAlert: (state: AlertInterface, action: ShowActionInterface) => {
      state = action.payload;
      state.isDynamic = !!action.payload.isDynamic;
      if (['SUCCESS', 'INFORMATION'].includes(action.payload.type)) {
        state.closeIcon = true;
      }
      return state;
    },
    clearAlert: (state: AlertInterface) => {
      state = initialState;
      return state;
    },
    updatePreviousMessage: (state: AlertInterface, action:any) => {
      state.previousAlertMessage = action.payload;
      return state;
    }
  }
});

export const { showAlert, clearAlert, updatePreviousMessage} = alertReducer.actions;

export default alertReducer.reducer;
