import { createSlice } from '@reduxjs/toolkit';

class ResponseError {
  message: string;
  status: boolean;
  statusCode: number | undefined | null;
  reason: any;

  constructor(message = '', status = false, statusCode = null, reason = null) {
    this.message = message;
    this.status = status;
    this.statusCode = statusCode;
    this.reason = reason;
  }
}
interface AddEditCard {
  unverifiedEmail: {
    status: boolean;
    responseError: {};
  };
  unverifiedPhone: {
    status: boolean;
    responseError: {};
  };
  postPaymentInstruments: {
    status: boolean;
    last4Digits: string;
    responseError: ResponseError;
  };
  patchPaymentInstruments: {
    status: boolean;
    data: {};
    responseError: ResponseError;
  };
  deleteCard: {
    status: boolean;
    responseError: ResponseError;
  };
}

const initialState: AddEditCard = {
  unverifiedEmail: {
    status: false,
    responseError: {}
  },
  unverifiedPhone: {
    status: false,
    responseError: {}
  },
  postPaymentInstruments: {
    status: false,
    last4Digits: '',
    responseError: new ResponseError()
  },
  patchPaymentInstruments: {
    status: false,
    data: {},
    responseError: new ResponseError()
  },
  deleteCard: {
    status: false,
    responseError: new ResponseError()
  }
};

const addEditCardSlice = createSlice({
  name: 'add-edit-card',
  initialState,
  reducers: {
    deleteUnverifiedEmail: (state: AddEditCard, action) => {
      return state;
    },

    unverifiedEmailSuccess: (state: AddEditCard) => {
      state.unverifiedEmail.status = true;
      return state;
    },

    unverifiedEmailFailure: (state: AddEditCard, action) => {
      state.unverifiedEmail.status = false;
      state.unverifiedEmail.responseError = action.payload;
      return state;
    },

    deleteUnverifiedPhone: (state: AddEditCard, action) => {
      return state;
    },

    unverifiedPhoneSuccess: (state: AddEditCard) => {
      state.unverifiedPhone.status = true;
      return state;
    },

    unverifiedPhoneFailure: (state: AddEditCard, action) => {
      state.unverifiedPhone.status = false;
      state.unverifiedPhone.responseError = action.payload;
      return state;
    },

    postPaymentInstruments: (state: AddEditCard, action) => {
      //https://issues.trusted.visa.com/browse/MOBENB-20055
      const last4Digits = (action.payload?.data?.paymentInstrument?.accountNumber || '').substring(12);
      state.postPaymentInstruments.last4Digits = last4Digits;
      return state;
    },

    paymentInstrumentsSuccess: (state: AddEditCard) => {
      state.postPaymentInstruments.status = true;
      return state;
    },

    paymentInstrumentsFailure: (state: AddEditCard, action) => {
      const { errorMessage, reason, status } = action.payload;
      state.postPaymentInstruments.status = false;
      state.postPaymentInstruments.responseError =  new ResponseError(errorMessage, true, status, reason);
      return state;
    },

    patchPaymentInstruments: (state: AddEditCard, action) => {
      return state;
    },
    patchPaymentInstrumentsSuccess: (state: AddEditCard, action) => {
      state.patchPaymentInstruments.status = true;
      state.patchPaymentInstruments.data = action.payload;
      return state;
    },
    patchPaymentInstrumentsFailure: (state: AddEditCard, action) => {
      const { errorMessage, reason, status } = action.payload;
      state.patchPaymentInstruments.status = false;
      state.patchPaymentInstruments.responseError =  new ResponseError(errorMessage, true, status, reason);
      return state;
    },

    deleteCard: (state: AddEditCard, action) => {
      return state;
    },

    deleteCardSuccess: (state: AddEditCard) => {
      state.deleteCard.status = true;
      return state;
    },

    deleteCardFailure: (state: AddEditCard, action) => {
      const { errorMessage, reason, status } = action.payload;
      state.deleteCard.status = false;
      state.deleteCard.responseError =  new ResponseError(errorMessage, true, status, reason);
      return state;
    },

    createEncryptedPayload: (state: AddEditCard, action) => {
      return state;
    },

    resetFlags: (state: AddEditCard) => {
      state.deleteCard.status = false;
      state.postPaymentInstruments.status = false;
      state.patchPaymentInstruments.status = false;
    }
  }
});

export const {
  deleteUnverifiedEmail,
  unverifiedEmailSuccess,
  unverifiedEmailFailure,
  deleteUnverifiedPhone,
  unverifiedPhoneSuccess,
  unverifiedPhoneFailure,
  postPaymentInstruments,
  paymentInstrumentsSuccess,
  paymentInstrumentsFailure,
  patchPaymentInstruments,
  patchPaymentInstrumentsSuccess,
  patchPaymentInstrumentsFailure,
  deleteCard,
  deleteCardSuccess,
  deleteCardFailure,
  createEncryptedPayload,
  resetFlags
} = addEditCardSlice.actions;

export default addEditCardSlice.reducer;
