import { createSlice } from '@reduxjs/toolkit';

interface Unsubscribe {
  isUnsubscribed: boolean;
  responseError: {
    message: string;
    status: boolean;
    statusCode: number | undefined | null;
    reason: any;
  };
}

const initialState: Unsubscribe = {
  isUnsubscribed: false,
  responseError: {
    message: '',
    status: false,
    statusCode: null,
    reason: null
  }
};

const unsubscribe = createSlice({
  name: 'unsubscribe',
  initialState,
  reducers: {
    postUnsubscribe: (state: Unsubscribe, action) => {
      return state;
    },

    unsubscribeSuccess: (state: Unsubscribe, action) => {
      state.isUnsubscribed = action.payload.isSuccess;
      return state;
    },

    unsubscribeFailure: (state: Unsubscribe, action) => {
      state.isUnsubscribed = false;
      return state;
    }
  }
});

export const { postUnsubscribe, unsubscribeSuccess, unsubscribeFailure } = unsubscribe.actions;
export default unsubscribe.reducer;
