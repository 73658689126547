import { Utils } from '../../../utils';

const getPostalCode = (postalCode: string) => {
  return postalCode?.length == 1 ? `0${postalCode}` : postalCode;
};

export const getEditDefaultValues = (card_id, userDetails) => {
  const cardDetail = userDetails.paymentInstruments.find(card => card.panGuid === card_id);
  if (cardDetail) {
    const { firstName, lastName } = userDetails.vuser;
    const { accountNumberLastFourDigits, cardNickname, expirationMonth, expirationYear } = cardDetail;
    const { addressLine1, addressLine2, city, postalCode, stateProvinceCode, addressName } = cardDetail.billingAddress;
    return {
      cardType: null,
      accountNumber: accountNumberLastFourDigits,
      accountNickname: cardNickname,
      expiration: `${expirationMonth}/${expirationYear.slice(2)}`,
      cvv: '',
      firstName: firstName,
      lastName: lastName,
      name: addressName,
      addressLine1: addressLine1,
      addressLine2: addressLine2, // removed from schema as it is optional
      city: city,
      postalCode: postalCode,
      stateProvinceCode: stateProvinceCode
    };
  }
};

export const getAddDefaultValues = (checkEligibility, enroll, userDetails) => {
  return {
    cardType: null,
    accountNumber: checkEligibility.formData?.panPrefix,
    accountNickname: '',
    expiration: '',
    cvv: '',
    firstName: enroll.formData?.firstName ? enroll.formData?.firstName : userDetails.vuser?.firstName,
    lastName: enroll.formData?.lastName ? enroll.formData?.lastName : userDetails.vuser?.lastName,
    addressLine1: '',
    addressLine2: '', // removed from schema as it is optional
    city: '',
    postalCode: '',
    stateProvinceCode: ''
  };
};

const getBillingAddressPayload = data => {
  const { addressLine1, addressLine2, firstName, lastName, city, postalCode, stateProvinceCode } = data;
  // const pCode = postalCode?.length == 1 ? `0${postalCode}` : postalCode;
  if (Utils.isUS()) {
    return {
      addressLine1: addressLine1,
      addressLine2: addressLine2?.trim(),
      addressLine3: '',
      addressName: `${firstName} ${lastName}`,
      city: city,
      countryCode: Utils.getCountryNumber(Utils.getCountryCode()),
      postalCode: getPostalCode(postalCode),
      stateProvinceCode: stateProvinceCode
    };
  } else if (Utils.isVI()) {
    return {
      addressName: `${firstName} ${lastName}`,
      countryCode: Utils.getCountryNumber(Utils.getCountryCode()),
      postalCode: getPostalCode(postalCode)
    };
  } else {
    const result = {
      addressName: `${firstName} ${lastName}`,
      countryCode: Utils.getCountryNumber(Utils.getCountryCode())
    };
    if (postalCode) result['postalCode'] = getPostalCode(postalCode);
    return result;
  }
};

export const getAddPayload = data => {
  const { cardType, accountNickname, accountNumber, firstName, lastName, expiration, cvv } = data;
  return {
    accountNickname: accountNickname,
    accountNumber: accountNumber.split(' ').join(''),
    accountType: 'CREDIT_CARD',
    billingAddress: getBillingAddressPayload(data),
    cardType: cardType,
    cvv: cvv,
    expirationMonth: expiration.split('/')[0],
    expirationYear: '20' + expiration.split('/')[1],
    nameOnAccount: `${firstName} ${lastName}`,
    sequenceNumber: '0'
  };
};

const getBillingAddressEditPayload = (data, card) => {
  // const cardDetail = userDetails.paymentInstruments.find(card => card.panGuid === card_id);
  const { guid } = card.billingAddress;
  const { addressLine1, addressLine2, city, postalCode, stateProvinceCode, name } = data;

  const billingData = {};
  billingData['addressName'] = name;
  billingData['countryCode'] = Utils.getCountryNumber(Utils.getCountryCode());
  billingData['guid'] = guid;
  billingData['isActive'] = true;

  if (getPostalCode(postalCode)) {
    billingData['postalCode'] = getPostalCode(postalCode);
  }
  if (Utils.isUS()) {
    billingData['addressLine1'] = addressLine1;
    billingData['addressLine2'] = addressLine2?.trim();
    billingData['addressLine3'] = '';
    billingData['city'] = city;
    billingData['stateProvinceCode'] = stateProvinceCode;
  } else {
    billingData['addressType'] = 'BILLING_ADDRESS';
  }
  return billingData;
};

export const getEditPayload = (data, card) => {
  const { accountNickname, expiration, cvv, name } = data;
  return {
    accountNickname: accountNickname,
    billingAddress: getBillingAddressEditPayload(data, card),
    cvv: cvv,
    expirationMonth: expiration.split('/')[0],
    expirationYear: '20' + expiration.split('/')[1],
    nameOnAccount: name,
    sequenceNumber: 0
  };
};
const getUserPayload = data => {
  const { firstName, lastName } = data;
  return {
    firstName,
    lastName
  };
};
export const getEncryptedAPIPayload = data => {
  const { cardType, accountNickname, accountNumber, firstName, lastName, expiration, cvv } = data;
  return {
    accountNickname: accountNickname,
    accountNumber: accountNumber.split(' ').join(''),
    billingAddress: getBillingAddressPayload(data),
    cardBrand: cardType,
    cvv2: cvv,
    user: getUserPayload(data),
    expirationMonth: expiration.split('/')[0],
    expirationYear: '20' + expiration.split('/')[1],
    nameOnAccount: `${firstName} ${lastName}`,
  };
};
