import { useEffect, useState, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DialogWrapper, ButtonWrapper } from '../elements/core-ui';
import { RedirectRoutes } from '../constants';
import { Utils } from '../utils';
import { showAlert } from '../elements/shared';
import { postTokenRenewal } from './user-auth-reducer';

// please don't remove the comment code, as we would need this to track the SESSION.

const Session = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  // const count = useRef(45);                            // ** this is only for reference. This  will be removed later ** //
  // const referenceTimeInterval = useRef<any>(null);     // ** this is only for reference. This  will be removed later ** //

  const timer = useRef<any>(null);
  // this timer will be instantiated when the component mounts and
  // the callback function provided for setTimeout method will be invoked after 6 mins of inactivity
  const popuptimer = useRef<any>(null);
  // this timer will be instantiated when the component mounts and
  // the callback function provided for setTimeout method will be invoked if the user does not click on continue and log the user out
  const renewtokenTimer = useRef<any>(null); // this will be instantiated for 8 mins
  const maxSessionTime = 360 * 1000; // 6 mins
  const maxPopupTime = 480 * 1000; // 8 mins
  const maxRenewTokenTime = 360 * 1000; // 6 mins
  const events = ['mousemove', 'mousedown', 'click', 'scroll', 'keypress', 'focus', 'pageshow'];
  const dispatch = useDispatch();
  const getCurrentTimeStamp = () => Date.now().toString();
  const [openDialog, setOpenDialog] = useState(false);
  const { userAuth } = useSelector((state: any) => state);

  const setTimers = () => {
    startSession();
    startRenewTokenTimer();
    addEvents();
  };

  useEffect(() => {
    setTimers();
    return () => {
      clearSession();

      clearTokenTimer();
      removeEvents();
    };
  }, [userAuth.isPartiallyLogged]);

  useEffect(() => {
    // console.log('second useEffect will only run after the 1st successfull renewalToken response');
    if (
      userAuth.accessToken &&
      (userAuth.shouldRestartSession || userAuth.shouldRestartSessionPostChangePwd) &&
      userAuth.isFullyLogged
    ) {
      // console.log('entire session will restart now');
      // count.current = 45;                             // ** this is only for reference. This  will be removed later ** //
      if (userAuth.shouldRestartSessionPostChangePwd) {
        clearTimeout(renewtokenTimer.current);
        renewtokenTimer.current = null;
      }
      setTimers();
    }
    return () => removeEvents();
  }, [userAuth.accessToken]);

  function addEvents() {
    // console.log("events added")
    for (let i = 0; i < events.length; i++) {
      window.addEventListener(events[i], startSession);
    }
  }

  function removeEvents() {
    // console.log("remove events")
    for (let i = 0; i < events.length; i++) {
      window.removeEventListener(events[i], startSession);
    }
  }

  const startSession = useCallback(
    (e?) => {
      if (timer.current || popuptimer.current) clearSession();
      // console.log(e ? 'started session due to some event' : 'session has started');
      sessionStorage.setItem('lastActiveTimeStamp', getCurrentTimeStamp());
      timer.current = setTimeout(showPopup, maxSessionTime);
      popuptimer.current = setTimeout(logout, maxPopupTime);
    },
    [timer, popuptimer]
  );

  // function startSession(e?) {
  //   if (timer.current || popuptimer.current) clearSession();
  //   addEvents();
  //   console.log(e ? 'started session due to some event' : 'session has started');
  //   sessionStorage.setItem('lastActiveTimeStamp', getCurrentTimeStamp());
  //   timer.current = setTimeout(showPopup, maxSessionTime);
  //   popuptimer.current = setTimeout(logout, maxPopupTime);
  // }

  function showPopup() {
    // console.log("showPopup")
    removeEvents();
    setOpenDialog(true);
  }

  function logout() {
    Utils.invalidateSession(dispatch, true);
    navigate(RedirectRoutes.login);
    dispatch(
      showAlert({
        type: 'INFORMATION',
        message: 'auth.sessionExpired'
      })
    );
    clearSession();
    clearTokenTimer();
    sessionStorage.removeItem('lastActiveTimeStamp');
    sessionStorage.removeItem('renewTokenTimer');
    setOpenDialog(false);
  }

  function resetSession() {
    // console.log("resetSession")
    clearSession();
    clearTokenTimer();
    dispatch(postTokenRenewal({ dispatch: dispatch, navigate: navigate }));
    setOpenDialog(false);
  }

  function clearSession() {
    // console.log("clearSession")
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = null;
    }
    if (popuptimer.current) {
      clearTimeout(popuptimer.current);
      popuptimer.current = null;
    }
  }

  function startRenewTokenTimer() {
    // startTimerRefernce();                             // ** this is only for reference. This  will be removed later ** //
    sessionStorage.setItem('renewTokenTimer', getCurrentTimeStamp());
    renewtokenTimer.current = setTimeout(() => {
      if (sessionStorage.getItem('renewTokenTimer') == sessionStorage.getItem('lastActiveTimeStamp')) {
        return;
      } else {
        clearSession();
        clearTokenTimer();
        dispatch(postTokenRenewal({ dispatch: dispatch, navigate: navigate }));
      }
    }, maxRenewTokenTime);
  }

  function clearTokenTimer() {
    // console.log('clearTokenTimer')
    if (renewtokenTimer.current) {
      clearTimeout(renewtokenTimer.current);
      // clearInterval(renewtokenTimer.current)
      renewtokenTimer.current = null;

      // clearInterval(referenceTimeInterval.current); // ** this is only for reference. This  will be remove when raising PR ** //
      // referenceTimeInterval.current = null; // ** this is only for reference. This  will be remove when raising PR ** //
    }
  }

  // function startTimerRefernce() {
  //   // ** this is only for reference. This function will be remove when raising PR ** //
  //   referenceTimeInterval.current = setInterval(() => {
  //     if (count.current > 0) {
  //       if (count.current % 6 === 0 || count.current === 45) console.log('api counter', count.current);
  //       count.current = count.current - 1;
  //     }
  //   }, 1000);
  // }

  const body = (
    <>
      <div>{t('auth.popup.aboutToExpire')}</div>
      {t('auth.popup.clickContinue')}
    </>
  );

  const actionBtn = (
    <ButtonWrapper
      isFullWidth={true}
      colorScheme="primary"
      ariaLabel={t('auth.popup.continue')}
      handleClick={() => resetSession()}
      label={t('auth.popup.continue')}
    />
  );

  return (
    <div>
      <DialogWrapper open={openDialog} body={body} actionButtons={actionBtn} role="alertdialog" />
    </div>
  );
};

export default Session;

// session starts in both cases

// 1. When the user is partially logged in
// 2. When the user is fully logged in

// Session time out warning pop up after 6 min of inactivity

// Session renewal time to be reset when clicked continue on pop up

// when no action performed on session time out warning session to be timed out at 8 th min (6 + 2)

// Hard renew to keep session alive - check for timer in existing code, it should be reset

// After clicking continue on OK on warning try making API call, should be successful

// After successfull api call reset all the timers
