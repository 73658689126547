import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import '../../../src/styles/styles.scss';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// import styles from './forgot-password.module.scss';
import { useTranslation } from 'react-i18next';
import { AllRegex } from '../../constants';
import { RedirectRoutes } from '../../constants';
import { ButtonWrapper, FormWrapper, InputWrapper } from '../../elements/core-ui';
import { GenericPanelComponent } from '../../elements/shared';
import { useTitle } from '../../hooks/use-document-title';
import { Utils } from '../../utils';
import { postForgotUserName } from './forgot-username-reducer';

type ForgotUserNameProps = {
  [key: string]: any;
};

const defaultValues = {
  email: ''
};

const ForgotUserName: React.FC<ForgotUserNameProps> = () => {
  const [alertOpen, setAlertOpen] = useState<{ status: boolean; message: string }>({ status: false, message: '' });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useTitle('app.title.forgotUsername');

  const schema = yup.object({
    email: yup.string().trim().required('use_another_email.validation.blank').matches(AllRegex.emailRegex, {
      excludeEmptyString: true,
      message: 'formValidate.error.validEmail'
    })
  });

  useEffect(() => {
    Utils.hideServerAlert(dispatch);
  }, []);

  const methods = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
    mode: 'onBlur',
    reValidateMode: 'onBlur'
  });

  const {
    formState: { errors },
    getValues
  } = methods;

  const handleCancel = () => {
    navigate(RedirectRoutes.login);
  };

  const handleContinueClick = (data: any) => {
    if (!!data?.email?.length) {
      dispatch(postForgotUserName({ emailAddress: data.email, navigate, t }));
    }
  };

  const bodyComponent = (
    <FormWrapper onSubmit={methods.handleSubmit(handleContinueClick)}>
      <InputWrapper
        id="email"
        type={'text'}
        required={true}
        methods={methods}
        name={'email'}
        label={t('forgotUserName.email')}
        contentBeforeInputIconName={'email'}
      />
      <>
        <ButtonWrapper
          isFullWidth
          tag="button"
          type="submit"
          colorScheme="primary"
          ariaLabel={t('forgotUserName.continue')}
          label={t('forgotUserName.continue')}
        />
        <ButtonWrapper
          isFullWidth
          colorScheme="tertiary"
          handleClick={handleCancel}
          ariaLabel={t('forgotUserName.cancel')}
          label={t('forgotUserName.cancel')}
        />
      </>
    </FormWrapper>
  );

  return (
    <GenericPanelComponent
      bodyComponent={bodyComponent}
      pageTitle={t('forgotUserName.title')}
      panelSubText={t('forgotUserName.note')}
    />
  );
};

export default ForgotUserName;
