import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetAppData } from '../../../app/app-reducer';
import { dateTimeUtil } from '../../../utils';
import { Utils } from '../../../utils';
import { ContactDetail } from '../../../constants/user-profile';
interface SignIn {
  username: string;
  guid: string;
  userGuid: string;
  rememberme: boolean;
  timeStamp: string;
  response: any;
  scope: any;
  accessToken: string;
  pendingTerms: boolean;
  tokenSuccess: boolean;
  tokenFailure: boolean;
  token2Failure: boolean;
  configurationsSuccess: boolean;
  userDetails: any;
  authCode: string;
  configurationsResponseSuccess: any;
  tokenFailureResponse: any;
  emailGuid: string;
  emailStatus: string;
  verifyEmail?: boolean;
}

const initialState: SignIn = {
  username: '',
  guid: '',
  userGuid: '',
  rememberme: false,
  scope: {},
  accessToken: '',
  response: {},
  pendingTerms: false,
  timeStamp: '',
  tokenSuccess: false,
  tokenFailure: false,
  token2Failure: false,
  configurationsSuccess: false,
  userDetails: {},
  authCode: '',
  configurationsResponseSuccess: {},
  tokenFailureResponse: null,
  emailGuid: '',
  emailStatus: '',
  verifyEmail: false
};
const extractScope = (responseScope: any, responseSubjects: any) => {
  let unverified = false,
    challenge = false,
    isAcceptTerms = false,
    allScopes;
  if (responseScope) {
    allScopes = responseScope.split(' ');

    allScopes.forEach(function (scope, index, array) {
      if (scope.indexOf('/idv') > -1) {
        unverified = true;
      } else if (scope.indexOf('/2fa') > -1) {
        challenge = true;
      }
    });
  }

  const termsGuid = responseSubjects.probes.map(probe => {
    isAcceptTerms = probe.type === 'ACCEPT_TNC';
    unverified = probe.type === 'VERIFY_USERNAME';
    challenge = probe.type === 'SOLVE_CHALLENGE';
  });

  return {
    unverified,
    challenge,
    isAcceptTerms
  };
};

const getEmailGuid = contacts => {
  const result = {
    emailGuid: '',
    status: ''
  };
  if (Array.isArray(contacts) && Boolean(contacts.length)) {
    const primaryEmailContact = contacts.find(
      contact => contact.contactPreference == ContactDetail.PRIMARY && contact.contactType == ContactDetail.EMAIL
    );

    if (primaryEmailContact) {
      (result.emailGuid = primaryEmailContact.guid), (result.status = primaryEmailContact.verificationStatus);
    }
    return result;
  } else {
    return result;
  }
};
const signInSlice = createSlice({
  name: 'sign-in',
  initialState,
  reducers: {
    setUsername: (state: SignIn, action: PayloadAction<any>) => {
      state.username = action.payload;
      return state;
    },
    // setGuid: (state: SignIn, action: PayloadAction<any>) => {
    //   const guid = action.payload;
    //   state.guid = guid;
    //   return state;
    // },
    // setUserGuid: (state: SignIn, action: PayloadAction<any>) => {
    //   return {
    //     ...state,
    //     userGuid: action.payload
    //   };
    // },
    // setAccessToken: (state: SignIn, action: PayloadAction<any>) => {
    //   state.accessToken = action.payload;
    //   return state;
    // },
    postLoginSuccess: (state: SignIn, action: PayloadAction<any>) => {
      const { payload } = action;
      state.scope = extractScope(payload.scope, payload.subjects[0]);
      state.timeStamp = dateTimeUtil.timeStampToLocaleDateTime(payload.subjects[0].visited_on);
      state.tokenSuccess = true;
      state.verifyEmail = payload?.subjects?.[0]?.subject_auth_status === 'P';
      const { emailGuid, status } = getEmailGuid(payload?.data?.contacts || []);
      state.emailGuid = emailGuid;
      state.emailStatus = status;
      // state.accessToken = payload.access_token;
      // state.userGuid = payload.userGuid;
    },
    postEmailUpdateSuccess: (state: SignIn, action: PayloadAction<any>) => {
      const { payload } = action;
      const { emailGuid } = getEmailGuid(payload?.data?.contacts || []);
      return {
        ...state,
        emailGuid
      };
    },
    // setToken2Success: (state: SignIn) => {
    //   return {
    //     ...state,
    //     token2Success: true
    //   };
    // },
    setConfigurationsSuccess: (state: SignIn, action) => {
      // state.configurationsSuccess = true;
      // return state;
      return {
        ...state,
        configurationsSuccess: true,
        configurationsResponseSuccess: action.payload
      };
    },
    setTokenSuccess: (state: SignIn) => {
      state.tokenSuccess = true;
      return state;
    },
    setTokenFailure: (state: SignIn, action: PayloadAction<any>) => {
      state.tokenFailure = action.payload;
      return state;
    },
    postLoginFailure: (state: SignIn, action: PayloadAction<any>) => {
      state.tokenFailure = true;
      state.tokenFailureResponse = action.payload;
    },
    resetSuccessFlags: (state: SignIn) => {
      state.tokenSuccess = false;
      state.configurationsSuccess = false;
      // state.token2Success = false;
      state.tokenFailureResponse = false;
      state.tokenFailure = false;
      state.token2Failure = false;
      state.pendingTerms = false;
      return state;
    },
    setPendingTerms: (state: SignIn, action: PayloadAction<any>) => {
      state.pendingTerms = action.payload;
      return state;
    },
    postLogin: (state: SignIn, action: PayloadAction<any>) => {
      return state;
    },
    getConfigurations: (state: SignIn) => {
      return state;
    },
    tokenAsync: (state: SignIn, action: PayloadAction<any>) => {
      return state;
    },
    postToken: (state: SignIn) => {
      return state;
    },
    postTokenFailure: (state: SignIn) => {
      state.token2Failure = true;
      return state;
    },
    setAuthCode: (state: SignIn, action: PayloadAction<any>) => {
      state.authCode = action.payload;
      return state;
    },
    resetAuthenticationFlags: (state: SignIn, action: PayloadAction<any>) => {
      state.authCode = '';
      state.accessToken = '';
      state.userGuid = '';
      return state;
    }
  },
  extraReducers: builder => {
    builder.addCase(resetAppData, () => {
      return initialState;
    });
  }
});

export const {
  postLogin,
  setUsername,
  getConfigurations,
  postToken,
  postTokenFailure,
  postLoginSuccess,
  setConfigurationsSuccess,
  setTokenSuccess,
  setTokenFailure,
  postLoginFailure,
  setPendingTerms,
  resetSuccessFlags,
  setAuthCode,
  postEmailUpdateSuccess,
  resetAuthenticationFlags
} = signInSlice.actions;

export default signInSlice.reducer;
