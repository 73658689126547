import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import useTranslator from '../../hooks/translation-hook';
import { GenericPanelComponent } from '../../elements/shared';
import { ButtonWrapper, InputWrapper } from '../../elements/core-ui';
import { FormWrapper } from '../../elements/core-ui';
import { RedirectRoutes } from '../../constants';
import { useTitle } from '../../hooks/use-document-title';
import { Utils, Validator } from '../../utils';
import { forgotPassword } from './forgot-password-reducer';

type ForgotPasswordProps = {
  [key: string]: any;
};

const ForgotPassword: React.FC<ForgotPasswordProps> = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const t = useTranslator();

  useTitle('app.title.forgotPassword');
  useEffect(() => {
    Utils.hideServerAlert(dispatch);
  }, []);

  const initialValues = {
    username: ''
  };
  const schema = yup.object({
    username: yup
      .string()
      .required('forgotPassword.validation.requiredField')
      .min(7, 'forgotPassword.validation.minLength')
      .test((value: any, context: any) => Validator.userNameValidator(value, context))
  });
  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: initialValues,
    resolver: yupResolver(schema)
  });

  const handleContinueClick = (data: any) => {
    dispatch(forgotPassword({ username: data.username, navigate, t }));
  };
  const handleCancelClick = () => {
    navigate(RedirectRoutes.login);
  };
  const bodyComponent = (
    <FormWrapper onSubmit={methods.handleSubmit(handleContinueClick)}>
      <InputWrapper
        id="username"
        type={'text'}
        required={true}
        methods={methods}
        name={'username'}
        label={t('forgotPassword.username')}
      />
      <>
        <ButtonWrapper
          isFullWidth
          tag="button"
          type="submit"
          colorScheme="primary"
          ariaLabel={t('forgotPassword.btnContinue')}
          label={t('forgotPassword.btnContinue')}
        />
        <ButtonWrapper
          isFullWidth
          colorScheme="tertiary"
          handleClick={handleCancelClick}
          ariaLabel={t('forgotPassword.btnCancel')}
          label={t('forgotPassword.btnCancel')}
        />
      </>
    </FormWrapper>
  );

  return (
    <GenericPanelComponent
      bodyComponent={bodyComponent}
      pageTitle={t('forgotPassword.heading')}
      panelSubText={t('forgotPassword.panelNote')}
    />
  );
};

export default ForgotPassword;
