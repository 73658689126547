import { configureStore, combineReducers } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { checkReducer } from '../pages/create-account/check';
import { unsubscribeReducer } from '../pages/unsubscribe';
import { addEditCardReducer } from '../elements/shared';
import { enrollReducer } from '../pages/create-account/enroll';
import { enrollIdvVerifyReducer } from '../pages/create-account/enroll';
import { forgotUsernameReducer } from '../pages/forgot-username';
import { forgotPasswordReducer } from '../pages/forgot-password';
import { signInReducer, emailVerifyReducer, idvVerifyReducer, forcePasswordResetReducer } from '../pages/sign-in';
import { alertReducer, stickyAlertReducer } from '../elements/shared';
import { changeEmailReducer } from '../pages/profile/change-email';
import { acceptTnCReducer } from '../pages/sign-in';
import { useAnotherEmailReducer, spinnerReducer } from '../elements/shared';
import {
  alertsHistoryReducer,
  alertsReducer,
  userDetailsReducer,
  manageCardReducer,
  cardControlReducer
} from '../pages/home';
import { updatePhoneReducer, changePasswordReducer, messageLanguageReducer } from '../pages/profile';
import { userAuthReducer } from '../auth';
import { appReducer } from '../app';
import errorModalReducer from '../elements/shared/error-modal/error-modal-reducer';
import rootSaga from './root-saga';

// const rootPersistConfig = {
//     key: 'root',
//     storage:storageSession,
//     stateReconciler: autoMergeLevel1,
//     whitelist: ['counter']
//   }

//   const userPersistConfig = {
//     key: 'user',
//     storage,
//     whilelist:['value']
//   }

const rootReducer = combineReducers({
  alert: alertReducer,
  stickyAlert: stickyAlertReducer,
  anotherEmail: useAnotherEmailReducer,
  changeEmail: changeEmailReducer,
  changePassword: changePasswordReducer,
  forgotPassword: forgotPasswordReducer,
  checkEligibility: checkReducer,
  forgotUserName: forgotUsernameReducer,
  enroll: enrollReducer,
  emailVerify: emailVerifyReducer,
  idvVerify: idvVerifyReducer,
  enrollIdvVerify: enrollIdvVerifyReducer,
  signin: signInReducer,
  acceptTnc: acceptTnCReducer,
  addEditCard: addEditCardReducer,
  manageCard: manageCardReducer,
  userDetails: userDetailsReducer,
  updatePhone: updatePhoneReducer,
  messageLanguage: messageLanguageReducer,
  userAuth: userAuthReducer,
  spinner: spinnerReducer,
  app: appReducer,
  errorModal: errorModalReducer,
  alerts: alertsReducer,
  cardControls: cardControlReducer,
  alertsHistory: alertsHistoryReducer,
  unsubscribe: unsubscribeReducer,
  forceResetPassword: forcePasswordResetReducer
});

const sagaMiddleware = createSagaMiddleware();
//add middlewares in the array if needed
const middleware = [sagaMiddleware];

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }).concat(middleware)
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
sagaMiddleware.run(rootSaga);
//export const persistor = persistStore(store)
export default store;
