import { call, put, select, takeLatest } from 'redux-saga/effects';
import { showAlert } from '../../../../elements/shared';
import { ajaxHandler, apiPaths } from '../../../../setup';
import { Utils } from '../../../../utils';
import { getAlerts, getAlertsFailure, getAlertsSuccess, updateAlertsFailure, updateAlertsSuccess } from './alerts-reducer';

function* alertsHandler(action) {
  const { userAuth, userDetails, alerts } = yield select((state: any) => state);

  const headers = {
    access_token: userAuth.accessToken
  };
  const guidReplacedURL = Utils.replaceUserGuid(apiPaths.alerts.alertUrl, userAuth.userGuid);

  const getAlertsURL = Utils.replacePaymentGuid(guidReplacedURL, userDetails.selectedCard.panGuid);
  let response: any;
  if (action.payload.method === 'PUT') {
    response = yield call(ajaxHandler, getAlertsURL, true, {
      data: action.payload.updatedServiceOfferings,
      headers,
      method: 'PUT',
      urlWithPlaceholder: apiPaths.alerts.alertUrl
    });
  } else {
    response = yield call(ajaxHandler, getAlertsURL, true, {
      headers,
      urlWithPlaceholder: apiPaths.alerts.alertUrl
    });
  }
  if (!response.error) {
    yield put(getAlertsSuccess(response.data));
    if (action.payload.method === 'PUT') {
      yield put(updateAlertsSuccess());
      const successMsg = 'alerts.success.message';
      yield put(showAlert({ type: 'SUCCESS', message: successMsg }));
    }
  } else {
    if (action.payload.method === "PUT") {
      yield put(updateAlertsFailure(response.error.response));
      yield put(showAlert({ type: 'ERROR', message: response.error.response.data.message }));
    } else {
      const type = 'ERROR';
      const message = `alerts.errors.unavailable`;
      yield put(showAlert({ type, message }));
      yield put(getAlertsFailure(response.error.response));
    }
  }
}

export default function* watchAlerts() {
  yield takeLatest(getAlerts, alertsHandler);
}
