import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface MessageLanguage {
  updateMessageLanguageSuccess: boolean;
}

const initialState: MessageLanguage = {
  updateMessageLanguageSuccess: false
};
const messageLanguageReducer = createSlice({
  name: 'messageLanguage',
  initialState,
  reducers: {
    putMessageLanguage: (state: MessageLanguage, action: PayloadAction<any>) => {
      return state;
    },
    putMessageLanguageSuccess: (state: MessageLanguage) => {
      state.updateMessageLanguageSuccess = true;
      return state;
    },
    resetFlags: (state: MessageLanguage) => {
      state.updateMessageLanguageSuccess = false;
    }
  }
});

export const { putMessageLanguage, putMessageLanguageSuccess, resetFlags } = messageLanguageReducer.actions;

export default messageLanguageReducer.reducer;
