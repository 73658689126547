import { createSlice } from '@reduxjs/toolkit';
import { resetAppData } from '../../../app/app-reducer';

interface ForcePasswordReset {
  vuser: any;
  accessToken: string;
  OTPError: any;
  OTPSuccess: boolean;
  username: string;
  userGuid: string;
  forcePasswordResetError: any;
  forcePasswordResetSuccess: boolean;
  requestForgotPasswordError: any;
}

const initialState: ForcePasswordReset = {
  vuser: {},
  accessToken: '',
  username: '',
  userGuid: '',
  OTPError: null,
  OTPSuccess: false,
  forcePasswordResetError: null,
  forcePasswordResetSuccess: false,
  requestForgotPasswordError: null
};

const forcePasswordResetReducer = createSlice({
  name: 'forgot-password',
  initialState,
  reducers: {
    forcePasswordReset: (state, action) => {
      return state;
    },
    forcePasswordResetSuccess: (state, action) => {
      state.forcePasswordResetSuccess = true;
      return state;
    },
    forcePasswordResetFailure: (state, action) => {
      state.forcePasswordResetSuccess = false;
      state.forcePasswordResetError = action.payload;
      return state;
    },
    forcePasswordResetUpdateAccessToken: (state: ForcePasswordReset, action: any) => {
      return state;
    },
    resetSuccessFlags: (state: ForcePasswordReset) => {
      state.forcePasswordResetSuccess = false;
      return state;
    },
    restoreForgotPasswordData: (state, action) => {
      // this method is only used for restoring the forgot password state on reload
      state = action.payload;
      return state;
    }
  },
  extraReducers: builder => {
    builder.addCase(resetAppData, () => {
      return initialState;
    });
  }
});

export const {
  forcePasswordReset,
  forcePasswordResetFailure,
  forcePasswordResetSuccess,
  forcePasswordResetUpdateAccessToken,
  resetSuccessFlags,
  restoreForgotPasswordData
} = forcePasswordResetReducer.actions;

export default forcePasswordResetReducer.reducer;
