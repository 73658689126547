import { put, takeLatest, call, select } from 'redux-saga/effects';
import { ajaxHandler, apiPaths } from '../../../setup';
import { showAlert } from '../../../elements/shared';
import { replaceUserGuid } from '../../../utils/utils';
import { putMessageLanguage, putMessageLanguageSuccess } from './message-language-reducer-slice';

function* putMessageLanguageHandler(action) {
  const { payload } = action;
  const userAuth = yield select((state: any) => state.userAuth);
  const updateLanguage = replaceUserGuid(apiPaths.messageLang.put, userAuth.userGuid);
  const headers = {
    access_token: userAuth.accessToken
  };
  const response = yield call(ajaxHandler, updateLanguage, false, {
    data: payload.payload,
    method: 'PUT',
    headers,
    urlWithPlaceholder: apiPaths.messageLang.put
  });
  if (!response.error) {
    const type = 'SUCCESS';
    const message = 'messageLanguage.success';
    yield put(putMessageLanguageSuccess());
    yield put(
      showAlert({
        type,
        message
      })
    );
  }
}

export default function* watchMessageLanguage() {
  yield takeLatest(putMessageLanguage, putMessageLanguageHandler);
}
