import React, { useEffect, useState } from 'react';
import { Input } from '@visa/vds';
import InputOption from '@visa/vds/input-option';
import Icon from '@visa/vds/icon';
import ButtonIcon from '@visa/vds/button-icon';
import { useTranslation } from 'react-i18next';
import { Controller, Noop } from 'react-hook-form';
import { InputWrapperProps } from '../model';
import { addDescribedbyId } from '../../../utils/utils';

const InputWrapper: React.FC<InputWrapperProps> = ({
  id = '',
  name = '',
  type = '',
  label = '',
  methods = {},
  helpText = '',
  className = '',
  minLength = 50,
  maxLength = 255,
  describedBy = '',
  required = false,
  placeholder = '',
  showHelpText = false,
  handleBlur = f => f,
  handleFocus = f => f,
  handleChange = f => f,
  hidePasswordIcon = false,
  contentBeforeInputIconName = '',
  contentBeforeInputIconResolution = ''
}) => {
  const {
    register,
    formState: { errors },
    control,
    setValue,
    setError,
    getValues,
    clearErrors
  } = methods;

  const { t } = useTranslation();
  const [passwordType, setPasswordType] = useState(type);

  useEffect(() => {
    addDescribedbyId(id, describedBy);
  }, [id, describedBy]);

  const handlePasswordToggle = () => {
    passwordType === 'password' ? setPasswordType('text') : setPasswordType('password');
  };

  const handleInputFocus = (): void => {
    handleFocus && handleFocus(name);
  };

  const handleInputBlur = (blur: Noop) => {
    handleBlur && handleBlur(name);
    blur();
  };

  const handleInputChange = e => {
    setValue(name, e.target.value);
    handleChange && handleChange(name, e);
  };

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          return (
            <Input
              id={id}
              ariaLabel={label}
              className={className}
              label={label}
              type={passwordType}
              {...field}
              onFocus={() => handleInputFocus()}
              onBlur={() => handleInputBlur(field.onBlur)}
              onChange={e => handleInputChange(e)}
              helpText={helpText ? helpText : null}
              showHelpText={showHelpText}
              errorText={t(`${errors?.[name]?.message}`)}
              showErrorText={errors?.[name]?.message ? true : false}
              invalid={errors?.[name]?.message ? true : false}
              required={required}
              placeholder={placeholder}
              noValidate
              aria-required={Boolean(required)}
              minLength={minLength}
              maxLength={maxLength}
              contentBeforeInput={
                contentBeforeInputIconName && (
                  <InputOption>
                    <Icon name={contentBeforeInputIconName} resolution={contentBeforeInputIconResolution || 'low'} />
                  </InputOption>
                )
              }
            >
              {hidePasswordIcon ? (
                <InputOption>
                  <ButtonIcon
                    ariaLabel="Show Password"
                    aria-pressed={passwordType === 'text' ? 'false' : 'true'}
                    iconType="light-tiny"
                    onClick={handlePasswordToggle}
                  >
                    {passwordType === 'password' ? (
                      <Icon name="password-show" resolution="tiny" />
                    ) : (
                      <Icon name="password-hide" resolution="tiny" />
                    )}
                  </ButtonIcon>
                </InputOption>
              ) : null}
            </Input>
          );
        }}
      />
    </>
  );
};

export default InputWrapper;
