import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useTranslator from '../../../hooks/translation-hook';
import { VerifyCode } from '../../../elements/shared';
import { forgotPasswordOtp, forgotPassword, forgotPasswordResendOtp } from '../forgot-password-reducer';
import { Utils } from '../../../utils';

type VerifyCodeProps = {
  [key: string]: any;
};

const VerifyCodeWarpper: React.FC<VerifyCodeProps> = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const t = useTranslator();
  const forgotPasswordStore = useSelector((store: any) => store.forgotPassword);
  const handleSubmit = (data: any) => {
    Utils.hideServerAlert(dispatch);
    const config = {
      t,
      navigate,
      otp: data.otpCode,
      state: forgotPasswordStore,
      username: forgotPasswordStore.username
    };
    dispatch(forgotPasswordOtp(config));
  };
  const handleCancel = () => {
    const config = {
      navigate,
      username: forgotPasswordStore.username
    };
    dispatch(forgotPassword(config));
  };

  const handleResend = () => {
    dispatch(forgotPasswordResendOtp({ state: forgotPasswordStore, navigate, t }));
  };

  return (
    <div>
      <VerifyCode
        submitHandler={handleSubmit}
        cancelHandler={handleCancel}
        resendHandler={handleResend}
        forgotPasswordOtpPageTitle={t('verifyCode.panelText')}
      />
    </div>
  );
};

export default VerifyCodeWarpper;
