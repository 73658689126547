const RedirectRoutes = {
  home: '/home',

  login: '/login',
  loginVerifyEmail: '/login/verify',
  loginEditEmail: '/login/edit-email',
  acceptPendingTnc: '/login/accept-pending-tnc',
  loginChallengeVerifyEmail: '/login/challenge/verify/email',
  forcePasswordReset: '/login/force-password-reset',

  check: '/check',
  createAccount: '/create-account',
  createAccountEnroll: '/create-account/enroll',
  createAccountVerifyCode: '/create-account/verify',
  createAccountAddCard: '/create-account/add-card',
  createAccountEditEmail: '/create-account/edit-email',

  forgotUsername: '/forgot-username',

  forgotPassword: '/forgot-password',
  forgotPasswordVerifyCode: '/forgot-password/verify',
  resetPassword: '/forgot-password/reset-password',
  updatePassword: '/profile/change-password',

  profile: '/profile',
  updatePhone: '/profile/update-phone',
  editPhone: '/profile/edit-phone',
  phoneVerifyCode: '/profile/phone/verify-code',
  deleteProfile: '/profile/delete',
  changePassword: '/profile/change-password',
  changeEmail: '/profile/change-email',
  changeEmailVerifyCode: '/profile/change-email/verify',
  messageLanguage: '/profile/message-language',
  addCard: '/check-add-card',

  homeNoCard: '/home/no-card',
  homeAddCard: '/add-card',
  homeAlerts: '/home/alerts',
  homeEditCard: '/edit-card',
  homeManageCards: '/home/manage-cards',
  homeCardControl: '/home/card-control',
  homeAlertHistory: '/home/alert-history',
  homeCheckEligibility: '/check',

  terms: '/terms',
  privacy: '/privacy-policy',
  faq: '/faq',
  supportedCarriers: '/supported-carriers',
  unsubscribe: '/unsubscribe'
};

export default RedirectRoutes;
