import { Navigate, useLocation, useNavigate, useRoutes } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { homeRoutes } from '../pages/home';
import { createAccountRoutes } from '../pages/create-account';
import { signInRoutes } from '../pages/sign-in';
import { forgotPasswordRoutes } from '../pages/forgot-password';
import { forgotUsernameRoutes } from '../pages/forgot-username';
import { profileRoutes } from '../pages/profile';
import { unsubscribeRoutes } from '../pages/unsubscribe';
// import { changePasswordRoutes } from '../pages/change-password';
import { RedirectRoutes } from '../constants';
import { PageNotFound } from '../pages/page-not-found';
import translator from '../i18n';
import { Utils } from '../utils';
import { Header, Footer } from '../elements/shared';
import { getLocale } from '../utils/utils';
import { persistData, restoreRedux } from './persist-store';

const wildCardRoute = [
  {
    path: 'not-found',
    element: <PageNotFound />
  },
  {
    path: '*',
    element: <Navigate to="not-found" />
  }
];

const GlobalRoutes = [
  ...homeRoutes,
  ...createAccountRoutes,
  ...signInRoutes,
  ...forgotPasswordRoutes,
  ...forgotUsernameRoutes,
  // ...changePasswordRoutes,
  ...profileRoutes,
  ...unsubscribeRoutes,
  ...wildCardRoute // should always be at bottom
];

const Routing = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const {
    checkEligibility: { countryConfiguration }
  } = useSelector((store: any) => store);

  const store = useSelector((store: any) => store);
  const [isRenderValidated, setIsRenderValidated] = useState(false);

  window.onbeforeunload = function () {
    persistData(store);
  };

  useEffect(() => {
    /*
        render all the routes only after the first render is successfully and restoreRedux logic is executes
        isRenderValidated will always be true. It is just we are making sure that the UI has successfully completed
        its first render.   
    */
    setIsRenderValidated(true);
    restoreRedux(navigate);
  }, []);

  useEffect(() => {
    if (location.pathname === '/') {
      navigate(RedirectRoutes.login);
    }
  }, []);

  useEffect(() => {
    if (countryConfiguration?.supportedLocales.length) {
      translator(countryConfiguration.supportedLocales?.map(ele => ele.locale));
      Utils.removeQueryParam(navigate);
    }
  }, [countryConfiguration.supportedLocales]);

  useEffect(() => {
    window.webSDKInstance?.setLocale(getLocale());
  }, [getLocale()]);

  const routes = useRoutes(GlobalRoutes);
  return isRenderValidated ? (
    <>
      <Header />
      <main role="main" id="app-main">
        {routes}
      </main>
      <Footer />
    </>
  ) : null;
};

Routing.displayName = 'Routing';

export default Routing;
