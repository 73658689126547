import { createSlice } from '@reduxjs/toolkit';

interface AcceptPendingTnc {
  guid: string;
  acceptTncSuccess: boolean;
}

const initialState: AcceptPendingTnc = {
  guid: '',
  acceptTncSuccess: false
};

const acceptTnCReducer = createSlice({
  name: 'acceptTnC',
  initialState,
  reducers: {
    postAcceptTnC: (state: AcceptPendingTnc, action) => {
      return state;
    },
    postAcceptTnCSuccess: (state: AcceptPendingTnc) => {
      state.acceptTncSuccess = true;
      return state;
    },
    postAcceptTnCRequestFailure: (state: AcceptPendingTnc, action) => {
      state = action?.payload;
      return state;
    },
    resetTncFlags: (state: AcceptPendingTnc) => {
      state.acceptTncSuccess = false;
      return state;
    }
  }
});

export const { postAcceptTnC, postAcceptTnCSuccess, postAcceptTnCRequestFailure, resetTncFlags } =
  acceptTnCReducer.actions;

export default acceptTnCReducer.reducer;
