import { createSlice } from '@reduxjs/toolkit';

interface IForgotUserNameResponse {
  status: string;
  reason: string;
  userGuid: string;
  maskedEmail: string;
}

const initialState: IForgotUserNameResponse = {
  status: '',
  reason: '',
  userGuid: '',
  maskedEmail: ''
};

const forgotUserNameReducer = createSlice({
  name: 'forgotusername',
  initialState,
  reducers: {
    postForgotUserName: (state: IForgotUserNameResponse, action) => {
      return state;
    },
    postForgotUserNameSuccess: (state: IForgotUserNameResponse, action) => {
      state = action?.payload;
      return state;
    },
    postForgotUserNameFailure: (state: IForgotUserNameResponse, action) => {
      state = action?.payload;
      return state;
    }
  }
});

export const { postForgotUserName, postForgotUserNameSuccess, postForgotUserNameFailure } =
  forgotUserNameReducer.actions;

export default forgotUserNameReducer.reducer;
