import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ButtonWrapper } from '../../elements/core-ui';
import { GenericPanelComponent } from '../../elements/shared';
import { Utils } from '../../utils';
import { useTitle } from '../../hooks/use-document-title';
import { RedirectRoutes } from '../../constants';
import { postUnsubscribe } from './unsubscribe-reducer';
import Styles from './unsubscribe.module.scss';

type UnsubscribeProps = {
  [key: string]: any;
};

const Unsubscribe: React.FC<UnsubscribeProps> = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { unsubscribe } = useSelector((state: any) => state);
  const { t } = useTranslation();
  useTitle('app.title.unsubscribed');
  
  const handleUnsubscribeClick = () => {
    const payload = {
      externalCustomerId: Utils.getQueryPrameterByName('ExternalCustomerId'),
      hashedCustomerId: Utils.getQueryPrameterByName('HashedCustomerId'),
      emailAddress: Utils.getQueryPrameterByName('EmailAddress')
    };
    !unsubscribe.isUnsubscribed && dispatch(postUnsubscribe(payload));

    if (unsubscribe.isUnsubscribed) {
      navigate(RedirectRoutes.login);
    }
  };

  const heading = () =>
    unsubscribe.isUnsubscribed ? t('unsubscribe.success.heading') : t('unsubscribe.request.heading');
  const subText = () =>
    unsubscribe.isUnsubscribed ? t('unsubscribe.success.subheading') : t('unsubscribe.request.subheading');
  const actionButtons = () => (
    <ButtonWrapper
      className={Styles['btn']}
      colorScheme={'primary'}
      label={unsubscribe.isUnsubscribed ? t('unsubscribe.success.submitBtn') : t('unsubscribe.request.submitBtn')}
      ariaLabel={unsubscribe.isUnsubscribed ? t('unsubscribe.success.submitBtn') : t('unsubscribe.request.submitBtn')}
      isFullWidth={true}
      handleClick={handleUnsubscribeClick}
    />
  );

  return <GenericPanelComponent pageTitle={heading()} panelSubText={subText()} actionBtns={actionButtons()} />;
};

export default Unsubscribe;
