import React, { useEffect, Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Icons from '@visa/vds/icons';
import { GlobalConstants } from '../../src/constants';
import { Spinner, SkipLink } from '../elements/shared';
import { hideSpinner } from '../elements/shared/spinner/spinner-reducer';
import Routing from '../setup/routing';
import translator from '../i18n';
import Session from '../auth/session';
import ErrorModal from '../elements/shared/error-modal/error-modal';
import { Utils } from '../utils';
import { setEncNonce } from './app-reducer';

const App = () => {
  const dispatch = useDispatch();
  const {
    checkEligibility: { countryConfiguration },
    userAuth
  } = useSelector((store: any) => store);

  useEffect(() => {
    //isExtendedAddCard (shouldLoadSDK) based on country configuration
    if(countryConfiguration.shouldLoadSDK) {

      const hostname = window.location.hostname, 
      envUrl =
        hostname.includes('digitalcardservice.com') || hostname.includes('visa.com')
          ? 'https://' + hostname
          : GlobalConstants.defaultSdkUrl,
      scriptElement = document.getElementById('websdk_script');
      if (scriptElement) {
        document.body.removeChild(scriptElement);
      }
      const script = document.createElement('script');
      script.id = 'websdk_script';
      script.src = `${envUrl}/web-sdk/${GlobalConstants.sdkVersion}/web_sdk.js?appId=${GlobalConstants.extAppId}`;
      script.async = true;
      //SDK script load
      document.body.appendChild(script);  

      const parseError = error => {
        try {
          return JSON.parse(error);
        } catch (e) {
          return e;
        }
      };

      script.onload = function () {
        const webSDK = window.WebSDK({
          onError: error => {
            const err = parseError(error);
            console.log(err);
          },
          onUXIframeShown: () => {
            dispatch(hideSpinner());
          }
        });
        script.onerror = function (er) {
          dispatch(hideSpinner());
          console.log('Script load error!' + er);
       };

        //Rendering web sdk and initializing Nonce
        webSDK
        ?.render?.('web_sdk_container_id')
        .then(result => {
          if (result?.coreWebSDKLoaded) {
            return webSDK.initialize();
          }
        })
        .then(res => {
          if (res?.encNonce) {
            dispatch(setEncNonce(res.encNonce));
          }
        })
        .catch(error => {
          const err = parseError(error);
          console.log(err);
        });

        //Setting websdk instalce to window to remove instance when sdk closed. 
        if (Object.keys(webSDK).length > 0) {
          window.webSDKInstance = webSDK;
        }
      };
    } else {
      dispatch(setEncNonce(''));
    }
  }, [countryConfiguration.shouldLoadSDK]);

  return (
    <>
      <Suspense fallback={<Spinner></Spinner>}>
        {/* <React.StrictMode> */}
        <BrowserRouter basename="vca-web">
          <ErrorModal />
          <Spinner></Spinner>
          <SkipLink></SkipLink>
          {(userAuth.isPartiallyLogged && Utils.shouldStartPartialLoginSession()) || userAuth.isFullyLogged ? (
            <Session />
          ) : null}
          <Icons />
          <Routing />
        </BrowserRouter>
        {/* </React.StrictMode> */}
      </Suspense>
    </>
  );
};

export default App;
