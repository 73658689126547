import { createSlice } from '@reduxjs/toolkit';
import { resetAppData } from '../../../app/app-reducer';

interface ChangePassword {
  newPassword: string;
  currentPassword: string;
  changePasswordSuccess: boolean;
  tokenSuccess: boolean;
}

const initialState: ChangePassword = {
  newPassword: '',
  currentPassword: 'currentPassword',
  changePasswordSuccess: false,
  tokenSuccess: false
};

const changePasswordReducer = createSlice({
  name: 'change-password',
  initialState,
  reducers: {
    changeUserPassword: (state: any, action: any) => {
      state.newPassword = action.payload.newPassword;
      return state;
    },
    updateAccessToken: (state: ChangePassword, action: any) => {
      return state;
    },
    setChangePasswordSuccess: (state: ChangePassword) => {
      state.changePasswordSuccess = true;
      return state;
    },
    setTokenSuccess: (state: ChangePassword, action) => {
      state.tokenSuccess = true;
      return state;
    },
    resetSuccessFlags: (state: ChangePassword) => {
      state.tokenSuccess = false;
      state.changePasswordSuccess = false;
      return state;
    }
  },
  extraReducers: builder => {
    builder.addCase(resetAppData, () => {
      return initialState;
    });
  }
});

export const { changeUserPassword, updateAccessToken, setChangePasswordSuccess, setTokenSuccess, resetSuccessFlags } =
  changePasswordReducer.actions;

export default changePasswordReducer.reducer;
