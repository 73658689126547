import { Utils} from '../../../utils';
import { put, takeLatest, call, select } from 'redux-saga/effects';
import { ajaxHandler, apiPaths } from '../../../setup';
import { RedirectRoutes, GlobalConstants } from '../../../constants';
import { showAlert } from '../../../elements/shared';
import { getContactValue, replaceUserGuid } from '../../../utils/utils';
import { deletePreviousPhone } from '../update-phone/update-phone.reducer';
import { ContactDetail, ContactEventType, OTVCType, PhoneContactType } from '../../../constants/user-profile';
import { getUserDetails } from '../../home/home-reducer';
import {
  changeUserEmailFailure,
  changeUserEmail,
  changeEmailOtp,
  changeEmailOtpFailure,
  changeEmailOtpSuccess,
  changePhoneOtp,
  changePhoneOtpSuccess,
  changePhoneOtpFailure,
  resendPhoneOtpSuccess,
  changePrimaryEmail,
  changeUserEmailSuccess
} from './change-email-reducer';

function* changeEmailHandler(action) {
  const { payload } = action;
  const data = {
    emailAddress: {
      emailAddress: payload.newEmail,
      verificationStatus: ContactDetail.UNVERIFIED,
      priorityType: ContactDetail.SECONDARY
    },
    requestContext: {
      eventSource: null,
      eventSourceId: null,
      clientReferenceId: null
    }
  };
  const headers = {
    access_token: payload.state.accessToken
  };
  const changeEmailURL = replaceUserGuid(apiPaths.emailUrl, payload.state.userGuid);

  const response = yield call(ajaxHandler, changeEmailURL, false, {
    method: 'POST',
    data,
    headers,
    urlWithPlaceholder: apiPaths.emailUrl
  });
  if (!response.error) {
    const locationStr: string = response.headers['location'] || '';
    const pathArr = locationStr.split('/');
    const type = 'SUCCESS';
    const message = 'forgotPassword.codeSendSuccess';
    yield put(showAlert({ type, message }));
    if (action.payload.navigate) {
      action.payload.navigate(RedirectRoutes.changeEmailVerifyCode);
    }
    yield put(changeUserEmailSuccess({ newPrimaryEmail: pathArr[pathArr.length - 1] }));
  } else {
    yield put(showAlert({ type: 'ERROR', message: response.error.errorMessage }));
    yield put(changeUserEmailFailure(response.error.response));
  }
}

function* sendEmailOtpHandler(action) {
  const { payload } = action;
  const { changeEmail } = yield select(store => store);
  const data = {
    contactType: ContactDetail.EMAIL,
    eventType: ContactEventType.EMAIL_VERIFICATION,
    guid: changeEmail.newPrimaryEmail,
    otvcType: OTVCType.LONG
  };
  if (payload.code) {
    data['verificationCode'] = payload.code;
  } else {
    data['locale'] = payload.locale;
  }
  const headers = {
    access_token: payload.state.accessToken
  };
  const sendEmailOtpURL = replaceUserGuid(apiPaths.auth.sendOtvcUrl, payload.state.userGuid);

  const response = yield call(ajaxHandler, sendEmailOtpURL, false, {
    method: payload.code ? 'PUT' : 'POST',
    data,
    headers,
    urlWithPlaceholder: apiPaths.auth.sendOtvcUrl
  });
  if (!response.error) {
    yield put(changeEmailOtpSuccess(response));
    if (payload.code) {
      yield put(changePrimaryEmail());
      if (action.payload.navigate) {
        action.payload.navigate(RedirectRoutes.profile);
      }
    } else {
      yield put(showAlert({ type: 'SUCCESS', message: 'forgotPassword.codeSendSuccess' }));
    }
  } else {
    yield put(changeEmailOtpFailure(response));
  }
}

function* changePrimaryEmailHandler() {
  const { userAuth, changeEmail, userDetails } = yield select(store => store);
  const data = {
    priorityType: ContactDetail.PRIMARY
  };

  const primaryEmailContact = userDetails?.vuser?.contacts?.find(item => {
    if (item.contactType === ContactDetail.EMAIL && item.contactPreference === ContactDetail.PRIMARY) {
      return item;
    }
  });

  const headers = {
    access_token: userAuth.accessToken
  };
  const sendEmailOtpURL = replaceUserGuid(`${apiPaths.emailUrl}/${changeEmail.newPrimaryEmail}`, userAuth.userGuid);

  const response = yield call(ajaxHandler, sendEmailOtpURL, false, {
    method: 'PATCH',
    data,
    headers,
    urlWithPlaceholder: apiPaths.emailUrl
  });
  const emailGuid = response?.headers?.location?.split('/')?.reverse()?.[0];

  if (!response.error) {
    yield put(showAlert({ type: 'SUCCESS', message: 'update_email.success' }));
    yield put(getUserDetails());
    if (emailGuid !== primaryEmailContact.guid) {
      yield deleteSecondaryEmailHandler();
    }
  } else {
    yield put(showAlert({ type: 'ERROR', message: response.error.errorMessage }));
  }
}

function* sendPhoneOtpHandler(action) {
  const { userAuth, updatePhone, userDetails } = yield select((store: any) => store);

  const { payload } = action;
  const data = {
    contactType: ContactDetail.PHONE,
    eventType: ContactEventType.PHONE_VERIFICATION,
    guid:
      updatePhone.contactType === PhoneContactType.VERIFIED || updatePhone.contactType === PhoneContactType.ADD
        ? updatePhone.guid
        : userDetails.guid,
    otvcType: OTVCType.SHORT,
    verificationCode: payload?.otp,
    locale: payload.resendFlag ? userDetails.vuser.locale : undefined
  };
  const headers = {
    access_token: userAuth.accessToken
  };

  const httpMethod = payload.resendFlag ? 'POST' : 'PUT';

  const sendOtvcUrl = replaceUserGuid(apiPaths.auth.sendOtvcUrl, userAuth.userGuid);
  const response = yield call(ajaxHandler, sendOtvcUrl, false, {
    method: httpMethod,
    data,
    headers,
    urlWithPlaceholder: apiPaths.auth.sendOtvcUrl
  });
  if (!response.error) {
    if (payload.resendFlag) {
      yield put(resendPhoneOtpSuccess(response));
      const type = 'SUCCESS';
      const message = 'forgotPassword.codeSendSuccess';
      yield put(showAlert({ type, message }));
    } else {
      yield put(changePhoneOtpSuccess(response));
      if (updatePhone.contactType === PhoneContactType.UNVERIFIED) {
        yield put(showAlert({ type: 'SUCCESS', message: 'updatePhone.verified_confirmation_message' }));
      } else if (updatePhone.contactType === PhoneContactType.ADD) {
        yield put(showAlert({ type: 'SUCCESS', message: 'updatePhone.added_confirmation_message' }));
      } else {
        yield put(deletePreviousPhone());
      }
    }
  } else {
    yield put(changePhoneOtpFailure(response));
  }
}

function* deleteSecondaryEmailHandler() {
  const { userAuth } = yield select(store => store);
  const data = {};
  const headers = {
    access_token: userAuth.accessToken
  };
  const secondaryEmailGuid = getContactValue({contacts:[userAuth.userContactsDetails]},ContactDetail.PRIMARY, ContactDetail.EMAIL, false, true);
  const sendEmailOtpURL = replaceUserGuid(`${apiPaths.emailUrl}/${secondaryEmailGuid}`, userAuth.userGuid);

  yield call(ajaxHandler, sendEmailOtpURL, false, {
    method: 'DELETE',
    data,
    headers,
    urlWithPlaceholder: apiPaths.emailUrl
  });
}

export default function* changeEmailWatchers() {
  yield takeLatest(changeUserEmail, changeEmailHandler);
  yield takeLatest(changeEmailOtp, sendEmailOtpHandler);
  yield takeLatest(changePhoneOtp, sendPhoneOtpHandler);
  yield takeLatest(changePrimaryEmail, changePrimaryEmailHandler);
}
