import { put, takeLatest, call, select } from 'redux-saga/effects';
import { GlobalConstants, ReasonCodes } from '../../../constants';
import { showAlert } from '../../../elements/shared';
import { ajaxHandler, apiPaths } from '../../../setup';
import { postChangePasswordSuccess, setAccessToken, setToken2Success } from '../../../auth/user-auth-reducer';
import { Utils } from '../../../utils';
import {
  forcePasswordReset,
  forcePasswordResetSuccess,
  forcePasswordResetFailure,
  resetSuccessFlags,
  forcePasswordResetUpdateAccessToken
} from './force-password-reset-reducer';

let xAuthCode = '';

function* forcePasswordResetHandler(action) {
  const { payload } = action;
  const { userAuth } = yield select((store: any) => store);
  const response = yield call(ajaxHandler, apiPaths.resetPassword.b2cResetPasswordUrl, true, {
    method: 'PUT',
    data: {
      credentials: {
        to: payload.newPassword
      }
    },
    headers: {
      access_token: userAuth.accessToken
    },
    urlWithPlaceholder: apiPaths.resetPassword.b2cResetPasswordUrl
  });
  if (!response.error) {
    xAuthCode = response.headers['x-auth-code'] || '';
    yield put(forcePasswordResetSuccess(response.data));
  } else {
    const type = 'ERROR';
    let message = response.error.errorMessage;
    if (response.error?.response?.data) {
      const errorData = response.error.response.data;

      if (
        Array.isArray(errorData.error_details) &&
        Boolean(errorData.error_details.length) &&
        errorData.error_details[0].error === ReasonCodes.CREDENTIAL_HISTORY
      ) {
        message = 'errors.resetPassword.noDuplicateNew';
      } else if (
        Array.isArray(errorData.error_details) &&
        Boolean(errorData.error_details.length) &&
        errorData.error_details[0].error === ReasonCodes.CREDENTIAL_DENYLIST
      ) {
        message = 'check.errorCodes.CREDENTIAL_DENYLIST';
      }
    }
    yield put(showAlert({ type, message }));

    yield put(forcePasswordResetFailure(response));
  }
}

function* forcePasswordResetUpdateAccessTokenHandler(action) {
  const tokenPayload = {
    grant_type: 'authorization_code',
    code: xAuthCode,
    context: '{"channel":"Web","subContext":"VMCP"}'
  };
  tokenPayload['software_statement_claimset.uebas[0].ueba_source'] = 'VBA';
  tokenPayload['software_statement_claimset.software_version'] = '2022.04.00';
  tokenPayload['software_statement_claimset.tenancy_context.product_code'] = 'PAS';
  tokenPayload['software_statement_claimset.oauth2_version'] = '1.0';
  tokenPayload['software_statement_claimset.tenancy_context.app_id'] = GlobalConstants.extAppId;
  const response = yield call(ajaxHandler, apiPaths.changePassword.b2cOauth, false, {
    data: Utils.encodeUsingUrlEncoding(tokenPayload),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    },
    method: 'POST',
    urlWithPlaceholder: apiPaths.changePassword.b2cOauth
  });
  if (!response.error) {
    yield put(setAccessToken(response.data.access_token));
    yield put(postChangePasswordSuccess(response.data));
    yield put(setToken2Success());
  } else {
    const type = 'ERROR';
    const message = 'errorCodes.technical_error_message';
    yield put(showAlert({ type, message }));
  }
  yield put(resetSuccessFlags());
}

export default function* watchForcePasswordReset() {
  yield takeLatest(forcePasswordReset, forcePasswordResetHandler);
  yield takeLatest(forcePasswordResetUpdateAccessToken, forcePasswordResetUpdateAccessTokenHandler);
}
