import EditPhoneNumber from './edit-phone/edit-phone';
import PhoneVerifyCode from './phone-verify-code/phone-verify-code';
//import { Profile, UpdateEmail, UpdatePhone, CodeVerify} from './index';
import DeleteProfile from './delete-profile/delete-profile';
import ChangePassword from './change-password/change-password';
import { ChangeEmail, VerifyCode } from './change-email';
import SupportedCarriers from './supported-carriers/supported-carriers';
import { Profile, UpdatePhone, CodeVerify, MessageLanguage } from './index';
import PrivateRoutes from '../../private-routes';

const profileRoutes = [
  {
    path: 'profile',

    children: [
      {
        element: (
          <PrivateRoutes>
            <Profile />
          </PrivateRoutes>
        ),
        index: true
      },
      {
        path: 'change-email',
        element: (
          <PrivateRoutes>
            <ChangeEmail />
          </PrivateRoutes>
        )
      },
      {
        path: 'change-email/verify',
        element: (
          <PrivateRoutes>
            <VerifyCode />
          </PrivateRoutes>
        )
      },
      {
        path: 'message-language',
        element: (
          <PrivateRoutes>
            <MessageLanguage />
          </PrivateRoutes>
        )
      },
      {
        path: 'edit-phone',
        element: (
          <PrivateRoutes>
            <EditPhoneNumber />
          </PrivateRoutes>
        )
      },
      {
        path: 'update-phone',
        element: (
          <PrivateRoutes>
            <UpdatePhone />
          </PrivateRoutes>
        )
      },
      {
        path: 'phone/verify-code',
        element: (
          <PrivateRoutes>
            <PhoneVerifyCode />
          </PrivateRoutes>
        )
      },
      {
        path: 'change-password',
        element: (
          <PrivateRoutes>
            <ChangePassword />
          </PrivateRoutes>
        )
      },
      {
        path: 'delete',
        element: <DeleteProfile />
      }
    ]
  },
  {
    path: 'supported-carriers', //https://issues.trusted.visa.com/browse/MOBENB-20666
    element: (
        <SupportedCarriers />
    )
  }
];

export default profileRoutes;
