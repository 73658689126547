import { createSlice } from '@reduxjs/toolkit';

interface ChangeEmail {
  guid: string;
  newEmail: string;
  currentEmail: string;
  changeEmailRequestSuccess: boolean;
  changeEmailRequestError: any;
  otvcError: any;
  otvcSuccess: boolean;
  otvcPhoneSuccess: boolean;
  otvcPhoneError: boolean;
  newPrimaryEmail: string;
}

const initialState: ChangeEmail = {
  guid: '',
  newEmail: '',
  currentEmail: '',
  changeEmailRequestSuccess: false,
  changeEmailRequestError: null,
  otvcSuccess: false,
  otvcError: null,
  otvcPhoneSuccess: false,
  otvcPhoneError: false,
  newPrimaryEmail: ''
};

const changeEmailReducer = createSlice({
  name: 'change-email',
  initialState,
  reducers: {
    changeUserEmail: (state: any, action: any) => {
      state.newEmail = action.payload.newEmail;
      return state;
    },
    changeUserEmailSuccess: (state: any, action: any) => {
      state.changeEmailRequestSuccess = true;
      state.changeEmailRequestError = null;
      state.newPrimaryEmail = action.payload.newPrimaryEmail;

      return state;
    },
    changeUserEmailFailure: (state: any, action: any) => {
      state.changeEmailRequestSuccess = false;
      state.changeEmailRequestError = action.payload;

      return state;
    },

    //  otvc email
    changeEmailOtp: (state: any, action: any) => {
      return state;
    },
    changeEmailOtpSuccess: (state: any, action: any) => {
      state.otvcSuccess = true;
      state.otvcError = null;
      return state;
    },
    changeEmailOtpFailure: (state: any, action: any) => {
      state.otvcSuccess = false;
      state.otvcError = action.payload;
      return state;
    },

    //  otvc phone
    changePhoneOtp: (state: any, action: any) => {
      return state;
    },
    changePhoneOtpSuccess: (state: any, action: any) => {
      state.otvcPhoneSuccess = true;
      state.otvcPhoneError = null;
      return state;
    },
    changePhoneOtpFailure: (state: any, action: any) => {
      state.otvcPhoneSuccess = false;
      state.otvcPhoneError = action.payload;
      return state;
    },
    resendPhoneOtpSuccess: (state: any, action: any) => {
      return state;
    },
    resetChangePhoneFlags: (state: any) => {
      state.otvcPhoneSuccess = false;
      return state;
    },
    changePrimaryEmail: (state: any) => {
      return state;
    },
    changePrimaryEmailSuccess: (state: any) => {
      return state;
    },
    changePrimaryEmailFailure: (state: any) => {
      return state;
    }
  }
});

export const {
  changeUserEmail,
  changeUserEmailSuccess,
  changeUserEmailFailure,
  changeEmailOtp,
  changeEmailOtpSuccess,
  changeEmailOtpFailure,
  changePhoneOtp,
  changePhoneOtpSuccess,
  changePhoneOtpFailure,
  resendPhoneOtpSuccess,
  resetChangePhoneFlags,
  changePrimaryEmail,
  changePrimaryEmailSuccess,
  changePrimaryEmailFailure
} = changeEmailReducer.actions;

export default changeEmailReducer.reducer;
