import React, { useEffect, useRef } from 'react';
import { Col, Grid, Row, Typography, Card, CardContent, ButtonIcon, Icon } from '@visa/vds';
import { useDispatch, useSelector } from 'react-redux';
import { t } from 'i18next';
import { NavigationType, useNavigationType } from 'react-router-dom';
import { GlobalAlert } from '../index';
import { Utils } from '../../../utils';
import styles from './generic-panel.module.scss';

type ForgotPasswordProps = {
  postLogin?: boolean;
  backIcon?: boolean;
  pageTitle?: string;
  pageSubTitle?: string | React.ReactNode;
  panelMainText?: string;
  actionBtns?: React.ReactNode;
  panelSubText?: React.ReactNode;
  panelCardSubText?: string;
  bodyComponent?: React.ReactNode;
  containerClass?: string;
  handleIconClick?: (e: any) => void;
};

const GenericPanelComponent: React.FC<ForgotPasswordProps> = props => {
  const {
    pageTitle = '',
    pageSubTitle = '',
    panelSubText = '',
    actionBtns = null,
    bodyComponent = null,
    backIcon = false,
    panelMainText = '',
    panelCardSubText = '',
    containerClass = '',
    handleIconClick = f => f
  } = props;

  const aref = useRef();
  const dispatch = useDispatch();
  const navigationType = useNavigationType();
  const alert = useSelector((state: any) => state.alert);

  const containerClassStyle = () => {
    const defaultClass = styles['container'];
    return `${defaultClass} ${containerClass || ''}`;
  };

  const handleBackIcon = e => {
    Utils.hideServerAlert(dispatch);
    handleIconClick(e);
  };

  useEffect(() => {
    if (navigationType != NavigationType.Pop) {
      Utils.focusToH1(); //to focus to H1 during navigation between pages
    }
  }, [pageTitle]);

  const Body = () => (
    <Grid className={containerClassStyle()}>
      <Row noGutter className={styles['alert-row']}>
        <Col textAlign="center">{!alert.formMode && <GlobalAlert />}</Col>
      </Row>
      {pageTitle && backIcon ? (
        <Row className={styles['first-row']}>
          <Col lg={1} xs={1} md={1} sm={1} className={styles['back-icon']}>
            <ButtonIcon onClick={e => handleBackIcon(e)} iconType="light" ariaLabel={t('app.back')}>
              <Icon name="arrow-back" resolution="low" />
            </ButtonIcon>
          </Col>
          <Col valignCenter>
            <Typography tag="h1" className={`page-title ${styles['page-title-flex']}`}>
              {pageTitle}
            </Typography>
          </Col>
          <Col lg={1} xs={1} md={1} sm={1}>
            &nbsp;
          </Col>
        </Row>
      ) : pageTitle ? (
        pageSubTitle ? ( //https://jira.trusted.visa.com/browse/VMCP-14670
          <Row noGutter>
            <Col>
              <Typography tag="h1" className={'page-title'}>
                {pageTitle}
              </Typography>

              {pageSubTitle}
            </Col>
          </Row>
        ) : (
          <Row noGutter className={styles['first-row']}>
            <Col>
              <Typography tag="h1" className={'page-title'}>
                {pageTitle}
              </Typography>
            </Col>
          </Row>
        )
      ) : null}

      <Col noGutter>
        <Card type="content" className={styles['card']} id="generic-main" data-id="generic-main">
          <CardContent className={styles['card-content']}>
            {panelMainText && (
              <Row className={styles['inner-row']}>
                <Col>
                  <Typography tag="h2" className={`panel-text ${styles['panel-main-text']}`}>
                    {panelMainText}
                  </Typography>
                </Col>
              </Row>
            )}
            {panelCardSubText && (
              <Row>
                <Col>
                  <Typography className={styles['panel-card-sub-text']}>{panelCardSubText}</Typography>
                </Col>
              </Row>
            )}
            {panelSubText && (
              <Row>
                <Col>
                  <Typography className={`panel-text ${styles['panel-sub-text']}`}>{panelSubText}</Typography>
                </Col>
              </Row>
            )}
            {bodyComponent && <Row>{bodyComponent}</Row>}
            {actionBtns && <Row>{actionBtns}</Row>}
          </CardContent>
        </Card>
      </Col>
    </Grid>
  );

  return <>{Body()}</>;
};

export default GenericPanelComponent;
