import ForgotPassword from './forgot-password';
import VerifyCode from './forgot-password-otp/forgot-password-otp';
import ResetPassword from './reset-password/reset-password';
// import { ForgotPassword, CodeVerify, ResetPassword } from './index'

const forgotPasswordRoutes = [
  {
    path: 'forgot-password',
    children: [
      {
        path: '',
        element: <ForgotPassword />
      },
      {
        path: 'verify',
        element: <VerifyCode />
      },
      {
        path: 'reset-password',
        element: <ResetPassword />
      }
    ]
  }
];

export default forgotPasswordRoutes;
