import { put, takeLatest, call, select } from 'redux-saga/effects';
import { ajaxHandler, apiPaths } from '../../../setup';
import { Utils } from '../../../utils';
import { setAccessToken, setEmailGuid } from '../../../auth/user-auth-reducer';
import { replaceUserGuid } from '../../../utils/utils';

import { setLastLoginTime } from '../../../auth/user-auth-reducer';
import { showErrorModal } from '../../../elements/shared';
import { RedirectRoutes } from '../../../constants';
import {
  postVerifyTwoFactor,
  postVerifyTwoFactorSuccess,
  postVerifyTwoFactorFailure,
  getContactsVerified,
  getContactsVerifiedSuccess,
  getContactsVerifiedFailure,
  putOtpVerification,
  putOtpVerificationSuccess,
  putOtpVerificationFailure,
  resetSuccessFlags
} from './email-verify-reducer-slice';

// contactas verified api handler
function* getContactsVerifiedHandler(action) {
  const { payload } = action;
  const signin = yield select((state: any) => state.signin);
  const { userAuth } = yield select((store: any) => store);
  const headers = {
    access_token: userAuth.accessToken
  };
  // console.log({apiPaths});
  const getContactsVerifiedURL = replaceUserGuid(apiPaths.auth.contactsVerified, userAuth.userGuid);

  const response = yield call(ajaxHandler, `${getContactsVerifiedURL}?contacts=${payload.contacts_verified}`, false, {
    headers,
    urlWithPlaceholder: apiPaths.auth.contactsVerified
  });
  if (!response.error) {
    yield put(getContactsVerifiedSuccess(response.data));
    yield put(setEmailGuid(response.data));
  } else {
    yield put(resetSuccessFlags());
    yield put(getContactsVerifiedFailure(response.error.response));
    if (!response.error.errorHandled) {
      const message = 'errorCodes.technical_error_message';
      yield put(showErrorModal({ message, redirectTo: RedirectRoutes.login }));
    }
  }
}

/// otvc api handler
function* putOtpVerificationHandler(action) {
  const signin = yield select((state: any) => state.signin);
  const { userAuth } = yield select((store: any) => store);
  const { payload } = action;
  const { otvcPayload } = payload.getPayload;
  otvcPayload['guid'] = userAuth.emailGuid;
  const b2cPayload = {
    data: {
      contact: {
        contactValue: userAuth.userContactsDetails.contactValue,
        guid: userAuth.userContactsDetails.guid,
        contactType: userAuth.userContactsDetails.contactType
      }
    },
    grant_type: 'authorization_probe_reissuance',
    probes_ref: [
      {
        data: {
          type: 'OTP'
        }
      }
    ]
  };

  const putOtpVerificationURL = replaceUserGuid(
    apiPaths.auth.b2cSendOtvcUrl,
    userAuth.userGuid
  );
  const response = yield call(ajaxHandler, putOtpVerificationURL, false, {
    method: 'post',
    data: b2cPayload,
    headers: { Authorization: 'Bearer ' + userAuth.accessToken },
    urlWithPlaceholder: apiPaths.auth.b2cSendOtvcUrl
  });

  if (!response.error) {
    yield put(putOtpVerificationSuccess({ ...response.data, flag: action.payload.flag ? true : false }));
  } else {
    yield put(resetSuccessFlags());
    yield put(putOtpVerificationFailure(response.error));
  }
}

function* postVerifyTwoFactorHandler(action) {
  const { payload, navigate } = action.payload;
  const signin = yield select((state: any) => state.signin);
  const { userAuth } = yield select((store: any) => store);
  const { twoFactAuthPayload } = payload;
  twoFactAuthPayload['2faContextKey'] = userAuth.emailGuid;
  const data = {
    grant_type: 'authorization_probe',
    probes_ref: [
      {
        data: {
          type: 'OTP',
          value: twoFactAuthPayload['2faValue']
        }
      }
    ]
  };

  const response = yield call(
    ajaxHandler,
    `${apiPaths.auth.b2cVerifyOtvcForgotCred}`,
    false,
    {
      method: 'POST',
      data: data,
      headers: { Authorization: 'Bearer ' + userAuth.accessToken },
      urlWithPlaceholder: apiPaths.auth.b2cVerifyOtvcForgotCred
    }
  );

  if (!response.error) {
    yield put(setLastLoginTime(response));
    yield put(setAccessToken(response.data.access_token));
    if (response.data?.scope?.includes('/credentials/reset')) {
      navigate(RedirectRoutes.forcePasswordReset);
    } else {
      yield put(postVerifyTwoFactorSuccess(response.data));
    }
  } else {
    yield put(resetSuccessFlags());
    yield put(postVerifyTwoFactorFailure(response.error));
  }
}

export default function* watchEmailVerify() {
  yield takeLatest(postVerifyTwoFactor, postVerifyTwoFactorHandler);
  yield takeLatest(getContactsVerified, getContactsVerifiedHandler);
  yield takeLatest(putOtpVerification, putOtpVerificationHandler);
}
