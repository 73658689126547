import { createSlice } from '@reduxjs/toolkit';
import React from 'react';

type TYPES = 'ERROR' | 'SUCCESS' | 'WARNING' | 'INFORMATION';
interface StickyAlertInterface {
  type: TYPES;
  message: string | React.ReactNode;
  closeIcon?: boolean;
}

interface ShowStickyActionInterface {
  payload: {
    type: TYPES;
    message: string | React.ReactNode;
    closeIcon?: boolean;
  };
}

const initialState: StickyAlertInterface = {
  type: 'INFORMATION',
  message: '',
  closeIcon: true
};

const stickyAlertReducer = createSlice({
  name: 'stickyAlert',
  initialState,
  reducers: {
    showStickyAlert: (state: StickyAlertInterface, action: ShowStickyActionInterface) => {
      state.type = action.payload.type;
      state.message = action.payload.message;
      state.closeIcon = action.payload.closeIcon;
      return state;
    },
    clearStickyAlert: (state: StickyAlertInterface) => {
      state = initialState;
      return state;
    }
  }
});

export const { showStickyAlert, clearStickyAlert } = stickyAlertReducer.actions;

export default stickyAlertReducer.reducer;
