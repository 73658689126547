import { createSlice } from '@reduxjs/toolkit';
import { resetAppData } from '../../../app/app-reducer';
import { GlobalConstants } from '../../../constants';
import { Utils} from '../../../utils';

const VuserInitialData = {
  firstName: '',
  lastName: '',
  countryCode: '',
  languageCode: '',
  defaultTimezone: '',
  userType: '',
  primaryContactType: '',
  locale: '',
  clientWalletAccountId: '',
  contacts: []
};

type Contact = {
  contactType: string;
  contactValue: string;
  contactPreference: string;
  loginInd: boolean;
  verificationStatus: string;
  guid: string;
  notificationInd: boolean;
};

type Vuser = {
  firstName: string;
  lastName: string;
  countryCode: string;
  languageCode: string;
  defaultTimezone: string;
  userType: string;
  primaryContactType: string;
  locale: string;
  clientWalletAccountId: string;
  contacts: Contact[];
};

interface EnrollIdvVerify {
  successResponse: boolean;
  response: any;
  idvSuccess: boolean;
  vuser: Vuser;
  unverifiedSuccess: Date;
}
const initialState: EnrollIdvVerify = {
  successResponse: true,
  response: {},
  //contacts verified response object
  idvSuccess: false,
  vuser: VuserInitialData,
  unverifiedSuccess: new Date()
};

const enrollIdvVerifySlice = createSlice({
  name: 'idvVerify',
  initialState,
  reducers: {
    /// contacts unverified
    getContactUnverified: (state: EnrollIdvVerify, action) => {
      return state;
    },
    getContactUnverifiedSuccess: (state: EnrollIdvVerify, action) => {
      const { vuser } = action.payload;
      return {
        ...state,
        vuser,
        unverifiedSuccess: new Date()
      };
    },
    getContactUnverifiedFailure: (state: EnrollIdvVerify, action) => {
      return state;
    },

    //idv reducers
    postIdentityVerify: (state: EnrollIdvVerify, action) => {
      return state;
    },
    postIdentityVerifySuccess: (state: EnrollIdvVerify, action) => {
      return {
        ...state,
        idvSuccess: true,
        response: {
          ...action.payload
        }
      };
    },
    postIdentityVerifyFailure: (state: EnrollIdvVerify, action) => {
      return state;
    },

    resetSuccessFlags: (state: EnrollIdvVerify) => {
      state.idvSuccess = false;
      return state;
    },

    postOtvc: (state: EnrollIdvVerify, action: any) => {
      return state;
    },
    postOtvcSuccess: (state: EnrollIdvVerify, action: any) => {
      return {
        ...state,
        vuser:{
          ...state.vuser,
          contacts: action.payload.data.contacts
        }
      }
    },
    postOtvcFailure: (state: EnrollIdvVerify, action: any) => {
      return state;
    }
  },
  extraReducers: builder => {
    builder.addCase(resetAppData, () => {
      return initialState;
    });
  }
});
export const {
  postIdentityVerify,
  postIdentityVerifySuccess,
  postIdentityVerifyFailure,
  getContactUnverified,
  getContactUnverifiedSuccess,
  getContactUnverifiedFailure,
  resetSuccessFlags,
  postOtvc,
  postOtvcSuccess,
  postOtvcFailure
} = enrollIdvVerifySlice.actions;

export default enrollIdvVerifySlice.reducer;
