import React from 'react';
import { ListboxItem } from '@visa/vds';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CardTileComponent from '../card-tile/card-tile';
import styles from './card-list.module.scss';

type CardsListProps = {
  handleKeyDown: (e, index) => void;
  handleSelect: (e, card) => void;
  selectedOption: number;
};

const CardList: React.FC<CardsListProps> = props => {
  const { handleSelect, handleKeyDown, selectedOption } = props;
  const { userDetails } = useSelector((state: any) => state);
  const { selectedCard } = userDetails;
  const cards = userDetails?.paymentInstruments;
  const body = (
    <>
      {cards?.map((card, index) => {
        return (
          <React.Fragment key={card.accountNumberLastFourDigits}>
            <ListboxItem
              role="option"
              listItemValue={card.cardNickname}
              className={styles['listitem']}
              id={card.accountNumberLastFourDigits}
              tabIndex={0}
              onKeyDown={e => handleKeyDown(e, card)}
            >
              <Link to="" onClick={e => handleSelect(e, card)}>
                <CardTileComponent
                  className={styles['cardtile-custom-row']}
                  card={card}
                  leftIconName="checkmark"
                  showSelected={card.accountNumberLastFourDigits === selectedCard?.accountNumberLastFourDigits}
                />
              </Link>
            </ListboxItem>
          </React.Fragment>
        );
      })}
    </>
  );
  return body;
};

export default CardList;
