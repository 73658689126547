import { AllRegex } from '../constants';

const passwordContainsUsernameCheck = (value, context) => {
  if (value && context.parent?.userName) {
    return !value.toLowerCase().includes(context.parent?.userName.toLowerCase());
  }
  return true;
};

const caseInsensitiveEqualCheck = (value, context) => {
  if (value && context.parent?.userName) {
    return value.toLowerCase() !== context.parent?.userName.toLowerCase();
  }
  return true;
};
const userNameContainsPasswordCheck = (value, context) => {
  if (value && context.parent?.password) {
    return !(value.toLowerCase() === context.parent?.password.toLowerCase());
  }
  return true;
};

export const containsSpecialCharacters = value => {
  return AllRegex.username.test(value);
};

const newPassNotEqaulToCurrentCheck = context => {
  const formData = context.parent;
  if (formData && formData.newPassword && formData.currentPassword) {
    return formData.newPassword === formData.currentPassword;
  }
  return false;
};

export const passwordValidator = (value: any, context: any) => {
  const { upperCaseRegex, numberRegex, lowerCaseRegex, spaceRegex, checkRepeatedCharsRegex, onlyValidCharacters, passwordSpecialCharacterRegex} =
    AllRegex;
  const hasUpperCase = upperCaseRegex.test(value);
  const hasNumber = numberRegex.test(value);
  const hasLowerCase = lowerCaseRegex.test(value);
  const hasSpace = spaceRegex.test(value);
  const hasSpecialCharacter = passwordSpecialCharacterRegex.test(value);
  const checkValidCharacters = onlyValidCharacters.test(value);
  const checkRepeatedChars = checkRepeatedCharsRegex.test(value);
  const doesNothasUserName = passwordContainsUsernameCheck(value, context);
  const notEqualToUsername = caseInsensitiveEqualCheck(value, context);
  const checkRepeatedSequence = isConsecutiveCharacter(value);
  const newPassNotEqaulToCurrent = newPassNotEqaulToCurrentCheck(context);
  // const hasSymbole = /["!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~"]/.test(value);

  if (!hasUpperCase) {
    return context.createError({ message: 'createAccount.formValidate.error.password.alphabet' });
  }
  if (!hasLowerCase) {
    return context.createError({ message: 'createAccount.formValidate.error.password.alphabet' });
  }
  if (!hasNumber) {
    return context.createError({ message: 'createAccount.formValidate.error.password.number' });
  }
  if (!hasSpace) {
    return context.createError({ message: 'createAccount.formValidate.error.password.spaces' });
  }
  if (!hasSpecialCharacter) {
    return context.createError({ message: 'createAccount.formValidate.error.password.specialCharacter' });
  }

  if (!notEqualToUsername) {
    return context.createError({ message: 'createAccount.formValidate.error.password.username' });
  }
  if (!checkRepeatedSequence) {
    return context.createError({ message: 'createAccount.formValidate.error.password.sequence' });
  }
  if (checkRepeatedChars) {
    return context.createError({ message: 'createAccount.formValidate.error.password.noRepeat' });
  }
  if (!doesNothasUserName) {
    return context.createError({ message: 'createAccount.formValidate.error.password.containUN' });
  }
  if (!checkValidCharacters) {
    return context.createError({ message: 'createAccount.formValidate.error.password.onlyValidChars' });
  }
  if (newPassNotEqaulToCurrent) {
    return context.createError({ message: 'updatePassword.validation.notSameAsCurrent' });
  }
  return true;
};

export const userNameValidator = (value: any, context: any) => {
  const doesNotHasSpace = /^\S*$/.test(value);
  const doesNothasPassword = userNameContainsPasswordCheck(value, context);
  const hasSpecailCharacters = containsSpecialCharacters(value);
  const hasOnlyNumber = /^\d+$/.test(value);
  if (!hasSpecailCharacters) {
    return context.createError({ message: 'createAccount.formValidate.error.username.format' });
  }
  if (!doesNotHasSpace) {
    return context.createError({ message: 'createAccount.formValidate.error.password.spaces' });
  }
  if (!doesNothasPassword) {
    return context.createError({ message: 'createAccount.formValidate.error.username.containPassword' });
  }
  if (hasOnlyNumber) {
    return context.createError({ message: 'createAccount.formValidate.error.username.alphabet' });
  }
  return true;
};

export const cardExpiration = (value, context?) => {
  // validate on submit and revalidate on blur
  const errorMessage = context ? context.createError({ message: 'add_edit_card.formValidate.expirationDate' }) : false;
  if (!value) {
    return errorMessage;
  }

  const today = new Date();
  const monthToday = today.getMonth() + 1;
  const yearToday = today.getFullYear().toString().substr(-2);

  const [expMonth, expYear] = value.split('/');

  if (Number(expMonth) > 12) {
    return errorMessage;
  }
  if (Number(expMonth) < 1) {
    return errorMessage;
  }

  if (Number(expYear) < Number(yearToday)) {
    return errorMessage;
  } else if (Number(expMonth) < monthToday && Number(expYear) <= Number(yearToday)) {
    return errorMessage;
  }

  return true;
};

export const yearValidator = (value, dispatch, setError, showAlert, clearAlert, clearErrors) => {
  // we might need this in future its in testing for now
  const expYear = value.includes('/') ? value.split('/')[1] : value[value.length - 1];
  const today = new Date();
  const yearToday = today.getFullYear().toString().substr(-2);
  if (Number(expYear) < Number(yearToday)) {
    setError('expiration', { message: 'add_edit_card.formValidate.expirationDate' });
  } else if (Number(expYear) >= Number(yearToday)) {
    // dispatch(clearAlert())
    clearErrors('expiration');
  }
};

export const postalCodeValidator = (value: any, context: any) => {
  const validPostalCode = AllRegex.postalCodeFormat.test(value);
  if (value && !validPostalCode) {
    return context.createError({ message: 'add_edit_card.formValidate.invalidPostalCodeLAC' });
  }
  return true;
};

export const cardNickNameValidator = (value: any, context?: any) => {
  //since the cardNickNameFormat regex has g tag, the lastIndex has to be reset before executing the test method on the same object everytime
  AllRegex.cardNickNameFormat.lastIndex = 0;
  const validCardNickName = AllRegex.cardNickNameFormat.test(value);
  if (value && !validCardNickName) {
    return context ? context.createError({ message: 'add_edit_card.formValidate.invalidCardNickName' }) : false;
  }
  return true;
};

const isConsecutiveCharacter = function (value: any) {
  const count = 3;
  return !(checkConsecutiveNumber(value, count) || checkConsecutiveLetter(value, count));
};

function checkConsecutiveNumber(string: any, count: any) {
  // returns true if it detects count (or more) consecutive numbers in a row
  let seqStr = '',
    i;

  if (!string || string.length < count) {
    return false;
  }

  for (i = 0; i <= string.length; i++) {
    if (0 !== parseInt(string[i]) && !parseInt(string[i])) {
      //skip letters
      seqStr = '';
      continue;
    }

    if (0 == i) {
      seqStr += string[i];
      continue;
    }

    if (parseInt(string[i - 1]) == parseInt(string[i]) - 1) {
      seqStr += string[i];
    } else if (parseInt(string[i + 1]) == parseInt(string[i]) + 1) {
      seqStr = '';
      seqStr += string[i];
    } else {
      seqStr = '';
    }

    if (seqStr.length >= count) {
      return true;
    }
  }
  return false;
}

function checkConsecutiveLetter(string: any, count: any) {
  // returns true if it detects count (or more) consecutive letters in a row
  let i,
    seqStr = '';
  const letterArray = [
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z'
  ];

  if (!string || string.length < count) {
    return false;
  }

  //convert string to lower case
  string = string.toLowerCase();

  for (i = 0; i <= string.length; i++) {
    if (letterArray.indexOf(string[i]) === -1) {
      //skip characters that is not an alphabet
      seqStr = '';
      continue;
    }

    if (0 == i) {
      seqStr += string[i];
      continue;
    }

    if (letterArray.indexOf(string[i - 1]) == letterArray.indexOf(string[i]) - 1) {
      seqStr += string[i];
    } else if (letterArray.indexOf(string[i + 1]) == letterArray.indexOf(string[i]) + 1) {
      seqStr = '';
      seqStr += string[i];
    } else {
      seqStr = '';
    }

    if (seqStr.length >= count) {
      return true;
    }
  }
  return false;
}
