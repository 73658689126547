import { call, put, select, takeLatest } from 'redux-saga/effects';
import { ReasonCodes, RedirectRoutes } from '../../../constants';
import { showAlert } from '../../../elements/shared';
import { ajaxHandler, apiPaths } from '../../../setup';
import { Utils } from '../../../utils';
import { postChangePasswordSuccess, setAccessToken } from '../../../auth/user-auth-reducer';
import { GlobalConstants } from '../../../constants';
import {
  changeUserPassword,
  setChangePasswordSuccess,
  setTokenSuccess,
  updateAccessToken,
  resetSuccessFlags
} from './change-password-reducer';

let xAuthCode = '';

function* changePasswordHandler(action) {
  const { payload } = action;
  const signin = yield select(state => state.signin);
  const { userAuth } = yield select((store: any) => store);
  const credentialType = 'PASSWORD';
  const toType = 'PASSWORD';

  const response = yield call(ajaxHandler, apiPaths.changePassword.changePasswordUrl, false, {
    method: 'POST',
    data: {
      credentials: {
        from_type: credentialType,
        from: payload.currentPassword,
        to_type: toType,
        to: payload.newPassword
      }
    },
    headers: {
      Authorization: 'Bearer ' + userAuth.accessToken
    },
    urlWithPlaceholder: apiPaths.changePassword.changePasswordUrl
  });
  if (!response.error) {
    xAuthCode = response.headers['x-auth-code'] || '';
    yield put(setChangePasswordSuccess());
  } else {
    const type = 'ERROR';
    let message = 'errorCodes.technical_error_message';
    if (response.error?.response?.data) {
      const errorData = response.error.response.data;
      if (
        errorData.error_details &&
        Array.isArray(errorData.error_details) &&
        errorData.error_details[0].error === ReasonCodes.CREDENTIAL_HISTORY
      ) {
        message = 'errors.resetPassword.noDuplicateNew';
      }
    }
    yield put(showAlert({ type, message }));
  }
}

function* updateAccessTokenHandler(action) {
  const { payload } = action;
  const signin = yield select(state => state.signin);
  const { userAuth } = yield select((store: any) => store);
  const tokenPayload = {
    grant_type: 'authorization_code',
    code: xAuthCode,
    context: '{"channel":"Web","subContext":"VMCP"}'
  };
  tokenPayload['software_statement_claimset.uebas[0].ueba_source'] = 'VBA';
  tokenPayload['software_statement_claimset.software_version']="2022.04.00";
  tokenPayload['software_statement_claimset.tenancy_context.product_code'] = 'PAS';
  tokenPayload['software_statement_claimset.oauth2_version'] = '1.0';
  tokenPayload['software_statement_claimset.tenancy_context.app_id'] = GlobalConstants.extAppId;
  const response = yield call(ajaxHandler, apiPaths.changePassword.b2cOauth, false, {
    data: Utils.encodeUsingUrlEncoding(tokenPayload),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    },
    method: 'POST',
    urlWithPlaceholder:  apiPaths.changePassword.b2cOauth
  });
  if (!response.error) {
    const type = 'SUCCESS';
    const message = 'updatePassword.success';
    yield put(setAccessToken(response.data.access_token));
    yield put(setTokenSuccess(response.data));
    yield put(postChangePasswordSuccess(response.data))
    if (action.payload.navigate) {
      action.payload.navigate(RedirectRoutes.profile);
    }
    yield put(showAlert({ type, message }));
  } else {
    const type = 'ERROR';
    const message = 'errorCodes.technical_error_message';
    yield put(showAlert({ type, message }));
  }
  yield put(resetSuccessFlags());
}

export default function* watchChangePassword() {
  yield takeLatest(changeUserPassword, changePasswordHandler);
  yield takeLatest(updateAccessToken, updateAccessTokenHandler);
}
