import { createSlice } from '@reduxjs/toolkit';
import { resetAppData } from '../../../app/app-reducer';

const VuserInitialData = {
  firstName: '',
  lastName: '',
  countryCode: '',
  languageCode: '',
  defaultTimezone: '',
  userType: '',
  primaryContactType: '',
  locale: '',
  clientWalletAccountId: '',
  contacts: []
};

type Contact = {
  contactType: string;
  contactValue: string;
  contactPreference: string;
  loginInd: boolean;
  verificationStatus: string;
  guid: string;
  notificationInd: boolean;
};

type Vuser = {
  firstName: string;
  lastName: string;
  countryCode: string;
  languageCode: string;
  defaultTimezone: string;
  userType: string;
  primaryContactType: string;
  locale: string;
  clientWalletAccountId: string;
  contacts: Contact[];
};
interface IdvVerify {
  successResponse: boolean;
  response: any;
  idvSuccess: boolean;
  otvcSuccess: boolean;
  vuser: Vuser;
  idvResponseError: any;
  otvcResponseError: any;
  contactsUnverifiedResponseError: any;
}
const initialState: IdvVerify = {
  successResponse: true,
  response: {},
  idvSuccess: false,
  otvcSuccess: false,
  vuser: VuserInitialData,
  idvResponseError: {},
  otvcResponseError: {},
  contactsUnverifiedResponseError: {}
};

const idvVerifyReducer = createSlice({
  name: 'idvVerify',
  initialState,
  reducers: {
    /// contacts unverified
    getContactsUnverified: (state: IdvVerify, action) => {
      return state;
    },
    getContactsUnverifiedSuccess: (state: IdvVerify, action) => {
      const { vuser } = action.payload;
      return {
        ...state,
        vuser
      };
    },
    getContactsUnverifiedFailure: (state: IdvVerify, action) => {
      return {
        ...state,
        contactsUnverifiedResponseError: action.payload.response.data
      };
    },

    //idv reducers
    postIdentityVerification: (state: IdvVerify, action) => {
      return state;
    },
    postIdentityVerificationSuccess: (state: IdvVerify, action) => {
      return {
        ...state,
        idvSuccess: true,
        response: {
          ...action.payload.data
        }
      };
    },
    postIdentityVerificationFailure: (state: IdvVerify, action) => {
      return {
        ...state,
        idvResponseError: action.payload.response.data
      };
    },

    postSendOtvc: (state: IdvVerify, action) => {
      return state;
    },
    postSendOtvcSuccess: (state: IdvVerify, action) => {
      return {
        ...state,
        otvcSuccess: true
      };
    },
    postSendOtvcFailure: (state: IdvVerify, action) => {
      return {
        ...state,
        otvcResponseError: action.payload.response.data.errorResponse
      };
    },
    resetSuccessFlags: (state: IdvVerify) => {
      state.idvSuccess = false;
      state.otvcSuccess = false;
      return state;
    }
  },
  extraReducers: builder => {
    builder.addCase(resetAppData, () => {
      return initialState;
    });
  }
});
export const {
  postIdentityVerification,
  postIdentityVerificationSuccess,
  postIdentityVerificationFailure,
  getContactsUnverified,
  getContactsUnverifiedSuccess,
  getContactsUnverifiedFailure,
  postSendOtvc,
  postSendOtvcSuccess,
  postSendOtvcFailure,
  resetSuccessFlags
} = idvVerifyReducer.actions;

export default idvVerifyReducer.reducer;
