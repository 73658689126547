import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { GenericPanelComponent } from '../../../elements/shared';
import useTranslator from '../../../hooks/translation-hook';
import { SelectWrapper, ButtonWrapper, FormWrapper } from '../../../elements/core-ui';
import { RedirectRoutes } from '../../../constants';
import { useTitle } from '../../../hooks/use-document-title';
import { getUserDetails } from '../../home/home-reducer';
import { putMessageLanguage, resetFlags } from './message-language-reducer-slice';

const MessageLanguage: React.FC = () => {
  const t = useTranslator();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { checkEligibility, userDetails, messageLanguage, userAuth } = useSelector((store: any) => store);
  const defaultValues = userDetails?.vuser?.locale;
  const languages = checkEligibility.countryConfiguration?.supportedLocales?.map(language => ({
    key: language.locale,
    label: language.name,
    value: language.locale
  }));

  const methods = useForm({
    mode: 'onSubmit'
  });

  const submit = (data: any) => {
    const payload = {
      locale: data.messageLanguage
    };
    const config = {
      t,
      payload,
      navigate
    };
    dispatch(putMessageLanguage(config));
  };

  const handleCancel = () => {
    navigate(RedirectRoutes.profile);
  };

  useEffect(() => {
    if (messageLanguage.updateMessageLanguageSuccess && userAuth?.userGuid && userAuth?.accessToken) {
      dispatch(getUserDetails());
      navigate(RedirectRoutes.profile);
      dispatch(resetFlags());
    }
  }, [messageLanguage.updateMessageLanguageSuccess]);

  useTitle('app.title.changeMessageLanguage');

  const bodyComponent = (
    <>
      <FormWrapper onSubmit={methods.handleSubmit(submit)} noValidate>
        <SelectWrapper
          id="messageLanguage"
          methods={methods}
          label={t('messageLanguage.language')}
          name={'messageLanguage'}
          selectList={languages}
          defaultValue={defaultValues}
          required={true}
        />
        <ButtonWrapper
          colorScheme={'primary'}
          type="submit"
          tag="button"
          isFullWidth={true}
          ariaLabel={t('messageLanguage.update')}
          label={t('messageLanguage.update')}
        />
        <ButtonWrapper
          isFullWidth={true}
          colorScheme="tertiary"
          ariaLabel={t('messageLanguage.cancel')}
          label={t('messageLanguage.cancel')}
          handleClick={handleCancel}
        />
      </FormWrapper>
    </>
  );
  return (
    <GenericPanelComponent
      backIcon={true}
      postLogin={true}
      bodyComponent={bodyComponent}
      handleIconClick={handleCancel}
      pageTitle={t('messageLanguage.heading')}
      panelSubText={t('messageLanguage.subheading')}
    />
  );
};

export default MessageLanguage;
