const AllRegex = {
  onlyValidCharacters: /^[a-zA-Z0-9*_^()&'+\,\.:[\]`{}~!@#$%=?\/|-]+$/, //F22159
  passwordRegex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/,
  emailRegex: /^(?!.*\.{2})[A-Z0-9a-z._+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,15}$/,
  checkRepeatedCharsRegex:
    /([a-zA-Z0-9])\1\1+|(abc|bcd|cde|def|efg|fgh|ghi|hij|ijk|jkl|klm|lmn|mno|nop|opq|pqr|qrs|rst|stu|tuv|uvw|vwx|wxy|xyz|012|123|234|345|456|567|678|789)+/,
  upperCaseRegex: /[A-Z]/,
  passwordSpecialCharacterRegex: /[~!@#$%+=\/?*_^()[\]&'\,\.:`{|}-]/,
  numberRegex: /[0-9]/,
  lowerCaseRegex: /[a-z]/,
  spaceRegex: /^\S*$/,
  phoneNumber: /^\d{3}\-\d{3}-\d{4}$/,
  postalCodeFormat: /^[0-9a-zA-Z\-\s]{1,20}$/,
  specialChars: /[^a-zA-Z0-9]/,
  wholeNumbers: /^\d+$/,
  panPrefix: /^[0-9\s]*$/,
  cvvRegex: /^\d{3}$/,
  username: /^[a-zA-Z0-9@.\-_,]*$/,
  //g tag is not necessary. But using this regex since the same has been used in VDCA web and mobile as well.
  cardNickNameFormat: /^((?![0-9]{9})[a-zA-Z0-9\s])*$/g
};

export default AllRegex;
