import React, { ReactElement } from 'react';
import { Link } from '@visa/vds';
import { useTranslation } from 'react-i18next';
import styles from './link-wrapper.module.scss';

interface LinkWrapperProps {
  href?: string;
  inline?: boolean;
  disabled?: boolean;
  ariaLabel?: string;
  children: ReactElement | string;
  openInNewTab?: boolean;
  className?: string;
  onClick?: Function;
}

const LinkWrapper: React.FC<LinkWrapperProps> = props => {
  const {
    inline = true,
    children = null,
    className = '',
    disabled = false,
    openInNewTab = false,
    ariaLabel = ''
  } = props;

  const { t } = useTranslation();

  const newTabIndicator = (
    <svg
      width="12"
      height="12"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label={t('app.navigation.openInNewTab')}
      className={!Boolean(className) ? styles['icon-position'] : ''}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 3.914L9.707 8.207C9.316 8.598 8.684 8.598 8.293 8.207C7.902 7.816 7.902 7.184 8.293 6.793L12.586 2.5H12C11.447 2.5 11 2.052 11 1.5C11 0.948 11.447 0.5 12 0.5H15C15.553 0.5 16 0.948 16 1.5V4.5C16 5.052 15.553 5.5 15 5.5C14.447 5.5 14 5.052 14 4.5V3.914ZM11 14.5V10.5C11 9.948 11.447 9.5 12 9.5C12.553 9.5 13 9.948 13 10.5V14.891C13 15.78 12.28 16.5 11.392 16.5H1.608C0.72 16.5 0 15.78 0 14.891V5.109C0 4.22 0.72 3.5 1.608 3.5H7C7.553 3.5 8 3.948 8 4.5C8 5.052 7.553 5.5 7 5.5H2V14.5H11Z"
        fill="#1434CB"
      />
    </svg>
  );

  const linkChild = (
    <div className={`${styles['child-container']}`}>
      <span className={`vds-link vds-link--inline ${styles['link-label']}  ${className}`}>{children}</span>
      {openInNewTab ? newTabIndicator : null}
    </div>
  );

  const openNewTabProps = openInNewTab
    ? {
        target: '_blank',
        rel: 'noopener noreferrer'
      }
    : {};

  const propsToLink = {
    ...props,
    'aria-label': openInNewTab ? `${ariaLabel} (${t('app.navigation.openInNewTab')})` : ariaLabel,
    ...openNewTabProps
  };

  //  remove unwanted props;
  delete propsToLink['ariaLabel'];
  delete propsToLink['openInNewTab'];

  return <Link {...propsToLink}>{linkChild}</Link>;
};

export default LinkWrapper;
