import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetAppData } from '../app/app-reducer';
import { ContactDetail } from '../constants/user-profile';
import { Utils } from '../utils';

type userAuth = {
  accessToken: string;
  userGuid: string;
  emailGuid: string;
  phoneGuid: string;
  token2Success: boolean;
  isPartiallyLogged: boolean;
  isFullyLogged: boolean;
  lastLoginTimeStamp: string;
  expiresIn: number | null;
  tokenType: string;
  subjects: any;
  termsAndConditionsAcceptanceList: any;
  tokenError: any;
  shouldRestartSession: boolean;
  userContactsDetails: any;
  userConfigDetails: any;
  shouldRestartSessionPostChangePwd: boolean;
};

const initialState: userAuth = {
  accessToken: '',
  userGuid: '',
  emailGuid: '',
  phoneGuid: '',
  token2Success: false,
  isPartiallyLogged: false,
  isFullyLogged: false,
  lastLoginTimeStamp: '',
  expiresIn: null,
  tokenType: '',
  subjects: [],
  termsAndConditionsAcceptanceList: [],
  tokenError: null,
  shouldRestartSession: false,
  userContactsDetails:[],
  userConfigDetails:[],
  shouldRestartSessionPostChangePwd: false,
};
const userAuthSlice = createSlice({
  name: 'user-auth',
  initialState,
  reducers: {
    setAccessToken: (state: userAuth, action: PayloadAction<any>) => {
      return {
        ...state,
        accessToken: action.payload
      };
    },
    setUserGuid: (state: userAuth, action: PayloadAction<any>) => {
      return {
        ...state,
        userGuid: action.payload
      };
    },
    setEmailGuid: (state: userAuth, action: PayloadAction<any>) => {
      const contacts = action.payload.vuser?.contacts;
      if (Array.isArray(contacts) && Boolean(contacts.length)) {
        const primaryEmailContact = contacts.find(
          contact => contact.contactPreference == ContactDetail.PRIMARY && contact.contactType == ContactDetail.EMAIL
        );
        return {
          ...state,
          emailGuid: primaryEmailContact.guid
        };
      }
    },
    setPhoneGuid: (state: userAuth, action: PayloadAction<any>) => {
      return {
        ...state,
        phoneGuid: action.payload
      };
    },
    setToken2Success: (state: userAuth) => {
      return {
        ...state,
        token2Success: true
      };
    },
    // when user logged in partially
    setPartiallyLogged: (state: userAuth) => {
      state.isPartiallyLogged = true;
      state.isFullyLogged = false;
      return state;
    },
    // when user logged in completely
    setFullyLogged: (state: userAuth) => {
      Utils.setDataInSessionStorage('IFL', true);
      state.isFullyLogged = true;
      state.isPartiallyLogged = false;
      return state;
    },
    resetUserAuth: (state: userAuth) => {
      Utils.removeDataFromSessionStorage('IFL');
      state = initialState;
      return state;
    },
    // to set last login time
    setLastLoginTime: (state: userAuth, action: PayloadAction<any>) => {
      const response = action.payload.data;
      state.lastLoginTimeStamp = Utils.timestampToLocaleDateTime(
        response && response.subjects && response.subjects[0].visited_on
      );
      state.subjects = response.subjects;

      if (response.data && response.data.contacts && response.data.contacts.length > 0) {
        state.userContactsDetails = response.data.contacts[0] || {};
      }

      if (response.data && response.data.userConfigResponse) {
        state.userConfigDetails = response.data.userConfigResponse || {};
      }
      
      return state;
    },

    setV2ContactDetails: (state: userAuth, action: PayloadAction<any>) => {
      const response = action.payload;
      if (response.contacts && response.contacts.length > 0) {
        state.userContactsDetails = response.contacts[0] || {};
      }
      return state;
    },
    // token renewal
    postTokenRenewal: (state: userAuth, action) => {
      state.shouldRestartSession = false;
      return state;
    },

    postChangePasswordSuccess: (state: userAuth, action)=>{
      const { access_token, expires_in, token_type, subjects, userGuid, termsAndConditionsAcceptanceList } =
        action.payload;
        state.accessToken = access_token;
      state.expiresIn = expires_in;
      state.tokenType = token_type;
      state.subjects = subjects;
      state.termsAndConditionsAcceptanceList = termsAndConditionsAcceptanceList;
      state.shouldRestartSessionPostChangePwd = true;
      return state;
    },

    postTokenRenewalSuccess: (state: userAuth, action) => {
      const { access_token, expires_in, token_type, subjects, userGuid, termsAndConditionsAcceptanceList } =
        action.payload;
      // state.accessToken = access_token + Math.random();  // ** this is only for reference as qa-container wasn't working. This  will be removed later ** //
      state.accessToken = access_token;
      state.expiresIn = expires_in;
      state.tokenType = token_type;
      state.subjects = subjects;
      state.termsAndConditionsAcceptanceList = termsAndConditionsAcceptanceList;
      state.shouldRestartSession = true;
      return state;
    },

    postTokenRenewalFailure: (state: userAuth, action) => {
      state.tokenError = action.payload;
      state.shouldRestartSession = false;
      state.isFullyLogged = false;
      return state;
    }
  },
  extraReducers: builder => {
    builder.addCase(resetAppData, () => {
      return initialState;
    });
  }
});

export const {
  setAccessToken,
  setUserGuid,
  setEmailGuid,
  setPhoneGuid,
  setToken2Success,
  setPartiallyLogged,
  setFullyLogged,
  resetUserAuth,
  setLastLoginTime,
  postTokenRenewal,
  postTokenRenewalSuccess,
  postTokenRenewalFailure,
  postChangePasswordSuccess,
  setV2ContactDetails
} = userAuthSlice.actions;

export default userAuthSlice.reducer;
