import { createSlice } from '@reduxjs/toolkit';

interface UseAnotherEmail {
  newEmail: string;
  code: number;
  newEmailGuid: string;
}

const initialState: UseAnotherEmail = {
  newEmail: '',
  code: 0,
  newEmailGuid: ''
};

const anotherEmailReducer = createSlice({
  name: 'use-another-email',
  initialState,
  reducers: {
    setAnotherEmail: (state: any, action: any) => {
      state.newEmail = action.payload.email;
      return state;
    },
    anotherEmailSuccess: (state: any, action: any) => {
      state.newEmailGuid = action.payload.newEmailGuid;
      return state;
    },
    anotherEmailFailure: (state: any, action: any) => {
      return state;
    },
    getAnotherEmail: (state: any, action: any) => {
      return state;
    }
  }
});

export const { setAnotherEmail, anotherEmailSuccess, anotherEmailFailure, getAnotherEmail } =
  anotherEmailReducer.actions;

export default anotherEmailReducer.reducer;
