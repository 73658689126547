import React from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, Noop } from 'react-hook-form';
import Input from '@visa/vds/input';
import Icon from '@visa/vds/icon';
import InputOption from '@visa/vds/input-option';
import { CheckEligibilityInputWrapperProps } from '../model';
import Styles from './check-eligibility-input.module.scss';

export const cc_format = (value: string) => {
  const v = value.replace(/[^0-9]/gi, '');
  const matches = v.match(/\d{4,16}/g);
  const match = (matches && matches[0]) || '';
  const parts: string[] = [];
  for (let i = 0, len = match.length; i < len; i += 4) {
    parts.push(match.substring(i, i + 4));
  }

  if (parts.join(' ').length > 11) {
    return parts.join(' ').slice(0, 11);
  }
  if (parts.length) {
    return parts.join(' ');
  } else {
    return value;
  }
};

const CheckEligibilityInputWrapper: React.FC<CheckEligibilityInputWrapperProps> = ({
  methods,
  label,
  name,
  type,
  required,
  readOnly,
  disabled,
  placeholder,
  maxLength,
  className,
  handleFocus,
  handleBlur
}) => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    control,
    setValue,
    getValues,
    watch,
    setError,
    reset
  } = methods;

  const handleContentChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    setValue(name, value);
    if (getValues(name).length >= 4) {
      setValue(name, cc_format(getValues(name)));
    } else {
      setValue(name, value);
    }
  };

  const handleInputFocus = () => {
    handleFocus && handleFocus(name);
  };

  const handleInputBlur = (blur: Noop) => {
    handleBlur && handleBlur(name);
    blur();
  };

  const checkInput = (value: string) => {
    if (value.length > 0 && value.length <= 4 && !Number(value)) {
      setValue(name, value.slice(0, value.length - 1));
    }
    // if (value.length > 4 && !Number(value.split(' ').join(''))) {
    //   setValue(name, '')
    // }
  };
  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          checkInput(field.value);
          return (
            <Input
              aria-required={Boolean(required)}
              className={className ? className : null}
              contentAfterInput={
                <InputOption>
                  <Icon className={Styles['card-icon']} name="card-generic" resolution="low" />
                </InputOption>
              }
              label={label}
              type={type}
              {...field}
              onChange={(e: any) => handleContentChange(e)}
              onFocus={() => handleInputFocus()}
              onBlur={() => handleInputBlur(field.onBlur)}
              errorText={t(`${errors?.[name]?.message}`)}
              showErrorText={errors?.[name]?.message ? true : false}
              required={required ? true : false}
              invalid={errors?.[name]?.message && required ? true : false}
              readOnly={readOnly ? true : false}
              disabled={disabled ? true : false}
              placeholder={placeholder ? placeholder : null}
              noValidate
              maxLength={maxLength}
            />
          );
        }}
      />
    </>
  );
};

export default CheckEligibilityInputWrapper;
