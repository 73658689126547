import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { VerifyCode } from '../../../elements/shared';
import { getConfigurations, postToken } from '../sign-in/sign-in-reducer-slice';
import { RedirectRoutes } from '../../../constants';
import { showAlert, clearAlert } from '../../../elements/shared';
import { getContactValue, getLocale } from '../../../utils/utils';
import { ContactDetail } from '../../../constants/user-profile';
import { Utils } from '../../../utils';
import {
  postIdentityVerification,
  getContactsUnverified,
  postSendOtvc,
  resetSuccessFlags
} from './idv-verify-reducer-slice';

type IdvVerifyProps = {
  [key: string]: any;
};

const IdvVerify: React.FC<IdvVerifyProps> = () => {
  const { signin, idvVerify, userAuth, anotherEmail } = useSelector((store: any) => store);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getPayload = (data: any) => {
    const payload: { [key: string]: any } = {};
    (payload.access_token = userAuth.accessToken),
      (payload.guid = userAuth.emailGuid),
      (payload.userGuid = userAuth.userGuid);

    payload.contacts = {
      contactsUnverified: 'unverified'
    };

    payload.idvPayload = {
      grant_type: 'identity_verification',
      locale: 'en-US',
      eventType: 'IDENTITY_VERIFICATION',
      verificationType: 'OTP_LONG',
      verificationValue: data.otpCode,
      context: {
        channel: 'Web'
      }
    };

    payload.otvcPayload = {
      locale: getLocale(),
      eventType: 'IDENTITY_VERIFICATION',
      contactType: 'EMAIL',
      otvcType: 'LONG'
    };
    return payload;
  };

  const handleSubmit = data => {
    const payload = getPayload(data);
    dispatch(postIdentityVerification({ payload, navigate }));
    dispatch(clearAlert());
  };

  const handleCancel = () => {
    // dispatch(clearAlert());
  };
  const handleResend = () => {
    dispatch(postSendOtvc(getPayload({})));
  };

  //useEffect(() => {
  // if (idvVerify.idvResponseError?.error) {
  //   dispatch(
  //     showAlert({
  //       type: 'ERROR',
  //       message: `signin.errorCodes.${idvVerify.idvResponseError.error}`
  //     })
  //   );
  // }
  // if (idvVerify.otvcResponseError?.reason) {
  //   dispatch(
  //     showAlert({
  //       type: 'ERROR',
  //       message: `signin.errorCodes.${idvVerify.otvcResponseError.reason}`
  //     })
  //   );
  // }
  //   if (idvVerify.contactsUnverifiedResponseError?.reason) {
  //     dispatch(
  //       showAlert({
  //         type: 'ERROR',
  //         message: `signin.errorCodes.${idvVerify.contactsUnverifiedResponseError.reason}`
  //       })
  //     );
  //   }
  // }, [idvVerify.idvResponseError, idvVerify.otvcResponseError, idvVerify.contactsUnverifiedResponseError]);

  useEffect(() => {
    if (idvVerify.otvcSuccess) {
      const type = 'SUCCESS';
      const message = 'forgotPassword.codeSendSuccess';
      dispatch(showAlert({ type, message }));
    }
  }, [idvVerify.otvcSuccess]);

  // useEffect(() => {
  //   if (!userAuth.userGuid) {
  //     navigate(RedirectRoutes.login);
  //   }
  // }, [userAuth.UserGuid]);

  useEffect(() => {
    if (userAuth.userGuid) {
      dispatch(postSendOtvc(getPayload({})));
    }
  }, [userAuth.userGuid]);

  useEffect(() => {
    if (userAuth.token2Success) {
      navigate(RedirectRoutes.home);
    }
  }, [userAuth.token2Success]);

  useEffect(() => {
    if (
      (idvVerify.idvSuccess && idvVerify?.response?.scope && !idvVerify.response.scope.includes('/2fa')) ||
      (idvVerify.idvSuccess)
    ) {
      if (signin.pendingTerms) {
        navigate(RedirectRoutes.acceptPendingTnc);
      } else {
        dispatch(postToken());
      }
    }
  }, [signin.pendingTerms]);

  useEffect(() => {
    if (idvVerify.idvSuccess) {
      if (signin.scope.challenge) {
        navigate(RedirectRoutes.loginChallengeVerifyEmail);
      } else {
        dispatch(getConfigurations());
      }
    }
  }, [idvVerify.idvSuccess]);

  useEffect(() => {
    return () => {
      dispatch(resetSuccessFlags());
    };
  }, []);

  return (
    <div>
      <VerifyCode
        contactValueMasking={false}
        submitHandler={handleSubmit}
        cancelHandler={handleCancel}
        resendHandler={handleResend}
        contactValue={getContactValue(
          { contacts: [userAuth.userContactsDetails] },
          ContactDetail.PRIMARY,
          ContactDetail.EMAIL
        )}
      />
    </div>
  );
};
export default IdvVerify;
