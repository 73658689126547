import { RedirectRoutes } from '../constants';
import apiPaths from '../setup/ajax-api-config';

const errorMap = {
  // [apiPaths.alerts.alertUrl]: {
  //     'DPS_0003': { redirectTo: RedirectRoutes.login, type: 'modal', msg: 'commonErrorCodes.technical_error_message' },
  //     'DPS_0001': { redirectTo: RedirectRoutes.login, type: 'modal', msg: 'commonErrorCodes.technical_error_message' },
  //     'DPS_0004': { redirectTo: RedirectRoutes.login, type: 'modal', msg: 'commonErrorCodes.technical_error_message' },
  // },

  // [apiPaths.emailUrl]:{
  //     'PROFILE_001'   : { redirectTo: RedirectRoutes.login, type: 'modal', msg: 'commonErrorCodes.technical_error_message' },
  //     'PROFILE_1001'  : { redirectTo: RedirectRoutes.profile, type: 'modal', msg: 'commonErrorCodes.technical_error_message' },
  //     'PROFILE_1009'  : { redirectTo: RedirectRoutes.login, type: 'modal', msg: 'commonErrorCodes.technical_error_message' },
  //     'PROFILE_1010'  : { redirectTo: RedirectRoutes.login, type: 'modal', msg: 'commonErrorCodes.technical_error_message' },
  //     'PROFILE_1007'  : { redirectTo: RedirectRoutes.login, type: 'modal', msg: 'commonErrorCodes.technical_error_message' },
  //     'PROFILE_1004'  : { redirectTo: RedirectRoutes.login, type: 'modal', msg: 'commonErrorCodes.technical_error_message' },
  //     'PROFILE_0001'  : { redirectTo: RedirectRoutes.login, type: 'modal', msg: 'commonErrorCodes.technical_error_message' },
  //     'PROFILE_1011'  : { redirectTo: RedirectRoutes.login, type: 'modal', msg: 'commonErrorCodes.technical_error_message' },
  //     'PROFILE_1012'  : { redirectTo: RedirectRoutes.login, type: 'modal', msg: 'commonErrorCodes.technical_error_message' },
  //     'PROFILE_1013'  : { redirectTo: RedirectRoutes.login, type: 'modal', msg: 'commonErrorCodes.technical_error_message' },
  //     'PROFILE_1005'  : { redirectTo: RedirectRoutes.login, type: 'modal', msg: 'commonErrorCodes.technical_error_message' },
  //     'PROFILE_1006'  : { redirectTo: RedirectRoutes.login, type: 'modal', msg: 'commonErrorCodes.technical_error_message' }
  //   },

  // [apiPaths.phoneUrl]: {
  //     'VM001'         : { redirectTo: RedirectRoutes.login, type: 'modal', msg: 'commonErrorCodes.technical_error_message' },
  //     'VM002'         : { redirectTo: RedirectRoutes.login, type: 'modal', msg: 'commonErrorCodes.technical_error_message' },
  //     'VM003'         : { redirectTo: RedirectRoutes.login, type: 'modal', msg: 'commonErrorCodes.technical_error_message' },
  //     'VM004'         : { redirectTo: RedirectRoutes.login, type: 'modal', msg: 'commonErrorCodes.technical_error_message' },
  //     'VM005'         : { redirectTo: RedirectRoutes.login, type: 'modal', msg: 'commonErrorCodes.technical_error_message' },
  //     'PROFILE_0004'  : { redirectTo: RedirectRoutes.login, type: 'modal', msg: 'commonErrorCodes.technical_error_message' },
  //     'PROFILE_2004'  : { redirectTo: RedirectRoutes.login, type: 'modal', msg: 'commonErrorCodes.technical_error_message' }
  //   },

  // [apiPaths.auth.userdetails]: {
  //     'PROFILE_0004': { redirectTo: RedirectRoutes.login, type: 'modal', msg: 'commonErrorCodes.technical_error_message' }
  //   },

  [apiPaths.auth.sendOtvcUrl]: {
    PROFILE_1001: { redirectTo: RedirectRoutes.login, type: 'modal', msg: 'signin.errorCodes.VMCP_001' },
    PROFILE_1004: { redirectTo: RedirectRoutes.login, type: 'modal', msg: 'signin.errorCodes.VMCP_001' },
    PROFILE_2002: { redirectTo: RedirectRoutes.login, type: 'modal', msg: 'signin.errorCodes.VMCP_001' },
    PROFILE_001: { redirectTo: RedirectRoutes.login, type: 'modal', msg: 'signin.errorCodes.VMCP_001' },
    PROFILE_004: { redirectTo: RedirectRoutes.login, type: 'modal', msg: 'signin.errorCodes.VMCP_001' },
    PROFILE_2012: { redirectTo: RedirectRoutes.profile, type: 'modal', msg: 'signin.errorCodes.VMCP_001' },
    OTP_VALIDATION_FAILED: { type: 'notification', msg: 'errors.verifyCode.invalidCode' },
    OTP_VERIFICATION_FAILED_1: { type: 'notification', msg: 'errors.verifyCode.invalidCode' },
    OTP_VERIFICATION_FAILED_2: { type: 'notification', msg: 'errors.verifyCode.invalidCode' },
    OTP_VERIFICATION_FAILED_3: { type: 'notification', msg: 'errors.verifyCode.invalidCode' },
    OTP_EXPIRED: { type: 'notification', msg: 'errors.verifyCode.OTPExpired' },
    PROFILE_2013: { redirectTo: RedirectRoutes.profile, type: 'modal', msg: 'signin.errorCodes.VMCP_001' },
    '9612': { redirectTo: RedirectRoutes.login, type: 'modal', msg: 'signin.errorCodes.VMCP_001' },
    '9614': { redirectTo: RedirectRoutes.login, type: 'modal', msg: 'signin.errorCodes.VMCP_001' }
  },

  // [apiPaths.payment.baseUrl]:{
  // '400': { redirectTo: RedirectRoutes.login, type: 'modal', msg: 'commonErrorCodes.technical_error_message' },
  // 'PROFILE_0004': { redirectTo: RedirectRoutes.login, type: 'modal', msg: 'commonErrorCodes.technical_error_message' },
  // 'PROFILE_0002': { redirectTo: RedirectRoutes.login, type: 'modal', msg: 'commonErrorCodes.technical_error_message' },
  // 'inputValidationError' : { redirectTo: RedirectRoutes.login, type: 'modal', msg: 'commonErrorCodes.technical_error_message' },
  // 'DPS_0001': { redirectTo: RedirectRoutes.login, type: 'modal', msg: 'commonErrorCodes.technical_error_message' },
  // 'DPS_0002': { redirectTo: RedirectRoutes.login, type: 'modal', msg: 'commonErrorCodes.technical_error_message' },
  // 'DPS_0003': { redirectTo: RedirectRoutes.login, type: 'modal', msg: 'commonErrorCodes.technical_error_message' },
  // 'DPS_1002': { type: 'notification', msg: 'errors.addpi.prefix_not_match' },
  // 'PAS_3001': { type: 'notification', msg: 'errors.models.paymentInstrument.attributes.accountNumber.alreadyAdded' },
  // 'PAS_1200': { type: 'notification', msg: 'errors.models.paymentInstrument.attributes.accountNumber.invalid' },
  // 'PAS_4001' : {type : 'notification', msg: 'errors.cardsExceededTen.errorMsg'}, // GSM displayed for adding more than 10 cards
  // 'PAS_4004' : {type : 'notification', msg: 'errors.addpi.cardNotSupported'},  //GSM displayed for add card country mismatch
  // 'PROFILE_19001': {type: 'notification','msg' : 'errors.addpi.cardNotSupported'}
  // },

  // [apiPaths.forgotPassword.b2cSendEmailUrl]:{
  //     'ICM_0001': { redirectTo: RedirectRoutes.login, type: 'modal', msg: 'commonErrorCodes.technical_error_message' },
  //     'ICM_0003': { redirectTo: RedirectRoutes.login, type: 'modal', msg: 'commonErrorCodes.technical_error_message' },
  //     'ICM_1002': { redirectTo: RedirectRoutes.login, type: 'modal', msg: 'commonErrorCodes.technical_error_message' }
  // },

  [apiPaths.auth.enrollUrl]: {
    ICM_0001: { type: 'notification', msg: 'createAccount.errorCodes.ICM_0001' },
    ICM_0003: { type: 'notification', msg: 'createAccount.errorCodes.ICM_0001' },
    ICM_1003: { type: 'notification', msg: 'createAccount.errorCodes.noUserName' },
    ICM_1022: { type: 'notification', msg: 'createAccount.errorCodes.noUserName' },
    PROFILE_0001: { type: 'notification', msg: 'createAccount.errorCodes.generic' },
    VMCP_1008: { type: 'notification', msg: 'createAccount.errorCodes.ICM_0001' },
    VMCP_1006: { type: 'notification', msg: 'createAccount.errorCodes.VMCP_1006' },
    PROFILE_1002: { type: 'notification', msg: 'createAccount.errorCodes.duplicateEmail' },
    PROFILE_1014: { type: 'notification', msg: 'createAccount.errorCodes.emailInUse' },
    PROFILE_2001: { type: 'notification', msg: 'createAccount.errorCodes.ICM_0001' },
    PROFILE_15001: { type: 'notification', msg: 'check.errorCodes.PAS_1008' }
  },

  [apiPaths.auth.contactsVerified]: {
    PROFILE_0004: { redirectTo: RedirectRoutes.login, type: 'modal', msg: 'createAccount.errorCodes.ICM_0001' }
  },
  [apiPaths.auth.loginUrl]: {
    ICM_0001: { redirectTo: RedirectRoutes.login, type: 'modal', msg: 'signin.errorCodes.ICM_0001' },
    ICM_1006: { type: 'notification', msg: 'signin.errorCodes.ICM_1006' },
    '9618': { type: 'notification', msg: 'signin.errorCodes.9618' },
    '9617': { type: 'notification', msg: 'signin.errorCodes.9617' },
    VMCP_1006: { type: 'notification', msg: 'signin.errorCodes.VMCP_1006' },
    ICM_1025: { type: 'notification', msg: 'signin.errorCodes.ICM_1025' },
    ICM_0005: { type: 'notification', msg: 'signin.errorCodes.ICM_0005' }
  },
  [apiPaths.payment.baseUrl]: {
    PAS_1008: { type: 'notification', msg: 'add_edit_card.errorCodes.PAS_1008' },
    VMCP_1006: { type: 'notification', msg: 'add_edit_card.errorCodes.VMCP_1006' },
    VMCP_001: { type: 'notification', msg: 'add_edit_card.errorCodes.VMCP_001' },
    VMCP_002: { type: 'notification', msg: 'add_edit_card.errorCodes.VMCP_002' },
    PAS_1100: { type: 'notification', msg: 'add_edit_card.errorCodes.PAS_1100' },
    PAS_1012: { type: 'notification', msg: 'add_edit_card.errorCodes.PAS_1012' },
    PAS_4001: { type: 'notification', msg: 'add_edit_card.errorCodes.PAS_4001' },
    PAS_4004: { type: 'notification', msg: 'add_edit_card.errorCodes.PAS_4004' },
    PROFILE_16001: { type: 'notification', msg: 'add_edit_card.errorCodes.PROFILE_16001' },
    PROFILE_3006: { type: 'notification', msg: 'add_edit_card.errorCodes.PROFILE_3006' },
    PROFILE_3004: { type: 'notification', msg: 'add_edit_card.errorCodes.PROFILE_3004' },
    VMCP_70002: { type: 'notification', msg: 'add_edit_card.errorCodes.VMCP_002' }
  },
  [apiPaths.countryConfig]: {
    VMCP_002: { type: 'notification', msg: 'check.errorCodes.VMCP_002' }
  },
  [apiPaths.auth.checkeligibility]: {
    PAS_1100: { type: 'notification', msg: 'check.errorCodes.PAS_1100' },
    PAS_1101: { type: 'notification', msg: 'check.errorCodes.PAS_1101' },
    PAS_1012: { type: 'notification', msg: 'check.errorCodes.PAS_1012' },
    PAS_4004: { type: 'notification', msg: 'check.errorCodes.PAS_4004' },
    PAS_1008: { redirectTo: RedirectRoutes.login, type: 'modal', msg: 'check.errorCodes.PAS_1008' }
  },
  [apiPaths.forgotUserName]: {
    PROFILE_0003: { type: 'notification', msg: 'forgotUserName.errorCodes.missing_contact' },
    PROFILE_1016: { type: 'notification', msg: 'forgotUserName.errorCodes.missing_contact' },
    PROFILE_5001: { type: 'notification', msg: 'forgotUserName.errorCodes.missing_contact' },
    PROFILE_2002: { type: 'notification', msg: 'forgotUserName.errorCodes.missing_contact' },
    PROFILE_1015: { type: 'notification', msg: 'forgotUserName.errorCodes.unverified_primary_contact' },
    PROFILE_1019: { type: 'notification', msg: 'forgotUserName.errorCodes.unverified_contact' },
    PROFILE_2007: { type: 'notification', msg: 'forgotUserName.errorCodes.unverified_contact' }
  },
  [apiPaths.userTerms]: {
    VMCP_1006: { type: 'notification', msg: 'accept_pending_tnc.errorCodes.VMCP_1006' }
  },
  [apiPaths.phoneUrl]: {
    PROFILE_15001: {
      type: 'notification',
      msg: 'updatePhone.errorCodes.PROFILE_15001'
    },
    PROFILE_2006: {
      type: 'notification',
      msg: 'updatePhone.errorCodes.PROFILE_2006'
    },
    VMCP_1006: { type: 'notification', msg: 'updatePhone.errorCodes.VMCP_1006' },
    PROFILE_0001: {
      type: 'notification',
      msg: 'updatePhone.errorCodes.PROFILE_0001'
    },
    VM001: {
      redirectTo: RedirectRoutes.login,
      type: 'modal',
      msg: 'updatePhone.errorCodes.technical_error_message'
    },
    VM002: {
      redirectTo: RedirectRoutes.login,
      type: 'modal',
      msg: 'updatePhone.errorCodes.technical_error_message'
    },
    VM003: {
      redirectTo: RedirectRoutes.login,
      type: 'modal',
      msg: 'updatePhone.errorCodes.technical_error_message'
    },
    VM004: {
      redirectTo: RedirectRoutes.login,
      type: 'modal',
      msg: 'updatePhone.errorCodes.technical_error_message'
    },
    VM005: {
      redirectTo: RedirectRoutes.login,
      type: 'modal',
      msg: 'updatePhone.errorCodes.technical_error_message'
    }
  },
  [apiPaths.alerts.alertUrl]: {
    VMCP_1006: { type: 'notification', msg: 'alerts.errorCodes.VMCP_1006' },
    DPS_0003: { type: 'notification', msg: 'alerts.errorCodes.technical_error_message' },
    DPS_0001: { type: 'notification', msg: 'alerts.errorCodes.technical_error_message' },
    DPS_0004: { type: 'notification', msg: 'alerts.errorCodes.technical_error_message' }
  },
  [apiPaths.unsubscribe]: {
    VMCP_001: { type: 'notification', msg: 'unsubscribe.errorCodes.VMCP_001' },
    VMCP_002: { type: 'notification', msg: 'unsubscribe.errorCodes.VMCP_002' },
    PAS_1100: { type: 'notification', msg: 'unsubscribe.errorCodes.PAS_1100' }
  },
  [apiPaths.auth.b2cVerifyOtvcForgotCred]: {
    invalid_grant: { type: 'notification', msg: 'errors.verifyCode.invalidCode' }
  },
  [apiPaths.auth.b2cLoginUrl]: {
    invalid_grant: { type: 'notification', msg: 'signin.errorCodes.ICM_1006' },
    account_locked: { type: 'notification', msg: 'signin.errorCodes.9618' }
  }
};

//use this method when multiple endpoints share common errormaping
const multipleUrlMapping: any = [
  {
    map: {
      ICM_001: { redirectTo: RedirectRoutes.login, type: 'modal', msg: 'commonErrorCodes.technical_error_message' },
      ICM_003: { redirectTo: RedirectRoutes.login, type: 'modal', msg: 'commonErrorCodes.technical_error_message' },
      ICM_1001: { redirectTo: RedirectRoutes.login, type: 'modal', msg: 'commonErrorCodes.technical_error_message' },
      ICM_1002: { redirectTo: RedirectRoutes.login, type: 'modal', msg: 'commonErrorCodes.technical_error_message' },
      VM001: { redirectTo: RedirectRoutes.login, type: 'modal', msg: 'commonErrorCodes.technical_error_message' },

      VMCP_1006: { type: 'notification', msg: 'signin.errorCodes.VMCP_1006' },
      ICM_0001: { type: 'notification', msg: 'errors.resetPassword.noDuplicate' },
      ICM_1012: { type: 'notification', msg: 'errors.resetPassword.old_nomatch' },
      ICM_3001: { type: 'notification', msg: 'errors.resetPassword.noDuplicateNew' },

      invalid_request: { type: 'notification', msg: 'check.errorCodes.PAS_1008' }
    },
    urls: [apiPaths.resetPassword.b2cResetPasswordUrl, apiPaths.changePassword.changePasswordUrl]
  },
  {
    map: {
      '9616': { type: 'notification', msg: 'errors.verifyCode.OTPExpired' },
      '9602': { type: 'notification', msg: 'errors.verifyCode.invalidCode' },
      OTP_EXPIRED: { type: 'notification', msg: 'errors.verifyCode.OTPExpired' },
      VMCP_1006: { type: 'notification', msg: 'signin.errorCodes.VMCP_1006' },
      PROFILE_1014: { type: 'notification', msg: 'errors.verifyCode.emailInUse' },
      Profile_2006: { type: 'notification', msg: 'errors.verifyCode.phoneInUse' },
      PROFILE_2011: { type: 'notification', msg: 'errors.verifyCode.invalidOTVC' },
      PROFILE_2012: {
        redirectTo: RedirectRoutes.login,
        type: 'modal',
        msg: 'commonErrorCodes.technical_error_message'
      },
      PROFILE_2013: {
        redirectTo: RedirectRoutes.login,
        type: 'modal',
        msg: 'commonErrorCodes.technical_error_message'
      },
      inputValidationError: { type: 'notification', msg: 'errors.verifyCode.invalidCode' },
      OTP_VALIDATION_FAILED: { type: 'notification', msg: 'errors.verifyCode.invalidCode' },
      OTP_VERIFICATION_FAILED_1: { type: 'notification', msg: 'errors.verifyCode.invalidCode' },
      OTP_VERIFICATION_FAILED_2: { type: 'notification', msg: 'errors.verifyCode.invalidCode' },
      OTP_VERIFICATION_FAILED_3: { type: 'notification', msg: 'errors.verifyCode.invalidCode' },
      invalid_grant_loginlockout: { type: 'notification', msg: 'errors.verifyCode.armAccLock' },

      invalid_grant: { type: 'notification', msg: 'check.errorCodes.PAS_1008' },
      invalid_request: { type: 'notification', msg: 'check.errorCodes.PAS_1008' },
      unsupported_grant_type: { type: 'notification', msg: 'check.errorCodes.PAS_1008' }
    },
    urls: [
      // apiPaths.auth.b2cVerifyOtvcForgotCred,
      // apiPaths.forgotPassword.b2cReissuance,
      apiPaths.auth.verifyTwoFactor,
      apiPaths.enrollment.verifyIdentity
    ]
  }
];

multipleUrlMapping.forEach(el => {
  el.urls.forEach(url => {
    errorMap[url] = el.map;
  });
});
export default errorMap;
