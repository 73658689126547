import { Row, Col, Typography } from '@visa/vds';
import { useTranslation } from 'react-i18next';
import { InputWrapper } from '../../../core-ui';
import { Utils } from '../../../../utils';
import { SelectWrapper } from '../../../core-ui';
import Styles from './card-billing.module.scss';

const CardBilling = ({ methods, edit = false }) => {
  const { setValue } = methods;
  const { t } = useTranslation();

  const handleStateSelect = value => methods.setValue('stateProvinceCode', value);

  const handleChange = (name, e) => {
    if (name === 'postalCode') {
      const { value } = e.target;
      const result = value.replace(/[^0-9]/gi, '');
      setValue(name, result);
    }
  };

  const bodyComponent = (
    <>
      <Row noGutter className={Styles['first-row']}>
        <Col>
          <Typography tag="h2" className={`panel-text ${Styles['panel-text']}`}>
            {t('add_edit_card.subHeading.billingAddress')}
          </Typography>
        </Col>
      </Row>
      <Row className={Styles['input-row'] + ' two-col'}>
        {!edit ? (
          <>
            <Col xs={4} sm={8} md={8} lg={5}>
              <InputWrapper
                id="firstName"
                className={Styles['input-field']}
                methods={methods}
                label={t('add_edit_card.fieldLabel.firstName')}
                name={'firstName'}
                type={'text'}
                required={true}
                maxLength={100}
              />
            </Col>
            <Col xs={4} sm={8} md={8} lg={5}>
              <InputWrapper
                id="lastName"
                className={Styles['input-field']}
                methods={methods}
                label={t('add_edit_card.fieldLabel.lastName')}
                name={'lastName'}
                type={'text'}
                required={true}
                maxLength={100}
              />
            </Col>{' '}
          </>
        ) : (
          <InputWrapper
            id="name"
            className={Styles['input-field']}
            methods={methods}
            label={t('add_edit_card.fieldLabel.name')}
            name={'name'}
            type={'text'}
            required={true}
            maxLength={100}
          />
        )}
      </Row>
      <InputWrapper
        id="addressLine1"
        className={Styles['input-field']}
        methods={methods}
        label={t('add_edit_card.fieldLabel.addressLine1')}
        name={'addressLine1'}
        type={'text'}
        required={true}
        maxLength={50}
      />

      <InputWrapper
        id="addressLine2"
        className={Styles['input-field']}
        methods={methods}
        label={t('add_edit_card.fieldLabel.addressLine2')}
        name={'addressLine2'}
        type={'text'}
        required={false} //https://jira.trusted.visa.com/browse/VMCP-14616
        maxLength={50}
      />
      <InputWrapper
        id="city"
        className={Styles['input-field']}
        methods={methods}
        label={t('add_edit_card.fieldLabel.city')}
        name={'city'}
        type={'text'} //https://issues.trusted.visa.com/browse/TLBLWEB-2724
        required={true}
        maxLength={20}
      />

      <Row className={Styles['input-row'] + ' two-col'}>
        <Col xs={4} sm={8} md={8} lg={5}>
          <SelectWrapper
            id="stateProvinceCode"
            methods={methods}
            label={t('add_edit_card.fieldLabel.state')}
            name={'stateProvinceCode'}
            selectList={Utils.getStates()}
            setCountry={handleStateSelect}
            required={true}
          />
        </Col>
        <Col xs={4} sm={8} md={8} lg={5}>
          <InputWrapper
            id="postalCode"
            className={Styles['input-field']}
            methods={methods}
            label={t('add_edit_card.fieldLabel.zipCode')}
            name={'postalCode'}
            type={'text'}
            required={true}
            maxLength={5}
            handleChange={handleChange}
          />
        </Col>
      </Row>
    </>
  );

  return bodyComponent;
};

export default CardBilling;
