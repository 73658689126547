import { createSlice } from '@reduxjs/toolkit';
import { resetAppData } from '../../../../app/app-reducer';
import { NotificationTypes } from '../../../../constants/user-profile';
import { sortByRecentFirstOrder } from '../../../../utils/utils';

export interface NotificationType {
  notificationDetailId: string;
  securityCounter: number;
  contactType: 'Email' | 'NUMBER';
  contactValue: string;
  phoneCountryCode?: string;
  emailFormat?: 'Html';
  platform: null;
}

export interface TransactionDetail {
  lifeCycleTraceId: string;
  transactionDisplayAmount: string;
  transactionCurrency: string;
  billingDisplayAmount: string;
  billingCurrency: string;
  merchantName: string;
  merchantCity: string;
  merchantCountryCode: string;
  merchantCategoryCode: string;
  transactionTimeStamp: Date;
}

export type AllowedNotificationTypes =
  | NotificationTypes.System
  | NotificationTypes.Community
  | NotificationTypes.TransactionAlert
  | NotificationTypes.AggregationAlert;

export interface Notification {
  portfolioNumber: string;
  billingDisplayAmount: string;
  communityCode: string;
  customerId: string;
  serviceOffering: string;
  serviceOfferingSubType: string;
  language: string;
  notificationTimeStamp: Date;
  notificationType: NotificationTypes;
  last4: string;
  notificationDetails: NotificationType[];
  transactionDetails: TransactionDetail[];
  aggregationDetail?: any;
}
export interface AlertsHistoryInterface {
  notifications: Notification[];
  totalNotifications: number;
  isError: boolean;
}
const alertsHistoryInitialData: AlertsHistoryInterface = {
  notifications: [],
  totalNotifications: 0,
  isError: false
};

const alertsHistoryReducer = createSlice({
  name: 'alertsHistory',
  initialState: alertsHistoryInitialData,
  reducers: {
    getAlertsHistory: (state: AlertsHistoryInterface, action) => {
      return state;
    },
    getAlertsHistorySuccess: (state: AlertsHistoryInterface, action) => {
      const { notifications = [], totalNotifications = 0 } = action.payload;

      state.notifications = sortByRecentFirstOrder(notifications);
      state.totalNotifications = totalNotifications;
      state.isError = false;
      return state;
    },
    getAlertsHistoryFailure: (state: AlertsHistoryInterface) => {
      state.notifications = [];
      state.totalNotifications = 0;
      state.isError = true;
      return state;
    }
  },
  extraReducers: builder => {
    builder.addCase(resetAppData, () => {
      return alertsHistoryInitialData;
    });
  }
});

export const { getAlertsHistory, getAlertsHistorySuccess, getAlertsHistoryFailure } = alertsHistoryReducer.actions;

export default alertsHistoryReducer.reducer;
