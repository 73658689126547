import AddEditCard from './components/add-edit-card/add-edit-card';
import AlertHistory from './components/alert-history/alert-history';
import CardControl from './components/card-control/card-control';
import Alerts from './components/alerts/alerts';
import ManageCards from './components/manage-cards/manage-cards';
import NoCards from './components/no-cards/no-cards';
import Home from './home';
import PrivateRoutes from '../../private-routes';

const homeRoutes = [
  {
    path: 'home',
    children: [
      {
        path: '',
        element: (
          <PrivateRoutes>
            <Home />
          </PrivateRoutes>
        )
      },
      {
        path: 'alerts',
        element: (
          <PrivateRoutes>
            <Alerts />
          </PrivateRoutes>
        )
      },
      {
        path: 'alert-history',
        element: (
          <PrivateRoutes>
            <AlertHistory />
          </PrivateRoutes>
        )
      },
      {
        path: 'card-control',
        element: (
          <PrivateRoutes>
            <CardControl />
          </PrivateRoutes>
        )
      },
      {
        path: 'manage-cards',
        element: (
          <PrivateRoutes>
            <ManageCards />
          </PrivateRoutes>
        )
      },
      {
        path: 'no-card',
        element: (
          <PrivateRoutes>
            <NoCards />
          </PrivateRoutes>
        )
      }
    ]
  },
  {
    path: 'add-card',
    element: (
      <PrivateRoutes>
        <AddEditCard />
      </PrivateRoutes>
    )
  },
  {
    path: 'edit-card',
    element: (
      <PrivateRoutes>
        <AddEditCard editCard={true} />
      </PrivateRoutes>
    )
  }
];

export default homeRoutes;
