import React from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { RedirectRoutes } from '../../../constants';
import useTranslator from '../../../hooks/translation-hook';
import { resetPassword } from '../forgot-password-reducer';
import { GenericPanelComponent } from '../../../elements/shared';
import { ButtonWrapper, InputWrapper } from '../../../elements/core-ui';
import { FormWrapper } from '../../../elements/core-ui';
import { Validator } from '../../../utils';
import { useTitle } from '../../../hooks/use-document-title';

type ResetPasswordProps = {
  [key: string]: any;
};

const ResetPassword: React.FC<ResetPasswordProps> = props => {
  const navigate = useNavigate();
  const t = useTranslator();
  const dispatch = useDispatch();

  useTitle('app.title.resetPassword');

  const forgotPasswordStore = useSelector((store: any) => store.forgotPassword);

  const initialValues = {
    userName: forgotPasswordStore.username,
    newPassword: ''
  };
  const schema = yup.object({
    newPassword: yup
      .string()
      .required('resetPassword.model.attributes.password.blank')
      .min(8, 'resetPassword.model.attributes.password.minLength')
      .test((value: any, context: any) => Validator.passwordValidator(value, context))
  });
  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: initialValues,
    resolver: yupResolver(schema)
  });

  const handleContinueClick = (data: any) => {
    const config = {
      t,
      navigate,
      state: forgotPasswordStore,
      newPassword: data.newPassword
    };
    dispatch(resetPassword(config));
  };
  const handleCancelClick = () => {
    navigate(RedirectRoutes.login);
  };

  const bodyComponent = (
    <FormWrapper onSubmit={methods.handleSubmit(handleContinueClick)}>
      <InputWrapper
        id="newPassword"
        required={true}
        type={'password'}
        methods={methods}
        name={'newPassword'}
        hidePasswordIcon={true}
        label={t('resetPassword.newPassword')}
      />
      <>
        <ButtonWrapper
          isFullWidth
          tag="button"
          type="submit"
          colorScheme="primary"
          ariaLabel={t('resetPassword.btnSubmit')}
          label={t('resetPassword.btnSubmit')}
        />
        <ButtonWrapper
          isFullWidth
          colorScheme="tertiary"
          handleClick={handleCancelClick}
          ariaLabel={t('resetPassword.btnCancel')}
          label={t('resetPassword.btnCancel')}
        />
      </>
    </FormWrapper>
  );

  return <GenericPanelComponent bodyComponent={bodyComponent} pageTitle={t('resetPassword.heading')} />;
};

export default ResetPassword;
