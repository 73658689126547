import { Row, Col, Typography } from '@visa/vds';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { InputCardWrapper, InputWrapper, InputCvvWrapper } from '../../../core-ui';
import { Validator } from '../../../../utils';
import { AllRegex, Card } from '../../../../constants';
import Styles from './card-details.module.scss';

const CardDetails = ({ methods, handleParentBlur, edit }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    formState: { errors },
    getValues,
    setError,
    clearErrors,
    setValue
  } = methods;

  const monthValidator = value => {
    const expMonth = value.substr(0, 2);
    if (Number(expMonth) > 12 || Number(expMonth) < 1) {
      setError('expiration', { message: 'add_edit_card.formValidate.expirationDate' });
    } else if (Number(expMonth) < 12 || Number(expMonth) > 0) {
      // dispatch(clearAlert());
    }
  };

  const handleChange = (name, e) => {
    let result = e.target.value;
    if (name === 'expiration') {
      if (result.length === 1) {
        // if initially entered value is greater than 1 it will append zero (i.e ==> 2 == 02) as month is max 12
        const appendZeroBefore = /[2-9]/.test(result);
        if (appendZeroBefore) {
          result = '0' + result;
          setValue(name, result);
          return;
        }
      }
      result.length == 2 && monthValidator(result);
      const replaceValue = result.replace(/[^0-9]/gi, '');
      // (replace any character other than numbers to empty string)
      const matchArr = replaceValue.match(/\d/g);
      if (matchArr?.length > 2) {
        const month = matchArr.join('').substr(0, 2) + '/';
        const year = matchArr.join('').substr(2, 2);
        const isValidCardExpiry = result.length > 2 && Validator.cardExpiration(result);

        !isValidCardExpiry
          ? setError('expiration', { message: 'add_edit_card.formValidate.expirationDate' })
          : clearErrors(name);
        result = month + year;
        setValue(name, result);
        return;
      } else {
        setValue(name, replaceValue);
        return;
      }
    }
  };

  const handleBlur = (name, errorMessage?) => {
    // initially validate filed on blur
    const value = getValues(name);
    if (name === 'accountNumber') {
      errorMessage && getValues(name) && setError(name, { message: errorMessage });
    }
    if (name === 'cvv') {
      if (!AllRegex.cvvRegex.test(value)) {
        setError(name, { message: 'add_edit_card.formValidate.cvvLength' });
      } else {
        if (value.length === 3) clearErrors('cvv');
      }
    }
    if (name === 'expiration') {
      if (value.length < 5 && value.length > 0) {
        setError(name, { message: 'add_edit_card.formValidate.expirationDate' });
      }
      if (value.length === 5) {
        const isValidCardExpiry = Validator.cardExpiration(value);
        !isValidCardExpiry && setError('expiration', { message: 'add_edit_card.formValidate.expirationDate' });
      }
    }

    if (name === 'accountNickname') {
      const cardNickNameValidator = Validator.cardNickNameValidator(value);

      if (!cardNickNameValidator) {
        setError('accountNickname', { message: 'add_edit_card.formValidate.invalidCardNickName' });
      } else {
        clearErrors('accountNickname');
      }
    }
    handleParentBlur && handleParentBlur();
  };

  const bodyComponent = edit ? (
    <>
      <Typography className={Styles['edit-card-label']}>{t('add_edit_card.fieldLabel.editCardNumber')}</Typography>
      <Typography className={Styles['edit-card-ellipses']}>{'....' + getValues('accountNumber')}</Typography>
      <InputWrapper
        id="accountNickname"
        methods={methods}
        label={t('add_edit_card.fieldLabel.cardNickname')}
        name={'accountNickname'}
        type={'text'} //https://issues.trusted.visa.com/browse/TLBLWEB-2724
        required={true}
        maxLength={15}
        handleBlur={handleBlur}
        handleChange={handleChange}
      />
      <Row className={Styles['input-row'] + ' two-col'}>
        <Col xs={4} sm={8} md={8} lg={5}>
          <InputWrapper
            id="expiration"
            className={`${Styles['input-field']} ${Styles['expiration']}`}
            methods={methods}
            label={t('add_edit_card.fieldLabel.expirationDate')}
            name={'expiration'}
            type={'text'}
            required={true}
            maxLength={5}
            placeholder={'mm/yy'}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
        </Col>
        <Col xs={4} sm={8} md={8} lg={5}>
          <InputCvvWrapper
            id="cvv"
            className={Styles['input-field'] + ' code-input-cvv'}
            methods={methods}
            label={t('add_edit_card.fieldLabel.securityCode')}
            name={'cvv'}
            required={true}
            maxLength={3}
            handleBlur={handleBlur}
          />
        </Col>
      </Row>
    </>
  ) : (
    <>
      <InputCardWrapper
        id="accountNumber"
        methods={methods}
        required={true}
        label={t('add_edit_card.fieldLabel.cardNumber')}
        name={'accountNumber'}
        acceptedCards={[Card.VISA]}
        cardBins={[400444, 412356]}
        handleBlur={handleBlur}
      />
      <Row className={Styles['input-row'] + ' two-col'}>
        <Col xs={4} sm={8} md={8} lg={5}>
          <InputWrapper
            id="expiration"
            className={`${Styles['input-field']} ${Styles['expiration']}`}
            methods={methods}
            label={t('add_edit_card.fieldLabel.expirationDate')}
            name={'expiration'}
            type={'text'}
            required={true}
            maxLength={5}
            placeholder={'mm/yy'}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
        </Col>
        <Col xs={4} sm={8} md={8} lg={5}>
          <InputCvvWrapper
            id="cvv"
            className={Styles['input-field'] + ' code-input-cvv'}
            methods={methods}
            label={t('add_edit_card.fieldLabel.securityCode')}
            name={'cvv'}
            required={true}
            maxLength={3}
            handleBlur={handleBlur}
          />
        </Col>
      </Row>
      <InputWrapper
        id="accountNickname"
        methods={methods}
        label={t('add_edit_card.fieldLabel.cardNickname')}
        name={'accountNickname'}
        type={'text'} //https://issues.trusted.visa.com/browse/TLBLWEB-2724
        required={true}
        maxLength={15}
        handleBlur={handleBlur}
        handleChange={handleChange}
      />
    </>
  );

  return bodyComponent;
};

export default CardDetails;
