import { useNavigate } from 'react-router-dom';
import { Row, Typography } from '@visa/vds';
import { useDispatch } from 'react-redux';
import { RedirectRoutes } from '../../../../constants';
import useTranslator from '../../../../hooks/translation-hook';
import { CardArtComponent, GenericPanelComponent } from '../../../../elements/shared';
import { ButtonWrapper } from '../../../../elements/core-ui';
import { resetFlags } from '../../../../elements/shared/add-edit-card/add-edit-card-reducer';
import styles from './no-cards.module.scss';
import { useTitle } from '../../../../hooks/use-document-title';

const NoCards = () => {
  const navigate = useNavigate();
  const t = useTranslator();
  const dispatch = useDispatch();
  
  useTitle('app.title.noCards');

  const handleAddCardClick = () => {
    dispatch(resetFlags());
    navigate(RedirectRoutes.check);
  };

  const bodyComponent = (
    <>
      <Row className={styles['cardart-row']}>
        <CardArtComponent showAdd={true} theme="grey" handleIconClick={handleAddCardClick} />
      </Row>
      <Row className={styles['button-row']}>
        <ButtonWrapper
          type="button"
          tag="button"
          isFullWidth
          colorScheme="primary"
          ariaLabel={t('noCards.addCardBtn')}
          label={t('noCards.addCardBtn')}
          handleClick={handleAddCardClick}
        />
      </Row>
    </>
  );

  const pageSubTitle = (
    <Typography tag="h2" className={`panel-text ${styles['page-subtitle']}`}>
      {t('noCards.subTitle')}
    </Typography>
  );

  return (
    <>
      <GenericPanelComponent
        pageTitle={t('noCards.title')}
        bodyComponent={bodyComponent}
        pageSubTitle={pageSubTitle}
        containerClass={styles['panel-card']}
      />
    </>
  );
};

export default NoCards;
