import { ReactElement, useState } from 'react';
import { InputCvv } from '@visa/vds';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface InputCvvWrapperProps {
  id: string;
  className?: string;
  methods: Record<string, any>;
  label: string | ReactElement;
  name: string;
  maxLength?: number;
  handleBlur?: Function;
  handleFocus?: Function;
  required?: boolean;
}

const InputCvvWrapper: React.FC<InputCvvWrapperProps> = ({
  id = '',
  className,
  methods,
  label,
  name,
  maxLength,
  handleBlur,
  handleFocus,
  required = false
}) => {
  const {
    formState: { errors },
    control
  } = methods;

  const { t } = useTranslation();
  const [type, setType] = useState('text');

  const handleInputCvvBlur = e => {
    const { value } = e.target;
    value.length === maxLength && setType('password');
    handleBlur && handleBlur(name, value);
  };

  const handleInputCvvFocus = () => {
    handleFocus && handleFocus(name);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <InputCvv
            id={id}
            type={type}
            maxLength={maxLength}
            className={className ? className : null}
            showInfoButton={false}
            label={label}
            onChange={(e: any) => field.onChange(e)}
            onBlur={e => handleInputCvvBlur(e)}
            onFocus={() => handleInputCvvFocus()}
            required={errors[name]?.message ? true : false}
            showErrorText={errors[name]?.message ? true : false}
            errorText={t(`${errors[name]?.message}`)}
            invalid={errors[name]?.message ? true : false}
            noValidate
            aria-required={Boolean(required)}
          />
        );
      }}
    />
  );
};

export default InputCvvWrapper;
