import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@visa/vds';
import Icon from '@visa/vds/icon';
import { GenericPanelComponent, showAlert, clearAlert } from '../index';
import { FormWrapper, ButtonWrapper, DialogWrapper } from '../../core-ui';
import { Validator, Utils } from '../../../utils';
import { RedirectRoutes, Card, AllRegex } from '../../../constants';
import { getUserDetails, resetPaymentInstruments } from '../../../pages/home/home-reducer';
import {
  clearSelectedEditCard,
  setSelectedEditCard
} from '../../../pages/home/components/manage-cards/manage-cards-reducer';
import CardDetails from './card-details/card-details';
import CardBilling from './card-billing/card-billing';
import CardProfile from './card-profile/card-profile';
import Styles from './add-edit-card.module.scss';
import { postPaymentInstruments, patchPaymentInstruments, deleteCard, createEncryptedPayload } from './add-edit-card-reducer';
import { getAddDefaultValues, getEditDefaultValues, getAddPayload, getEditPayload } from './utility';

type AddCardProps = {
  edit: boolean;
  postLogin?: boolean;
};

const AddEditCard: React.FC<AddCardProps> = ({ edit = false, postLogin = false }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [remove, setRemove] = React.useState(false);
  const { enroll, checkEligibility, userAuth, addEditCard, userDetails, manageCard, alert, app } = useSelector(
    (state: any) => state
  );
  const { card } = manageCard;
  const { encNonce } = app;
  const isLac = Utils.isLac();

  const defaultValues = getAddDefaultValues(checkEligibility, enroll, userDetails);

  useEffect(() => {
    if (edit) {
      document.title = `${t('app.title.editCard')} - ${t('app.title.visaPurchaseAlerts')}`;
    } else {
      document.title = `${t('app.title.addCard')} - ${t('app.title.visaPurchaseAlerts')}`;
    }
  }, [edit, t]);
  const cardSchema = edit
    ? yup.object({
        expiration: yup
          .string()
          .min(5, 'add_edit_card.formValidate.expirationDate')
          .required('add_edit_card.formValidate.expirationDate')
          .test((value, context) => Validator.cardExpiration(value, context)),
        cvv: yup
          .string()
          .min(3, 'add_edit_card.formValidate.cvvLength')
          .required('add_edit_card.formValidate.cvvLength'),
        accountNickname: yup
          .string()
          .trim()
          .required('add_edit_card.formValidate.cardNickname')
          .test((value: any, context: any) => Validator.cardNickNameValidator(value, context))
      })
    : yup.object({
        accountNumber: yup
          .string()
          .min(19, 'add_edit_card.formValidate.accountNumber')
          .required('add_edit_card.formValidate.accountNumber'),
        expiration: yup
          .string()
          .min(5, 'add_edit_card.formValidate.expirationDate')
          .required('add_edit_card.formValidate.expirationDate')
          .test((value, context) => Validator.cardExpiration(value, context)),
        cvv: yup
          .string()
          .min(3, 'add_edit_card.formValidate.cvvLength')
          .required('add_edit_card.formValidate.cvvLength'),
        accountNickname: yup
          .string()
          .trim()
          .required('add_edit_card.formValidate.cardNickname')
          .test((value: any, context: any) => Validator.cardNickNameValidator(value, context))
      });

  const billingSchema = !edit
    ? yup.object({
        firstName: yup.string().trim().required('add_edit_card.formValidate.firstName'),
        lastName: yup.string().trim().required('add_edit_card.formValidate.lastName'),
        addressLine1: yup.string().trim().required('add_edit_card.formValidate.addressLine1'),
        city: yup.string().trim().required('add_edit_card.formValidate.city'),
        postalCode: yup.string().trim().required('add_edit_card.formValidate.zipCode'),
        stateProvinceCode: yup.string().required('add_edit_card.formValidate.State')
      })
    : yup.object({
        name: yup.string().trim().required('add_edit_card.formValidate.name'),
        addressLine1: yup.string().trim().required('add_edit_card.formValidate.addressLine1'),
        city: yup.string().trim().required('add_edit_card.formValidate.city'),
        postalCode: yup.string().trim().required('add_edit_card.formValidate.zipCode'),
        stateProvinceCode: yup.string().required('add_edit_card.formValidate.State')
      });
  const profileSchema = !edit
    ? Utils.isVI()
      ? yup.object({
          firstName: yup.string().trim().required('add_edit_card.formValidate.firstName'),
          lastName: yup.string().trim().required('add_edit_card.formValidate.lastName'),
          postalCode: yup
            .string()
            .trim()
            .required('add_edit_card.formValidate.postalCode')
            .test((value: any, context: any) => Validator.postalCodeValidator(value, context))
        })
      : yup.object({
          firstName: yup.string().trim().required('add_edit_card.formValidate.firstName'),
          lastName: yup.string().trim().required('add_edit_card.formValidate.lastName'),
          postalCode: yup
            .string()
            .trim()
            .test((value: any, context: any) => Validator.postalCodeValidator(value, context))
        })
    : Utils.isVI()
    ? yup.object({
        name: yup.string().trim().required('add_edit_card.formValidate.name'),
        postalCode: yup
          .string()
          .trim()
          .required('add_edit_card.formValidate.postalCode')
          .test((value: any, context: any) => Validator.postalCodeValidator(value, context))
      })
    : yup.object({
        name: yup.string().trim().required('add_edit_card.formValidate.name'),
        postalCode: yup
          .string()
          .trim()
          .test((value: any, context: any) => Validator.postalCodeValidator(value, context))
      });

  const schema = !isLac ? Utils.mergeSchema(cardSchema, billingSchema) : Utils.mergeSchema(cardSchema, profileSchema);

  const methods = useForm<any>({
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
    mode: 'onBlur',
    reValidateMode: 'onBlur'
  });

  const {
    formState: { errors, submitCount },
    getValues,
    setValue,
    setError,
    clearErrors
  } = methods;

  useEffect(() => {
    if (edit && userDetails.vuser?.firstName && card) {
      // this is for edit card update the form default value
      const defaultValues = getEditDefaultValues(card.panGuid, userDetails);
      for (const key in defaultValues) setValue(key, defaultValues[key]);
    }
    if (card?.panGuid && userDetails?.paymentInstruments?.length) {
      const selectedCard = userDetails.paymentInstruments.find(cards => cards.panGuid === card.panGuid);
      dispatch(setSelectedEditCard(selectedCard));
    }
  }, [userDetails, card]);

  useEffect(() => {
    if (userAuth?.userGuid && userAuth?.accessToken) dispatch(getUserDetails());
  }, []);

  useEffect(() => {
    if (errors && Object.keys(errors).length) {
      if (errors.hasOwnProperty('accountNumber')) {
        document.getElementById('accountNumber')?.focus();
      } else if (!errors.hasOwnProperty('expiration') && errors.hasOwnProperty('cvv')) {
        document.getElementById('cvv')?.focus();
      }
    }
  }, [errors]);
  //deleting unverified contacts in secure call (deleting in add-card is not needed)
  // useEffect(() => {
  //   if (userDetails.vuser?.contacts.length) {
  //     const unverifiedEmailContact = Utils.getContactGuid(userDetails.vuser.contacts, ContactDetail.EMAIL);
  //     const unverifiedPhoneContact = Utils.getContactGuid(userDetails.vuser.contacts, ContactDetail.PHONE);
  //     unverifiedEmailContact && dispatch(deleteUnverifiedEmail({ unverifiedEmailContact: unverifiedEmailContact }));
  //     unverifiedPhoneContact && dispatch(deleteUnverifiedPhone({ unverifiedPhoneContact: unverifiedPhoneContact }));
  //   }
  // }, [userDetails.vuser?.contacts]);

  const handleBackIconClick = () => {
    dispatch(clearSelectedEditCard()); // in edit card page if the user clicks back icon clear the selected card.
    edit ? navigate(RedirectRoutes.homeManageCards) : navigate(RedirectRoutes.check);
  };

  const handleBlur = () => {
    // if (Object.keys(errors).length === Object.keys(defaultValues).length - 2 && submitCount) {
    //   dispatch(clearAlert());
    // }
    // if (Object.keys(errors).length !== Object.keys(defaultValues).length - 2 && !submitCount) {
    //   dispatch(clearAlert());
    // }
  };

  const handleError = errorsList => {
    // global errors only on submit
    let fieldContainsValue = false;
    const values = getValues();
    for (const key in values) {
      fieldContainsValue = Boolean(values[key]);
      if (fieldContainsValue) break;
    }
  };

  const handleRemoveCard = () => {
    dispatch(deleteCard({ card_id: card.panGuid, navigate: navigate }));
  };

  //on successfully deleting the last card, resetting payment instruments while redirecting to home.
  useEffect(() => {
    if (addEditCard.deleteCard.status) {
      if (userDetails.numberOfCards === 1) {
        dispatch(resetPaymentInstruments());
      } else if (userDetails.numberOfCards > 1) {
        navigate(RedirectRoutes.homeManageCards);
      }
      dispatch(
        showAlert({
          type: 'SUCCESS',
          message: 'add_edit_card.delete.deletedSuccessfully'
        })
      );
    }
  }, [addEditCard.deleteCard.status]);

  const submit = data => {
    if (data.cardType && data.cardType !== Card.VISA) {
      setError('accountNumber', { message: 'add_edit_card.error.notSupported' });
      return;
    }
    if (!AllRegex.cvvRegex.test(data.cvv)) {
      setError('cvv', { message: 'add_edit_card.formValidate.cvvLength' });
      return;
    }
    //We first call encripted api, which will return encripted data - We send this to web sdk which returns an identity token
    //We use this identity token for post payment instrument api call. We will get the data from a  util method for that call. 
    if (!edit && encNonce) {
      dispatch(createEncryptedPayload({ data, navigate, t }));
    } else {
      const paymentInstrumentData = edit ? getEditPayload(data, card) : getAddPayload(data);
      const payload = {
        paymentInstrument: paymentInstrumentData
      };
      edit
        ? dispatch(patchPaymentInstruments({ data: paymentInstrumentData, navigate: navigate, card_id: card.panGuid }))
        : dispatch(postPaymentInstruments({ data: payload, navigate: navigate, t }));
      dispatch(clearAlert());
    }
  };

  //https://issues.trusted.visa.com/browse/MOBENB-19506
  const handleCancelBtnClick = () => {
    if (userAuth.isFullyLogged && postLogin) {
      handleBackIconClick();
    } else {
      navigate(RedirectRoutes.home);
    }
  };

  const removeCardLabel = (
    <>
      <Icon className={Styles['delete-icon']} name="delete" resolution="low" />
      {t('add_edit_card.edit.removeCard')}
    </>
  );

  const bodyComponent = (
    <FormWrapper onSubmit={methods.handleSubmit(submit, handleError)}>
      <CardDetails methods={methods} handleParentBlur={handleBlur} edit={edit} />
      {!isLac ? <CardBilling methods={methods} edit={edit} /> : <CardProfile methods={methods} edit={edit} />}
      <ButtonWrapper
        tag="button"
        type="submit"
        isFullWidth
        colorScheme="primary"
        ariaLabel={t('add_edit_card.button.save')}
        label={t('add_edit_card.button.save')}
        className={Styles['save-btn']}
      />
      {edit ? (
        <>
          <ButtonWrapper
            isFullWidth
            colorScheme="tertiary"
            label={t('add_edit_card.edit.cancel')}
            ariaLabel={t('add_edit_card.edit.cancel')}
            handleClick={() => navigate(RedirectRoutes.homeManageCards)}
          />
          <ButtonWrapper
            id="removeCardBtn"
            className={Styles['remove-btn']}
            isFullWidth
            colorScheme="tertiary"
            ariaLabel={t('add_edit_card.edit.removeCard')}
            label={removeCardLabel}
            handleClick={() => setRemove(true)}
            buttonType="delete"
          />
        </>
      ) : (
        <ButtonWrapper
          isFullWidth
          colorScheme="tertiary"
          ariaLabel={
            userAuth.isFullyLogged && postLogin ? t('add_edit_card.edit.cancel') : t('add_edit_card.button.skip')
          }
          label={userAuth.isFullyLogged && postLogin ? t('add_edit_card.edit.cancel') : t('add_edit_card.button.skip')}
          handleClick={handleCancelBtnClick}
        />
      )}
    </FormWrapper>
  );

  const pageSubTitle = (
    <Typography tag="h2" className={`panel-text ${Styles['page-subtitle']}`}>
      {t('add_edit_card.heading.subTitle')}
    </Typography>
  );

  const actionDialogButtons = (
    <>
      <ButtonWrapper
        type="button"
        tag="button"
        isFullWidth
        colorScheme="primary"
        label={t('add_edit_card.button.remove')}
        handleClick={handleRemoveCard}
        ariaLabel={t('add_edit_card.button.remove')}
      />
      <ButtonWrapper
        tag="button"
        type="button"
        isFullWidth
        colorScheme="tertiary"
        label={t('add_edit_card.button.cancel')}
        ariaLabel={t('add_edit_card.button.cancel')}
        handleClick={() => setRemove(false)}
      />
    </>
  );

  const dialogBody = (
    <p role="alert">
      {t('add_edit_card.delete.deleteNote')}
    </p>
  );

  return edit && remove ? (
    <DialogWrapper
      role="alertdialog" //allows narrator to read dialog content.
      hasCloseIcon={true}
      open={remove}
      body={dialogBody}
      actionButtons={actionDialogButtons}
      handleCloseIconClick={() => setRemove(false)}
      parentActiveElementId={'removeCardBtn'}
    />
  ) : (
    <GenericPanelComponent
      backIcon={postLogin}
      bodyComponent={bodyComponent}
      pageTitle={edit ? t('add_edit_card.edit.title') : t('add_edit_card.heading.title')}
      pageSubTitle={pageSubTitle}
      panelMainText={t('add_edit_card.subHeading.cardDetails')}
      handleIconClick={handleBackIconClick}
      panelCardSubText={t('add_edit_card.subHeading.info')}
    />
  );
};

export default AddEditCard;
