import { createSlice } from '@reduxjs/toolkit';
import { resetAppData } from '../../app/app-reducer';

interface ForgotPassword {
  vuser: any;
  accessToken: string;
  OTPError: any;
  OTPSuccess: boolean;
  username: string;
  userGuid: string;
  resetPasswordError: any;
  resetPasswordSuccess: boolean;
  requestForgotPasswordError: any;
}

const initialState: ForgotPassword = {
  vuser: {},
  accessToken: '',
  username: '',
  userGuid: '',
  OTPError: null,
  OTPSuccess: false,
  resetPasswordError: null,
  resetPasswordSuccess: false,
  requestForgotPasswordError: null
};

const forgotPasswordReducer = createSlice({
  name: 'forgot-password',
  initialState,
  reducers: {
    //  /forgot-password
    forgotPassword: (state: any, action: any) => {
      state.username = action.payload.username;
      return state;
    },
    forgotPasswordSuccess: (state, action) => {
      state.vuser = action.payload.data;
      state.accessToken = action.payload.access_token;
      state.userGuid = action.payload.userGuid;
      state.requestForgotPasswordError = null;
      return state;
    },
    forgotPasswordFailure: (state, action) => {
      state.userGuid = '';
      state.accessToken = '';
      state.requestForgotPasswordError = action.payload;
      return state;
    },

    //  /forgot-password/verify-code

    forgotPasswordOtp: (state, action) => {
      return state;
    },
    forgotPasswordOtpSuccess: (state, action) => {
      state.vuser = action.payload.data || {};
      state.accessToken = action.payload.access_token;
      state.OTPSuccess = true;
      return state;
    },
    forgotPasswordOtpFailure: (state, action) => {
      state.OTPSuccess = false;
      state.OTPError = action.payload;
      return state;
    },

    forgotPasswordResendOtp: (state, action) => {
      return state;
    },
    forgotPasswordResendOtpSuccess: (state, action) => {
      state.vuser = action.payload.data || {};
      state.accessToken = action.payload.access_token;
      state.OTPSuccess = true;
      return state;
    },
    forgotPasswordResendOtpFailure: (state, action) => {
      state.OTPSuccess = false;
      state.OTPError = action.payload;
      return state;
    },

    //  /forgot-password/reset-password

    resetPassword: (state, action) => {
      return state;
    },
    resetPasswordSuccess: (state, action) => {
      state.resetPasswordSuccess = true;
      return state;
    },
    resetPasswordFailure: (state, action) => {
      state.resetPasswordSuccess = false;
      state.resetPasswordError = action.payload;
      return state;
    },

    restoreForgotPasswordData: (state, action) => {
      // this method is only used for restoring the forgot password state on reload
      state = action.payload;
      return state;
    }
  },
  extraReducers: builder => {
    builder.addCase(resetAppData, () => {
      return initialState;
    });
  }
});

export const {
  forgotPassword,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  forgotPasswordOtp,
  forgotPasswordOtpFailure,
  forgotPasswordOtpSuccess,
  forgotPasswordResendOtp,
  forgotPasswordResendOtpSuccess,
  forgotPasswordResendOtpFailure,
  resetPassword,
  resetPasswordFailure,
  resetPasswordSuccess,
  restoreForgotPasswordData
} = forgotPasswordReducer.actions;

export default forgotPasswordReducer.reducer;
