import 'focus-visible/dist/focus-visible.min.js';
import '@visa/vds/_stys/vds-modern.min.css';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import App from './app/app';
import translator from './i18n';
import store from './setup/store';
import { Utils } from './utils';
import './index.scss';

translator([Utils.getLocale()]);
////////
///Below can be used to extend global window object
////////

declare global {
  interface Window {
    visa: any;
    WebSDK: any;
    webSDKInstance: any;
  }
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
