import { put, takeLatest, call, select } from 'redux-saga/effects';
import { GlobalConstants, ReasonCodes, RedirectRoutes } from '../../constants';
import { showAlert } from '../../elements/shared';
import { ajaxHandler, apiPaths } from '../../setup';
import { Utils } from '../../utils';
import {
  resetPassword,
  forgotPassword,
  forgotPasswordOtp,
  resetPasswordSuccess,
  resetPasswordFailure,
  forgotPasswordFailure,
  forgotPasswordSuccess,
  forgotPasswordOtpSuccess,
  forgotPasswordOtpFailure,
  forgotPasswordResendOtp,
  forgotPasswordResendOtpSuccess,
  forgotPasswordResendOtpFailure
} from './forgot-password-reducer';

function* forgotPasswordHandler(action) {
  const { payload } = action;
  const data = {
    grant_type: 'idproof_promise',
    promises: [
      {
        ref: 1,
        data_kind: 'USERNAME',
        data: payload.username
      }
    ],
    response_type: ['token'],
    function: 'FORGOT_PASSWORD',
    software_statement_claimset: {
      software_id: 'PAS',
      software_version: '23.01',
      software_mode_hint: '1',
      oauth2_version: '1.0',
      tenancy_context: {
        product_code: 'PAS',
        app_id: GlobalConstants.extAppId
      },
      uebas: [
        {
          ueba_data: 'threat_mextrix_uebaData',
          ueba_id: Utils.getServerSessionId(),
          ueba_source: 'THREAT_METRIX'
        }
      ]
    }
  };
  const response = yield call(ajaxHandler, apiPaths.forgotPassword.b2cSendEmailUrl, false, {
    method: 'POST',
    data,
    urlWithPlaceholder: apiPaths.forgotPassword.b2cSendEmailUrl
  });
  if (!response.error) {
    yield put(forgotPasswordSuccess(response.data));
    yield put(showAlert({ type: 'SUCCESS', message: 'forgotPassword.codeSendSuccess' }));
    if (action.payload.navigate) {
      action.payload.navigate(RedirectRoutes.forgotPasswordVerifyCode);
    }
  } else {
    yield put(forgotPasswordFailure(response.error.response));
  }
}

function* forgotPasswordResendOtpHandler(action) {
  const { payload } = action;

  const data = {
    grant_type: 'authorization_probe_reissuance',
    probes_ref: [
      {
        data: {
          ref: 1
        }
      }
    ]
  };
  const headers = {
    access_token: payload.state.accessToken
  };

  const response = yield call(ajaxHandler, apiPaths.forgotPassword.b2cReissuance, false, {
    method: 'POST',
    data,
    headers,
    urlWithPlaceholder: apiPaths.forgotPassword.b2cReissuance
  });
  if (!response.error) {
    yield put(showAlert({ type: 'SUCCESS', message: 'forgotPassword.codeSendSuccess' }));
    yield put(forgotPasswordResendOtpSuccess(response.data));
  } else {
    yield put(forgotPasswordResendOtpFailure(response));
  }
}

function* forgotPasswordOtpHandler(action) {
  const { payload } = action;

  const data = {
    grant_type: 'authorization_probe',
    probes_ref: [
      {
        data: {
          type: 'OTP',
          value: payload.otp
        }
      }
    ]
  };
  const headers = {
    access_token: payload.state.accessToken
  };

  const response = yield call(ajaxHandler, apiPaths.auth.b2cVerifyOtvcForgotCred, false, {
    method: 'POST',
    data,
    headers,
    urlWithPlaceholder: apiPaths.auth.b2cVerifyOtvcForgotCred
  });
  if (!response.error) {
    yield put(forgotPasswordOtpSuccess(response.data));
    if (action.payload.navigate) {
      action.payload.navigate(RedirectRoutes.resetPassword);
    }
  } else {
    // console.log(response.error);
    yield put(forgotPasswordOtpFailure(response));
  }
}
function* resetPasswordHandler(action) {
  const { payload } = action;
  const { forgotPassword } = yield select((store: any) => store);
  const response = yield call(ajaxHandler, apiPaths.resetPassword.b2cResetPasswordUrl, false, {
    method: 'PUT',
    data: {
      credentials: {
        to: payload.newPassword
      }
    },
    headers: {
      access_token: forgotPassword.accessToken
    },
    urlWithPlaceholder: apiPaths.resetPassword.b2cResetPasswordUrl
  });
  if (!response.error) {
    yield put(resetPasswordSuccess(response.data));
    yield put(showAlert({ type: 'SUCCESS', message: 'resetPassword.resetSuccess' }));
    if (action.payload.navigate) {
      action.payload.navigate(RedirectRoutes.login);
    }
  } else {
    const type = 'ERROR';
    let message = response.error.errorMessage;
    if (response.error?.response?.data) {
      const errorData = response.error.response.data;

      if (
        Array.isArray(errorData.error_details) &&
        Boolean(errorData.error_details.length) &&
        errorData.error_details[0].error === ReasonCodes.CREDENTIAL_HISTORY
      ) {
        message = 'errors.resetPassword.noDuplicateNew';
      } else if (
        Array.isArray(errorData.error_details) &&
        Boolean(errorData.error_details.length) &&
        errorData.error_details[0].error === ReasonCodes.CREDENTIAL_DENYLIST
      ) {
        message = 'check.errorCodes.CREDENTIAL_DENYLIST';
      }
    }
    yield put(showAlert({ type, message }));

    yield put(resetPasswordFailure(response));
  }
}

export default function* forgotPasswordWatchers() {
  yield takeLatest(resetPassword, resetPasswordHandler);
  yield takeLatest(forgotPassword, forgotPasswordHandler);
  yield takeLatest(forgotPasswordOtp, forgotPasswordOtpHandler);
  yield takeLatest(forgotPasswordResendOtp, forgotPasswordResendOtpHandler);
}
