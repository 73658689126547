import { put, takeLatest, call, select } from 'redux-saga/effects';
import { ajaxHandler, apiPaths } from '../../../setup';
import { replaceUserGuid } from '../../../utils/utils';
//import { encodeUsingUrlEncoding } from '../../utils/helper';
import { showAlert } from '../../../elements/shared';
import { resetSuccessFlags, setAuthCode } from '../../sign-in/sign-in/sign-in-reducer-slice';
import { setAccessToken, setFullyLogged, setToken2Success } from '../../../auth/user-auth-reducer';
import { Utils } from '../../../utils';
import RedirectRoutes from '../../../constants/redirect-routes';
import {
  postAcceptTnC,
  postAcceptTnCRequestFailure,
  postAcceptTnCSuccess,
  resetTncFlags
} from './accept-pending-tnc-slice';

function* postAcceptTnCHandler(action) {
  const { navigate } = action.payload;
  const signin = yield select(state => state.signin);
  const { userAuth } = yield select((store: any) => store);
  const termsGuid = signin.configurationsResponseSuccess?.terms?.map(terms => {
    return {
      value_kind: 'GUID',
      value: terms.termsGuid
    };
  });

  let b2cTNCPayload = {};
  b2cTNCPayload = {
    grant_type: 'authorization_probe',
    probes_ref: [
      {
        data: {
          value_set: termsGuid,
          type: 'APP#TNC'
        }
      }
    ],
    data: {
      ...signin.configurationsResponseSuccess
    }
  };

  const postAcceptTnCURL = replaceUserGuid(
    apiPaths.b2cUserTerms,
    userAuth.userGuid
  );
  const response = yield call(ajaxHandler, postAcceptTnCURL, false, {
    data: b2cTNCPayload,
    method: 'POST',
    headers: { Authorization: 'Bearer ' + userAuth.accessToken },
    urlWithPlaceholder: apiPaths.b2cUserTerms
  });
  if (!response.error) {
    if (response.data?.scope?.includes('/credentials/reset')) {
      yield put(setAccessToken(response.data.access_token));
      navigate(RedirectRoutes.forcePasswordReset);
    } else {
      yield put(setAccessToken(response.data.access_token));
      yield put(setFullyLogged());
      yield put(setToken2Success());
      yield put(
        showAlert({
          type: 'SUCCESS',
          message: 'signin.lastLoginTime',
          isDynamic: true,
          dynamicParts: [userAuth.lastLoginTimeStamp]
        })
      );
    }
    yield put(setAuthCode(response.headers['x-auth-code']));
  } else {
    yield put(resetTncFlags());
    yield put(resetSuccessFlags());
    yield put(postAcceptTnCRequestFailure(response.error));
  }
}

export default function* watchAcceptTnC() {
  yield takeLatest(postAcceptTnC, postAcceptTnCHandler);
}
