import { put, takeLatest, call, select } from 'redux-saga/effects';
import { RedirectRoutes } from '../../../constants';
import { showAlert } from '../../../elements/shared';
import { ajaxHandler, apiPaths } from '../../../setup';
import { replaceUserGuid } from '../../../utils/utils';

import {
  deletePreviousPhone,
  deletePreviousPhoneFailure,
  deletePreviousPhoneSuccess,
  setGuid,
  updatePhone,
  updatePhoneFailure,
  updatePhoneSuccess
} from './update-phone.reducer';

function* updatePhoneHandler(action) {
  const { userAuth } = yield select((state: any) => state);
  const { payload } = action;

  const headers = {
    access_token: userAuth.accessToken
  };
  const phoneUrl = replaceUserGuid(apiPaths.phoneUrl, userAuth.userGuid);
  const response = yield call(ajaxHandler, phoneUrl, false, {
    data: payload?.data,
    headers,
    method: 'POST',
    urlWithPlaceholder: apiPaths.phoneUrl
  });
  const updateGuid = response?.headers?.location?.split('/')?.reverse()?.[0];
  if (!response.error) {
    yield put(updatePhoneSuccess(response.data));
    yield put(setGuid(updateGuid));
  } else {
    yield put(updatePhoneFailure(response.error.response));
    if (payload.navigate && response.error.reason === 'PROFILE_2001') {
      const type = 'ERROR';
      const message = 'updatePhone.errorCodes.PROFILE_2001';
      yield put(showAlert({ type, message }));
      payload.navigate(RedirectRoutes.profile);
    }
  }
}

function* deletePreviousPhoneHandler(action) {
  const { userAuth } = yield select((state: any) => state);
  const previousPhoneGuid = yield select(state => state.userDetails?.guid);
  const payload = {};

  const headers = {
    access_token: userAuth.accessToken
  };

  const phoneUrl = replaceUserGuid(apiPaths.phoneUrl, userAuth.userGuid);
  const response = yield call(ajaxHandler, `${phoneUrl}/${previousPhoneGuid}`, false, {
    data: payload,
    headers,
    method: 'DELETE',
    urlWithPlaceholder: apiPaths.phoneUrl
  });

  if (!response.error) {
    yield put(deletePreviousPhoneSuccess(response.data));
  } else {
    yield put(deletePreviousPhoneFailure(response.error.response));
  }
}

export default function* watchUpdatePhone() {
  yield takeLatest(updatePhone, updatePhoneHandler);
  yield takeLatest(deletePreviousPhone, deletePreviousPhoneHandler);
}
