import React from 'react';
import Typography from '@visa/vds/typography';
import { GenericPanelComponent } from '../../../elements/shared';
import { useTitle } from '../../../hooks/use-document-title';
import useTranslator from '../../../hooks/translation-hook';
import styles from './supported-carriers.module.scss';

const SupportedCarriers: React.FC = () => {
  const t = useTranslator();
  useTitle('app.title.supportedCarriers');
  const bodyComponent = (
    <>
      <Typography tag="h4" className={styles['body']}>
        {t('supportedCarriers.body')}
      </Typography>
    </>
  );
  return (
    <GenericPanelComponent
      postLogin={true}
      bodyComponent={bodyComponent}
      pageTitle={t('supportedCarriers.heading')}
      containerClass={styles['container']}
    />
  );
};

export default SupportedCarriers;
