import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { getCountryConfigration } from './pages/create-account/check/check-reducer-slice';
import store from './setup/store';
import { GlobalConstants } from './constants';
import { Utils } from './utils';

store.subscribe(() => {
  return;
});

if (Utils.getCountryCode()) {
  const payload = {
    country_pref: Utils.getCountryCode()
  };
  store.dispatch(getCountryConfigration(payload));
}

export default function translator(supportedLngs?) {
  i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(Backend)
    .init({
      // lng: window.location.search.split('=')[1] || localStorage.getItem('i18nextLng') || undefined,
      fallbackLng: 'en',
      supportedLngs: supportedLngs[0] && supportedLngs,
      detection: {
        order: ['querystring', 'localStorage'],
        caches: ['localStorage'],
        lookupQuerystring: 'lang'
      },
      backend: {
        // loadPath: () => {
        //   return (GlobalConstants.isProd  ? `${GlobalConstants.assetsPrefix}/locales/{{lng}}.json`: `/vca-web/locales/{{lng}}.json`);
        // }
        loadPath: `${GlobalConstants.assetsPrefix}/locales/{{lng}}.json`
      }
    });
}
